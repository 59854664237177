import React from "react";
import { Common } from "../../../common/Common";
import { Container, Text } from "../../../react-responsive-utilities/src";

export default function Action({
  minnumberoftickets,
  entryValue,
  date,
  winner,
}: {
  minnumberoftickets: any;
  entryValue: any;
  date: any;
  winner?: any;
}) {
  const isFinished = (new Date(date) < new Date()) || winner;
  return (
    <>
      {!isFinished ? (
        <div className="bg-[#161616] w-full sm:h-[46.9px] h-[56.95px]  grid grid-cols-2 rounded-[55.07px]">
          <div className="flex flex-col justify-center items-center  sm:py-auto py-2">
            <Common.typography
              fontFamily="Sequel100Wide-65"
              fontSize="14px"
              fontSizeSmall="11.53px"
              color="#FFFFFF"
              className="text-center uppercase leading-none"
            >
              Min Tickets:
            </Common.typography>
            <Common.typography
              fontFamily="Sequel100Wide95"
              fontSize="21.3px"
              fontSizeSmall="17.94px"
              color="#FFFFFF"
              className="text-center leading-none"
            >
              {minnumberoftickets}
            </Common.typography>
          </div>
          <div className="border-2 bg-[#DDE404] shadow-lg shadow-black rounded-[55.07px] flex flex-col justify-center sm:py-auto py-2">
            <Common.typography
              className="text-black uppercase leading-none block text-center"
              fontFamily="Sequel100Wide-65"
              fontSize="14px"
              fontSizeSmall="11.51px"
            >
              entry value:
            </Common.typography>
            <Common.typography
              className="text-black leading-none font-[MontBlancBlack] block text-center"
              fontFamily="Sequel100Wide95"
              fontSize="21.3px"
              fontSizeSmall="17.51px"
            >
              ${entryValue}
              {/* $0.99 */}
            </Common.typography>
          </div>
        </div>
      ) : (
        <Container
          height={[34.03, 61.92, 78.69]}
          //width={[0, 135.55 + 32.14, 235 + 55.72]}
          width={[0, "full", "full"]}
          // style={{
          //   marginTop: "clamp(6.91px, 0vw, 12.79px)",
          // }}
          // onClick={(e) => {
          //   e.preventDefault();
          //   handleClick(UID ?? "");
          // }}
          className="flex items-center w-full mt-[-0.3em] p-3 ticketPriceBox rounded-[15px] //hover:cursor-pointer between-200-299:h-5 "
        >
          {/* <h3 className="text-[25px] //sm:text-[12px]  font-['Sequel100Wide'] text-black //text-[#373635] mx-auto  sm:!text-[5vw]">
      FINISHED
    </h3> */}
          <Text
            textSize={[0, 26.24, 33.04]}
            className=" font-['Sequel100Wide'] text-black //text-[#373635] mx-auto"
          >
            FINISHED
          </Text>
        </Container>
      )}
    </>
  );
}
