import React from "react";
import { useWallet } from "../../Contexts/WalletContext";
import { Common } from "../common/Common";
import PopupWithIcon from "../common/PopupWithIcon/PopupWithIcon";

interface LoaderProps {
  message?: string;
  zIndex?: number;
}

const Loader: React.FC<LoaderProps> = ({ message,zIndex }) => {
  const { disconnect } = useWallet();
  return (
    <>
      <PopupWithIcon
      zIndex={zIndex}
        open={true}
        onClose={() => {
          disconnect();
          //setIsConnecting(false);
        }}
        children={
          <>
            <Common.typography
              fontFamily="Sequel100Wide95"
              fontSize="20px"
              fontSizeSmall="20px"
              color="#fff"
              className="uppercase text-center"
            >
              CONNECT A WALLET
            </Common.typography>
            <hr className="max-w-[478.99px] m-auto w-full mt-4  border-[1px] border-[#fff] " />
            <div className="w-full flex flex-col flex-1 gap-4 items-center justify-center xl:py-16 lg:py-16 md:py-16">
              <div className="flex flex-col items-center">
                {/* animate image it to spining */}
                <img
                  className="animate-spin"
                  src={"/Images/Loader.svg"}
                  alt={""}
                />
              </div>
              <Common.typography
                fontFamily="Sequel100Wide95"
                fontSize="22px"
                fontSizeSmall="22px"
                color="#fff"
                className="uppercase text-center"
              >
               {(message)?.toUpperCase()}
              </Common.typography>
            </div>
          </>
        }
      ></PopupWithIcon>
      {/* <div
        className="fixed inset-0 bg-black bg-opacity-80 backdrop-blur-md z-50 flex flex-col items-center justify-center"
        style={{ background: "#2b2b2b" }}
      >
        <div className="w-12 h-12 border-4 border-yellow-500 border-t-transparent rounded-full animate-spin"></div>
        {message && (
          <p className="mt-4 text-yellow-600 text-lg font-semibold">
            {message}
          </p>
        )}
        <button
          onClick={disconnect}
          className="mt-8 bg-yellow-500 hover:bg-yellow-600 text-black font-semibold py-2 px-4 rounded shadow-md focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-opacity-50 transition duration-300"
        >
          Disconnect
        </button>
      </div> */}
    </>
  );
};

export default Loader;
