import { AbiCoder, JsonRpcProvider, ethers, keccak256, toBigInt } from "ethers";
import React, { useContext, useEffect, useMemo } from "react";
import AdvertBox from "../Components/Page Components/AdvertBox";
import PhoneStores from "../Components/Page Components/PhoneStores";
import GenerateButton from "../Components/RandomNumberGenerator/button/GenerateButton";
import Loadmore from "../Components/RandomNumberGenerator/button/Loadmore";
import LoadmoreBig from "../Components/RandomNumberGenerator/button/LoadmoreBig";
import AddressChainlinkContainer from "../Components/RandomNumberGenerator/containers/AddressChainlinkContainer";
import GeneratorContainer from "../Components/RandomNumberGenerator/containers/GeneratorContainer";
import LeftContainer from "../Components/RandomNumberGenerator/containers/LeftContainer";
import RightContainer from "../Components/RandomNumberGenerator/containers/RightContainer";
import ChainlinkHash from "../Components/RandomNumberGenerator/others/ChainlinkHash";
import RangeField from "../Components/RandomNumberGenerator/others/RangeField";
import Table from "../Components/RandomNumberGenerator/others/Table";
import TableSmall from "../Components/RandomNumberGenerator/others/TableSmall";
import Chainlink from "../Components/RandomNumberGenerator/texts/Chainlink";
import ContractAddress from "../Components/RandomNumberGenerator/texts/ContractAddress";
import Description from "../Components/RandomNumberGenerator/texts/Description";
import RecentlyGeneratedNumbers from "../Components/RandomNumberGenerator/texts/RecentlyGeneratedNumbers";
import Title from "../Components/RandomNumberGenerator/texts/Title";
import Value from "../Components/RandomNumberGenerator/texts/Value";
import WhatIsRng from "../Components/RandomNumberGenerator/texts/WhatIsRng";
import VSpacer from "../Components/common/Spacer/VSpacer";
import { baseConfigs, ethereumConfigs } from "../Config/blockchain.config";
import { ErrorContext } from "../Contexts/ErrorContext";
import { useWallet } from "../Contexts/WalletContext";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
import { getRNGData, registerRng } from "../Services/userService";

export interface rngData {
  maxnumber: number;
  minnumber: number;
  numbergenerated: number;
  transactionhash: string;
  walletaddress: string;
}

const getRequestId = (
  keyHash: string,
  sender: string,
  subId: string,
  nonce: number
) => {
  const encodeData = AbiCoder.defaultAbiCoder().encode(
    ["bytes32", "address", "uint256", "uint256"],
    [keyHash, sender, subId, nonce]
  );

  const preSeed = keccak256(encodeData);

  return keccak256(
    AbiCoder.defaultAbiCoder().encode(
      ["bytes32", "bytes32"],
      [keyHash, preSeed]
    )
  );
};

function RandomNumberGeneratorPage() {
  const [rngData, setRngData] = React.useState<rngData[]>([]);
  const [page, setPage] = React.useState(1);
  const [min, setMin] = React.useState(0);
  const [max, setMax] = React.useState(1);
  const [number, setNumber] = React.useState(0);
  const [componentMounted, setComponentMounted] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [trxHash, setTrxHash] = React.useState("");
  const [networkSupported, setNetworkSupported] = React.useState(true);
  const [requestId, setRequestId] = React.useState("");
  const { connector, network, account } = useWallet();
  const { addError } = useContext(ErrorContext) || {};
  const isProduction = process.env.REACT_APP_ENV === "production";

  useEffect(() => {
    setComponentMounted(true);
    return () => {
      setComponentMounted(false);
    };
  }, []);

  useMemo(() => {
    if (!["ethereum", "base"].includes(network ?? ""))
      setNetworkSupported(false);
    if (network !== "ethereum" && !isProduction) setNetworkSupported(false);
    if (network !== "base" && isProduction) setNetworkSupported(false);
  }, [network]);

  async function getRandomNumber({ min, max }: { min: number; max: number }) {
    setLoader(true);
    if (min > max) [min, max] = [max, min];
    try {
      if (network !== "ethereum" && !isProduction)
        throw new Error("Network not supported");

      if (network !== "base" && isProduction)
        throw new Error("Network not supported");

      const { RNG, VRF, RPC } = isProduction ? baseConfigs : ethereumConfigs;
      const { CONTRACT_ADDRESS, DIGITS, ABI } = isProduction
        ? baseConfigs.USDC
        : ethereumConfigs.USDC;

      const value = toBigInt(5 * 10 ** DIGITS);

      // approval of usdt to contract
      const approvalTx = await connector?.callContractMethod(
        CONTRACT_ADDRESS,
        ABI,
        "approve",
        [RNG.CONTRACT_ADDRESS, value],
        true
      );
      await approvalTx.wait();

      // setting min and max to use
      const rangeTx = await connector?.callContractMethod(
        RNG.CONTRACT_ADDRESS,
        RNG.ABI,
        "set_min_max_range",
        [min, max],
        true
      );
      await rangeTx.wait();

      // call dice on contract for random num
      const rollTx = await connector?.callContractMethod(
        RNG.CONTRACT_ADDRESS,
        RNG.ABI,
        "rollDice",
        [account, account],
        true
      );
      const trx = await rollTx.wait();
      console.log(trx.hash, "Transaction hash");

      const provider = new JsonRpcProvider(RPC);
      const contract = new ethers.Contract(
        RNG.CONTRACT_ADDRESS,
        RNG.ABI,
        provider
      );
      const events = await contract.queryFilter(
        "SelectingRandomNumber",
        0,
        "latest"
      );
      const nonce = events.length;

      const _requestId = getRequestId(
        VRF.KEY_HASH,
        RNG.CONTRACT_ADDRESS,
        VRF.SUBSCRIPTION_ID,
        nonce
      );
      console.log(_requestId, "req id");

      setRequestId(_requestId);
      setTrxHash(trx.hash);
    } catch (err) {
      console.log("Error in getting random number: ", err);
      addError?.(`Error in getting random number: ${(err as Error)?.message}`);
      setLoader(false);
    }
  }

  async function handleGetRNGData(body: { page: number; limit: number }) {
    try {
      const response = await getRNGData(body);
      setRngData(rngData?.length === 0 ? response : [...rngData, ...response]);
    } catch (error) {
      console.error("Error fetching RNG data:", error);
      addError?.(`Error fetching RNG data: ${(error as Error)?.message}`);
    }
  }

  useEffect(() => {
    handleGetRNGData({ page: page, limit: 10 });
  }, [page]);

  interface registerChainlinkBody {
    walletaddress: string;
    transactionhash: string;
    minnumber: number;
    maxnumber: number;
    numbergenerated: number;
  }

  const handleRegisterChainlink = async (body: registerChainlinkBody) => {
    const response = await registerRng(body);
    console.log("registerChainlink response: ", response);
  };

  useEffect(() => {
    if (componentMounted) {
      handleRegisterChainlink({
        walletaddress: account ?? "",
        transactionhash: trxHash,
        minnumber: min,
        maxnumber: max,
        numbergenerated: number,
      });
    }
  }, [number]);

  useEffect(() => {
    const { RNG } = isProduction ? baseConfigs : ethereumConfigs;
    if (requestId) {
      if (network === "ethereum" || network === "base") {
        try {
          connector?.listenToContractEvent(
            RNG.CONTRACT_ADDRESS,
            RNG.ABI,
            "RandomNumberSelected",
            (eventData) => {
              try {
                console.log("Transfer event data:", eventData);
                if (eventData.eventData[0] === BigInt(requestId)) {
                  setNumber(
                    Number(toBigInt(eventData.eventData[1]).toString())
                  );
                  setLoader(false);
                  connector?.stopListeningToContractEvent(
                    RNG.CONTRACT_ADDRESS,
                    RNG.ABI,
                    "RandomNumberSelected"
                  );
                }
              } catch (err) {
                console.log("Error in listener: ", err);
                addError?.(`Error in listener: ${(err as Error)?.message}`);
              }
            }
          );
        } catch (err) {
          addError?.(`Error in listener: ${(err as Error)?.message}`);
        }
      }
    }

    return () => {
      if (network === "ethereum" || network === "base") {
        connector?.stopListeningToContractEvent(
          RNG.CONTRACT_ADDRESS,
          RNG.ABI,
          "RandomNumberSelected"
        );
      }
    };
  }, [connector, requestId]);

  return (
    <div className="mt-[26.87px]">
      {/* <Loader show={showLoader} /> */}
      <div className="bg-gradient-to-bl from-transparent via-transparent to-[#360d26] my-0 ">
        <div className="py-0 min-h-[1397px] sm_md_lg:min-h-[0px] sm_md_lg:rounded-none flex flex-col items-center bg-[#1A1A1A] mx-[43px] sm_md_lg:mx-0 sm_md_lg:rounded-0 rounded-[30px] ">
          <VSpacer treatLgAsSmall big={72} small={33} />
          <Title />
          <VSpacer treatLgAsSmall big={49} small={30} />
          <div className="max-w-[1240px] w-full flex flex-col justify-center items-center //sm_md_lg:max-w-[387px] sm_md_lg:px-[21px] px-[clamp(0px,1.5vw,25px)]">
            <GeneratorContainer
              left={
                <LeftContainer>
                  <RangeField
                    onChange={(e) => setMin(parseInt(e.target.value))}
                    value={min}
                    max={max}
                    type="min"
                  />
                  <VSpacer treatLgAsSmall big={18} small={11} />
                  <RangeField
                    onChange={(e) => setMax(parseInt(e.target.value))}
                    value={max}
                    type="max"
                  />
                  <VSpacer treatLgAsSmall big={48} small={15} />
                  {!networkSupported ? (
                    <>
                      <div className="flex justify-center items-center w-full rounded-md shadow-lg">
                        <button
                          className="w-full bg-yellow-400 text-gray-700 font-bold uppercase text-lg py-3 px-6 rounded opacity-60 cursor-not-allowed"
                          style={{ height: "70px" }}
                          disabled
                        >
                          This network is not supported
                        </button>
                      </div>
                    </>
                  ) : (
                    <GenerateButton
                      onClick={() => getRandomNumber({ min, max })}
                    />
                  )}
                </LeftContainer>
              }
              right={
                <div className="relative p-4">
                  {loader && (
                    <div className="absolute inset-0 bg-white/80 flex items-center rounded-[30px] justify-center z-10">
                      {/* Spinner */}
                      <div className="animate-spin rounded-full h-12 w-12 border-4 border-t-transparent border-blue-500"></div>
                    </div>
                  )}
                  <div className="relative" style={{ height: "100%" }}>
                    <RightContainer>
                      <Value number={number} />
                      <ChainlinkHash value={trxHash} />
                    </RightContainer>
                  </div>
                </div>
              }
            />
            <VSpacer treatLgAsSmall big={70} small={40} />
            <WhatIsRng />
            <VSpacer treatLgAsSmall big={39} small={29} />
            <Description />
          </div>
          <VSpacer treatLgAsSmall big={59} small={36} />
          <AddressChainlinkContainer>
            <ContractAddress value="0x568Ba9169598F714386Cfbe941ad94c18ff9E227" />
            <Chainlink value="0xd5D517aBE5cF79B7e95eC98dB0f0277788aFF634" />
          </AddressChainlinkContainer>
          <VSpacer treatLgAsSmall big={69.25} small={0} />
          <Table tableData={rngData} />
          <LoadmoreBig setPage={setPage} />
          <div className="sm_md_lg:hidden block">
            <RecentlyGeneratedNumbers />
            <VSpacer treatLgAsSmall big={0} small={30} />
            <TableSmall tableData={rngData} />
            <VSpacer treatLgAsSmall big={0} small={19} />
            <Loadmore setPage={setPage} />
          </div>
          <VSpacer treatLgAsSmall big={64} small={0} />
        </div>
        <div className="sm_md_lg:block hidden sm_md_lg:rounded-none sm_md_lg:bg-gradient-to-b sm_md_lg:from-[#1D1319] sm_md_lg:to-[#2B0C1E]">
          <VSpacer treatLgAsSmall big={0} small={29} />
          <RecentlyGeneratedNumbers />
          <VSpacer treatLgAsSmall big={0} small={30} />
          <TableSmall tableData={rngData} />
          <VSpacer treatLgAsSmall big={0} small={19} />
          <Loadmore setPage={setPage} />
          <VSpacer treatLgAsSmall big={0} small={19} />
        </div>
        <VSpacer treatLgAsSmall big={44} small={0} />
      </div>

      <AdvertBox />
      <PhoneStores background="bg-gradient-to-r from-[#a51269] to-[#2b2b2b] h-screen " />
    </div>
  );
}

export default WithNavbarAndFooter(RandomNumberGeneratorPage);
