/* eslint-disable @typescript-eslint/no-explicit-any */
import Provider, { EthereumProvider } from "@walletconnect/ethereum-provider";
import {
  BrowserProvider,
  Contract,
  Eip1193Provider,
  ethers,
  parseEther,
  parseUnits,
} from "ethers";
import {
  baseConfigs,
  ethereumConfigs,
} from "../../../Config/blockchain.config";

const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID as string;

// Todo: add typescript method descriptors to control the flow, init should be called before any method invocation
export class WCEthersAdapter {
  private walletState:
    | {
        connected: boolean;
        account: string | null;
        network: string | null;
        providerType: string | null;
      }
    | undefined;
  private provider?: Provider;
  private ethersProvider?: BrowserProvider;
  private intervalMap: Map<string, NodeJS.Timeout>;

  constructor() {
    this.intervalMap = new Map();
  }

  async init(network: string) {
    if (!["ethereum", "base"].includes(network))
      throw new Error("Network not supported");
    const chainId =
      network === "ethereum" ? ethereumConfigs.CHAIN_ID : baseConfigs.CHAIN_ID;
    if (!this.provider || !this.ethersProvider) {
      this.provider = await EthereumProvider.init({
        projectId,
        chains: [chainId],
        methods: [
          "personal_sign",
          "eth_sendTransaction",
          "wallet_switchEthereumChain",
        ],
        showQrModal: true,
        qrModalOptions: {
          themeMode: "light",
        },
        metadata: {
          name: "THEPRIZE",
          description:
            "THE PRIZE - The world's first crypto and luxury raffles platform.",
          url: "https://theprize.io/",
          icons: ["https://theprize.io/logo512.png"],
        },
        rpcMap: {
          [ethereumConfigs.CHAIN_ID]: ethereumConfigs.RPC,
          [baseConfigs.CHAIN_ID]: baseConfigs.RPC,
        },
        relayUrl: "wss://relay.walletconnect.org",
      });
      this.ethersProvider = new BrowserProvider(
        this.provider as Eip1193Provider
      );
      this.provider.on("connect", () => {
        console.log("Connected to account");
      });
      this.walletState = {
        connected: true,
        account: null,
        providerType: null,
        network,
      };
    }
    return this.provider;
  }

  async connect(storedAccount?: string) {
    if (!this.provider || !this.ethersProvider)
      throw new Error("Ethers adapter not initialised");
    try {
      console.log(this.provider.session, "SESSION RESTORED");
      if (this.provider.session && !storedAccount) {
        await this.provider.disconnect();
      }

      if (!this.provider.session) await this.provider!.connect();

      const connectedNetwork = await this.ethersProvider.getNetwork();

      const correctChainId =
        this.walletState?.network === "base"
          ? baseConfigs.CHAIN_ID
          : ethereumConfigs.CHAIN_ID;

      if (Number(connectedNetwork.chainId) !== correctChainId) {
        throw new Error("Please switch to correct network on wallet");
      }

      const connectedAccount = this.provider!.accounts[0].toString();
      this.walletState = {
        connected: true,
        account: connectedAccount,
        network: this.walletState?.network ?? "",
        providerType: "walletconnect",
      };
    } catch (err) {
      throw new Error(err as any);
    }
  }

  async disconnect() {
    if (!this.provider) throw new Error("Ethers adapter not initialised");
    await this.provider!.disconnect();
    this.walletState = undefined;
  }

  async signMessage(message: string) {
    if (!this.provider) throw new Error("Ethers adapter not initialised");
    try {
      if (this.walletState) {
        // Sign a message with the selected account
        const signer = await this.ethersProvider!.getSigner(
          this.provider.accounts[0]
        );
        const signature = await signer.signMessage(message);

        if (signature) {
          return signature;
        } else {
          throw new Error("Failed to generate signature.");
        }
      } else {
        throw new Error("Wallet not initialized");
      }
    } catch (err) {
      throw new Error(err as any);
    }
  }

  getWalletState() {
    return this.walletState;
  }

  async readContractMethod(
    contractAddress: string,
    abi: any[],
    methodName: string,
    args: any[] = []
  ): Promise<any> {
    if (!this.ethersProvider)
      throw new Error("Ethers provider not initialized");
    try {
      const contract = new Contract(contractAddress, abi, this.ethersProvider);
      return await contract[methodName](...args);
    } catch (error) {
      console.error("Error reading contract method:", error);
      throw new Error(error as any);
    }
  }

  async writeContractMethod(
    contractAddress: string,
    abi: any[],
    methodName: string,
    args: any[] = [],
    value: string = "0"
  ): Promise<any> {
    if (!this.ethersProvider)
      throw new Error("Ethers provider not initialized");
    try {
      const signer = await this.ethersProvider.getSigner(
        this.provider!.accounts[0]
      );
      const contract = new Contract(contractAddress, abi, signer);
      const tx = await contract[methodName](...args, {
        value: parseEther(value),
      });
      return tx; // Wait for the transaction to be mined
    } catch (error) {
      console.error("Error writing contract method:", error);
      throw new Error(error as any);
    }
  }

  async sendTransaction(
    to: string,
    value: string,
    data?: string
  ): Promise<any> {
    if (!this.ethersProvider)
      throw new Error("Ethers provider not initialized");
    try {
      const signer = await this.ethersProvider.getSigner(
        this.provider!.accounts[0]
      );

      const tx = {
        to,
        value: parseUnits(value, "ether"),
        data: data || "0x",
      };

      const transaction = await signer.sendTransaction(tx);
      return await transaction.wait(); // Wait for transaction confirmation
    } catch (error) {
      console.error("Error sending transaction:", error);
      throw new Error(error as any);
    }
  }

  async listenToContractEvent(
    contractAddress: string,
    abi: any[],
    eventName: string,
    callback: (eventData: any) => void
  ): Promise<void> {
    try {
      if (!this.walletState || !this.ethersProvider) {
        throw new Error("Wallet not connected or provider not available.");
      }

      if (["ethereum", "base"].includes(this.walletState?.network ?? "")) {
        const provider = this.ethersProvider;
        const contract = new ethers.Contract(contractAddress, abi, provider);

        console.log(
          `Listening to event "${eventName}" on contract ${contractAddress}...`
        );

        // Set up the event listener
        contract.on(eventName, (...args) => {
          const eventData = args.slice(0, args.length - 1); // Exclude the event metadata
          const event = args[args.length - 1]; // Extract event metadata
          console.log(`Event "${eventName}" detected:`, { eventData, event });

          // Invoke the callback with event data
          callback({ eventData, event });
        });

        // Set up the interval as a fallback for missed events
        const intervalId = setInterval(async () => {
          const currentBlock = await provider.getBlockNumber();
          const filter = contract.filters[eventName]();
          const logs = await provider.getLogs({
            topics: await filter.getTopicFilter(),
            fromBlock: Math.max(currentBlock - 10, 0), // Adjust block range as needed
            toBlock: "latest",
          });

          logs?.forEach((log) => {
            const parsedLog = contract.interface.parseLog(log);
            if (parsedLog) {
              // Extract event arguments and convert to array
              const eventData = Object.values(parsedLog.args);

              // Create event metadata similar to contract.on
              const event = {
                blockNumber: log.blockNumber,
                transactionHash: log.transactionHash,
                logIndex: log.index,
                address: log.address,
                data: log.data,
                topics: log.topics,
              };

              console.log(`Event "${eventName}" detected:`, {
                eventData,
                event,
              });

              // Invoke the callback with formatted event data and metadata
              callback({ eventData, event });
            }
          });
        }, 5_000); // Every 5 seconds

        // Store the interval ID in the map
        const key = `${contractAddress}-${eventName}`;
        this.intervalMap.set(key, intervalId);
      }
    } catch (error) {
      console.error(`Error listening to contract event "${eventName}":`, error);
      throw new Error(error as any);
    }
  }

  async stopListeningToContractEvent(
    contractAddress: string,
    abi: any[],
    eventName: string
  ): Promise<void> {
    try {
      if (!this.walletState || !this.ethersProvider) {
        throw new Error("Wallet not connected or provider not available.");
      }

      if (["ethereum", "base"].includes(this.walletState?.network ?? "")) {
        const provider = this.ethersProvider;
        const contract = new ethers.Contract(contractAddress, abi, provider);

        console.log(
          `Stopping listener for event "${eventName}" on contract ${contractAddress}...`
        );

        contract.removeAllListeners(eventName);

        // Clear the interval
        const key = `${contractAddress}-${eventName}`;
        const intervalId = this.intervalMap.get(key);
        if (intervalId) {
          clearInterval(intervalId);
          this.intervalMap.delete(key);
        }
      }
    } catch (error) {
      console.error(
        `Error stopping listener for contract event "${eventName}":`,
        error
      );
      throw new Error(error as any);
    }
  }
}
