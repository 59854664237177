/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import UniversalProvider from "@walletconnect/universal-provider";
import { WalletConnectModal } from "@walletconnect/modal";

export enum BitcoinChains {
  Mainnet = "bip122:000000000019d6689c085ae165831e93",
}

const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID as string;
const events: string[] = [];
const chains = [BitcoinChains.Mainnet];
const methods = ["signMessage"];
const modal = new WalletConnectModal({
  projectId,
  chains,
});
const handleModalOpen = async (uri: string) => {
  await modal.openModal({
    uri,
  });
};

export class WCBitcoinAdapter {
  private walletState:
    | {
        connected: boolean;
        account: string | null;
        network: string | null;
        providerType: string | null;
      }
    | undefined;
  private provider?: UniversalProvider;

  constructor() {}

  async init() {
    if (!this.provider) {
      this.provider = await UniversalProvider.init({
        logger: "error",
        projectId: projectId,
        metadata: {
          name: "THEPRIZE",
          description:
            "THE PRIZE - The world's first crypto and luxury raffles platform.",
          url: "https://theprize.io/",
          icons: ["https://theprize.io/logo512.png"],
        },
      });
      this.provider.removeListener("display_uri", handleModalOpen);
      this.provider.on("display_uri", handleModalOpen);
    }
    return this.provider;
  }

  async connect(storedAccount?: string) {
    if (!this.provider) throw new Error("Bitcoin adapter not initialised");
    try {
      if (this.provider.session && !storedAccount) {
        await this.provider.disconnect();
      }
      if (!this.provider.session)
        await this.provider.connect({
          optionalNamespaces: {
            bip122: {
              methods,
              chains,
              events,
            },
          },
        });

      const connectedAccount =
        this.provider.session?.namespaces.bitcoin?.accounts[0].split(":")[2];

      this.walletState = {
        connected: true,
        account: connectedAccount ?? "",
        network: "bitcoin",
        providerType: "walletconnect",
      };
    } catch (err) {
      console.log(err, "error in connection to Bitcoin");
      throw new Error(err as any);
    }
    modal.closeModal();
  }

  async disconnect() {
    if (!this.provider) throw new Error("Bitcoin adapter not initialised");
    await this.provider.disconnect();
    this.walletState = undefined;
  }

  async signMessage(message: string) {
    if (!this.provider) throw new Error("Bitcoin adapter not initialised");
    if (!this.walletState?.account) throw new Error("Wallet not connected");
    try {
      const result = await this.provider.request<{ signature: string }>({
        method: "bitcoin_signMessage",
        params: {
          address: this.walletState.account,
          message,
        },
      });
      return result.signature;
    } catch (err) {
      console.error(err);
      throw new Error("Error signing message");
    }
  }

  async sendTransaction(to: string, value: string, data?: string) {
    throw new Error("Not Supported");
  }

  getWalletState() {
    return this.walletState;
  }

  async getBalance(): Promise<string> {
    throw new Error("Not Supported");
  }
  async readContractMethod(
    contractAddress: string,
    abi: any[],
    methodName: string,
    args: any[] = []
  ): Promise<any> {
    throw new Error("Not Supported");
  }

  async writeContractMethod(
    contractAddress: string,
    abi: any[],
    methodName: string,
    args: any[] = [],
    value: string = "0"
  ): Promise<any> {
    throw new Error("Not Supported");
  }

  async listenToContractEvent(
    contractAddress: string,
    abi: any[],
    eventName: string,
    callback: (eventData: any) => void
  ): Promise<void> {
    throw new Error("Not Supported");
  }

  async stopListeningToContractEvent(
    contractAddress: string,
    abi: any[],
    eventName: string
  ): Promise<void> {
    throw new Error("Not Supported");
  }
}
