import React from "react";
import axios from "axios";
import { Provider } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import AuthProvider from "./Contexts/AuthContext";
import { WalletProvider } from "./Contexts/WalletContext";
import Routes from "./Routes/Routes";
import "./Styles/Dashboard.css";
import "./Styles/LandingPage.css";
import "./Styles/Slider.css";
import { store } from "./redux/store";
import { ErrorProvider } from "./Contexts/ErrorContext";
import { ModalProvider } from "./Components/Page Components/ClipboardModalContext";

axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;

function App() {
  return (
    <Provider store={store}>
      <ModalProvider>
        <ErrorProvider>
          <AuthProvider>
            <WalletProvider>
              <Routes />
            </WalletProvider>
          </AuthProvider>
        </ErrorProvider>
      </ModalProvider>
    </Provider>
  );
}

export default App;
