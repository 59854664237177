import React, { ReactNode, useEffect, useState, useRef } from "react";
import Button from "../../Button";
import { poundSign } from "../../../Pages/LandingPage";
import RangeSlider from "../../RangeSlider";
import moment from "moment";
import Countdown from "react-countdown";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material";
import { useAuth } from "../../../Contexts/AuthContext";
import Popup from "../../Dashboard Components/Popup";
import {
  getAvailableTickets,
  getInstantWinPrizes,
  joinCompetition,
} from "../../../Services/userService";
import { useNavigate } from "react-router-dom";
import BuyTicketsPopup from "../../Dashboard Components/BuyTicketsPopup";
import { CompetitionImages } from "../Competitions";
import { Text } from "../../react-responsive-utilities/src";
import Counter from "../../common/Counter/Counter";
import VSpacer from "../../common/Spacer/VSpacer";
import Coin from "../../Animated/Coin";
import CounterWithoutSecs from "../../common/Counter/CounterWithoutSeconds";
import ColoredCoin from "../../Animated/ColoredCoin";
import { listindividualcompetition } from "../../../Services/userService";
import { useWallet } from "../../../Contexts/WalletContext";
import Table from "./Table";
import TableSmall from "./TableSmall";
import LoadmoreBig from "../../RandomNumberGenerator/button/LoadmoreBig";
import Loadmore from "../../RandomNumberGenerator/button/Loadmore";
import Entries from "../../common/Entries/Entries";
import { TicketData, TicketResponse } from "../../NumberPicker";
import PrizeCardsContainer from "../../InstantWinCompetitionPage/PrizeCardsContainer/PrizeCardsContainer";
import {
  IPrize,
  IPrizePerPageData,
} from "../../../Pages/InstantWinCompetitionPage";
import PrizeCard from "../../InstantWinCompetitionPage/PrizeCard/PrizeCard";
import { Common } from "../../common/Common";
import Timer from "../../User Pages Components/View Result/Others/Timer";
import { rngaudit } from "../../../Services/userService";
import { copyToClipboard } from "../../../utils/CopyToClipboard";

const progress = (200 / 460) * 100;
// const small_chicken = "/Images/small_yellow_chicken.png";

export interface tableData {
  TXHash: string;
  Min: number;
  Max: number;
  WinningNumber: number;
  Result: string;
}

// const staticTableData: tableData[] = [
//     {
//         TXHash: "ASKCJA28189SQWERTYQWERTYQWERTYQW",
//         Min: 1,
//         Max: 999999999999,
//         WinningNumber: 432329000,
//         Result: "432,329,000",
//     },
//     {
//         TXHash: "ASKCJA28189SQWERTYQWERTYQWERTYQW",
//         Min: 1,
//         Max: 999999999999,
//         WinningNumber: 432329000,
//         Result: "432,329,000",
//     },
//     {
//         TXHash: "ASKCJA28189SQWERTYQWERTYQWERTYQW",
//         Min: 1,
//         Max: 999999999999,
//         WinningNumber: 432329000,
//         Result: "432,329,000",
//     },
// ];

const FinishedCompetitionDetails: React.FC<any> = ({
  getCompetition,
  competition,
  competitionId,
}) => {
  const [prizes, setPrizes] = useState<IPrize[]>([]);
  const [prizesPage, setPrizesPage] = useState(1);
  const [prizeLoading, setPrizeLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(-1);
  const [tablePage, setTablePage] = useState(1);
  const [currentImageIndex] = useState(0);
  const navigate = useNavigate();
  const [value, setValue] = useState(competition?.minnumberoftickets);
  const [buying, setBuying] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [noOfTicket, setNoOfTicket] = useState(0);
  const [competitionImages, setCompetitionImages] = React.useState<
    CompetitionImages[]
  >([]);
  const [trxHash, setTrxHash] = useState("");
  const [transferHash, setTransferHas] = useState("");
  const [winner, setWinner] = useState("");
  const [competitionName, setCompetitionName] = useState("");
  const [competitionInformation, setCompetitionInformation] = useState("");
  const [drawDate, setDrawDate] = useState("");

  const [isInstantWin, setIsInstantWin] = useState(false);

  const [featuredImage, setFeaturedImage] = useState<CompetitionImages>();

  const [tableData, setTableData] = useState<tableData[]>([]);
    // {
    //   TXHash: "0x123456787654321",
    //   Min: 10,
    //   Max: 20,
    //   WinningNumber: 3,
    //   Result: "first",
    // },
    // {
    //   TXHash: "0x123456787654321",
    //   Min: 10,
    //   Max: 20,
    //   WinningNumber: 3,
    //   Result: "first",
    // },
  // ]);

  const sold = (200 / 460) * 100;
  const { user, fetchUser } = useWallet();

  const images = [
    {
      imgSrc: "/Images/coin_btc.png",
    },
    {
      imgSrc: "/Images/coin_btc.png",
    },
    {
      imgSrc: "/Images/coin_btc.png",
    },
    {
      imgSrc: "/Images/coin_btc.png",
    },
    {
      imgSrc: "/Images/coin_btc.png",
    },
    {
      imgSrc: "/Images/coin_btc.png",
    },
    {
      imgSrc: "/Images/coin_btc.png",
    },
    {
      imgSrc: "/Images/coin_btc.png",
    },
  ];

  const handleBackButtonClick = () => {
    navigate("/competitions/all"); // Navigate to the About page
  };

  const getImages = async () => {
    // const images = await getCompetitionImages({
    //   competitionid: competition?.UID ?? "",
    // });
    // console.log(images);
    const images = competition?.imageUrls;
    if (images.length > 0) {
      setFeaturedImage(images[0]);
      setCompetitionImages(images);
    }
  };

  const getInstantPrizes = async () => {
    try {
      if (competitionId) {
        if (totalPages === -1 || prizesPage <= totalPages) {
          setPrizeLoading(true);
          const result: IPrizePerPageData = await getInstantWinPrizes({
            competitionid: competitionId,
            page: prizesPage,
          })
            ?.then((res: any) => {
              setPrizes((prev) => [...prev, ...res.data]);
              setTotalPages(res.totalPages);
              setPrizeLoading(false);
              return res;
            })
            ?.catch((error: any) => {
              console.log(error);
              setPrizeLoading(false);
            })
            ?.finally(() => {
              setPrizeLoading(false);
            });
          setPrizesPage(prizesPage + 1);
        }
      } else {
        throw new Error("Competition ID is undefined");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadMorePrizes = async () => {
    await getInstantPrizes();
    // setDummyPrizes((prev) => [...prev, ...dummyPriizes]);
  };

  useEffect(() => {
    getInstantPrizes();
  }, [competitionId]);

  useEffect(() => {
    if (competition?.UID) {
      getImages();
    }
  }, [competition?.UID]);

  useEffect(() => {
    getlistindividualcompetition({ competitionId: competitionId });
    fetchData({ competitionId: competitionId });
  }, []);

  const chickenSpot = sold - sold / 22;

  const navigateToResult = (competition?: any, UID?: string) => {
    navigate(`/competitionResult/${UID}`);
  };

  const finishedCompetition = (competitionenddate?: Date) => {
    const currentDate = new Date();
    const competitionEndDate = new Date(competitionenddate ?? new Date());
    if (currentDate > competitionEndDate) {
      return true;
    } else {
      return false;
    }
  };

  const Title = ({ title }: { title: string }) => (
    <Text
      textSize={[30, 30.0, 50.0]}
      className="text-[#FFFFFF] font-['Sequel100Wide95'] //leading-[35px] leading-normal font-[60] block sm:text-center md:text-center"
    >
      {title}
    </Text>
  );

  const Description = ({ description }: { description: string }) => (
    <Text
      textSize={[14, 14.0, 19.96]}
      className="
      text-[#FFFFFF] font-['Sequel100WideVF45'] leading-[17px] font-[10] block sm:text-center md:text-center"
    >
      {description}
    </Text>
  );

  const RightContainer = ({ children }: { children: ReactNode }) => (
    <div className=" lg:pt-[20px]   flex flex-col gap-[2em] sm:gap-0 md:gap-0 lg:gap-0    rounded-[30px] //xl:ml-[30px] //xl:mr-[30px] //lg:ml-[5px] //lg:mr-[5px]  //sm:ml-[10px] //sm:mr-[10px] //md:ml-[10px] //md:mr-[10px] m-auto  min-h-[518px] sm:min-h-[317px] md:min-h-[389.9px] bg-[#232323] sm:bg-[rgba(239,0,143,0.0)] *:m-[initial] *:text-left sm:*:m-auto md:*:m-auto sm:*:text-center md:*:text-center relative">
      {children}
    </div>
  );

  // Define the type for the competition detail object
  interface CompetitionDetail {
    label: string;
    value: string;
    isCopyEnabled: boolean;
  }

  // Create an array of competition details

  const timestamp = competition?.competitionenddate;
  const date = new Date(timestamp);

  const textRef = useRef<HTMLParagraphElement>(null);

  const copyToClipboardFn = (rng?: boolean) => {
    if (textRef.current) {
      const text = textRef.current.innerText;
      if (rng) {
        const isProduction = process.env.REACT_APP_ENV === "production";
        let preText;

        if (!isProduction) {
          preText = `https://sepolia.etherscan.io/tx/`;
        } else {
          preText = `https://basescan.org/tx/`;
        }
        copyToClipboard(preText + text);
      } else {
        copyToClipboard(text);
      }
    }
  };

  // Extract day, month, and year and format them as dd-mm-yyyy
  const formattedDate = `${String(date.getDate()).padStart(2, "0")}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${date.getFullYear()}`;

  const fetchData = async ({ competitionId }: { competitionId: string }) => {
    console.log("Function called");
    try {
      const response = await rngaudit({
        competitionid: competitionId,
      });
      console.log("Response RNNG", response, competitionId);
      const data = await response;

      // Map the API response to match the static tableData format
      const mappedData: tableData[] = data.map((item: any) => ({
        TXHash: item.transactionhash,
        Min: item.min,
        Max: item.max,
        WinningNumber: item.winningnumber,
        Result: item.winningnumber.toLocaleString(), // Format the result as a string with commas
      }));

      setTableData(mappedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getlistindividualcompetition = async ({
    competitionId,
  }: {
    competitionId: string;
  }) => {
    const response = await listindividualcompetition({
      competitionid: competitionId,
      // competitionId,
    });

    if (response["data"]["prizetransferhash"] != null) {
      setTransferHas(response["data"]["prizetransferhash"]);
    } else {
      setTransferHas("No Data");
    }
    if (response["data"]["rngtrxhash"]) {
      setTrxHash(response["data"]["rngtrxhash"]);
    } else {
      setTrxHash("No Data");
    }
    if (response["data"]["winner"]) {
      if (winner === "0x0000000000000000000000000000000000") {
        setWinner("NO PURCHASED TICKETS WERE DRAWN");
      } else {
        setWinner(response["data"]["winner"]);
      }
    } else {
      setWinner("No Data");
    }
    if (response["data"]["competitionname"]) {
      setCompetitionName(response["data"]["competitionname"]);
    } else {
      setCompetitionName("No Data");
    }
    if (response["data"]["competitioninformation"]) {
      setCompetitionInformation(response["data"]["competitioninformation"]);
    } else {
      setCompetitionInformation("No Data");
    }
    if (response["data"]["competitionenddate"]) {
      setDrawDate(response["data"]["competitionenddate"]);
      const formattedDrawDate = moment(
        response["data"]["competitionenddate"]
      ).format("YYYY/MM/DD");
      setDrawDate(formattedDrawDate);
    } else {
      setDrawDate("No Data");
    }
    if (response["data"]["instant"]) {
      setIsInstantWin(Boolean(response["data"]["instant"]));
    } else {
      setIsInstantWin(false);
    }
    // if (response?.message === "Successfully joined the competition") {
    //     console.log("Successfully joined the competition");
    //     window.location.reload();
    // } else {
    //     console.log("Failed to buy tickets ");
    // }
  };

  const competitionDetails: CompetitionDetail[] = [
    { label: "Winner", value: winner, isCopyEnabled: false },
    {
      label: "Prize Transfer TX Hash",
      value: transferHash,
      isCopyEnabled: true,
    },
    {
      label: "Blockchain RNG TX Hash",
      value: trxHash,
      isCopyEnabled: true,
    },
  ];
  const [entries, setEntries] = useState<TicketData[]>([]);
  const [active, setActive] = useState(-1);
  const [loading, setLoading] = useState<boolean>(false);

  const [iteration, setIteration] = useState(1);
  const [displayedPageContents, setDisplayedPageContents] = useState<
    TicketResponse[]
  >([]);
  const initialCurrentPageContents: TicketResponse = {
    data: [],
    currentPage: 0,
    pageSize: 0,
    totalItems: 0,
    totalPages: 0,
  };
  const [currentPageContents, setCurrentPageContents] =
    useState<TicketResponse>(initialCurrentPageContents);
  const [items, setItems] = useState<number[]>([]);

  const fetchTickets = async (startFrom: number) => {
    setLoading(true);
    const response1 = await getAvailableTickets(competitionId, {
      page: startFrom,
      limit: 100,
    });

    const response2 = await getAvailableTickets(competitionId, {
      page: startFrom + 1,
      limit: 100,
    });
    const response3 = await getAvailableTickets(competitionId, {
      page: startFrom + 2,
      limit: 100,
    });
    const response4 = await getAvailableTickets(competitionId, {
      page: startFrom + 3,
      limit: 100,
    });
    const response5 = await getAvailableTickets(competitionId, {
      page: startFrom + 4,
      limit: 100,
    });
    setDisplayedPageContents([
      response1.data,
      response2.data,
      response3.data,
      response4.data,
      response5.data,
    ]);
    if (currentPageContents === initialCurrentPageContents)
      setCurrentPageContents(response1?.data);
    setCurrentPageContents(response1?.data);
    setLoading(false);
  };

  // Effect to fetch the initial set of tickets
  useEffect(() => {
    if (iteration === 1) {
      fetchTickets(1);
    } else fetchTickets(5 * iteration - 5);

    console.log("data: ", currentPageContents);
  }, [iteration]);

  // Effect to update entries based on current page data
  useEffect(() => {
    const newEntries = Array.isArray(currentPageContents.data)
      ? currentPageContents.data.map((entry) => {
          return {
            Ticket: entry.Ticket,
            Date: entry.Date === null ? "N/A" : entry.Date,
            Name: entry.Name === null ? "N/A" : entry.Name,
          };
        })
      : [];
    setEntries(newEntries);
  }, [currentPageContents]);

  const isCurrentPage = (page: TicketResponse) => {
    return page === currentPageContents;
  };

  const changePage = (page: TicketResponse) => {
    setCurrentPageContents(page);
  };

  const pageContainer = () => {
    return (
      <div className="flex flex-row flex-wrap gap-y-3 gap-x-2 justify-center ml-[20px] mr-[20px]">
        {Array.isArray(displayedPageContents) &&
          displayedPageContents[0]?.data[0]?.Ticket &&
          displayedPageContents.map((item, index) => (
            <div
              key={index}
              onClick={() => changePage(item)}
              className={`xl:w-[237px] lg:w-[237px] md:w-[237px] sm:w-[207px] min-w-[119px] flex-shrink:1 h-[47px] flex items-center justify-center rounded-[40px] border ${
                isCurrentPage(item) ? "border-[#FFFFFF]" : "border-none"
              } ${isCurrentPage(item) ? "bg-primary" : ""} `}
              style={{
                display: item?.data[0]?.Ticket ? "flex" : "none",
                backgroundColor: !isCurrentPage(item)
                  ? "rgba(255, 255, 255, 0.15)"
                  : undefined,
              }}
            >
              <p
                className={`font-['Sequel100Wide95'] text-[12px] font-normal ${
                  isCurrentPage(item) ? "text-[#280508]" : "text-[#FFFFFF]"
                }`}
                style={{
                  fontSize: "clamp(1px, 2.815621311vw, 14.18px)",
                }}
              >
                {item?.data[0]?.Ticket} -{" "}
                {item?.data[item?.data.length - 1]?.Ticket}
              </p>
            </div>
          ))}
      </div>
    );
  };

  const handleNext = () => {
    if (iteration === currentPageContents?.totalPages) {
      return;
    }
    if (
      displayedPageContents?.indexOf(currentPageContents) + 1 ===
      displayedPageContents?.length
    ) {
      const ispage = displayedPageContents
        ?.map((page) => page.totalPages)
        .includes(currentPageContents.currentPage);
      if (ispage) {
        return;
      }
      setIteration(iteration + 1);
    } else {
      const currentPageIndex =
        displayedPageContents?.indexOf(currentPageContents);
      setCurrentPageContents(displayedPageContents[currentPageIndex + 1]);
    }
  };

  const handlePrev = () => {
    if (iteration === 1) {
      return;
    }
    if (displayedPageContents?.indexOf(currentPageContents) === 0) {
      setIteration(iteration - 1);
    } else {
      const currentPageIndex =
        displayedPageContents?.indexOf(currentPageContents);
      setCurrentPageContents(displayedPageContents[currentPageIndex - 1]);
    }
  };

  return (
    <div className="w-full mx-[43px] ">
      <div className="relative z-[10002] max-w-[1332px] w-[calc(100%-18px)] m-auto p-[40px] bg-[#191919] sm:min-h-[739px] sm:max-w-[394px] sm:w-[calc(100%-18px)] sm:min-h-[539px] rounded-[30px] grid grid-cols-2 sm:grid-cols-1 max-870:!grid-cols-1">
        {" "}
        <div>
          <img
            src={featuredImage?.imageurl ?? "/Images/No Image.svg"}
            alt=""
            className="w-[590px] h-[423px] sm:w-full md:w-full lg:w-full sm:h-full sm:max-h-[344px] md:h-full lg:h-full  rounded-3xl object-cover"
            onError={(e) => {
              e.currentTarget.src = "/Images/No Image.svg";
            }}
          />
        </div>
        <div className="bg-[#191919]">
          <VSpacer big={0} small={29.88} />
          <Title title={competition?.competitionname} />
          <VSpacer big={16} small={9.73} />
          <hr className="max-w-[320.18px] sm:m-auto max-870:m-auto w-full border-b-[5px] border-t-0 border-[#EF008F] sm:block md:block h-[3.14px] rounded-full" />
          <VSpacer big={15} small={10.68} />
          <Description description={competition?.competitioninformation} />
          <VSpacer big={26} small={28} />
          <div className="sm:m-auto w-fit max-870:m-auto">
            {/* <CounterWithoutSecs
              title={"FINISHED DATE"}
              isFinishedCompetition={true}
              competitionEndDate={competition?.competitionenddate}
              key={competition?.UID}
            /> */}
            <Timer competitionEndDate={competition?.competitionenddate} />
          </div>
          <VSpacer big={18.24} small={0} />
          <p
            className="mt-[8px] text-secondary font-['Sequel100Wide95'] text-[14px] font-semibold sm:hidden md:hidden lg:hidden"
            style={{
              fontSize: "clamp(1px, 2.815621311vw, 14.18px)",
            }}
          >
            BUY TICKETS AND SPIN TO WIN!
          </p>
          <VSpacer big={21.6} small={30.43} />
          <Button
            disabled={finishedCompetition(competition?.competitionenddate)}
            // className={`mt-2  xl:h-[61.3px] xl:w-[613px] lg:h-[61.3px] lg:w-[613px] md:h-[61.3px] md:w-[613px] sm:h-[54.08px] sm:max-w-[355px] sm:min-w-0 sm:w-full text-[#1F1F1F] bg-customGray text-2xl font-['Sequel100Wide'] w-[70%] rounded-[13px] mb-7`}
            className="w-full sm:h-[54.08px] h-[61.34px] !rounded-[13.31px] sm:!rounded-[12.55px] !bg-[#414141] font-[Sequel100Wide95]"
            content={"FINISHED"}
            customStyle={{
              fontSize: "clamp(1px,5.361012052vw,30px)",
            }}
            onClick={() => {
              navigateToResult(competition, competition?.UID);
            }}
          />
        </div>
      </div>

      {/* {trxHash != "" && transferHash != "" ? (
                <> */}
      <VSpacer small={20} big={30} />
      <div className="md:items-left md:text-left    md:pr-0 lg:pr-0  md:pl-0 lg:pl-0  max-w-[1332px] w-[calc(100%-18px)] m-auto ">
        <div className="relative overflow-hidden w-full ">
          <RightContainer>
            <div className="px-[max(0vw,53.7px)] sm:px-[max(0vw,15px)] md:px-[max(0vw,15px)] lg:px-[max(0vw,15px)] ">
              <VSpacer small={20} big={30} />

              {competitionDetails.map((detail, index) => (
                <div key={index} className="w-full text-left  ">
                  <div className="text-[#DDE404] font-['Sequel100Wide95'] leading-[30px] xl:text-[28px] lg:text-[25px] sm:text-[18px] md:text-[20px] text-left md:ml-5 lg:ml-5">
                    {detail.label}
                  </div>

                  <div className="sm:h-[8px] lg:h-[8px] xl:h-[2px]"></div>

                  <div className="flex flex-row flex-wrap items-center justify-between text-[#FFFFFF] font-['Sequel100Wide95'] leading-[35px] xl:text-[32px] lg:text-[30px] sm:text-[18px] md:text-[22px] md:ml-5 lg:ml-5 text-left">
                    <div className="xl:w-[70%] lg:w-[70%] md:w-[60%] sm:w-[60%] max-w-[70%] h-auto break-words">
                      <p ref={textRef}>{detail.value}</p>
                    </div>
                    {detail.isCopyEnabled ? (
                      <div
                        style={{ zIndex: 85 }}
                        onClick={() => {
                          copyToClipboardFn(index === 2);
                        }}
                      >
                        <img
                          src="/Images/copy-icon.svg"
                          alt="Copy Icon"
                          className="h-[26px] w-[26px] sm:h-[20px] sm:w-[20px] md:mr-5 lg:ml-5"
                        />
                      </div>
                    ) : // <button onClick={copyToClipboard}>
                    //     Copy Text
                    // </button>
                    null}
                  </div>

                  <div className="sm:h-[8px] lg:h-[40px] xl:h-[10px] md: h-[5px]"></div>

                  {/* <VSpacer small={8}  big={10} /> */}
                </div>
              ))}

              <div
                onClick={handleBackButtonClick}
                className="flex justify-start w-full sm:pr-[360px] xl:mt-[50px] lg:mt-[50px] md:ml-[20px] lg:ml-[20px] max-870:mt-[30px]"
              >
                {" "}
                {/* Flex container for left alignment */}
                <button className="back-button h-[52px]  w-[145px] min-w-[145px] border border-[#DDE404] text-[#DDE404] bg-transparent rounded-[30px] font-bold flex items-center justify-center gap-3.5 md:mb-5 md:ml-5">
                  <img
                    src="/Images/Arrow up-circle.svg"
                    alt="Back Arrow"
                    className="h-[26px] w-[26px]"
                  />
                  <span className="text-[#FFFFFF] font-light font-['Sequel100Wide95'] leading-normal text-[11px]">
                    BACK
                  </span>
                </button>
              </div>
              {/* <VSpacer small={11.96} big={24} /> */}
              {/* <div className="absolute  sm:hidden md:right-[50%] md:top-[50%] lg:-right-[2%] lg:top-[25%] xl:-right-[4%] xl:top-[2%] sm:transform sm:translate-x-1/2 sm:-translate-y-1/2 md:transform md:translate-x-1/2 md:-translate-y-1/2 opacity-[15%] ">
              <ColoredCoin height="1174" width="1174" />
            </div> */}
            </div>
            <div className="h-[126px] w-full relative  sm:hidden">
              <img
                src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
                alt="THEPRIZE Token Logo"
                className="absolute right-0  bottom-0 w-[648px] sm:w-[60%] max-870:w-[60%]  rounded-br-[30px]"
              />
            </div>
          </RightContainer>
        </div>
        <div className="h-[500px] py-8 mt-8 flex flex-col items-center bg-[#1A1A1A] relative z-[1] rounded-[30px] sm_md_lg:hidden">
          <Table tableData={tableData} />
          {tableData.length > 0 ? null : (
            <p className="text-[#FFFFFF] font-['Sequel100Wide95'] leading-[35px] xl:text-[32px] lg:text-[30px] sm:text-[18px] md:text-[22px] text-center mt-5">
              No Data
            </p>
          )}
        </div>
        <div className="overflow-scroll h-[500px] py-8 mt-8  flex flex-col items-center bg-[#1A1A1A] relative z-[1] rounded-[30px]  sm_md_lg:block hidden">
          {/* <RecentlyGeneratedNumbers /> */}
          <VSpacer treatLgAsSmall big={0} small={30} />
          <TableSmall tableData={tableData} />
          <VSpacer treatLgAsSmall big={0} small={19} />
          {/* <Loadmore setPage={setTablePage} /> */}
        </div>
        {isInstantWin && (
          <div className="*:p-0 *:m-0 mt-8">
            <div className="bg-primary h-[184px] flex justify-center items-center w-full">
              <Common.typography
                fontFamily="Sequel100Wide95"
                fontSize="40px"
                fontSizeSmall="25px"
                color="#0000000"
                className="uppercase"
              >
                INSTANT WIN PRIZES
              </Common.typography>
            </div>

            <PrizeCardsContainer onReachedEnd={loadMorePrizes}>
              {prizes?.map((prize: IPrize, index: number) => (
                <PrizeCard
                  description={prize.description}
                  title={prize.prize}
                  image={prize?.imageUrl}
                  key={index}
                  date={new Date("2022-09-09T00:00:00.000Z").toISOString()}
                  winningTicket={prize?.winningTicket || ""}
                  winner={prize?.winningWalletAddress || ""}
                />
              ))}
            </PrizeCardsContainer>
          </div>
        )}
        <div className="mt-8">
          <Entries
            page={currentPageContents.currentPage}
            pageContainer={pageContainer}
            entries={entries}
            iteration={iteration}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        </div>
      </div>

      {/* </>
            ) : (
                <></>
            )} */}
      <VSpacer small={20} big={30} />
    </div>
  );
};

export default FinishedCompetitionDetails;
