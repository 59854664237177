import React from "react";
import { Common } from "../../../common/Common";
import VSpacer from "../../../common/Spacer/VSpacer";

interface WinnerItemProps {
  itemType?: "winner" | "ticket";
  itemValue?: string;
}
function WinnerItem(props: WinnerItemProps) {
  return (
    <div className="flex gap-1 min-w-0">
      <div className="w-fit">
        <Common.typography
          fontFamily="Sequel100WideVF45"
          className="sm_md:!font-[Sequel100WideVF55]  xl:h-auto below-430:h-auto 1529-1542:h-auto h-auto"
          fontSize="11.66px"
          fontSizeSmall="9.66px"
          color="#ffff"
        >
          {props.itemType === "winner" ? "Winner" : "Ticket"}
        </Common.typography>
        <VSpacer big={4.46} small={4.91} />
        <div
          className={`${
            props.itemType === "winner"
              ? "bg-white"
              : "bg-primary border-[1px] border-white"
          } h-[32.07px] flex items-center justify-center px-[10px] xl:px-[14px] sm_md:h-[19.25px]`}
        >
          <Common.typography
            fontFamily="Sequel100Wide95"
            fontSize="15.55px"
            fontSizeSmall="9.66px"
            color="#000000"
            className="truncate max-w-[140px] xl:max-w-[184.65px] min-lg-max-1529:max-w-28 531-595:max-w-20 below-430:max-w-20 1530-1654:max-w-44"
          >
            {/* {(props.itemValue ?? "").length > 8
              ? props.itemValue?.slice(0, 8) + "..."
              : props.itemValue} */}
            {props.itemValue}
          </Common.typography>
        </div>
      </div>
    </div>
  );
}

export default WinnerItem;
