import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Responsive } from "./Components/react-responsive-utilities/src";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Responsive screenWidth={436}>
        {/* !Commenting strick mode because it trigger wallet connection twice */}
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
    </Responsive>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
