import React, { useEffect, useState } from "react";
import AdvertBox from "../Components/Page Components/AdvertBox";
import {
  CompetitionProps,
  Competitions,
} from "../Components/Page Components/Competitions";
//import Hero from "../Components/Page Components/Hero";
import HowItWorksBox, {
  HowItWorksBoxProps,
} from "../Components/Page Components/HowItWorksBox";
import WinnerBox, {
  WinnerBoxProps,
} from "../Components/Page Components/WinnerBox";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
// Import Swiper React components

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import Slider from "../Components/Slider/Slider";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getUserCompetitions } from "../redux/reducers/competitionsSlice";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getPreviousWinners,
  getUserListCompetition,
} from "../Services/userService";
import moment from "moment";
import Button from "../Components/Button";
import PhoneStores from "../Components/Page Components/PhoneStores";
import { Text } from "../Components/react-responsive-utilities/src";
import Categorybar from "../Components/Page Components/Categorybar";
import Carousel from "../Components/common/Carousel/Carousel";
import HowItWorksBoxSmall from "../Components/Page Components/HowItWorksBoxSmall";
import HeroSlider from "../Components/Page Components/Hero/HeroSlider";
import CompetitionCard from "../Components/Page Components/CompetitionCard/CompetitionCard";
import { Loader } from "../Components/common/loader/Loader";
import SoftLaunchPopup from "../Components/others/SoftLaunchPopup";

const enter = "/Images/enter-icon.svg";
const crown = "/Images/crown-icon.svg";
const nuclear = "/Images/nuclear-icon.svg";

export const poundSign = "$";

export const howItWorks: HowItWorksBoxProps[] = [
  {
    icon: enter,
    title: "LOADING YOUR ACCOUNT",
    content: `
1. Simply connect your wallet to create an account. \n
2. Access "Purchase Tickets" in your account section. \n
3. Choose how many tickets you want and follow the wallet prompts. \n
4. Purchase more tickets for larger discounts and prepare for competitions! \n
      `,
  },
  {
    icon: crown,
    title: `ENTERING THE COMPETITIONS`,
    content: `After purchasing tickets, you can enter any of our competitions. Simply browse the selection and head to the competition page. You can choose to enter by lucky dip or select your own numbers! See our <a href="/policies/t&c#targetSection" class="text-blue-500 underline"> terms </a>for free entry details. `,
  },
  {
    icon: nuclear,
    title: "WINNER SELECTION",
    content:
      "Winners are chosen automatically using the provably fair Chainlink VRF when the timer expires or the competition sells out. In your account section provide your Telegram handle and add your wallet addresses for future payouts.",
  },
];

const OPTIONS = { dragFree: true, startIndex: 1, loop: true };

const LandingPage = () => {
  const [competitionsLoaded, setCompetitionsLoaded] = useState<boolean>(false);
  const [winnersLoaded, setWinnersLoaded] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [currentFilter, setCurrentFilter] = useState<any>("");
  const [competitions, setCompetitions] = useState<CompetitionProps[]>([]);

  const [page, setPage] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [winners, setWinners] = useState<WinnerBoxProps[]>([]);

  const [compPages, setCompPages] = useState<number>(0);

  const fetchCompetitions = async (filtering: boolean) => {
    const body = {
      category: currentFilter,
      page: page?.toString(),
      filter: "0",
    };
    try {
      const response = await getUserListCompetition(body);
      setCompPages(response?.totalPages);
      if (filtering) {
        setCompetitions(response?.data);
      } else {
        setCompetitions(
          response?.length > 0
            ? response?.data
            : [...competitions, ...response?.data]
        );
      }
      setCompetitionsLoaded(true);
    } catch (error) {
      console.log(error);
      setCompetitionsLoaded(true);
    }
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    fetchCompetitions(true);
  }, [currentFilter]);

  useEffect(() => {
    fetchCompetitions(false);
  }, [page]);

  useEffect(() => {
    console.log("competitions: ", competitions);
  }, [competitions]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAllPreviousWinners = async () => {
    try {
      const response = await getPreviousWinners();
      setWinners(response);
      setWinnersLoaded(true);
    } catch (error) {
      console.log(error);
      setWinnersLoaded(true);
    }
  };

  useEffect(() => {
    getAllPreviousWinners();
  }, []);
  const [filters, setFilters] = useState<any>([
    { category: "BITCOIN" },
    { category: "CARS & WATCHES" },
    { category: "INSTANT WINS" },
    { category: "HIGH ROLLERS" },
    { category: "NFTS" },
    { category: "ALTCOINS" },
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  // const itemsPerPage = 12;
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [competitionsList, setCompetitionsList] = useState<CompetitionProps[]>(
    []
  );
  const [displayedAllCompetitions, setDisplayedAllCompetitions] =
    useState<CompetitionProps[]>(competitions);
  const dispatch = useAppDispatch();

  const [finishedCompetitions, setFinishedCompetitions] = useState<
    CompetitionProps[]
  >([]);

  useEffect(() => {
    if (window.screen.width < 640) {
      setItemsPerPage(999);
    } else {
      setItemsPerPage(12);
    }
  }, [window.screen.width]);

  function changeFilter(filter: string) {
    setCurrentFilter(filter);
  }

  function loadCompetition(UID?: string) {
    navigate(`/competition/${UID}`);
  }

  function changeSlider(action: string) {
    if (action === "previous") {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    }
    if (action === "next") {
      if (currentIndex < winners.length - 3) {
        setCurrentIndex(currentIndex + 1);
      }
    }
  }

  const handleChangeFilter = (filter: string) => {
    setCurrentFilter(filter);
  };

  useEffect(() => {
    if (currentFilter === "") {
      setDisplayedAllCompetitions(competitions);
    } else {
      setDisplayedAllCompetitions(
        competitions.filter(
          (competition) => competition.category === currentFilter
        )
      );
    }
  }, [competitions, currentFilter]);

  const navigateToResult = (competition?: any, UID?: string) => {
    navigate(`/competitionResult/${UID}`);
  };

  // useEffect(() => {
  //   console.log("loadedApis: ", loadedApis);
  //   if (loadedApis.competitions && loadedApis.winners) {
  //     setShowLoader(false);
  //   }
  // }, [loadedApis]);

  useEffect(() => {
    if (competitionsLoaded && winnersLoaded) {
      setShowLoader(false);
    }
  }, [competitionsLoaded, winnersLoaded]);

  const [showSoftLaunchPopup, setShowSoftLaunchPopup] = useState<boolean>(
    localStorage?.getItem("token") ? false : true
  );

  return (
    <div className="">
      {/* {!showLoader && (
        <SoftLaunchPopup
          isOpen={showSoftLaunchPopup}
          handleConfirm={() => setShowSoftLaunchPopup(false)}
          setIsOpen={setShowSoftLaunchPopup}
        />
      )} */}

      <Loader show={showLoader} />
      <div className="hero p-10 sm:p-[12.61px] md:p-7 sm:pb-0 md:pb-0">
        {/* <Slider2 /> */}
        {/* <Hero /> */}

        <HeroSlider key={competitions?.length} />

        <div className="w-full flex flex-col items-center max-w-[1500px] m-auto ">
          <h1
            className={`pageHeading font-['Sequel100Wide95']`}
            style={{
              fontSize: "clamp(1px,5.529128597vw,45px)",
            }}
          >
            HOW IT WORKS
          </h1>
          {/* <div className="above-1367:scale-[1] flex font-['Sequel100WideVF'] gap-[1em] sm:hidden md:hidden xl:scale-[0.85] lg:scale-[0.80]"> */}
          {/* <div className="grid-cols-8 w-full  place-content-center place-items-center xl:grid lg:grid md:grid hidden">
            {howItWorks.map((item, index) => (
              <>
                <div className="col-span-2">
                  <HowItWorksBox
                    icon={item.icon}
                    title={item.title}
                    content={item.content}
                  />
                </div>
                <div className="flex justify-center items-center col-span-1">
                  {index + 1 !== howItWorks.length && (
                    <img
                      src="/Images/next_white.png"
                      alt=""
                      className="sm:hidden md:hidden"
                    />
                  )}
                </div>
              </>
            ))}
          </div> */}

          <div className="flex container flex-shrink max-w-[100%] sm:hidden max-800:hidden mb-[49.55px]">
            {howItWorks.map((item, index) => (
              <>
                <HowItWorksBox
                  icon={item.icon}
                  title={item.title}
                  content={item.content}
                  key={index}
                />
                {index + 1 !== howItWorks?.length && (
                  <div
                    className="min-w-[5%] flex justify-center items-center"
                    key={index}
                  >
                    <img
                      src="/Images/next_white.png"
                      alt=""
                      className="object-scale-down "
                    />
                  </div>
                )}
              </>
            ))}
          </div>

          {/* how it works mobile */}
          <div className="sm:block max-800:block hidden">
            {/* <Slider slides={howItWorks} options={OPTIONS} /> */}
            <Carousel>
              {howItWorks.map((item, index) => (
                <HowItWorksBoxSmall
                  icon={item.icon}
                  title={item.title}
                  content={item.content}
                  key={index}
                />
              ))}
            </Carousel>
          </div>
        </div>
      </div>

      <div className="competitions p-[2em] sm:pt-[1em] md:pt-[1em] flex flex-col items-center bg-bg_small">
        <h1
          className="flex //text-[40px] font-[Sequel100WideVF] text-primary my-[1em] mt-0 //sm:text-[20px] sm:mb-[0.5em]"
          style={{ fontSize: "clamp(1px,5.361012052vw,45px)" }}
        >
          LIVE COMPETITIONS
        </h1>

        <Categorybar
          filters={filters}
          changeFilter={changeFilter}
          currentFilter={currentFilter}
        />
        {loader && (
          <div className="flex justify-center items-center w-full h-[200px]">
            <CircularProgress />
          </div>
        )}
        {!loader && competitions?.length === 0 && (
          // finishedCompetitions?.length !== 0 &&
          <div className="w-full mt-[5em]">
            <div
              className={`text-[2rem] font-[Sequel100WideVF55] sm:text-[20px] flex flex-col h-[10rem]  relative  w-full sm:w-full rounded-2xl text-center justify-center text-primary`}
            >
              No Competitions in this Category is Found. Please try another
              category.
            </div>
          </div>
        )}
        {/* <div className="grid 1366:scale-[0.97] 1366:w-max grid-cols-4  gap-8 gap-y-10 sm:grid-cols-2 sm:gap-6 sm:mt-[3em] w-full mt-[4em] md:grid-cols-2"> */}
        <div className="xl:grid lg:grid max-1331:grid-cols-2 md:grid-cols-2 between-641-800:grid-cols-1 grid-cols-3 sm:grid-cols-1 lg:grid-cols-2 md:grid hidden max-911:hidden sm:min-w-[100%]  between-200-299:min-w-[100%]   xl:gap-x-[27.59px] xl:gap-y-[53px] gap-x-[13.63px] gap-y-[11.92px] m-auto w-max max-w-[100%] *:m-auto mt-[3em]">
          {!loader &&
            displayedAllCompetitions?.map((competition, index) => (
              <CompetitionCard
                {...competition}
                isHighRoller={competition?.category === "HIGH ROLLERS"}
                onClick={() => loadCompetition(competition?.UID)}
                key={index}
                width={"w-[244px]"}
              />
            ))}
        </div>
        <div className="xl:hidden lg:hidden md:hidden max-911:block block mt-[40.37px]">
          <Carousel
            handleOnLastSlide={() => {
              handleLoadMore();
            }}
          >
            {displayedAllCompetitions?.map((competition, index) => (
              //   <Competitions
              //     {...competition}
              //     isHighRoller={competition?.categroy === "HIGH ROLLERS"}
              //     onClick={() => loadCompetition(competition?.UID)}
              //     key={index}
              //     width={"w-[244px]"}
              //   />
              <CompetitionCard
                {...competition}
                isHighRoller={competition?.category === "HIGH ROLLERS"}
                onClick={() => loadCompetition(competition?.UID)}
                key={index}
                width={"w-[244px]"}
              />
            ))}
          </Carousel>
        </div>
        {compPages > 1 ? (
          <div className="mb-2  sm_md:hidden">
            <Button
              content="Load More"
              onClick={handleLoadMore}
              className="text-black font-[Sequel100Wide95] text-[24px]
         sm:text-[3vw]
         sm:w-[80%]
         uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]"
            />
          </div>
        ) : null}
      </div>

      <div className="p-[2em] //winnerBox winnerContainer  flex flex-col items-center xl:bg-gradient-to-r xl:from-[#bf0d76] xl:to-[#2b2b2b] ">
        <h1
          className="flex //text-[45px] font-[Sequel100Wide] text-white relative mb-[1em] mt-[0.5em]"
          style={{
            fontSize: "clamp(1px,6.52vw,45px)",
          }}
        >
          W
          <span className="flex flex-col justify-start items-start relative">
            <img
              className="absolute -top-1 left-0"
              src="/Images/small_wheel.svg"
            />
            I
          </span>
          NNERS
        </h1>

        <div className=" w-full grid grid-cols-3 sm:hidden md:hidden rounded-t-[20px] overflow-hidden ">
          {winners
            ?.slice(currentIndex, currentIndex + 3)
            .map((winner, index) => (
              <WinnerBox
                competitionprize={winner.competitionprize}
                name={winner.competitionname}
                competitionname={winner.competitionname}
                location={winner.location}
                date={winner.date}
                key={index}
                backgroundImg={winner.promouteWinnerUrl}
                hover={true}
                imageurl={winner.imageurl}
                winnerFont={winner.winnerFont}
                winner={winner.winner}
                rngtrxhash={winner.rngtrxhash}
                prizetransferhash={winner.prizetransferhash}
                instant={winner.instant}
              />
            ))}
        </div>
        <div className="bg-white w-full h-[52px] relative sm:hidden md:hidden rounded-b-3xl">
          <img
            src="/Images/prev_black.png"
            alt=""
            className="absolute left-5  cursor-pointer top-[26%] h-[21px]"
            onClick={() => changeSlider("previous")}
          />
          <img
            src="/Images/next_black.png"
            alt=""
            className="absolute right-5 cursor-pointer top-[26%] h-[21px]"
            onClick={() => changeSlider("next")}
          />
          <div className=" flex items-center gap-2 mx-auto py-[1.7%] h-[2px] w-[230px]">
            <span
              className={`${
                currentIndex === 0 ? "bg-[#1A1A1A]" : "bg-[#E0E0E0]"
              } h-[8px] w-[65px] rounded-full`}
            ></span>
            <span
              className={`${
                currentIndex !== 0 && currentIndex !== winners?.length - 3
                  ? "bg-[#1A1A1A]"
                  : "bg-[#E0E0E0]"
              } h-[8px] w-[65px] rounded-full`}
            ></span>
            <span
              className={`${
                currentIndex === winners?.length - 3
                  ? "bg-[#1A1A1A]"
                  : "bg-[#E0E0E0]"
              } h-[8px] w-[65px] rounded-full`}
            ></span>
          </div>
        </div>

        {/* <WinnerSlider /> */}
        <div className="xl:hidden lg:hidden">
          {/* <Slider slides={dummyWinners} options={OPTIONS} mode="winners" /> */}
          <Carousel>
            {winners.map((winner, index) => (
              <WinnerBox
                instant={winner.instant}
                competitionprize={winner.competitionprize}
                name={winner.competitionname}
                competitionname={winner.competitionname}
                location={winner.location}
                date={winner.date}
                key={index}
                backgroundImg={winner.promouteWinnerUrl}
                hover={true}
                imageurl={winner.imageurl}
                winnerFont={winner.winnerFont}
                winner={winner.winner}
                rngtrxhash={winner.rngtrxhash}
                prizetransferhash={winner.prizetransferhash}
              />
            ))}
          </Carousel>
        </div>
      </div>
      <div>
        <AdvertBox />
      </div>
      {/* <PhoneStores background="bg-gradient-to-r from-[#a51269] to-[#2b2b2b] h-screen " /> */}
      <PhoneStores background="bg-gradient-to-r from-[#a51269] to-[#2b2b2b] h-screen " />
    </div>
  );
};

export default WithNavbarAndFooter(LandingPage);
