import React from "react";
import { Common } from "../../common/Common";
import {
  copyToClipboard,
  copyToClipboardTrx,
} from "../../../utils/CopyToClipboard";
import { useWallet } from "../../../Contexts/WalletContext";

interface TableValueProps {
  copyable?: boolean;
  truncated?: boolean;
  title: string;
  trxHash?: boolean;
}

export default function TableValue(props: TableValueProps) {
  const { network } = useWallet();
  // Truncate logic
  const truncatedTitle =
    props.title.length > 10 ? props.title.slice(0, 10) + "..." : props.title;

  const displayTitle = props.truncated ? truncatedTitle : props.title;

  return (
    <div className="flex justify-center items-center gap-x-[18px]">
      <Common.typography
        fontFamily="Sequel100WideVF55"
        fontSize="22px"
        fontSizeSmall="16px"
        color="#ffffff"
        className="uppercase"
      >
        {displayTitle}
      </Common.typography>
      {props.copyable && (
        <Common.image
          src="/Images/copy-white.svg"
          alt="copy"
          width="24px"
          height="24px"
          heightSmall="24px"
          widthSmall="24px"
          objectFit="contain"
          objectPosition="center"
          onClick={() => {
            if (props.trxHash) {
              const isProduction = process.env.REACT_APP_ENV === "production";
              let preText;

              if (!isProduction) {
                preText = `https://sepolia.etherscan.io/tx/`;
              } else {
                preText = `https://basescan.org/tx/`;
              }
              copyToClipboard(preText + props.title);
            } else {
              copyToClipboard(props.title);
            }
          }}
        />
      )}
    </div>
  );
}
