import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, LinearProgress } from "@mui/material";
import { useRef } from "react";
import Loadmore from "../buttons/Loadmore/Loadmore";
import { copyToClipboardTrx } from "../../../../utils/CopyToClipboard";
import { useWallet } from "../../../../Contexts/WalletContext";
interface OrdersTableProps {
  isFetching: boolean;
  onScrolledToBottom: () => void;
  table: tableData[];
  loadmoreFunction: () => void;
}

const TableHeading = ({ text }: { text: string }) => (
  <th className="font-[Sequel100Wide-65] text-[20px] text-white text-center uppercase text-wrap flex items-center">
    {text}
  </th>
);

const TableData = ({
  text,
  truncate,
  hasCopyButton,
  network,
}: {
  text: string;
  truncate?: boolean;
  hasCopyButton?: boolean;
  network?: string | undefined;
}) => {
  return hasCopyButton ? (
    <td className="font-[Sequel100WideVF45] h-[30px] text-[20px]  w-fit mx-auto flex justify-center">
      <p className="w-fit text-white text-center">
        {truncate
          ? text?.length > 9
            ? `${text.substring(0, 9)}...`
            : text
          : text}
      </p>
      {
        // Copy Button
        hasCopyButton && (
          <IconButton
            onClick={() => {
              // navigator.clipboard.writeText(text);
              copyToClipboardTrx(network ?? "", text);
            }}
            className="text-white"
          >
            <ContentCopyIcon className="text-white" />
          </IconButton>
        )
      }
    </td>
  ) : (
    <td className="font-[Sequel100WideVF45] h-[50px] text-[20px]  w-fit mx-auto flex justify-center">
      <p className="w-fit text-white text-center">
        {truncate
          ? text?.length > 9
            ? `${text.substring(0, 9)}...`
            : text
          : text}
      </p>
    </td>
  );
};
export default function OrdersTable(props: OrdersTableProps) {
  const scroller = useRef<HTMLDivElement | null>(null);
  const handleScroll = () => {
    const div = scroller.current;
    if (div) {
      const isEndReached =
        div.scrollHeight - div.scrollTop === div.clientHeight;
      if (isEndReached) {
        props.onScrolledToBottom();
      }
    }
  };
  return (
    <div
      ref={scroller}
      onScroll={handleScroll}
      className="max-1280:hidden border-2 border-primary rounded-[20px] overflow-auto  w-full  "
    >
      {/* Headings */}
      <div className="grid grid-cols-5 gap-[80px] uppercase place-content-center place-items-center border-b-[2px] border-primary px-[clamp(0px,4vw,38px)] py-[29px]">
        <TableHeading text="Tickets Bought" />
        <TableHeading text="Network" />
        <TableHeading text="TX Hash" />
        <TableHeading text="Date / Time" />
        <TableHeading text="Amount" />
      </div>
      {/* Data */}
      <div className="px-[clamp(0px,4vw,38px)] py-[29px] w-full">
        <div className="scrollbar scrollbar-thumb-secondary scrollbar-track-[#3B3B3B] scrollbar-w-[4px] scrollbar-h-[4px] overflow-auto max-h-[586px] w-full">
          <div className="grid grid-cols-5 gap-x-[80px] ">
            {props.table.map((data) => {
              return (
                <>
                  <TableData text={data.ticketsbought} />
                  <TableData text={data.network} />
                  <TableData
                    hasCopyButton
                    truncate
                    text={data.TransactionHash}
                    network={data.network}
                  />
                  <TableData text={data.DateTime} />
                  <TableData text={data.Amount} />
                </>
              );
            })}
          </div>
          <Loadmore setPage={props?.loadmoreFunction} />
          <LinearProgress
            className={
              "w-full rounded-b-[30px] " +
              (props.isFetching ? "opacity-100" : "opacity-0")
            }
            color="secondary"
          />
        </div>
      </div>
    </div>
  );
}
