import React from "react";
import { Common } from "../../../common/Common";

interface DescriptionProps {
  description: string;
}
export default function Description(props: DescriptionProps) {
  return (
    <Common.typography
      fontFamily="MontBlancRegular"
      fontSize="15.55px"
      fontSizeSmall="11.59px"
      color="#fff"
      className="w-[calc(100%-68.03px)] sm_md:w-[calc(100%-16.42px)]"
    >
      <div
        // style={{
        //   display: "-webkit-box",
        //   WebkitBoxOrient: "vertical",
        //   overflow: "hidden",
        //   textOverflow: "ellipsis",
        //   WebkitLineClamp: 3,
        // }}
        // instead of style do it with classname
        className="line-clamp-3 sm:line-clamp-2 md:line-clamp-2 lg:line-clamp-2 leading-[19px] 1529-1542:line-clamp-2"
      >
        {props.description}
      </div>
    </Common.typography>
  );
}
