interface ChainlinkHashContainerProps {
  children: React.ReactNode;
}
export default function ChainlinkHashContainer(
  props: ChainlinkHashContainerProps
) {
  return (
    <div className="flex justify-center items-center gap-x-[23px] absolute bottom-[41px] //sm_md:bottom-[20.44px] sm_md:relative sm_md:bottom-auto sm_md:py-[20.55px]">
      {props?.children}
    </div>
  );
}
