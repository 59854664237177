import { ReactNode } from "react";
import { getExplorerTrxLink } from "./ExplorerLink";
import { openGlobalModal } from "../Components/Page Components/ClipboardModalContext";

function copyToClipboardFallback(text: string) {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand("copy");
    console.log("Text copied to clipboard");
  } catch (err) {
    console.error("Failed to copy text", err);
  }
  document.body.removeChild(textarea);
}

export const copyToClipboard = (text: string) => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard
      .writeText(text)
      .catch((err) => console.error("Error:", err));
  } else {
    copyToClipboardFallback(text);
  }
  if (openGlobalModal) {
    openGlobalModal({text: "Copied to clipboard" });
  }
};

export const copyToClipboardTrx = (network: string, text: string) => {
  if (network === "BTC") {
    copyToClipboard(text);
    return;
  }
  if (!["solana", "ethereum", "base", "tron"].includes(network)) {
    alert("Network not detected");
    return;
  }
  const trxLink = getExplorerTrxLink(network, text) ?? "";
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard
      .writeText(trxLink)
      .catch((err) => console.error("Error:", err));
  } else {
    copyToClipboardFallback(trxLink);
  }
  alert("Copied to clipboard");
};
