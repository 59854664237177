import React, { useEffect, useState } from "react";
import { FaExclamationTriangle, FaTelegramPlane } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { copyToClipboard } from "../../utils/CopyToClipboard";
import { Common } from "../common/Common";
import { SmallLoaderSVG } from "../common/loader/SmallLoaderSVG";
import PopupWithIcon from "../common/PopupWithIcon/PopupWithIcon";

interface PaymentStatusModalProps {
  isOpen: boolean;
  onClose: () => void;
  paymentStatus: Array<
    | "initiated"
    | "approvalRequest"
    | "approval"
    | "signing"
    | "paymentRequest"
    | "completed"
    | "error"
  >;
  transactionHash?: string;
  errorMessage?: string;
  isApprovalStage?: boolean;
  isSigningStage?: boolean;
  paymentOption?: string;
}

const PaymentStatusModal: React.FC<PaymentStatusModalProps> = ({
  isOpen,
  onClose,
  paymentStatus,
  transactionHash,
  errorMessage,
  isApprovalStage = true,
  isSigningStage = true,
  paymentOption,
}) => {
  const [copiedText, setCopiedText] = useState("");
  const navigate = useNavigate();

  // Ensure useEffect is always called
  useEffect(() => {
    if (paymentStatus?.includes("completed")) {
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        event: "Purchase - Check Out",
        transactionId: "Success-Payment",
      });
    }
  }, [paymentStatus]);

  // Render the component's UI conditionally
  if (!isOpen) {
    return null;
  }

  const getStatusStyle = (currentStatus: string) =>
    paymentStatus.includes(
      currentStatus as
        | "initiated"
        | "approvalRequest"
        | "paymentRequest"
        | "approval"
        | "signing"
        | "completed"
        | "error"
    ) || paymentStatus.includes("completed")
      ? "text-green-500"
      : "text-gray-400";

  function copyToClipboardFallback(text: string) {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand("copy");
      console.log("Text copied to clipboard");
    } catch (err) {
      console.error("Failed to copy text", err);
    }
    document.body.removeChild(textarea);
  }

  // const copyToClipboard = (text: string) => {
  //   if (navigator.clipboard && navigator.clipboard.writeText) {
  //     navigator.clipboard
  //       .writeText(text)
  //       .catch((err) => console.error("Error:", err));
  //     alert("Copied to clipboard");
  //   } else {
  //     copyToClipboardFallback(text);
  //   }
  //   setCopiedText(text);
  //   setTimeout(() => setCopiedText(""), 2000); // Reset the copied state after 2 seconds
  // };

  const TruncatedBox = ({ text, label }: { text?: string; label?: string }) => (
    <div
      className="flex flex-col mt-6 p-3 border-[#fff] border-[1px] text-white text-sm rounded justify-center sm:mx-[20px]"
      style={{ height: "50px" }}
    >
      <div className="flex flex-row justify-between items-center xs:gap-0 gap-4">
        <div
          className=" xs:w-[150px] text-ellipsis overflow-hidden xs:text-[10px] font-[Sequel100WideVF45]"
          style={{
            display: "-webkit-box", // Enables truncation
            WebkitBoxOrient: "vertical", // Allows setting text direction
            WebkitLineClamp: 1, // Limits the lines displayed
            wordBreak: "break-word", // Prevents words from breaking layout
            whiteSpace: "normal", // Avoid single-line restriction
          }}
        >
          {label && <strong>{label}:</strong>} {text}
        </div>
        {/* Only render copy button if there is no error */}
        {label !== "Error" && (
          <button
            onClick={() => copyToClipboard(text ?? "")}
            className="text-yellow-500 hover:text-yellow-300 w-6 h-6"
          >
            <img
              src="/Images/Copy-icon-yellow.svg"
              className="inline-block mr-1"
            />
            {/* {copiedText === text ? "Copied!" : "Copy"} */}
          </button>
        )}
      </div>
    </div>
  );

  const ReturnButton = ({
    text,
    label,
    onClick,
  }: {
    text?: string;
    label?: string;
    onClick?: () => void;
  }) => (
    <div
      onClick={onClick}
      className="flex flex-col sm:mt-2 mt-6 sm:p-2 p-3 border-[#fff] border-[1px] text-white text-sm rounded justify-center sm:mx-[20px]"
      style={{ height: "50px" }}
    >
      <div className="flex flex-row justify-center items-center">
        <div
          className="overflow-hidden text-ellipsis line-clamp-3 font-[Sequel100WideVF45]"
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
          }}
        >
          {label && <strong>{label}:</strong>}
          {text}
        </div>
      </div>
    </div>
  );

  const ViewCompetitionsButton = ({
    text,
    label,
    onClick,
  }: {
    text?: string;
    label?: string;
    onClick?: () => void;
  }) => (
    <div
      onClick={onClick}
      className="flex flex-col sm:mt-2 mt-6 sm:p-2 p-3 bg-[#DDE404] text-white text-sm rounded justify-center sm:mx-[20px]"
      style={{ height: "50px" }}
    >
      <div className="flex flex-row justify-center items-center">
        <div
          className="overflow-hidden text-[#000] text-ellipsis line-clamp-3 font-[Sequel100Wide95] sm:text-[16px] below-430:text-[12px] text-[22px]"
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            textAlign: "center",
          }}
        >
          VIEW COMPETITIONS
        </div>
      </div>
    </div>
  );

  return (
    <PopupWithIcon
      showCloseButton
      open={isOpen}
      onClose={() => {
        onClose();
        window.location.reload();
      }}
      children={
        <>
          <Common.typography
            fontFamily="Sequel100Wide95"
            fontSize="20px"
            fontSizeSmall="20px"
            color="#fff"
            className="uppercase text-center"
          >
            PAYMENT STATUS
          </Common.typography>
          <hr className="max-w-[478.99px] m-auto w-full mt-4 mb-6 border-[1px] border-[#fff] " />
          {!transactionHash ? (
            <>
              <Common.typography
                fontFamily="Sequel100WideVF45"
                fontSize="16px"
                fontSizeSmall="14px"
                color={"#DDE404"}
                className="text-center"
              >
                {!errorMessage &&
                  `Please do not close the browser while payment is being made`.toLocaleUpperCase()}
              </Common.typography>
              {!errorMessage ? (
                <div className="space-y-2 below-430:space-y-2 font-[Sequel100WideVF45] border-[#fff] border-[1px] p-[20px] xl:m-[20px] lg:m-[20px] md:m-[20px] rounded-lg mt-6 below-430:mt-4">
                  {/* Payment Initiated */}
                  <div className="flex items-center gap-4">
                    <div>
                      {paymentStatus.includes("error") &&
                      !paymentStatus.includes("initiated") ? (
                        <FaExclamationTriangle className="w-[20px] h-[20px] text-red-500" />
                      ) : paymentStatus.includes("initiated") ? (
                        <img
                          src="/Images/check_circle.svg"
                          alt="Check"
                          className={`w-[20px] h-[20px] ${getStatusStyle(
                            "initiated"
                          )}`}
                        />
                      ) : (
                        <SmallLoaderSVG className="w-[20px] h-[20px] animate-spin text-gray-500" />
                      )}
                    </div>
                    <div className={`${getStatusStyle("initiated")} text-sm `}>
                      Payment initiated
                    </div>
                  </div>

                  {/* Approval Stage */}
                  {isApprovalStage && (
                    <>
                      {/* Approval Request */}
                      <div className="flex items-center gap-4">
                        <div>
                          {paymentStatus.includes("error") &&
                          !paymentStatus.includes("approvalRequest") ? (
                            <FaExclamationTriangle className="w-[20px] h-[20px] text-red-500" />
                          ) : paymentStatus.includes("approvalRequest") ? (
                            <img
                              src="/Images/check_circle.svg"
                              alt="Check"
                              className={`w-[20px] h-[20px]  ${getStatusStyle(
                                "approvalRequest"
                              )}`}
                            />
                          ) : (
                            <SmallLoaderSVG className="w-[20px] h-[20px] animate-spin text-gray-500" />
                          )}
                        </div>
                        <div
                          className={`${getStatusStyle(
                            "approvalRequest"
                          )} text-sm sm:w-[250px]`}
                        >
                          Sent token approval request to wallet
                        </div>
                      </div>

                      {/* Approval Granted */}
                      <div className="flex items-center gap-4">
                        <div>
                          {paymentStatus.includes("error") &&
                          !paymentStatus.includes("approval") ? (
                            <FaExclamationTriangle className="w-[20px] h-[20px]  text-red-500" />
                          ) : paymentStatus.includes("approval") ? (
                            <img
                              src="/Images/check_circle.svg"
                              alt="Check"
                              className={`w-[20px] h-[20px]  ${getStatusStyle(
                                "approval"
                              )}`}
                            />
                          ) : (
                            <SmallLoaderSVG className="w-[20px] h-[20px] animate-spin text-gray-500" />
                          )}
                        </div>
                        <div
                          className={`${getStatusStyle("approval")} text-sm `}
                        >
                          Token approval granted
                        </div>
                      </div>
                    </>
                  )}

                  {/* Signing Stage */}
                  {isSigningStage && (
                    <div className="flex items-center gap-4">
                      <div>
                        {paymentStatus.includes("error") &&
                        !paymentStatus.includes("signing") ? (
                          <FaExclamationTriangle className="w-[20px] h-[20px]  text-red-500" />
                        ) : paymentStatus.includes("signing") ? (
                          <img
                            src="/Images/check_circle.svg"
                            alt="Check"
                            className={`w-[20px] h-[20px] ${getStatusStyle(
                              "signing"
                            )}`}
                          />
                        ) : (
                          <SmallLoaderSVG className="w-[20px] h-[20px]  animate-spin text-gray-500" />
                        )}
                      </div>
                      <div className={`${getStatusStyle("signing")} text-sm `}>
                        Payment transaction signed
                      </div>
                    </div>
                  )}

                  {/* Payment Request */}
                  <div className="flex items-center gap-4">
                    <div>
                      {paymentStatus.includes("error") &&
                      !paymentStatus.includes("paymentRequest") ? (
                        <FaExclamationTriangle className="w-[20px] h-[20px] text-red-500" />
                      ) : paymentStatus.includes("paymentRequest") ? (
                        <img
                          src="/Images/check_circle.svg"
                          alt="Check"
                          className={`w-[20px] h-[20px] ${getStatusStyle(
                            "paymentRequest"
                          )}`}
                        />
                      ) : (
                        <SmallLoaderSVG className="w-[20px] h-[20px] animate-spin text-gray-500" />
                      )}
                    </div>
                    <div
                      className={`${getStatusStyle("paymentRequest")} text-sm `}
                    >
                      Sent token payment request to wallet
                    </div>
                  </div>

                  {/* Transaction Completed */}
                  <div className="flex items-center gap-4">
                    <div>
                      {paymentStatus.includes("error") &&
                      !paymentStatus.includes("completed") ? (
                        <FaExclamationTriangle className="w-[20px] h-[20px]  text-red-500" />
                      ) : paymentStatus.includes("completed") ? (
                        <img
                          src="/Images/check_circle.svg"
                          alt="Check"
                          className={`w-[20px] h-[20px] ${getStatusStyle(
                            "completed"
                          )}`}
                        />
                      ) : (
                        <SmallLoaderSVG className="w-[20px] h-[20px] animate-spin text-gray-500" />
                      )}
                    </div>
                    <div className={`${getStatusStyle("completed")} text-sm `}>
                      Transaction completed
                    </div>
                  </div>
                </div>
              ) : (
                <div className="font-[Sequel100WideVF45] border-[#EF008F] border-[1px] h-[287px] sm:px-[20px] xl:m-[20px] lg:m-[20px] md:m-[20px]  px-[40px] rounded-lg mt-6">
                  <div className="h-36 flex flex-col items-center gap-4 justify-center">
                    <Common.typography
                      fontFamily="Sequel100Wide95"
                      fontSize="20px"
                      fontSizeSmall="20px"
                      color="#EF008F"
                      className="uppercase text-center"
                    >
                      ERROR
                    </Common.typography>
                    <div className="w-full overflow-scroll">
                      <Common.typography
                        fontFamily="Sequel100WideVF45"
                        fontSize="16px"
                        fontSizeSmall="14px"
                        color={"#FFF"}
                        className="text-center"
                      >
                        {errorMessage?.toLocaleUpperCase()}
                        {/* TRANSACTIONEXPIREDTIMEOUTERROR: TRANSACTION WAS NOT
                        CONFIRMED IN 30.00 SECONDS. IT IS UNKNOWN IF IT
                        SUCCEEDED OR FAILED. CHECK SIGNATURE
                        4IILM8REBVKYUVRRUUCAD3DH4AXXJSOW1SFOQCO8XNS3YODYPE6QBBNGCVD1MG2VJEMW4HCYFWDSIFFIDZF4JDNU
                        USING THE SOLANA EXPLORER OR CLI TOOLS. */}
                      </Common.typography>
                    </div>
                  </div>
                  <ReturnButton text={"RETURN"} onClick={onClose} />
                  <div className="flex flex-row justify-center items-center mt-6">
                    <FaTelegramPlane className="w-[20px] h-[20px] mr-3 text-[#FFF]" />
                    <p
                      className={`cursor-pointer`}
                      onClick={() => {
                        window.open(
                          "https://t.me/ThePrize_Support_bot",
                          "_blank"
                        );
                      }}
                      style={{
                        fontSize: "clamp(1px,2.67vw,17.48px)",
                        textDecoration: "underline",
                        color: "#FFF",
                      }}
                    >
                      {"Community support"}
                    </p>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className=" flex flex-col justify-center font-[Sequel100WideVF45] border-[#DDE404] border-[1px] h-[287px] sm:px-[20px] xl:m-[20px] lg:m-[20px] md:m-[20px]  px-[40px] rounded-lg mt-6">
              <div className="flex flex-col items-center justify-center gap-4">
                <Common.typography
                  fontFamily="Sequel100Wide95"
                  fontSize="20px"
                  fontSizeSmall="20px"
                  color="#DDE404"
                  className="uppercase text-center"
                >
                  SUCCESS!
                </Common.typography>
                <div>
                  <div className="flex flex-row gap-2">
                    <Common.typography
                      fontFamily="Sequel100Wide95"
                      fontSize="20px"
                      fontSizeSmall="20px"
                      color="#fff"
                      className="uppercase text-center"
                    >
                      {paymentOption}
                    </Common.typography>
                    <Common.typography
                      fontFamily="Sequel100WideVF45"
                      fontSize="20px"
                      fontSizeSmall="20px"
                      color="#fff"
                      className="text-center"
                    >
                      Tokens added.
                    </Common.typography>
                  </div>
                  <Common.typography
                    fontFamily="Sequel100WideVF45"
                    fontSize="20px"
                    fontSizeSmall="20px"
                    color="#fff"
                    className="text-center"
                  >
                    Good Luck
                  </Common.typography>
                </div>
              </div>
              <ViewCompetitionsButton
                text={"RETURN"}
                onClick={() => {
                  // navigate("/competitions/all");
                  window.location.href = "/competitions/all";
                }}
              />
              <TruncatedBox text={transactionHash} />
            </div>
          )}

          {/* Transaction Hash or Error Message */}
          {/* {errorMessage ? (
            <TruncatedBox text={errorMessage} label="Error" />
          ) : (
            transactionHash && (
              <TruncatedBox text={transactionHash} label="Transaction Hash" />
            )
          )} */}

          {/* Close Button */}
          {/* <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-400 hover:text-white"
            style={{ width: "50px", fontSize: "30px" }}
          >
            &times;
          </button> */}
        </>
      }
    ></PopupWithIcon>
  );
};

export default PaymentStatusModal;
