import { Common } from "../../common/Common";
import VSpacer from "../../common/Spacer/VSpacer";

export default function Description() {
  return (
    <Common.typography
      fontFamily="MontBlancRegular"
      fontSize="19.5px"
      fontSizeSmall="14px"
      color="#ffffff"
      className="sm_md:text-center"
    >
      <strong>What is RNG?</strong>
      <br />
      Our platform uses our own state-of-the-art Random Number Generator (RNG)
      leveraging blockchain technology to ensure the result of each competition
      is provably fair and unbiased. This system operates independently to
      produce verified, random outcomes every time via{" "}
      {
        <a
          className="underline font-[MontBlancBold]"
          href="https://docs.chain.link/vrf"
          target="_blank"
          rel="noreferrer"
        >
          Chainlink VRF (Verifiable Random Function)
        </a>
      }
      .
      <VSpacer big={33} small={20} />
      To maintain transparency and quality, using the RNG involves a small fee.
      This fee covers the costs associated with sourcing a secure, third-party
      RNG system that meets the highest standards for accuracy and reliability.
      We cover all costs associated with our own draws. However, if you would
      like to use the THEPRIZE.IO RNG yourself, you can do so by connecting your
      wallet and paying the associated fee.
      <VSpacer big={33} small={20} />
      <strong>How it Works</strong>

      <br />
      The RNG operates on the Base Network, offering seamless integration with
      blockchain capabilities. To use the tool:
      <ul className="list-decimal ml-10 mt-2">
        <li>Connect your wallet to the platform.</li>
        <li>
          Pay the required fee in USDC (currently set at $1) and cover standard gas
          fees, as with regular blockchain transactions.
        </li>
        <li>Input a minimum and maximum value for the random number range.</li>
        <li>Confirm the transaction and pay the associated gas fees.</li>
        <li>
          Wait for the system to process your request. This can take over a minute
          due to the time required for the Chainlink subscription to process.
        </li>
        <li>
          Receive your randomly generated number along with the RNG transaction hash
          (TX Hash) for verification.
        </li>
      </ul>
      This streamlined process ensures provable fairness while maintaining the
      highest standards of security and reliability.
    </Common.typography>
  );
}
