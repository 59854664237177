import { copyToClipboard } from "../../../../utils/CopyToClipboard";
import { Common } from "../../../common/Common";

interface IOrderDetails {
  competitionName: string;
  subTotal: string;
  paymentCurrency: string;
  total: string;
  walletAddress: string;
  transactionHash: string;
}

interface IViewOrderTableProps {
  orderNumber: string;
  orderDescription: string;
  orderDetails: IOrderDetails;
}

const Heading = (props: { title: string }) => {
  return (
    <div className="flex w-full justify-between //min-w-[156px]">
      <Common.typography
        className="uppercase text-wrap sm_md:!text-[#fff]"
        fontFamily="Sequel100WideVF45"
        fontSizeSmall="12px"
        fontSize="20px"
        color="#a1a1a1"
      >
        {props.title}
      </Common.typography>
    </div>
  );
};

const Data = (props: { text: string; iscopyable: boolean }) => {
  return (
    <div className="flex w-full justify-between h-[24px] sm_md:justify-end">
      <Common.typography
        className="uppercase truncate sm_md:max-w-[91px]"
        fontFamily="Sequel100WideVF45"
        fontSizeSmall="12px"
        fontSize="20px"
        color="#a1a1a1"
      >
        {props.text}
      </Common.typography>
      {props.iscopyable && (
        <>
          <img
            src="/Images/copy.svg"
            alt="copy"
            className="cursor-pointer size-5"
            onClick={() => {
              // navigator.clipboard.writeText(props.text);
              // alert("Copied to clipboard");
              copyToClipboard(props?.text ?? "")
            }}
          />
        </>
      )}
    </div>
  );
};

export default function ViewOrderTable(props: IViewOrderTableProps) {
  return (
    <div className="flex flex-col border-primary border-[2px] rounded-[30px] w-full">
      <div className="grid grid-cols-1 border-b-[2px] border-primary">
        <div className="w-[calc(100%-44px)] m-auto py-[25px]">
          <Common.typography
            className="uppercase"
            fontFamily="Sequel100Wide85"
            fontSizeSmall="16px"
            fontSize="22px"
            color="#fff"
          >
            {props.orderNumber}
          </Common.typography>
          <Common.typography
            fontFamily="Sequel100Wide46"
            fontSizeSmall="12px"
            fontSize="18px"
            color="#fff"
          >
            {props.orderDescription}
          </Common.typography>
        </div>
      </div>
      <div className="flex justify-between w-[calc(100%-44px)] m-auto  overflow-hidden">
        <div className="grid grid-rows-6 w-full max-w-[327px] py-[20px] border-r-[2px] sm_md:border-none gap-y-5 border-primary">
          <Heading title="Competition:" />
          <Heading title="Subtotal:" />
          <Heading title="Payment Currency:" />
          <Heading title="Total:" />
          <Heading title="Wallet Address:" />
          <Heading title="Transaction Hash:" />
        </div>
        <div className="grid grid-rows-6 //w-full w-full py-[20px] px-[42px] sm_md:px-1 gap-y-5">
          <Data text={props.orderDetails.competitionName} iscopyable={false} />
          <Data text={props.orderDetails.subTotal} iscopyable={false} />
          <Data text={props.orderDetails.paymentCurrency} iscopyable={false} />
          <Data text={props.orderDetails.total} iscopyable={false} />
          <Data text={props.orderDetails.walletAddress} iscopyable={true} />
          <Data text={props.orderDetails.transactionHash} iscopyable={true} />
        </div>
      </div>
    </div>
  );
}
