import { IconButton, MenuItem, Modal, Select } from "@mui/material";
import { Common } from "../../common/Common";
import _ from "lodash";
import { supportedNetworks } from "../../../Config/blockchain.config";
import { useState } from "react";
import PopupWithIcon from "../../common/PopupWithIcon/PopupWithIcon";
import { toPascalCase } from "../../../utils/helpers";

export default function WalletConnectPopup({
  selectedNetwork,
  setSelectedNetwork,
  dynamicWalletList,
  connectWalletFunction,
  showWallet,
}: {
  selectedNetwork: string | null;
  setSelectedNetwork: (value: string | null) => void;
  dynamicWalletList: any;
  connectWalletFunction: any;
  showWallet: boolean;
}) {
  console.log("dyl", dynamicWalletList(selectedNetwork || ""));
  //   const [selectedNetwork, setSelectedNetwork] = useState<string | null>("-1");
  return (
    <PopupWithIcon
      showCloseButton
      open={showWallet}
      onClose={() => {
        connectWalletFunction(false);
        setSelectedNetwork("-1");
      }}
      children={
        <>
          <Common.typography
            fontFamily="Sequel100Wide95"
            fontSize="20px"
            fontSizeSmall="20px"
            color="#fff"
            className="uppercase text-center"
          >
            CONNECT A WALLET
          </Common.typography>
          <hr className="max-w-[478.99px] m-auto w-full mt-4  border-[1px] border-[#fff] " />
          <div className="w-full p-6 flex flex-col items-center justify-center">
            {selectedNetwork === "-1" && (
              <Common.typography
                fontFamily="Sequel100WideVF45"
                fontSize="16px"
                fontSizeSmall="14px"
                color="#fff"
                className="text-center"
              >
                Please ensure you have selected the correct network inside your
                wallet
              </Common.typography>
            )}
          </div>

          <div className="w-full flex flex-col items-center justify-center">
            <Select
              className="w-full max-w-[478.99px]  !font-[Sequel100Wide95] border-[1px] border-[#fff] m-auto"
              variant="outlined"
              color="primary"
              sx={{
                "& .MuiSelect-select": {
                  color: "#fff",
                  paddingLeft: "30px",
                  paddingRight: 0,
                },
                "& .MuiSelect-icon": { color: "#fff", left: "30px" },

                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "yellow",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "yellow",
                },

                borderColor: "#fff",
                borderRadius: "10px",
              }}
              placeholder="Select a network"
              IconComponent={() =>
                selectedNetwork === "-1" ? (
                  <img
                    src="/Images/arrow-down dropdown.svg"
                    alt="Arrow down"
                    className="pr-6 right-0  absolute pointer-events-none"
                  />
                ) : (
                  <img
                    src="/Images/Checkbox.svg"
                    alt="Checkbox"
                    className="right-0 absolute pointer-events-none"
                    //className="p-4"
                  />
                )
              }
              defaultValue="-1"
              value={selectedNetwork}
              onChange={(e) => setSelectedNetwork(e.target.value as string)}
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: "#222", // Dropdown background color
                    "& .MuiMenuItem-root": {
                      color: "#fff", // Text color for list items
                      borderBottom: "1px solid #fff", // Bottom border for each list item
                      "&:last-child": {
                        borderBottom: "none", // Remove border from the last item
                      },
                      "&:hover": {
                        bgcolor: "#DDE404",
                        color: "#000",
                        fontWeight: "bold",
                      },
                      "&.Mui-selected": {
                        bgcolor: "#DDE404", // Background color when selected
                        color: "#000", // Text color when selected
                        fontWeight: "bold", // Bold text when selected
                      },
                    },
                  },
                },
              }}
            >
              <MenuItem
                className="sm:text-[10px]"
                sx={{ display: "none" }}
                value="-1"
              >
                <p>Select a network</p>
              </MenuItem>
              {supportedNetworks?.map((network, key) => (
                <MenuItem
                  className="w-full mt-6 text-[#fff] !font-[Sequel100WideVF45] border-b-[1px] border-[#fff] m-auto bg-inherit"
                  key={key}
                  value={network}
                >
                  {network?.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div
            className={`flex justify-center sm:gap-0 gap-4 mt-6 m-auto w-full items-center`}
          >
            {dynamicWalletList(selectedNetwork || "")?.map(
              // const isPhantomBrowser = windowObj.phantom?.solana?.isPhantom;
              (item: any, index: number) =>
                selectedNetwork != "-1" && (
                  <IconButton
                    onClick={() => {
                      console.log("item", item);

                      // if (item.key === "walletconnect") {
                      //   const windowObj = window as any;
                      //   console.log("windowObj", windowObj);
                      //   const isPhantomBrowser =
                      //     windowObj.phantom?.solana?.isPhantom;
                      //   if (
                      //     isPhantomBrowser &&
                      //     /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
                      //       navigator.userAgent
                      //     )
                      //   ) {
                      //     alert(
                      //       "walletconnect is not supported in Phantom browser"
                      //     );
                      //     return;
                      //   }
                      // }

                      item?.buttuonHandler(
                        item.key,
                        selectedNetwork ?? "ethereum"
                      );
                      connectWalletFunction(false);
                    }}
                    key={index}
                    className="flex flex-col items-center sm:w-[110px] w-[120px]"
                  >
                    <img src={item?.icon} alt={item.key} />
                    <img
                      className={`absolute top-[-5px] right-0`}
                      src={`/Images/${selectedNetwork}-top.svg`}
                      alt={item.key}
                    />
                    <Common.typography
                      fontFamily="Sequel100WideVF45"
                      fontSize="14px"
                      fontSizeSmall="14px"
                      color="#fff"
                      className="text-center mt-4"
                    >
                      {item.label}
                    </Common.typography>
                  </IconButton>
                )
            )}

            {/* <IconButton className="flex flex-col items-center">
        <img src="/Images/phantom.svg" alt="Phantom" />
        <Common.typography
          fontFamily="Sequel100WideVF45"
          fontSize="14px"
          fontSizeSmall="14px"
          color="#fff"
          className="text-center"
        >
          Phantom
        </Common.typography>
      </IconButton>
      {selectedNetwork !== "solana" && (
        <IconButton className="flex flex-col items-center">
          <img src="/Images/metamask.svg" alt="Phantom" />
          <Common.typography
            fontFamily="Sequel100WideVF45"
            fontSize="14px"
            fontSizeSmall="14px"
            color="#fff"
            className="text-center"
          >
            MetaMask
          </Common.typography>
        </IconButton>
      )}

      <IconButton className="flex flex-col items-center">
        <img src="/Images/walletconnect.svg" alt="Phantom" />
        <Common.typography
          fontFamily="Sequel100WideVF45"
          fontSize="14px"
          fontSizeSmall="14px"
          color="#fff"
          className="text-center"
        >
          WalletConnect
        </Common.typography>
      </IconButton> */}
          </div>
          {selectedNetwork !== "-1" && (
            <div className="mt-4 hidden sm:block">
              <Common.typography
                fontFamily="Sequel100WideVF45"
                fontSize="16px"
                fontSizeSmall="14px"
                color="#fff"
                className="text-center"
              >
                The site may reopen in your browser wallet to complete the
                transactions
              </Common.typography>
            </div>
          )}
        </>
      }
    ></PopupWithIcon>
  );
}
