/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

const signConfirm = async (
  walletid: string,
  message: string,
  signature: any,
  network: string
) => {
  try {
    const result = await confirmSignature({
      walletid: walletid,
      message: message,
      signature: signature,
      network,
    });

    if (result.status === 403) throw new Error("Banned");
    if (result.status === 401) throw new Error(`${await result.text()}`);
    if (!result.accessToken) throw new Error("Login failed");

    return result.accessToken;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

const getUserDetails = async (wallet_address: string) => {
  try {
    const response = await axios.get(`/listuserdetails/${wallet_address}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getCompetitionStatus = async (competitionId: string) => {
  try {
    const body = {
      competitionId: competitionId,
    };
    const response = await axios.post(`/competitionstatus`, body);
    console.log("response from staus of competition", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

interface CompetitionListRequestBody {
  category?: string;
  page?: string;
  filter?: string;
}

const getCompetitionById = async (body: any) => {
  try {
    //  body = {
    //     "competitionid":"29103d182fc479df46fefc50efb5c43e"
    // }
    const response = await axios.post(`/listindividualcompetition`, body);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getUserListCompetition = async (body: CompetitionListRequestBody) => {
  try {
    const response = await axios.post("/userlistcompetitions", body);
    console.log("response from getUserListCompetition: ", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

interface JoinCompetitionRequestBody {
  competitionid?: string;
  numberoftickets: number;
  walletaddress: string;
}

const joinCompetition = async (body: JoinCompetitionRequestBody) => {
  try {
    const response = await axios.post("/joinCompetition", body);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getPreviousWinners = async () => {
  try {
    const response = await axios.get("/previouswinners");
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getEnteredCompetition = async () => {
  try {
    const response = await axios.get("/listenteredcompetitions");
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const userTransactions = async (pageNo: number) => {
  try {
    const response = await axios.get(`/accounttransactions?page=${pageNo}`);
    console.log("response from userTransactions: ", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const resetUserPassword = async (
  body: { password: string; confirmPassword: string },
  token?: string | null
) => {
  try {
    const response = await axios.post(
      `/forgotpasswordconfirm?token=${token}`,
      body
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const confirmSignature = async (body: {
  walletid: string;
  message: string;
  signature: string;
  network: string;
}) => {
  try {
    const response = await axios.post(`/confirmsignature`, body);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const editUserDetails = async (body: any) => {
  try {
    const response = await axios.post("/userdetails", body);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getAllAvatars = async () => {
  try {
    const response = await axios.get("/getAvatar");
    console.log("getAllAvatars: ", response.data);
    return response.data;
  } catch (error) {
    console.log("getAllAvatars: ", error);
    throw error;
  }
};

const setAvatar = async (avatarUID: string) => {
  try {
    const response = await axios.patch("/setAvatarInUser", {
      url: avatarUID,
    });
    console.log("setAvatar: ", response.data);
    return response.data;
  } catch (error) {
    console.log("setAvatar: ", error);
    throw error;
  }
};

const editTelegramHandle = async (handle: string) => {
  try {
    const response = await axios.post("/submitTelegram", {
      telegram: handle,
    });
    console.log("editTelegramHandle: ", response.data);
    return response.data;
  } catch (error) {
    console.log("editTelegramHandle: ", error);
    throw error;
  }
};

const getPaymentOptions = async () => {
  try {
    const response = await axios.get("/paymentoptions");
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const submitPayment = async (body: {
  chain: string;
  token: string;
  price: string;
  transactionHash: string;
  uniqueIdentifier: string;
}) => {
  try {
    const response = await axios.post("/submitpayment", body);
    if (response.data?.length === 0) {
      throw new Error("Payment failed");

      return;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const rngaudit = async (body: { competitionid: string }) => {
  try {
    const response = await axios.post(`/rngaudit`, body);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getWallet = async () => {
  try {
    const response = await axios.get("/getwallet");
    console.log("getWallet: ", response.data?.data);
    return response.data?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const setWallet = async (body: any) => {
  try {
    const response = await axios.patch("/submitWallet", body);
    console.log("setWallet: ", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const resetPassword = async (body: { email: string }) => {
  try {
    const response = await axios.post("/forgotpassword", body);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const cancelSubscription = async () => {
  try {
    const response = await axios.get("/cancelsubscription");
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getCompetitionImages = async (body: { competitionid: string }) => {
  try {
    const response = await axios.post("/getimagestream", body);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getAvailableTickets = async (
  competitionId: string,
  body: {
    page: number;
    limit?: number;
  }
) => {
  try {
    const response = await axios.post(
      `/listavailabletickets/${competitionId}`,
      body
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const buySelectedTickets = async (body: {
  competitionid: string;
  tickets: number[];
  walletaddress: string;
}) => {
  try {
    const response = await axios.post("/buyselectedtickets", body);
    console.log("buySelectedTickets, body: ", body);
    console.log("buySelectedTickets: ", response.data);
    return response.data;
  } catch (error) {
    console.log("buySelectedTickets: ", error);
    console.log("buySelectedTickets, body: ", body);
    throw error;
  }
};

const getRNGData = async (body: { page: number; limit: number }) => {
  try {
    const response = await axios.post("/rngdata", body);
    console.log("getRNGData: ", response?.data?.data?.data);
    return response?.data?.data?.data;
  } catch (error) {
    console.log("getRNGData: ", error);
    throw error;
  }
};

const registerRng = async (body: {
  walletaddress: string;
  transactionhash: string;
  minnumber: number;
  maxnumber: number;
  numbergenerated: number;
}) => {
  try {
    const response = await axios.post("/rngregister", {
      walletaddress: body.walletaddress,
      transactionhash: body.transactionhash,
      minnumber: body.minnumber,
      maxnumber: body.maxnumber,
      numbergenerated: body.numbergenerated,
    });
    console.log("registerRng: ", response.data);
    return response.data;
  } catch (error) {
    console.log("registerRng: ", error);
    throw error;
  }
};

const listindividualcompetition = async (body: { competitionid: string }) => {
  try {
    const response = await axios.post("/listindividualcompetition", body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getMyOrdersTicketsData = async (pageNo: number) => {
  try {
    const response = await axios.get(`/ticketsspent?page=${pageNo}`);
    console.log("response from getMyOrdersTicketsData: ", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const submitTelegram = async (body: { telegram: string }) => {
  try {
    const response = await axios.post(`/submitTelegram`, body);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getInstantWinPrizes = async ({
  competitionid,
  page,
}: {
  competitionid: string;
  page: number;
}) => {
  try {
    const response = await axios.post(`/getinstantprizes`, {
      competitionid,
      page,
    });
    console.log("response from getInstantWinPrizes: ", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export {
  signConfirm,
  submitPayment,
  getUserDetails,
  getCompetitionById,
  getUserListCompetition,
  resetPassword,
  getPaymentOptions,
  getEnteredCompetition,
  joinCompetition,
  getPreviousWinners,
  resetUserPassword,
  editUserDetails,
  cancelSubscription,
  getCompetitionImages,
  userTransactions,
  editTelegramHandle,
  getAllAvatars,
  setAvatar,
  getWallet,
  setWallet,
  getAvailableTickets,
  buySelectedTickets,
  getRNGData,
  registerRng,
  listindividualcompetition,
  getMyOrdersTicketsData,
  confirmSignature,
  submitTelegram,
  rngaudit,
  getInstantWinPrizes,
};
