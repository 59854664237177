import React, { createContext, useState, useContext, ReactNode } from "react";
import { Modal, Box, Button } from "@mui/material";

// Types for the context
interface ModalContextType {
  openModal: (props: {
    content?: ReactNode;
    text?: string;
  }) => void | undefined;
  closeModal: () => void;
}


interface ModalProviderProps {
  children: ReactNode;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);


export const useClipboardModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};

let globalOpenModal: (props: {
  content?: ReactNode;
  text?: string;
}) => void | undefined = () => {};
let globalCloseModal: () => void = () => {};

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReactNode>(null);

  const openModal = (props?: { content?: ReactNode; text?: string }) => {
    setModalContent(props?.text);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalContent(null);
  };

  globalOpenModal = openModal;
  globalCloseModal = closeModal;

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <Modal
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[2000]">
          <div className="flex flex-col bg-primary rounded-lg shadow-lg p-6 sm:px-[1.5rem] px-[4.5rem] xl:w-[400px] lg:w-[400px] md:w-[400px] sm:w-[315px] z-10 text-center items-center justify-center">
            <span className="font-[Sequel100WideVF45] text-lg  mb-4 text-center">
              {modalContent}
            </span>
           

            {/* Flex container to align the input and button in one line on XL screens only */}
            <div className="flex xl:flex-row flex-col gap-4 items-center w-[215.38px]">
              <button
                onClick={closeModal}
                className="bg-[#212121]  rounded-[14px] border-2 border-white font-[Sequel100wide] text-white h-[56px] !w-full  sm:w-full lg:w-full"
              >
                {modalContent==="Copied to clipboard" ? "OK" : "CLOSE"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </ModalContext.Provider>
  );
};

export const openGlobalModal = (props: { content?: ReactNode; text?: string } = {}) => {
  globalOpenModal(props);
};

export const closeGlobalModal = () => {
  globalCloseModal();
};
