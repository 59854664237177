import React, { useEffect } from "react";
import { useState } from "react";
import AdvertBox from "../Components/Page Components/AdvertBox";
import FAQ from "../Components/Page Components/FAQ";
import WinnerBox, {
  WinnerBoxProps,
} from "../Components/Page Components/WinnerBox";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
//import { winners } from "./LandingPage";
import { getPreviousWinners } from "../Services/userService";
import Slider from "../Components/Slider/Slider";
import PhoneStores from "../Components/Page Components/PhoneStores";
import Carousel from "../Components/common/Carousel/Carousel";
import { Loader } from "../Components/common/loader/Loader";

const OPTIONS = { dragFree: true, startIndex: 1 };

const WinnersPage = () => {
  const [loadedWinners, setLoadedWinners] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    if (loadedWinners) {
      setShowLoader(false);
    }
  }, [loadedWinners]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [winners, setWinners] = useState<WinnerBoxProps[]>([]);

  const baseUrl = window.location.origin;

  const dummyWinners = [
    {
      itemWon: null,
      name: "Win loads of SOL",
      location: "",
      date: "",
      backgroundImg: `${baseUrl}/Images/Solana.jpg`,
      promouteWinnerUrl: `${baseUrl}/Images/Solana.jpg`,
      hover: true,
      winnerFont: "",
      homePage: true,
      className: "",
      winnerRadius: "",
      competitionprize: "20 x SOL",
      competitionname: "Win loads of SOL",
      imageurl: `${baseUrl}/Images/Solana.jpg`,
      competitionenddate: new Date("2024-08-13T00:00:00.000Z"),
    },
    {
      itemWon: null,
      name: "Win loads of ETH",
      location: "",
      date: "",
      backgroundImg: `${baseUrl}/Images/static1.jpg`,
      promouteWinnerUrl: `${baseUrl}/Images/static1.jpg`,
      hover: true,
      winnerFont: "",
      homePage: true,
      className: "",
      winnerRadius: "",
      competitionprize: "20 x ETH",
      competitionname: "Win loads of ETH",
      imageurl: `${baseUrl}/Images/static1.jpg`,
      competitionenddate: new Date("2024-08-14T00:00:00.000Z"),
    },
    {
      itemWon: null,
      name: "Win BTC",
      location: "",
      date: "",
      backgroundImg: `${baseUrl}/Images/bitcoin-2007769_1280.jpg`,
      promouteWinnerUrl: `${baseUrl}/Images/bitcoin-2007769_1280.jpg`,
      hover: true,
      winnerFont: "",
      homePage: true,
      className: "",
      winnerRadius: "",
      competitionprize: "2 x BTC",
      competitionname: "Win BTC",
      imageurl: `${baseUrl}/Images/bitcoin-2007769_1280.jpg`,
      competitionenddate: new Date("2024-08-11T00:00:00.000Z"),
    },
  ];

  const getAllPreviousWinners = async () => {
    try {
      const response = await getPreviousWinners();
      console.log("response winner", response);

      setWinners(response);
      setLoadedWinners(true);
    } catch (error) {
      console.log(error);
      setLoadedWinners(true);
    }
  };

  useEffect(() => {
    getAllPreviousWinners();
  }, []);

  const homepageWinner: WinnerBoxProps[] = [
    {
      itemWon: "Won The DJI Mini 4 Pro Fly More Combo RC 2",
      name: "Michaela Ralph",
      location: "Hopeman ,Scotland",
      date: "April 19th 2024",
      backgroundImg: "http://localhost:3000/Images/woman.jpg",
      hover: true,
      winnerFont: "text-white ",
    },
    {
      itemWon: "Won The DJI Mini 4 Pro Fly More Combo RC 2",
      name: "Michaela Ralph",
      location: "Hopeman ,Scotland",
      date: "April 19th 2024",
      backgroundImg: "http://localhost:3000/Images/man.png",
      hover: true,
      winnerFont: "text-white ",
    },
    {
      itemWon: "Won The DJI Mini 4 Pro Fly More Combo RC 2",
      name: "Michaela Ralph",
      location: "Hopeman ,Scotland",
      date: "April 19th 2024",
      backgroundImg: "http://localhost:3000/Images/couple.png",
      hover: true,
      winnerFont: "text-white ",
    },
  ];

  function changeSlider(action: string) {
    if (action === "previous") {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    }
    if (action === "next") {
      if (currentIndex < winners.length - 3) {
        setCurrentIndex(currentIndex + 1);
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Loader show={showLoader} />
      <div className="">
        <div className="hero">
          <div className="p-10 pt-0 sm:p-5 md:p-5  ">
            <div className="flex flex-col items-center liveCompetition pt-0 w-full font-[Sequel100Wide95]">
              <h1
                className="pageHeading"
                style={{
                  fontSize: "clamp(1px, 6.527976190476191vw, 45px)",
                }}
              >
                WINNERS
              </h1>
            </div>

            <>
              <div className=" w-full grid grid-cols-3 sm:hidden md:hidden rounded-xl mt-[3em] rounded-t-[20px] overflow-hidden">
                {winners
                  ?.slice(currentIndex, currentIndex + 3)
                  .map((winner, index) => (
                    <WinnerBox
                      instant={winner.instant}
                      competitionprize={winner.competitionprize}
                      competitionenddate={winner.competitionenddate}
                      name={winner.competitionname}
                      competitionname={winner.competitionname}
                      // competitionprize={winner.competitionprize}
                      location={winner.location}
                      date={winner.date}
                      key={index}
                      backgroundImg={winner.promouteWinnerUrl}
                      hover={true}
                      imageurl={winner.imageurl}
                      winnerFont={winner.winnerFont}
                      winner={winner.winner}
                      rngtrxhash={winner.rngtrxhash}
                      prizetransferhash={winner.prizetransferhash}
                    />
                  ))}
              </div>
              <div className="bg-white w-full h-[52px] relative sm:hidden md:hidden rounded-b-3xl">
                <img
                  src="/Images/prev_black.png"
                  alt=""
                  className="absolute left-5  cursor-pointer top-[26%] h-[21px]"
                  onClick={() => changeSlider("previous")}
                />
                <img
                  src="/Images/next_black.png"
                  alt=""
                  className="absolute right-5 cursor-pointer top-[26%] h-[21px]"
                  onClick={() => changeSlider("next")}
                />
                <div className=" flex items-center gap-2 mx-auto py-[1.7%] h-[2px] w-[230px]">
                  {/* <span
                  className={`${
                    currentIndex === 0 ? "bg-[#1A1A1A]" : "bg-[#E0E0E0]"
                  } h-[8px] w-[65px] rounded-full`}
                ></span>
                <span
                  className={`${
                    currentIndex === 1 ? "bg-[#1A1A1A]" : "bg-[#E0E0E0]"
                  } h-[8px] w-[65px] rounded-full`}
                ></span>
                <span
                  className={`${
                    currentIndex === 2 ? "bg-[#1A1A1A]" : "bg-[#E0E0E0]"
                  } h-[8px] w-[65px] rounded-full`}
                ></span> */}
                  <span
                    className={`${
                      currentIndex === 0 ? "bg-[#1A1A1A]" : "bg-[#E0E0E0]"
                    } h-[8px] w-[65px] rounded-full`}
                  ></span>
                  <span
                    className={`${
                      currentIndex !== 0 && currentIndex !== winners.length - 3
                        ? "bg-[#1A1A1A]"
                        : "bg-[#E0E0E0]"
                    } h-[8px] w-[65px] rounded-full`}
                  ></span>
                  <span
                    className={`${
                      currentIndex === winners.length - 3
                        ? "bg-[#1A1A1A]"
                        : "bg-[#E0E0E0]"
                    } h-[8px] w-[65px] rounded-full`}
                  ></span>
                </div>
              </div>
            </>
          </div>
          <div className="xl:hidden lg:hidden ">
            {/* <Slider slides={winners} options={OPTIONS} mode="winners" /> */}
            <div className="xl:hidden lg:hidden">
              {/* <Slider slides={dummyWinners} options={OPTIONS} mode="winners" /> */}
              <Carousel>
                {winners
                  // ?.slice(currentIndex, currentIndex + 3)
                  .map((winner, index) => (
                    <WinnerBox
                      instant={winner.instant}
                      competitionprize={winner.competitionprize}
                      competitionenddate={winner.competitionenddate}
                      name={winner.competitionname}
                      competitionname={winner.competitionname}
                      location={winner.location}
                      date={winner.date}
                      key={index}
                      backgroundImg={winner.promouteWinnerUrl}
                      hover={true}
                      imageurl={winner.imageurl}
                      winnerFont={winner.winnerFont}
                      winner={winner.winner}
                      rngtrxhash={winner.rngtrxhash}
                      prizetransferhash={winner.prizetransferhash}
                    />
                  ))}
              </Carousel>
            </div>
          </div>
          <FAQ className={""} fontSize={"text-[45px] mt-[-0.5em]"} />

          <AdvertBox />

          <PhoneStores background="bg-gradient-to-r from-[#a51269] to-[#2b2b2b] h-screen " />
        </div>
      </div>
    </>
  );
};

export default WithNavbarAndFooter(WinnersPage);
