import { useEffect, useState } from "react";
import WithNonAbsolutedSidebarNavbarFooter from "../../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import VSpacer from "../../../Components/common/Spacer/VSpacer";
import CategoryContainer from "../../../Components/User Pages Components/ViewEntry/Containers/Category/CategoryContainer";
import Category from "../../../Components/User Pages Components/ViewEntry/Others/Category";
import DetailsContainer from "../../../Components/User Pages Components/ViewEntry/Containers/Details/DetailsContainer";
import FirstSection from "../../../Components/User Pages Components/ViewEntry/Containers/Details/FirstSection";
import { Common } from "../../../Components/common/Common";
import Heading from "../../../Components/User Pages Components/ViewEntry/Texts/Heading";
import Title from "../../../Components/User Pages Components/ViewEntry/Texts/Title";
import Description from "../../../Components/User Pages Components/ViewEntry/Texts/Description";
import Timer from "../../../Components/User Pages Components/ViewEntry/Others/Timer";
import ViewBtn from "../../../Components/User Pages Components/ViewEntry/Buttons/ViewBtn";
import SecondSection from "../../../Components/User Pages Components/ViewEntry/Containers/Details/SecondSection";
import TicketSectionText from "../../../Components/User Pages Components/ViewEntry/Texts/TicketSectionText";
import Ticket from "../../../Components/User Pages Components/ViewEntry/Others/Ticket";
import BackBtn from "../../../Components/User Pages Components/ViewEntry/Buttons/BackBtn";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCompetitionById,
  getEnteredCompetition,
} from "../../../Services/userService";
import { Loader } from "../../../Components/common/loader/Loader";

export default function ViewEntry() {
  const [competition, setCompetition] = useState<any>();
  console.log("competition: ", competition);
  const [tickets, setTickets] = useState<any>([]);
  const [entryLoaded, setEntryLoaded] = useState(false);
  const [ticketsLoaded, setTicketsLoaded] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    document.getElementById("view_entry_main")?.scrollIntoView();
  }, []);
  const params = useParams();
  useEffect(() => {
    getCompetitionById({
      competitionid: params["id"],
    }).then((res: any) => {
      setCompetition(res.data);
      setEntryLoaded(true);
    });
  }, []);
  useEffect(() => {
    if (entryLoaded && ticketsLoaded) {
      setShowLoader(false);
    }
  }, [entryLoaded]);
  useEffect(() => {
    getEnteredCompetition().then((res: any) => {
      const filteredComp = res?.data?.filter(
        (comp: any) => comp.UID === params["id"]
      )[0];
      setTickets(filteredComp?.ticketsbought);
      setTicketsLoaded(true);
      console.log("filteredTickets: ", filteredComp?.ticketsbought);
    });
  }, []);
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (competition?.instant) {
      navigate(`/view/competition/instant-win/${competition?.UID}`);
    } else navigate(`/live-competition/${competition?.UID}`);
  };

  const isAuthenticated = (): boolean => {
    const token = localStorage.getItem("token"); // Or your preferred method of storing the token
    return !!token; // Return true if token exists, otherwise false
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/"); // Redirect to the homepage if not authenticated
    }
  }, [navigate]);

  return (
    <WithNonAbsolutedSidebarNavbarFooter
      Component={
        <div
          id="view_entry_main"
          className="overflow-hidden box-border bg-[#181818] w-full sm_md_lg:mx-auto sm_md_lg:w-[calc(100%-20px)]  sm_md_lg:my-[0px] xl:my-8 h-full mx-[43px] rounded-[30px] py-[63px] px-[78px] sm_md:py-[44px] sm_md:px-[11px] flex flex-col items-center justify-center "
        >
          {/* <Loader show={showLoader} /> */}
          <Heading />
          <VSpacer big={60} small={28} />
          <CategoryContainer>
            <Category
              text="Live Competitions"
              isActive={true}
              onClick={() => {
                navigate("/entries/live");
              }}
            />
            <Category
              text="Finished Competitions"
              isActive={false}
              onClick={() => {
                navigate("/entries/finished");
              }}
            />
          </CategoryContainer>
          <VSpacer big={75} small={27.92} />
          <DetailsContainer>
            <FirstSection
              left={
                // <Common.image
                //   src="/Images/leftAvatar.svg"
                //   height="383.06px"
                //   width="393.92px"
                //   heightSmall="317px"
                //   //widthSmall="326px"
                //   widthSmall="100%"
                //   alt="Left Avatar"
                // />
                <Common.image
                  // src="/Images/leftAvatar.svg"
                  src={
                    competition?.imageUrls?.[0]?.imageurl ||
                    "/Images/No Image.svg"
                  }
                  // height="383.06px"
                  // width="393.92px"
                  //height="100%"
                  width="100%"
                  // heightSmall="317px"
                  heightSmall="100%"
                  //widthSmall="326px"
                  widthSmall="100%"
                  className="below-1440:!h-[calc(60%-30px)] below-1024:!h-[calc(100%-30px)] min-1439:!w-[calc(100%-30px)] min-1439:!h-[calc(100%-30px)] min-1439:absolute min-1439:top-0 min-1439:left-0 rounded-[20px] between-1440-1675:!h-[calc(60%-30px)]"
                  alt="Left Avatar"
                />
              }
              right={
                <div className="sm_md:flex sm_md:flex-col sm_md:justify-center sm_md:items-center">
                  <Title text={competition?.competitionname} />
                  <VSpacer big={14.92} small={20.92} />
                  <div className="bg-[#EF008F] h-[4.53px] w-[289.95px] sm_md:w-full" />
                  <VSpacer big={13.58} small={16.55} />
                  <Description text={competition?.competitioninformation} />
                  <VSpacer big={34.1} small={24} />
                  <Timer
                    competitionEndDate={
                      new Date(competition?.competitionenddate)
                    }
                  />
                  <VSpacer big={46.77} small={23} />
                  <ViewBtn onClick={handleNavigation} />
                  <VSpacer big={51.07} small={27.07} />
                </div>
              }
            />
            <hr className="border-primary w-full" />
            <VSpacer big={21} small={18} />
            <TicketSectionText />
            <VSpacer big={21.07} small={19} />
            <SecondSection>
              {tickets.map((ticket: any, index: any) => {
                return <Ticket key={index} text={ticket?.toString()} />;
              })}
            </SecondSection>
            <VSpacer big={28.5} small={23.36} />
            <BackBtn />
          </DetailsContainer>
        </div>
      }
    />
  );
}
