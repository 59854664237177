interface DetailsContainerProps {
  children?: React.ReactNode;
}
export default function DetailsContainer(props: DetailsContainerProps) {
  return (
    <div className="flex flex-col rounded-[30px] py-[39px] px-[37px] sm_md:px-[13px] sm_md:py-[13px] w-full border-[2px] border-[#EF008F]">
      {props.children}
    </div>
  );
}
