/* eslint-disable @typescript-eslint/no-explicit-any */
import EventEmitter from "events";
import { WalletConnector } from "../factories/WalletConnector";
import { WCEthersAdapter } from "./Adapters/WalletConnect/EthersAdapter";
import { WCTronAdapter } from "./Adapters/WalletConnect/TronAdapter";
import { WCSolanaAdapter } from "./Adapters/WalletConnect/SolanaAdapter";
import { signConfirm } from "../Services/userService";
import { PublicKey } from "@solana/web3.js";
import { WCBitcoinAdapter } from "./Adapters/WalletConnect/BtcAdapter";

export class WalletConnectConnector
  extends EventEmitter
  implements WalletConnector
{
  private walletState:
    | {
        connected: boolean;
        account: string | null;
        network: string | null;
        providerType: string | null;
      }
    | undefined;
  private adapter?:
    | WCEthersAdapter
    | WCTronAdapter
    | WCSolanaAdapter
    | WCBitcoinAdapter;

  async authenticate(): Promise<string> {
    try {
      if (this.walletState) {
        // Sign a message with the selected account
        const message = this.walletState.account?.toString() ?? "";
        const signature = await this.adapter!.signMessage(message);

        console.log(signature, "Walletconnect signature");

        // If signature is successful, call your signConfirm function
        const accessToken = await signConfirm(
          this.walletState.account ?? "",
          this.walletState.account ?? "",
          signature,
          this.walletState.network!
        );

        return accessToken;
      } else {
        throw new Error("Wallet not initialized");
      }
    } catch (err) {
      throw new Error(err as any);
    }
  }

  async connect(chain: string, storedAccount?: string): Promise<void> {
    console.log(storedAccount, "Stored Account");
    if (!["ethereum", "base", "tron", "solana", "btc"].includes(chain)) {
      throw new Error("WalletConnect does not support this chain.");
    }
    console.log(`Connecting to ${chain} with WalletConnect...`);

    if (chain === "ethereum" || chain === "base") {
      this.adapter = new WCEthersAdapter();
      await this.adapter.init(chain);
    } else if (chain === "tron") {
      this.adapter = new WCTronAdapter();
      await this.adapter?.init();
    } else if (chain === "solana") {
      this.adapter = new WCSolanaAdapter();
      await this.adapter?.init();
    } else if (chain === "btc") {
      this.adapter = new WCBitcoinAdapter();
      await this.adapter?.init();
    }

    await this.adapter!.connect(storedAccount);
    console.log(`Connected to ${chain} with WalletConnect.`);

    this.walletState = this.adapter!.getWalletState();

    console.log(this.walletState, "wallet state after connection");
    this.emit("stateChange", this.walletState);
  }

  async reconnect(chain: string, account: string): Promise<void> {
    if (!["ethereum", "base", "solana", "tron"].includes(chain)) {
      throw new Error("Wallet Connect does not support this chain.");
    }
    console.log(`Reconnecting to ${chain} with Wallet Connect... ${account}`);
    await this.connect(chain, account);
  }

  async disconnect(): Promise<void> {
    console.log("Disconnected from WalletConnect.");
    await this.adapter!.disconnect();
    this.walletState = undefined;
    this.adapter = undefined;
    this.emit("stateChange", this.walletState);
  }

  async callContractMethod(
    contractAddress: string,
    abi: any[],
    methodName: string,
    args: any[] = [],
    isWrite: boolean = false,
    value: string = "0",
    additionalKeys: {
      pubkey: PublicKey;
      propertyName: string;
    }[] = []
  ): Promise<any> {
    try {
      if (!this.adapter) {
        throw new Error("Adapter is not initialized.");
      }

      // Call the respective adapter's method for contract interaction
      if (isWrite) {
        const tx = await this.adapter.writeContractMethod(
          contractAddress,
          abi,
          methodName,
          args,
          value,
          additionalKeys
        );
        return tx;
      } else {
        return await this.adapter.readContractMethod(
          contractAddress,
          abi,
          methodName,
          args
        );
      }
    } catch (error) {
      console.error("Error calling contract method:", error);
      throw error;
    }
  }

  async sendTransaction(
    to: string,
    value: string,
    data?: string
  ): Promise<any> {
    try {
      if (!this.adapter) {
        throw new Error("Adapter is not initialized.");
      }

      // Create the transaction payload
      const txPayload: any = {
        to,
        value,
        data,
      };

      // Call the adapter's sendTransaction method
      return await this.adapter.sendTransaction(
        txPayload.to,
        txPayload.value,
        txPayload.data
      );
    } catch (error) {
      console.error("Error sending transaction:", error);
      throw new Error(error as any);
    }
  }

  async listenToContractEvent(
    contractAddress: string,
    abi: any[],
    eventName: string,
    callback: (eventData: any) => void
  ): Promise<void> {
    try {
      if (!this.adapter) {
        throw new Error("Adapter is not initialized.");
      }
      if (!["ethereum", "base"].includes(this.walletState?.network ?? "")) {
        throw new Error(
          "Only sepolia and base is supported for events listening"
        );
      }
      return await this.adapter.listenToContractEvent(
        contractAddress,
        abi,
        eventName,
        callback
      );
    } catch (err) {
      console.log(err);
    }
  }

  async stopListeningToContractEvent(
    contractAddress: string,
    abi: any[],
    eventName: string
  ): Promise<void> {
    try {
      if (!this.adapter) {
        throw new Error("Adapter is not initialized.");
      }
      if (!["ethereum", "base"].includes(this.walletState?.network ?? "")) {
        throw new Error(
          "Only sepolia and base is supported for events listening"
        );
      }
      return await this.adapter.stopListeningToContractEvent(
        contractAddress,
        abi,
        eventName
      );
    } catch (err) {
      console.log(err);
    }
  }
}
