import React from "react";
import { ReactNode, createContext, useContext, useState } from "react";

export const ErrorContext = createContext<{
  addError: (message: string) => void;
} | null>(null);

export const ErrorProvider = ({ children }: { children: ReactNode }) => {
  const [errors, setErrors] = useState<string[]>([]);

  const addError = (message: string) => {
    setErrors((prevErrors) => [...prevErrors, message]);
    setTimeout(() => {
      setErrors((prevErrors) => prevErrors.slice(1));
    }, 5000); // Remove error after 5 seconds
  };

  return (
    <ErrorContext.Provider value={{ addError }}>
      {children}
      <div className="fixed bottom-4 right-4 space-y-2 z-50">
        {errors.map((error, index) => (
          <div
            key={index}
            className="bg-red-500 text-white p-3 rounded shadow-md animate-fade-in"
          >
            {error}
          </div>
        ))}
      </div>
    </ErrorContext.Provider>
  );
};

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error("useError must be used within an ErrorProvider.");
  }
  return context;
};
