import moment from "moment";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { userTransactions } from "../../Services/userService";
import { useAppDispatch } from "../../redux/hooks";
import { setSideNavHeight } from "../../redux/reducers/sidebarSlice";

const AccountTransactions = () => {
  const [transactionData, setTransactionData] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(10);

  const fetchTransactions = async (pageNo: number) => {
    console.log("pageNo", pageNo);
    try {
      const response = await userTransactions(pageNo);
      console.log("transactions", response);
      if (response.data.length > 0) {
        const sortedArray = _.orderBy(
          response.data,
          (o: any) => {
            return moment(o.DATE_TIME).format("lll");
          },
          ["desc"]
        );

        console.log("sortedArray", sortedArray);
        setTransactionData(sortedArray);
        setPage(parseInt(response.page));
        setTotalPages(response.totalPages);
        setTotalRecords(response.totalRecords);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTransactions(page);
  }, []);

  return (
    <div className="mt-[5em] //sm:hidden mb-[114px]">
      <h2
        className="//text-[30px] font-bold text-white mx-auto //xl:w-[330px] w-max"
        style={{
          fontSize: "clamp(1px,3.214460512vw,29.39px)",
        }}
      >
        Account Transactions
      </h2>
      <div className="!w-[100%] overflow-x-auto">
        <table className="w-[95%] mx-auto">
          <tr className="text-[22px] text-white border-b-4 border-b-primary  text-center">
            <th>
              <p
                className="p-5 w-max uppercase"
                style={{
                  fontSize: "clamp(1px,3.214460512vw,21.75px)",
                }}
              >
                {/* UID */}
                Product ID
              </p>
            </th>
            <th>
              <p
                className="p-5 w-max uppercase"
                style={{
                  fontSize: "clamp(1px,3.214460512vw,21.75px)",
                }}
              >
                TYPE
              </p>
            </th>
            {/* <th>
              <p
                className="p-5 w-max uppercase"
                style={{
                  fontSize: "clamp(1px,3.214460512vw,21.75px)",
                }}
              >
                USER EMAIL
              </p>
            </th> */}
            <th>
              <p
                className="p-5 w-max uppercase"
                style={{
                  fontSize: "clamp(1px,3.214460512vw,21.75px)",
                }}
              >
                DATE
              </p>
            </th>
            <th>
              <p
                className="p-5 w-max uppercase"
                style={{
                  fontSize: "clamp(1px,3.214460512vw,21.75px)",
                }}
              >
                COST
              </p>
            </th>
            <th>
              <p
                className="p-5 w-max uppercase"
                style={{
                  fontSize: "clamp(1px,3.214460512vw,21.75px)",
                }}
              >
                Competition NAME
              </p>
            </th>
            <th>
              <p
                className="p-5 w-max uppercase"
                style={{
                  fontSize: "clamp(1px,3.214460512vw,21.75px)",
                }}
              >
                STATUS
              </p>
            </th>
          </tr>

          {transactionData.map((transaction: any, index) => (
            <tr className="w-full text-[22px] text-white text-center">
              <td>
                <p
                  className="p-5 w-max uppercase"
                  style={{
                    fontSize: "clamp(1px,3.214460512vw,21.75px)",
                  }}
                >
                  {transaction.UID}
                </p>
              </td>
              <td>
                <p
                  className="p-5 w-max uppercase"
                  style={{
                    fontSize: "clamp(1px,3.214460512vw,21.75px)",
                  }}
                >
                  {transaction.TYPE}
                </p>
              </td>
              {/* <td>
                <p
                  className="p-5 w-max uppercase"
                  style={{
                    fontSize: "clamp(1px,3.214460512vw,21.75px)",
                  }}
                >
                  {transaction.USER_EMAIL}
                </p>
              </td> */}
              <td>
                <p
                  className="p-5 w-max uppercase"
                  style={{
                    fontSize: "clamp(1px,3.214460512vw,21.75px)",
                  }}
                >
                  {moment(transaction.DATE_TIME).format("lll")}
                </p>
              </td>
              <td>
                <p
                  className="p-5 w-max uppercase"
                  style={{
                    fontSize: "clamp(1px,3.214460512vw,21.75px)",
                  }}
                >
                  {transaction.COST}
                </p>
              </td>
              <td>
                <p
                  className="p-5 w-max uppercase"
                  style={{
                    fontSize: "clamp(1px,3.214460512vw,21.75px)",
                  }}
                >
                  {transaction.NAME}
                </p>
              </td>
              <td>
                <p
                  className="p-5 w-max uppercase"
                  style={{
                    fontSize: "clamp(1px,3.214460512vw,21.75px)",
                  }}
                >
                  {transaction.STATUS}
                </p>
              </td>
            </tr>
          ))}
        </table>
      </div>
      {/* Create Next and Previous Buttons with page no in center */}
      <div className="flex items-center justify-center gap-4 mt-5">
        <button
          className={`text-[#232323] ${
            page > 1 ? "bg-primary" : "bg-black"
          } font-[Sequel100Wide95] text-[24px] sm:text-[3vw] sm:w-[80%] uppercase text-center px-[20px] py-[10px]  rounded-[30px]`}
          onClick={() => {
            if (page > 1) {
              //setPage(page - 1);
              fetchTransactions(page - 1);
            }
          }}
        >
          Previous
        </button>
        <div className="bg-black font-[Sequel100WideVF55] p-[10px] w-[80px] rounded-3xl text-center">
          <p className="text-white text-[24px]">{page}</p>
        </div>
        <button
          className={`text-[#232323] ${
            page < totalPages ? "bg-primary" : "bg-black"
          } font-[Sequel100Wide95] text-[24px] sm:text-[3vw] sm:w-[80%] uppercase text-center px-[20px] py-[10px]  rounded-[30px]`}
          onClick={() => {
            if (page < totalPages) {
              //setPage(page + 1);
              fetchTransactions(page + 1);
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};
//1115px
export default AccountTransactions;
