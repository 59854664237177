import React from "react";
import { Common } from "../../../common/Common";

interface TableTitleProps {
  title: string;
}
export default function TableTitle(props: TableTitleProps) {
  return (
    <Common.typography
      fontFamily="Sequel100Wide95"
      fontSize="22px"
      fontSizeSmall="14px"
      color="#ffffff"
      className="uppercase"
    >
      {props.title}
    </Common.typography>
  );
}
