import { useEffect, useState } from "react";
import VSpacer from "../../../Components/common/Spacer/VSpacer";
import WalletField from "../../../Components/User Pages Components/My Wallet/WalletField/WalletField";
import WithNavbarFooterAndSidebar from "../../../Layout/WithNavbarFooterAndSidebar";
import WithNonAbsolutedSidebarNavbarFooter from "../../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import {
  getWallet,
  setWallet as setWalletApi,
} from "../../../Services/userService";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../Components/common/loader/Loader";

export default function MyWallet() {
  const [walletLoaded, setWalletLoaded] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  function convertObjectKeysToCamelCase(obj: any) {
    const newObj: { [key: string]: any } = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const formattedKey =
          key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
        newObj[formattedKey] = obj[key];
      }
    }
    console.log("newObj", newObj);
    return newObj;
  }

  const [wallet, setWallet] = useState<any>({
    Bitcoin: "",
    Ethereum: "",
    Polygon: "",
    Base: "",
    Solana: "",
  });

  const handleGetWallet = async () => {
    try {
      const response = await getWallet();

      const updatedWallet = convertObjectKeysToCamelCase(response);

      setWallet(updatedWallet);
      setWalletLoaded(true);
    } catch (error) {
      console.log(error);
      setWalletLoaded(true);
    }
  };
  useEffect(() => {
    console.log("wallet ", wallet);
  }, [wallet]);

  useEffect(() => {
    if (walletLoaded) {
      setShowLoader(false);
    }
  }, [walletLoaded]);

  useEffect(() => {
    handleGetWallet();
  }, []);

  const handleSave = async (key: string, value: string) => {
    console.log({ [key]: value });
    try {
      setWalletApi({ [key]: value }).then((res) => {
        alert("Wallet Updated Successfully ");
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.getElementById("my_orders_main")?.scrollIntoView();
  }, []);

  const isAuthenticated = (): boolean => {
    const token = localStorage.getItem("token"); // Or your preferred method of storing the token
    return !!token; // Return true if token exists, otherwise false
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/"); // Redirect to the homepage if not authenticated
    }
  }, [navigate]);

  return (
    // Main View
    <WithNonAbsolutedSidebarNavbarFooter
      Component={
        // Main container
        <div className="bg-[#181818] w-full sm_md_lg:mx-auto sm_md_lg:w-[calc(100%-20px)]  sm_md_lg:my-[0px] xl:my-8 h-full mx-[43px] rounded-[30px] py-[63px] px-[78px] sm_md:py-[44px] sm_md:px-[31px] flex flex-col items-center justify-center ">
          {/* <Loader show={showLoader} /> */}
          {/* 1 - Title */}
          <p className="font-[Sequel100Wide95] uppercase text-[45px] sm_md:text-[30px] text-white ">
            My Wallet
          </p>
          {/* 2 - Spacer Title and wallat */}
          <VSpacer small={35} big={55} />
          {/* 3 - Wallet fields */}
          <div className="flex flex-col gap-[16px] w-full">
            {/* 3.1 - bitcoin */}
            <WalletField
              onChange={(value, label) => {
                setWallet({ ...wallet, [label]: value });
              }}
              value={wallet.Bitcoin}
              label="Bitcoin"
              type="text"
              onSave={() => {
                handleSave("Bitcoin", wallet.Bitcoin);
              }}
            />
            {/* 3.2 - Ethereum */}
            <WalletField
              onChange={(value, label) => {
                setWallet({ ...wallet, [label]: value });
              }}
              value={wallet.Ethereum}
              label="Ethereum"
              type="text"
              onSave={() => {
                handleSave("Ethereum", wallet.Ethereum);
              }}
            />
            {/* 3.4 - Solana */}
            <WalletField
              onChange={(value, label) => {
                setWallet({ ...wallet, [label]: value });
              }}
              value={wallet.Solana}
              label="Solana"
              type="text"
              onSave={() => {
                handleSave("Solana", wallet.Solana);
              }}
            />
            {/* 3.5 - Cardano */}
            <WalletField
              onChange={(value, label) => {
                setWallet({ ...wallet, [label]: value });
              }}
              value={wallet.Base}
              label="Base"
              type="text"
              onSave={() => {
                handleSave("Base", wallet.Base);
              }}
            />
            {/* 3.5 - Cardano */}
            <WalletField
              onChange={(value, label) => {
                setWallet({ ...wallet, Polygon: value }); // Always update Polygon
              }}
              value={wallet.Polygon} // Use the Polygon value
              label="TRON" // Display TRON heading
              type="text"
              onSave={() => {
                handleSave("Polygon", wallet.Polygon); // Save to Polygon field
              }}
            />
          </div>
        </div>
      }
    />
  );
}
