import moment from "moment";
import { CompetitionImages, CompetitionProps } from "../Competitions";
import {
  // getCompetitionImages,
  getUserListCompetition,
} from "../../../Services/userService";

export const ticketSold = (
  totalticketsbought?: number,
  competitionticketsize?: number
) => {
  if (totalticketsbought && competitionticketsize) {
    const sold = ((totalticketsbought / competitionticketsize) * 100).toFixed(
      0
    );
    return Number(sold);
  } else {
    return 0;
  }
};

export const getTimeLeft = (competitionenddate?: Date) => {
  const now = moment();
  const end = moment(competitionenddate);
  const duration = moment.duration(end.diff(now));

  if (duration.asSeconds() > 0) {
    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.hours());
    const minutes = Math.floor(duration.minutes());
    const seconds = Math.floor(duration.seconds());

    // Formatting each unit with leading zeros if needed
    const formattedDays = String(days).padStart(2, "0");
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return "00:00:00:00";
  }
};

export const finishedCompetition = (competitionenddate?: Date) => {
  const currentDate = new Date();
  const competitionEndDate = new Date(competitionenddate ?? new Date());
  if (currentDate > competitionEndDate) {
    return true;
  } else {
    return false;
  }
};

const filterThePrizeCompetition = (competitions: CompetitionProps[]) => {
  const filteredCompetitions = competitions?.filter((competition: any) => {
    return [
      "BITCOIN",
      "bitcoin",
      "WEN LAMBO",
      "WATCHES",
      "HIGH ROLLERS",
      "NFTS",
      "ALTCOINS",
    ].includes(competition?.category);
  });
  return filteredCompetitions;
};

export const getCompetitions = async (
  setLoader: any,
  setCompetitionsList: any,
  setSliders: any
) => {
  try {
    setLoader(true);
    const response = await getUserListCompetition({
      category: "",
      page: 1?.toString(),
      filter: "4",
    });
    //const featured = filterThePrizeCompetition(response?.data ?? []);
    console.log("testingfeaturedlog:", response?.data);
    setCompetitionsList(response?.data ?? []);
    setSliders(response?.data ?? []);
    setLoader(false);
  } catch (error) {
    console.log("error", error);
    setLoader(false);
  }
};

export const getImages = async (
  setCompetitionImages: any,
  images: CompetitionImages
) => {
  // const images = await getCompetitionImages({ competitionid: UID ?? "" });
  setCompetitionImages(images);
};

export function loadCompetition(navigate?: any, UID?: string) {
  navigate(`/competition/${UID}`);
}
export const navigateToResult = (navigate?: any, UID?: string) => {
  navigate(`/competitionResult/${UID}`);
};
