import { Text } from "../react-responsive-utilities/src";

export default function Categorybar({
  filters,
  changeFilter,
  currentFilter,
}: {
  filters: any;
  changeFilter: any;
  currentFilter: string;
}) {
  return (
    <div className={"flex w-full max-w-full flex-col gap-y-[17px]"}>
      <button
        onClick={(e) => {
          e.preventDefault();
          changeFilter("");
        }}
        className={`
               ${
                 currentFilter === ""
                   ? "bg-primary font-[Sequel100Wide] text-black border-2 border-white"
                   : " text-white bg-black border-2 border-white"
               }  //w-[345px] grid place-content-center place-items-center w-full max-w-[100%]   font-[Sequel100WideVF55] rounded-full h-[85px] sm:w-full transition-all //text-[20px] //sm:text-[11px] sm:h-[35px] text-center uppercase mx-auto`}
      >
        <p
          style={{
            fontSize: "clamp(1px,2.796204527vw,25.3px)",
          }}
        >
          View All Competitions
        </p>
      </button>
      <div className="w-full m-auto grid grid-cols-3 gap-10 gap-y-4 //w-auto sm:w-full sm:grid-cols-2 sm:gap-2">
        {/* <button className="pointer-events-none xl:block hidden" />
        <button
          onClick={(e) => {
            e.preventDefault();
            changeFilter("");
          }}
          className={`
               ${
                 currentFilter === ""
                   ? "bg-primary font-[Sequel100Wide] text-black border-2 border-white"
                   : " text-white bg-black border-2 border-white"
               }  //w-[345px] w-full max-w-[100%] xl:block hidden font-[Sequel100WideVF55] rounded-full h-[85px] sm:w-full transition-all //text-[20px] //sm:text-[11px] sm:h-[35px] text-center uppercase mx-auto`}
        >
          <p
            style={{
              fontSize: "clamp(1px,2.796204527vw,25.3px)",
            }}
          >
            View All Competitions
          </p>
        </button>
        <button className="pointer-events-none xl:block hidden" /> */}
        {filters.map((filter: any, index: number) => (
          <button
            key={index}
            onClick={(e) => {
              e.preventDefault();
              changeFilter(filter.category);
            }}
            className={`${
              filter.category === currentFilter
                ? "bg-primary font-[Sequel100Wide] text-black border-2 border-white"
                : "filter text-white"
            }  //w-[345px] w-full max-w-[100%] font-[Sequel100WideVF55] rounded-full h-[85px] sm:w-full transition-all //text-[20px] //sm:text-[11px] sm:h-[35px] text-center uppercase mx-auto`}
            style={{
              fontSize: "clamp(1px,2.796204527vw,25.3px)",
            }}
          >
            {filter.category}
          </button>
        ))}
      </div>
    </div>
  );
}
