import React, { useEffect } from "react";
import { useRef, useState } from "react";
import AdvertBox from "../Components/Page Components/AdvertBox";
import CompetitionDetails from "../Components/Page Components/CompetitionDetails";
import {
  CompetitionProps,
  Competitions,
} from "../Components/Page Components/Competitions";
import FAQ from "../Components/Page Components/FAQ";
import HowToPlay from "../Components/Page Components/HowToPlay";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
import { useAppSelector } from "../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { CompetitionObject } from "../redux/reducers/competitionsSlice";
import { scrollTo } from "../utils/ScrollTo";
import {
  getCompetitionById,
  getInstantWinPrizes,
} from "../Services/userService";
import PhoneStores from "../Components/Page Components/PhoneStores";
import moment from "moment";
import LiveCompetitionDetails from "../Components/Page Components/LiveCompetitionDetails";
import Carousel from "../Components/common/Carousel/Carousel";
import HowItWorksBoxSmall from "../Components/Page Components/HowItWorksBoxSmall";
import HowItWorksBox, {
  HowItWorksBoxProps,
} from "../Components/Page Components/HowItWorksBox";
import LiveCompetitionFAQ from "../Components/Page Components/LiveCompetitionFAQDiv";
import { Common } from "../Components/common/Common";
import PrizeCardsContainer from "../Components/InstantWinCompetitionPage/PrizeCardsContainer/PrizeCardsContainer";
import PrizeCard from "../Components/InstantWinCompetitionPage/PrizeCard/PrizeCard";
import { howItWorks } from "./LandingPage";
import { Loader } from "../Components/common/loader/Loader";

export interface IPrize {
  UID: string;
  imageUrl: string;
  prize: string;
  description: string;
  competitionId: string;
  winningTicket: string | null;
  winningWalletAddress: string | null;
  priority: number;
}

export interface IPrizePerPageData {
  data: IPrize[];
  totalItems: number;
  totalPages: number;
}

const InstantWinCompetitionPage = () => {
  const [emptyEncounter, setEmptyEncounter] = useState(false);
  const [prizes, setPrizes] = useState<IPrize[]>([]);
  const [prizesPage, setPrizesPage] = useState(1);
  const [prizeLoading, setPrizeLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(-1);
  const [competition, setCompetition] = useState<any>();
  const [showLiveDraw, setShowLiveDraw] = useState<boolean>();
  const [totalItems, setTotalItems] = useState(0);
  const params = useParams();
  const competitionid = params?.id;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const enter = "/Images/enter-icon.svg";
  const crown = "/Images/crown-icon.svg";
  const nuclear = "/Images/nuclear-icon.svg";

  const getInstantPrizes = async () => {
    try {
      if (competitionid) {
        if (totalPages === -1 || prizesPage <= totalPages) {
          setPrizeLoading(true);
          const result: IPrizePerPageData = await getInstantWinPrizes({
            competitionid,
            page: prizesPage,
          })
            ?.then((res) => {
              if (res.data.length === 0) {
                setEmptyEncounter(true);
              }
              setPrizes((prev) => [...prev, ...res.data]);
              setTotalPages(res.totalPages);
              setTotalItems(res.totalItems);
              setPrizeLoading(false);
              return res;
            })
            ?.catch((error) => {
              console.log(error);
              setPrizeLoading(false);
            })
            ?.finally(() => {
              setPrizeLoading(false);
            });
        }
        setPrizesPage(prizesPage + 1);
      } else {
        throw new Error("Competition ID is undefined");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadMorePrizes = async () => {
    if (emptyEncounter) return;
    if (prizeLoading) return;
    await getInstantPrizes();
    // setDummyPrizes((prev) => [...prev, ...dummyPriizes]);
  };

  useEffect(() => {
    if (prizes?.length === 0 && prizeLoading === false) {
      // getInstantPrizes();
      loadMorePrizes();
    }
  }, [competitionid]);

  useEffect(() => {
    console.log("prizes: ", prizes);
    console.log("prizes length: ", prizes.length);
    console.log("prizes total pages: ", totalPages);
    console.log("prizes current page: ", prizesPage);
  }, [prizes, totalPages, prizesPage]);

  const getCompetition = async (competitionid: string) => {
    try {
      setLoading(true);
      const result = await getCompetitionById({ competitionid });
      console.log(result);
      setCompetition(result.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const formattedDateTime = moment(competition?.competitionenddate)
    .utcOffset("+00:00")
    .format("DD-MM-YYYY @ h:mmA");

  useEffect(() => {
    console.log("params", competitionid);
    getCompetition(competitionid || "");
  }, [competitionid]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log("prizes: ", competition?.instantPrizes);
  }, [competition]);

  return (
    <div className="hero mt-[16.87px]">
      <Loader show={loading} />
      <div className="w-full flex flex-col items-center ">
        {loading ? (
          <div className="loader"></div>
        ) : (
          <div className="flex flex-col items-center liveCompetition w-full">
            <LiveCompetitionDetails
              getCompetition={getCompetition}
              competition={competition}
              instant={true}
              totalItems={totalItems}
            />
          </div>
        )}
      </div>
      <div className="ml-[3%] mr-[3%] mt-[3%]">
        <div className="bg-black rounded-3xl ">
          <h1
            onClick={() => {
              setShowLiveDraw(!showLiveDraw);
            }}
            className="flex text-[35px] md:text-[35px] justify-between font-['Sequel100Wide'] sm:text-[28px] items-center font-[500] text-black uppercase bg-primary  h-[180px] w-full  pt-[-1em] sm:pl-0 md:pl-0 lg:pl-0 sm:text-center md:text-center lg:text-center rounded-3xl xl:pl-[90px] xl:pr-[90px] lg:pl-[90px] lg:pr-[90px] md:pl-[60px] md:pr-[60px] sm:pl-[30px] sm:pr-[30px]"
          >
            Competition Finishes {formattedDateTime} UTC
            <div className="align-right pr-[40px]"></div>
            <img src="/Images/arrow-down.svg" alt="" />
          </h1>

          {showLiveDraw ? (
            <div className="sm:rounded-t-none md:rounded-t-none lg:rounded-t-none rounded-3xl sm:border-primary md:border-primary lg:border-primary font-[Sequel100Wide] w-full p-[5em] px-[10%] text-white flex flex-col gap-[3em] sm:text-center md:text-center lg:text-center">
              {/* First Line: Competition Name */}
              <div>
                <h4 className="text-[40px] font-bold text-white sm:text-[22px]">
                  {competition?.competitionname ||
                    "Competition Name Unavailable"}
                </h4>
              </div>

              {/* Second Line: Competition Description */}
              <div className="flex flex-col gap-4 font-[MontBlancBold] text-[20px] sm:text-[16px]">
                <span>
                  {competition?.competitioninformation ||
                    "Competition Description Unavailable"}
                </span>
              </div>

              {/* Third Line: Optional Legal Information */}
              {competition?.legalinformation && (
                <div className="flex flex-col gap-4 font-[MontBlancBold] text-[20px] sm:text-[16px]">
                  <p>{competition.legalinformation}</p>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="w-full flex flex-col items-center max-w-[1400px] m-auto pl-[3%] pr-[3%] ">
        <h1
          className={`pageHeading font-['Sequel100Wide95']`}
          style={{
            fontSize: "clamp(1px,5.529128597vw,45px)",
          }}
        >
          HOW IT WORKS
        </h1>

        <div className="flex container flex-shrink max-w-[100%] sm:hidden  mb-[49.55px]">
          {howItWorks.map((item, index) => (
            <>
              <HowItWorksBox
                icon={item.icon}
                title={item.title}
                content={item.content}
                key={index}
              />
              {index + 1 !== howItWorks.length && (
                <div
                  className="min-w-[5%] flex justify-center items-center"
                  key={index}
                >
                  <img
                    src="/Images/next_white.png"
                    alt=""
                    className="object-scale-down "
                  />
                </div>
              )}
            </>
          ))}
        </div>

        {/* how it works mobile */}
        <div className="lg:hidden xl:hidden md:hidden">
          {/* <Slider slides={howItWorks} options={OPTIONS} /> */}
          <Carousel>
            {howItWorks.map((item, index) => (
              <HowItWorksBoxSmall
                icon={item.icon}
                title={item.title}
                content={item.content}
                key={index}
              />
            ))}
          </Carousel>
        </div>
      </div>
      <div className="w-full">
        <div className="bg-primary h-[184px] flex justify-center items-center w-full">
          <Common.typography
            fontFamily="Sequel100Wide95"
            fontSize="40px"
            fontSizeSmall="25px"
            color="#0000000"
            className="uppercase"
          >
            INSTANT WIN PRIZES
          </Common.typography>
        </div>
        <PrizeCardsContainer onReachedEnd={loadMorePrizes}>
          {prizes?.map((prize: IPrize, index: number) => (
            <PrizeCard
              description={prize.description}
              title={prize.prize}
              image={prize?.imageUrl}
              key={index}
              date={new Date("2022-09-09T00:00:00.000Z").toISOString()}
              winningTicket={prize?.winningTicket || ""}
              winner={prize?.winningWalletAddress || ""}
            />
          ))}
        </PrizeCardsContainer>
      </div>
      <LiveCompetitionFAQ
        className={"faq"}
        fontSize={"text-[60px] sm:text-[28px]"}
        isInstant={true}
      />
      <div>
        <AdvertBox />
      </div>
      {/* <PhoneStores background="bg-gradient-to-r from-[#a51269] to-[#2b2b2b] h-screen " /> */}
      <PhoneStores background="bg-gradient-to-r from-[#a51269] to-[#2b2b2b] h-screen " />
    </div>
  );
};

export default WithNavbarAndFooter(InstantWinCompetitionPage);
