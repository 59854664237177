import React, { useCallback, useEffect, useRef } from "react";
import {
  useBottomScrollListener,
  BottomScrollListener,
} from "react-bottom-scroll-listener";

interface PrizeCardContainerProps {
  children: React.ReactNode;
  onReachedEnd?: () => void;
}

function PrizeCardsContainer(props: PrizeCardContainerProps) {
  const handleContainerOnBottom = useCallback(() => {
    props?.onReachedEnd && props.onReachedEnd();
  }, [props?.onReachedEnd]);

  /* This will trigger handleOnContainerBottom when the container that is passed the ref hits the bottom */
  const containerRef = useBottomScrollListener(
    handleContainerOnBottom
  ) as React.RefObject<HTMLDivElement>;

  return (
    <div
      ref={containerRef}
      className="grid grid-cols-2 sm_md:grid-cols-1 gap-4 pr-[36px] sm_md:pr-2 sm_md:mt-[16.58px] max-h-[48rem] scrollbar scrollbar-thumb-secondary scrollbar-track-[#3B3B3B] scrollbar-w-[4px] scrollbar-h-[4px] overflow-auto m-12 mt-[50.63px] sm_md:m-3 "
      style={{ paddingRight: "0px", overscrollBehavior: "none" }}
    >
      {props.children}
    </div>
  );
}

export default PrizeCardsContainer;
