/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { WalletConnectModal } from "@walletconnect/modal";
import UniversalProvider from "@walletconnect/universal-provider";
// @ts-ignore
import { TronWeb } from "tronweb";
import { tronConfigs } from "../../../Config/blockchain.config";

export enum TronChains {
  Mainnet = "0x2b6653dc",
  Devnet = "0xcd8690dc",
}
const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID as string;
const events: string[] = [];
const chains = tronConfigs.CHAIN_ID;
const methods = ["tron_signMessage", "tron_signTransaction"];
const modal = new WalletConnectModal({
  projectId,
  chains,
});
const host = tronConfigs.HOST;
const handleModalOpen = async (uri: string) => {
  await modal.openModal({
    uri,
  });
};

// Todo: add typescript method descriptors to control the flow, init should be called before any method invocation
export class WCTronAdapter {
  private walletState:
    | {
        connected: boolean;
        account: string | null;
        network: string | null;
        providerType: string | null;
      }
    | undefined;
  private provider?: UniversalProvider;
  private tronWeb?: TronWeb;

  constructor() {}

  async init() {
    if (!this.provider) {
      this.provider = await UniversalProvider.init({
        logger: "error", // log level
        projectId: projectId,
        metadata: {
          name: "THEPRIZE",
          description:
            "THE PRIZE - The world's first crypto and luxury raffles platform.",
          url: "https://theprize.io/",
          icons: ["https://theprize.io/logo512.png"],
        },
      });
      this.provider.removeListener("display_uri", handleModalOpen);
      this.provider.on("display_uri", handleModalOpen);
      this.provider.on("connect", (session: any) => {
        console.log("Connected session:", session);
      });

      this.provider.on("session_update", (session: any) => {
        console.log("Session updated:", session);
      });
      this.tronWeb = new TronWeb({
        fullHost: host,
      });
    }
    return this.provider;
  }

  async connect(storedAccount?: string) {
    if (!this.provider) throw new Error("Tron adapter not initialised");
    try {
      console.log(this.provider.session, "SESSION RESTORED");
      if (this.provider.session && !storedAccount) {
        await this.provider.disconnect();
      }
      if (!this.provider.session)
        await this.provider.connect({
          optionalNamespaces: {
            tron: {
              methods,
              chains,
              events,
            },
          },
        });

      const connectedAccount =
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        this.provider.session?.namespaces.tron?.accounts[0].split(":")[2]!;

      this.tronWeb?.setAddress(connectedAccount);

      this.walletState = {
        connected: true,
        account: connectedAccount,
        network: "tron",
        providerType: "walletconnect",
      };
    } catch (err) {
      throw new Error(err as any);
    }
    modal.closeModal();
  }

  async disconnect() {
    if (!this.provider) throw new Error("Tron adapter not initialised");
    await this.provider!.disconnect();
    this.walletState = undefined;
  }

  async signMessage(message: string) {
    if (!this.provider) throw new Error("Tron adapter not initialised");
    try {
      if (this.walletState) {
        // Sign a message with the selected account
        const result = await this.provider!.request<{ signature: string }>(
          {
            method: "tron_signMessage",
            params: { address: this.walletState.account, message },
          },
          chains[0]
        );

        if (result.signature) {
          return result.signature;
        } else {
          throw new Error("Failed to generate signature.");
        }
      } else {
        throw new Error("Wallet not initialized");
      }
    } catch (err) {
      throw new Error(err as any);
    }
  }

  getWalletState() {
    return this.walletState;
  }

  async readContractMethod(
    contractAddress: string,
    abi: any[],
    methodName: string,
    args: any[] = []
  ): Promise<any> {
    if (!this.tronWeb) throw new Error("TronWeb not initialized");
    const contract = await this.tronWeb.contract(abi, contractAddress);
    return await contract[methodName](...args).call();
  }

  async writeContractMethod(
    contractAddress: string,
    abi: any[],
    methodName: string,
    args: any[] = [],
    value: string = "0"
  ): Promise<any> {
    if (!this.tronWeb || !this.provider || !this.walletState) {
      throw new Error("TronWeb, provider, or wallet state not initialized");
    }

    try {
      const tronWeb = this.tronWeb;

      // Define the options for the transaction
      const options = {
        feeLimit: 60_000000, // Fee limit for the transaction
        callValue: value ? Number(tronWeb.toSun(Number(value))) : undefined, // Convert value to TRX in sun (smallest unit)
      };

      // Dynamically map arguments with types
      const formattedArgs = args.map((arg) => {
        if (tronWeb.isAddress(arg)) {
          return { type: "address", value: arg };
        } else if (typeof arg === "string") {
          return { type: "bytes", value: arg };
        } else if (typeof arg === "number") {
          return { type: "uint256", value: arg };
        } else {
          throw new Error(`Unsupported argument type: ${typeof arg}`);
        }
      });

      // Trigger the smart contract method
      const transaction = await tronWeb.transactionBuilder.triggerSmartContract(
        contractAddress,
        methodName,
        options,
        formattedArgs,
        this.walletState.account ?? ""
      );

      // Sign the transaction
      const signedTransaction = await this.provider!.request<{ result: any }>(
        {
          method: "tron_signTransaction",
          params: {
            transaction,
            address: this.walletState.account ?? "",
          },
        },
        chains[0]
      );

      const tx = signedTransaction.result;

      // Send the transaction
      const res = await tronWeb.trx.sendRawTransaction(tx);

      if (res.result) {
        return {
          hash: res.transaction.txID,
        };
      } else {
        throw new Error(res.code?.toString() ?? "Something went wrong");
      }
    } catch (error: any) {
      console.error("Error executing contract method:", error.message || error);
      throw new Error(error.message || "Transaction failed.");
    }
  }

  async sendTransaction(
    to: string,
    value: string,
    data?: string
  ): Promise<any> {
    if (!this.tronWeb) throw new Error("TronWeb not initialized");

    const tx = await this.tronWeb.transactionBuilder.sendTrx(
      to,
      // this.tronWeb.toSun(val ?? undefined),
      undefined,
      this.walletState?.account ?? undefined
    );

    if (data) {
      // @ts-ignore
      tx.raw_data.contract[0].parameter.value.data = data;
    }

    return await this.tronWeb.trx.sign(tx);
  }

  async listenToContractEvent(
    contractAddress: string,
    abi: any[],
    eventName: string,
    callback: (eventData: any) => void
  ): Promise<void> {
    try {
      console.log(contractAddress);
      console.log(abi);
      console.log(eventName);
      console.log(callback);
    } catch (err) {
      console.log(err);
    }
  }

  async stopListeningToContractEvent(
    contractAddress: string,
    abi: any[],
    eventName: string
  ): Promise<void> {
    try {
      console.log(contractAddress);
      console.log(abi);
      console.log(eventName);
    } catch (err) {
      console.log(err);
    }
  }
}
