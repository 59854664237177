import React from "react";
import { Common } from "../../../common/Common";

interface TitleProps {
  title: string;
  fontSizeSmall?: string;
}
export default function Title(props: TitleProps) {
  return (
    <Common.typography
      fontFamily="Sequel100Wide95"
      fontSize="29.16px"
      fontSizeSmall={props.fontSizeSmall ? props.fontSizeSmall : "19.32px"}
      color="#fff"
      className="uppercase"
    >
      {props.title}
    </Common.typography>
  );
}
