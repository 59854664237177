import React from "react";
import { Common } from "../../common/Common";
import { copyToClipboard } from "../../../utils/CopyToClipboard";

interface ContractAddressProps {
  value: string;
}

export default function ContractAddress(props: ContractAddressProps) {
  return (
    <div className="flex gap-[12px] justify-center sm_md:w-full sm_md:justify-between items-center">
      <Common.typography
        fontFamily="Sequel100Wide85"
        fontSize="20px"
        fontSizeSmall="16px"
        color="#393939"
      >
        Contract Address: {props.value.substring(0, 20)}...
      </Common.typography>

      <Common.image
        src="/Images/copy-dark.svg"
        alt="copy"
        width="40px"
        height="40px"
        heightSmall="34px"
        widthSmall="34px"
        objectFit="contain"
        objectPosition="center"
        onClick={() => {
          const isProduction = process.env.REACT_APP_ENV === "production";
          let preText;

          if (!isProduction) {
            preText = `https://sepolia.etherscan.io/tx/`;
          } else {
            preText = `https://basescan.org/tx/`;
          }
          copyToClipboard(preText + props.value);
        }}
      />
    </div>
  );
}
