import React, { useEffect } from "react";

const ConfirmationPayment = ({
  isOpen,
  setIsOpen,
  handleConfirm,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleConfirm: () => void;
}) => {
  // const handleClose = () => setIsOpen(false);

  useEffect(() => {
    // Adding the 'blur' class to the background content only
    document.body.classList.add("overflow-hidden");

    // Clean up the blur effect when the popup is closed
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return (
    <>
      {/* Popup */}
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[2000]">
          <div className="bg-primary rounded-lg shadow-lg p-6 xl:w-[40%] lg:w-[40%] md:w-[60%] sm:w-[90%] z-10">
            <span className="font-[Sequel100Wide] text-lg font-semibold mb-4">
              PLEASE NOTE:{" "}
            </span>
            <span className="font-[Sequel100WideVF45] text-lg font-semibold mb-4">
              Payment confirmation can take up to 5 minutes in total to add your
              tickets to account.
            </span>
            <div className="h-[20px]"></div>

            {/* Flex container to align the input and button in one line on XL screens only */}
            <div className="flex xl:flex-row flex-col gap-4 items-center">
              <button
                onClick={handleConfirm}
                className="bg-[#212121] xl:py-[13px] rounded-[14px] border-2 border-white font-[Sequel100wide] text-white h-[56px] w-full xl:w-[30%] sm:w-full lg:w-full"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationPayment;
