import React from "react";
import { Common } from "../../common/Common";
import ChainlinkHashContainer from "../containers/ChainlinkHashContainer";
import ChainlinkHashText from "../texts/ChainlinkHashText";
import { copyToClipboard } from "../../../utils/CopyToClipboard";

interface ChainlinkHashProps {
  value: string;
}
export default function ChainlinkHash(props: ChainlinkHashProps) {
  return (
    <ChainlinkHashContainer>
      <ChainlinkHashText />

      <Common.image
        src="/Images/copy-dark.svg"
        alt="copy"
        height="40px"
        heightSmall="21.18px"
        width="40px"
        widthSmall="21.18px"
        onClick={() => {
          const isProduction = process.env.REACT_APP_ENV === "production";
          let preText;

          if (!isProduction) {
            preText = `https://sepolia.etherscan.io/tx/`;
          } else {
            preText = `https://basescan.org/tx/`;
          }
          copyToClipboard(preText + props.value);
        }}
      />
    </ChainlinkHashContainer>
  );
}
