import React, { useEffect, useState } from "react";
import WithNavbarFooterAndSidebar from "../../Layout/WithNavbarFooterAndSidebar";
import {
  CompetitionProps,
  Competitions,
} from "../../Components/Page Components/Competitions";
import { useNavigate } from "react-router-dom";
import CompetitionStatus from "../../Components/Dashboard Components/CompetitionStatus";
import Sidebar from "../../Components/Sidebar";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getUserCompetitions } from "../../redux/reducers/competitionsSlice";
import { setSideNavHeight } from "../../redux/reducers/sidebarSlice";
import { getEnteredCompetition } from "../../Services/userService";
import Button from "../../Components/Button";
import WithNonAbsolutedSidebarNavbarFooter from "../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import moment from "moment";

const EnteredCompetitions = () => {
  const navigate = useNavigate();
  const [showCompetition] = useState(false);
  const [competitions, setCompetitions] = useState([]);
  const [competitionsList, setCompetitionsList] = useState<CompetitionProps[]>(
    []
  );
  const [allCompetitionsLoaded, setAllCompetitionsLoaded] = useState(false);
  const [displayedCompetitions, setDisplayedCompetitions] = useState<
    CompetitionProps[]
  >([]);
  const itemsPerPage = 16;

  const dispatch = useAppDispatch();

  const finishedCompetition = (competitionenddate?: Date) => {
    const currentDate = new Date();
    const competitionEndDate = new Date(competitionenddate ?? new Date());
    if (currentDate > competitionEndDate) {
      return true;
    } else {
      return false;
    }
  };

  const getEnteredCompetitions = async () => {
    try {
      const response = await getEnteredCompetition();
      console.log("response entered competitions", response);
      const competitionList = response?.data;

      const fc: CompetitionProps[] = [];
      const lc: CompetitionProps[] = [];

      competitionList
        ?.filter((competition: any) => {
          if (
            competition?.category === "BITCOIN" ||
            competition?.category === "bitcoin" ||
            competition?.category === "WEN LAMBO" ||
            competition?.category === "WATCHES" ||
            competition?.category === "HIGH ROLLERS" ||
            competition?.category === "NFTS" ||
            competition?.category === "ALTCOINS"
          ) {
            return competition;
          }
        })
        ?.map((competition: any) => {
          if (finishedCompetition(competition?.competitionenddate)) {
            fc.push(competition);
          } else {
            lc.push(competition);
          }
        });

      const sortedCompetitionList = lc?.sort(
        (a: CompetitionProps, b: CompetitionProps) => {
          // return moment(b?.competitionenddate).diff(moment(a?.competitionenddate));
          let dateA = new Date(a.competitionenddate || 0) as any;
          let dateB = new Date(b.competitionenddate || 0) as any;
          return dateA - dateB;
        }
      );

      const concatedList = sortedCompetitionList?.concat(fc);
      setCompetitionsList(concatedList);
      setDisplayedCompetitions(concatedList?.slice(0, itemsPerPage));
      //setCompetitionsList(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEnteredCompetitions();
    window.scrollTo(0, 0);
  }, []);

  const fetchMoreCompetitions = async () => {
    if (allCompetitionsLoaded) return;
    const nextDisplayedItems = competitionsList.slice(
      0,
      displayedCompetitions.length + itemsPerPage
    );
    setDisplayedCompetitions(nextDisplayedItems); // Update displayed items

    console.log("dsajda", nextDisplayedItems.length, competitionsList.length);

    setAllCompetitionsLoaded(
      nextDisplayedItems.length === competitionsList.length
    ); // Check if all competitions are loaded
  };

  const handleLoadMoreClick = () => {
    fetchMoreCompetitions();
  };

  useEffect(() => {
    if (competitionsList.length === 0) {
      dispatch(setSideNavHeight(""));
    } else if (competitionsList?.length < 4) {
      dispatch(setSideNavHeight(""));
    } else {
      dispatch(setSideNavHeight("1340px"));
    }
  }, [competitionsList]);

  function buyTicket() {}
  const navigateToResult = (competition?: any, UID?: string) => {
    navigate(`/competitionResult/${UID}`);
  };
  function loadCompetition(UID?: string) {
    navigate(`/user/dashboard/competition/${UID}`);
  }

  useEffect(() => {
    const enteredComps = document.getElementById("entered_competition_main");
    enteredComps?.scrollIntoView();
  }, []);

  return (
    <WithNonAbsolutedSidebarNavbarFooter
      Component={
        <div className="hero w-auto" id="entered_competition_main">
          {/* <Sidebar height={"h-full"} show={true} /> */}

          <div
            className={`${
              competitionsList?.length === 0 && "h-[50rem]"
            }  1366:scale-x-[0.9] 1366:ml-[-30px] 1366:w-max //1366:!mx-[345px] flex flex-col items-center mb-[3em] enteredComps p-10 pt-0 //xl:mx-[400px] //w-max lg:w-full mx-0 sm:w-full sm:p-3`}
          >
            <h1
              className="pageHeading sm:text-[1rem] md:text-[2.4rem] lg:text-[2.6rem] "
              // ref={liveComp}
            >
              ENTERED COMPETITIONS
            </h1>
            {displayedCompetitions.length === 0 && (
              <div className="w-full mt-[5em]">
                <div
                  className={`text-[2rem] font-[Sequel100WideVF55] sm:text-[20px] flex flex-col h-[10rem]  relative  w-full sm:w-full rounded-2xl text-center justify-center text-primary`}
                >
                  You have not entered any competitions yet
                </div>
              </div>
            )}
            {showCompetition ? (
              <CompetitionStatus result={false} />
            ) : (
              // <div className="grid  grid-cols-3 sm:grid-cols-2 gap-4 gap-y-10 w-auto sm:w-full //w-full">
              <div className="grid grid-cols-3 sm:grid-cols-1 sm:w-full between-200-299:grid-cols-1 between-200-299:min-w-[100%] md:grid-cols-2 lg:grid-cols-3 xl:gap-x-[20.25px] xl:gap-y-[53px] gap-x-[13.63px] gap-y-[11.92px] m-auto w-max max-w-[100%] *:m-auto mt-[3em]">
                {displayedCompetitions?.map((competition, index) => (
                  <Competitions
                    totalticketsbought={competition?.numberofticketsbought}
                    {...competition}
                    onClick={() =>
                      finishedCompetition(competition?.competitionenddate)
                        ? navigateToResult(competition, competition?.UID)
                        : loadCompetition(competition?.UID)
                    }
                    key={index}
                    width={"w-[345px]"}
                  />
                ))}
                {/* <button
                          //onClick={() => loadCompetition(slider, slider?.UID)}
                          onClick={() =>
                            finishedCompetition(slider.competitionenddate)
                              ? navigateToResult(slider, slider?.UID)
                              : loadCompetition(slider, slider?.UID)
                          }
                          className={`${
                            finishedCompetition(slider.competitionenddate)
                              ? "transparent bg-transparent"
                              : "bg-primary border-2"
                          }  h-[75px] text-[#2D2022] sm:w-full //text-[29px] lg:w-full //lg:text-[1.7vw] //lg:text-[1.3vw] font-['MontBlancBlack'] w-[245px] //sm:w-[300px] md:w-full p-2  sm:ml-0 sm:mt-4 //md:mt-4 //md:ml-9  border-white rounded-full //ml-[-0.5em]`}
                          style={{
                            fontSize: "clamp(1px,5.361012052vw,26.89px)",
                          }}
                        >
                          {finishedCompetition(slider.competitionenddate)
                            ? "Finished"
                            : "BUY TICKETS"}
                        </button> */}
              </div>
            )}
            {competitionsList.length > displayedCompetitions.length &&
              !allCompetitionsLoaded && (
                <Button
                  content="Load More"
                  onClick={handleLoadMoreClick}
                  className="text-black font-[Sequel100Wide95] text-[24px]
            sm:text-[3vw]
            sm:w-[80%]
            uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]"
                />
              )}
          </div>
        </div>
      }
    />
  );
};
//1340px
export default EnteredCompetitions;
