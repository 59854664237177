/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  WalletConnector,
  WalletConnectorFactory,
} from "../factories/WalletConnector";
import axios from "axios";
import { getUserDetails } from "../Services/userService";
import { ErrorContext } from "./ErrorContext";
import { Eip1193Provider } from "ethers";

interface User {
  billingAddress: string | null;
  billingDetails: string | null;
  numberOfTickets: number;
  address1: string | null | undefined;
  address2: string | null | undefined;
  boomfisubscriptionId: string | null | undefined;
  country: string | null | undefined;
  city: string | null | undefined;
  postcode: string | null | undefined;
  email: string | null | undefined;
  name: string | null | undefined;
  phone: string | null | undefined;
  userName: string | null | undefined;
  telegramHandle: string | null | undefined;
  imageUrl: string | null | undefined;
  telegram: string | null | undefined;
}

interface WalletContextType {
  isConnecting: boolean;
  setIsConnecting: React.Dispatch<React.SetStateAction<boolean>>;
  connector?: WalletConnector;
  connect(provider: string, network: string): Promise<void>;
  reconnect(provider: string, network: string, account: string): Promise<void>;
  disconnect(): Promise<void>;
  account: string | undefined;
  token: string | undefined;
  user: User | undefined;
  network: string | undefined;
  providerType: string | undefined;
  setNetwork: React.Dispatch<React.SetStateAction<string | undefined>>;
  fetchUser: () => void;
}

interface WalletProviderProps {
  children: ReactNode;
}

const WalletContext = createContext<WalletContextType | undefined>(undefined);

export const WalletProvider = ({ children }: WalletProviderProps) => {
  const [connector, setConnector] = useState<WalletConnector>();
  const [providerType, setProviderType] = useState<string | undefined>();
  const [account, setAccount] = useState<string | undefined>();
  const [network, setNetwork] = useState<string | undefined>();
  const [token, setToken] = useState<string | undefined>();
  const [user, setUser] = useState<User | undefined>();
  const [metamaskProvider, setMetamaskProvider] = useState<
    Eip1193Provider | undefined
  >();
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [isAccountChanged, setIsAccountChanged] = useState<boolean>(false);
  const { addError } = useContext(ErrorContext) || {};

  const storedProviderType = localStorage.getItem("providerType");
  const storedNetworkType = localStorage.getItem("network");
  const storedAddress = localStorage.getItem("account");
  const storedToken = localStorage.getItem("token");

  const connect = async (provider: string, network: string) => {
    try {
      setIsConnecting(true);
      const newConnector = WalletConnectorFactory.createConnector(
        provider,
        metamaskProvider
      );
      newConnector?.removeAllListeners("stateChange");
      newConnector?.on("stateChange", handleStateChange);
      await newConnector.connect(network);
      setConnector(newConnector);
    } catch (err) {
      console.log("Error reconnecting, ", err);
      handleStateChange(undefined);
      setIsConnecting(false);
      addError?.(`Error reconnecting: ${(err as Error).message}`);
    }
  };

  const reconnect = async (
    provider: string,
    network: string,
    account: string
  ) => {
    try {
      if (account) {
        const newConnector = WalletConnectorFactory.createConnector(
          provider,
          metamaskProvider
        );
        newConnector?.removeAllListeners("stateChange");
        newConnector?.removeAllListeners("accountChanged");
        newConnector?.on("stateChange", handleStateChange);
        newConnector?.on("accountChanged", (updatedAccount) =>
          handleAccountChanged(newConnector, updatedAccount)
        );
        await newConnector.reconnect(network, account);
        setConnector(newConnector);
      }
    } catch (err) {
      console.log("Error reconnecting, ", err);
      handleStateChange(undefined);
      setIsConnecting(false);
      addError?.(`Error reconnecting: ${(err as Error).message}`);
    }
  };

  const disconnect = async () => {
    console.log("Disconnecting...");
    if (connector) {
      await connector.disconnect();
    }
    handleStateChange(undefined);
    window.location.reload();
  };

  const handleStateChange = (walletState: any) => {
    if (!walletState) {
      setConnector(undefined);
      setProviderType(undefined);
      setAccount(undefined);
      setNetwork(undefined);
      setToken(undefined);
      localStorage.removeItem("account");
      localStorage.removeItem("network");
      localStorage.removeItem("providerType");
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["Authorization"];
      return;
    }
    const { providerType, account, network } = walletState;
    setAccount(account);
    setNetwork(network);
    setProviderType(providerType);
    localStorage.setItem("account", account ?? "");
    localStorage.setItem("network", network ?? "");
    localStorage.setItem("providerType", providerType ?? "");
  };

  const handleAccountChanged = async (
    connector: WalletConnector,
    updatedAccount: string
  ) => {
    setIsAccountChanged(true);
    await fetchToken(true, connector, updatedAccount);
  };

  const fetchToken = async (
    refetch?: boolean,
    connectorAccountChanged?: WalletConnector,
    updatedAccount?: string
  ) => {
    try {
      setIsConnecting(true);
      let accessToken;
      const storedToken = localStorage.getItem("token");
      if (!refetch && storedToken) {
        accessToken = storedToken;
      } else {
        accessToken = await (connectorAccountChanged
          ? connectorAccountChanged?.authenticate()
          : connector?.authenticate());
      }

      if (accessToken) {
        setToken(accessToken ?? undefined);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        localStorage.setItem("token", accessToken);
        await fetchUser(updatedAccount);
      } else {
        handleStateChange(undefined);
      }
    } catch (err) {
      console.log("Error reconnecting, ", err);
      handleStateChange(undefined);
      addError?.(`Error reconnecting: ${(err as Error).message}`);
    } finally {
      setIsConnecting(false);
    }
  };

  const fetchUser = async (updatedAccount?: string) => {
    try {
      const accountInUse = updatedAccount ?? account;
      const user = await getUserDetails(accountInUse || "");
      setUser(user);
    } catch (err) {
      console.log(err);
      handleStateChange(undefined);
      addError?.(`Error reconnecting: ${(err as Error).message}`);
    }
  };

  const fetchMetamaskProvider = () => {
    const windowObj = window as any;

    // fetch providers from browser using EIP 6963
    if (windowObj?.ethereum && windowObj?.ethereum.isMetaMask) {
      window.addEventListener("eip6963:announceProvider", (event: any) => {
        const provider = event.detail.provider;
        const providerName = event.detail.info.name;

        if (providerName === "MetaMask") {
          setMetamaskProvider(provider);
        }
      });
      window.dispatchEvent(new Event("eip6963:requestProvider"));
    } else {
      if (
        !/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          navigator.userAgent
        )
      ) {
        handleStateChange(undefined);
        setIsConnecting(false);
        addError?.(`Error connecting: Metamask is not installed`);
      }
    }
  };

  const rehydrateState = (
    storedProviderType: string | null,
    storedNetworkType: string | null,
    storedAddress: string | null,
    storedToken: string | null
  ) => {
    if (
      storedProviderType &&
      storedNetworkType &&
      storedAddress &&
      storedToken
    ) {
      reconnect(storedProviderType, storedNetworkType, storedAddress);
    } else if (storedAddress && !storedToken) {
      handleStateChange(undefined);
      setIsConnecting(false);
    } else if (storedToken && !storedAddress) {
      handleStateChange(undefined);
      setIsConnecting(false);
    } else {
      setIsConnecting(false);
    }
  };

  useEffect(() => {
    const authenticateUser = async () => {
      if (connector && account && !token && !isAccountChanged) {
        await fetchToken();
      }
    };

    authenticateUser();
  }, [connector]);

  // Reconnecting to metamask
  useEffect(() => {
    if (metamaskProvider && storedProviderType) {
      rehydrateState(
        storedProviderType,
        storedNetworkType,
        storedAddress,
        storedToken
      );
    }
  }, [metamaskProvider]);

  useEffect(() => {
    setIsConnecting(true);

    fetchMetamaskProvider();

    // Reconnecting to otherthan metamask
    if (storedProviderType !== "metamask")
      rehydrateState(
        storedProviderType,
        storedNetworkType,
        storedAddress,
        storedToken
      );
  }, []);

  return (
    <WalletContext.Provider
      value={{
        isConnecting,
        setIsConnecting,
        connector,
        connect,
        reconnect,
        disconnect,
        account,
        network,
        providerType,
        setNetwork,
        user,
        token,
        fetchUser,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error("useWallet must be used within a WalletProvider.");
  }
  return context;
};
