import { Dispatch, SetStateAction } from "react";
import Button from "../../Button";

interface LoadmoreBigProps {
  setPage: Dispatch<SetStateAction<number>>;
}
export default function LoadmoreBig({ setPage }: LoadmoreBigProps) {
  const handleClick = () => {
    setPage((prev) => prev + 1);
  };
  return (
    <Button
      content="Load More"
      onClick={handleClick}
      className="xl:block hidden text-black font-[Sequel100Wide95] text-[24px]
sm:text-[3vw]
sm:w-[80%]
uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]"
    />
  );
}
