import Modal from "@mui/material/Modal";
import { useState } from "react";

export default function PopupWithIcon({
  children,
  open,
  onClose,
  showCloseButton,
  zIndex,
}: {
  children: React.ReactNode;
  open: boolean;
  onClose?: () => void;
  showCloseButton?: boolean;
  zIndex?: number;
}) {
  return (
    <Modal
      disableAutoFocus
      // className="pt-[250px] below-430:pt-[100px] h-min-840:pt-[200px]"
      disableEscapeKeyDown
      itemScope
      disableEnforceFocus
      BackdropProps={{
        style: { outline: "none" },
      }}
      sx={{
        position: "fixed",
        zIndex: zIndex ?? 88,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // pt: "124px",
        // pt: "220px",
        outline: "none",
        maxWidth: "578px",
        width: "100%",
        // px: "clamp(5px, 1vw, 2px)",
        m: "auto",
        // blur the background
        "&::before": {
          content: '""',
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(5px)",
          zIndex: -1,
        },
        "&:focus-visible": { outline: "none" },
      }}
      open={open}
      onClose={onClose}
    >
      <div className="relative w-full flex flex-col items-center justify-start p-4 sm:p-4 sm:mx-6 below-430:mx-4  rounded-[20px] border-[1px] border-[#fff] bg-[#1A1A1A]  h-auto">
        {showCloseButton && (
          <img
            onClick={onClose}
            className="absolute top-[-14px] right-[-14px] cursor-pointer"
            src="/Images/white-cross.svg"
            alt="Close"
          />
        )}
        <img
          className="absolute top-[-3.3rem] "
          src="/Images/price_logo.svg"
          alt="Price logo"
        />
        <div className="mt-10 w-full">{children}</div>
      </div>
    </Modal>
  );
}
