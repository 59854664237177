interface AddressChainlinkContainerProps {
  children: React.ReactNode;
}
export default function AddressChainlinkContainer(
  props: AddressChainlinkContainerProps
) {
  return (
    <div
      className="
    flex
    flex-wrap
    justify-center
    items-center
    h-[97px]
    sm_md_lg:h-auto
    w-full 
    bg-primary
    gap-[31.33px]
    sm_md_lg:py-[26px]
    sm_md_lg:flex-col
    sm_md_lg:px-[44px]
    below-380:*:mx-[44px]
    below-380:px-0
    below-380:overflow-x-auto
    "
    >
      {props.children}
    </div>
  );
}
