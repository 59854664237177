import Countdown from "react-countdown";
import { Text } from "../../../react-responsive-utilities/src";
import VSpacer from "../../../common/Spacer/VSpacer";
import { useEffect, useState } from "react";

interface TimerProps {
  competitionEndDate?: Date;
  // finished?: boolean;
}

const Unit = ({
  value,
  unit,
  isLast = false,
}: {
  value: string;
  unit: string;
  isLast?: boolean;
}) => (
  <div>
    <div className="flex *:m-1 justify-center items-center">
      <div className="px-[clamp(0px,2vw,13px)] flex max-w-[45.17px] min-h-[clamp(0px,3vw,45px)] bg-white w-full text-center justify-center items-center rounded-[10px]">
        <Text
          textSize={[16, 21.93, 27.61]}
          className="text-[#2E2122] font-['MontBlancBlack'] leading-[29px] font-[218]"
        >
          {value[0]}
        </Text>
      </div>
      <div className="px-[clamp(0px,2vw,13px)] flex max-w-[45.17px] min-h-[clamp(0px,3vw,45px)] bg-white w-full text-center justify-center items-center rounded-[10px]">
        <Text
          textSize={[16, 21.93, 27.61]}
          className="text-[#2E2122] font-['MontBlancBlack'] leading-[29px] font-[218]"
        >
          {value[1]}
        </Text>
      </div>

      {value.length > 2 && (
        <div className="px-[clamp(0px,2vw,13px)] flex max-w-[45.17px] min-h-[clamp(0px,3vw,45px)] bg-white w-full text-center justify-center items-center rounded-[10px]">
          <Text
            textSize={[16, 21.93, 27.61]}
            className="text-[#2E2122] font-['MontBlancBlack'] leading-[29px] font-[218]"
          >
            {value[2]}
          </Text>
        </div>
      )}

      {value.length > 3 && (
        <div className="px-[clamp(0px,2vw,13px)] flex max-w-[45.17px] min-h-[clamp(0px,3vw,45px)] bg-white w-full text-center justify-center items-center rounded-[10px]">
          <Text
            textSize={[16, 21.93, 27.61]}
            className="text-[#2E2122] font-['MontBlancBlack'] leading-[29px] font-[218]"
          >
            {value[3]}
          </Text>
        </div>
      )}

      {!isLast && (
        <Text
          textSize={[16, 21.93, 27.61]}
          className={`text-[#FFFFFF] font-['MontBlancBlack'] leading-[29px] font-[218] block ${
            unit === "Hours" ? "lg:hidden" : ""
          }`}
        >
          :
        </Text>
      )}
    </div>

    <Text
      textSize={[0, 14.93, 16.0]}
      className="text-[#FFFFFF] sm_md:text-center w-full block font-['MontBlancRegular'] font-semibold leading-[20px] //font-[120] uppercase"
    >
      {unit}
    </Text>
  </div>
);

export default function Timer(props: TimerProps) {
  const date = new Date(props?.competitionEndDate || new Date());
  const day = date.getDate(); // Day (17)
  const month = date.getMonth() + 1; // Month (August is 7 in zero-based, so add 1)
  const year = date.getFullYear(); // Year (2024)
  useEffect(() => {
    console.log("qwerty: ", props.competitionEndDate); // Check the value in the console
  }, [props]);
  return (
    <div className="w-fit">
      <Text
        // textSize={[18, 20.0, 27.07]}
        textSize={[18, 22.0, 24.52]}
        className="
              text-[#FFFFFF] font-['Sequel100Wide95'] leading-[24px] font-[60] block sm_md:text-center uppercase"
      >
        Finished date
      </Text>
      <VSpacer small={15.5} big={22.11} />
      <div className="flex ">
        <Countdown
          date={new Date(props?.competitionEndDate || new Date())}
          renderer={({ formatted }) => (
            // <div className="grid grid-cols-4 lg:grid-cols-2">
            <div className="flex flex-wrap sm_md:justify-center">
              <Unit
                // value={props.finished ? "DD" : day.toString().padStart(2, "0")}
                value={day.toString().padStart(2, "0")}
                unit="Day"
              />
              <Unit
                // value={
                //   props.finished ? "MM" : month.toString().padStart(2, "0")
                // }
                value={month.toString().padStart(2, "0")}
                unit="Month"
              />
              <Unit
                // value={
                //   props.finished ? "YYYY" : year.toString().padStart(2, "0")
                // }
                value={year.toString().padStart(2, "0")}
                unit="Year"
                isLast
              />
            </div>
          )}
        />
      </div>
    </div>
  );
}
