import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import AdvertBox from "../Components/Page Components/AdvertBox";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
import PhoneStores from "../Components/Page Components/PhoneStores";

const Policy = ({
  title,
  content,
}: {
  title: JSX.Element;
  content: React.ReactNode; // Updated to accept JSX elements
}) => {
  return (
    <div className="p-10 mx-10 sm:mx-0 mt-[-1em] sm:mt-0 sm:pb-0 policyPage rounded-[30px] sm:rounded-none">
      {title}
      <div className="text-white text-[20px] pb-8 xl:pb-0">{content}</div>
    </div>
  );
};

const Policies = () => {
  const params = useParams();
  const { policy } = params;
  const { hash } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.href]);

  useEffect(() => {
    if (hash === "") return;

    setTimeout(() => {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        const yOffset = -0; // height of the fixed header
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }, 1000);
  }, [hash]);

  return (
    <>
      <div
        className="sm:block hidden h-[150px] w-full absolute top-0 left-0 z-[-1]"
        style={{
          background:
            "radial-gradient(circle at bottom left, #3f3f3f, #393939 30%)",
        }}
      ></div>
      <div className="hero flex flex-col ">
        <div
          className="pb-5 mt-[36.87px] sm:pb-0 "
          style={{
            background:
              "radial-gradient(circle at bottom left, #4b243b, #2b2b2b 30%)",
          }}
        >
          {policy === "cookies" ? (
            <Policy
              title={
                <h1
                  className="text-[40px] font-extrabold text-white text-center"
                  style={{
                    padding: "20px 0", // Add padding to top and bottom
                  }}
                >
                  COOKIES POLICY
                </h1>
              }
              content={
                <>
                  <p>
                    <strong>THEPRIZE.IO</strong> uses cookies to enhance your
                    experience, improve functionality, and analyze site usage.
                    By continuing to use our site, you consent to our use of
                    cookies.
                  </p>

                  <h3 className="mt-4 text-[22px] font-bold">
                    1. What Are Cookies?
                  </h3>
                  <p>
                    Cookies are small data files stored on your device that help
                    us remember your preferences and recognize your browser upon
                    return.
                  </p>

                  <h3 className="mt-4 text-[22px] font-bold">
                    2. Types of Cookies We Use
                  </h3>
                  <ul className="list-disc pl-5">
                    <li>
                      <strong>Essential Cookies:</strong> Required for the
                      operation of our site.
                    </li>
                    <li>
                      <strong>Performance Cookies:</strong> Collect data on site
                      usage to help us improve user experience.
                    </li>
                    <li>
                      <strong>Functional Cookies:</strong> Enable personalized
                      features, such as saving your competition entries.
                    </li>
                  </ul>

                  <h3 className="mt-4 text-[22px] font-bold">
                    3. Managing Your Cookies
                  </h3>
                  <p>
                    You can control your cookie preferences through your browser
                    settings. Disabling certain cookies may limit your ability
                    to use all features of our platform.
                  </p>

                  <h3 className="mt-4 text-[22px] font-bold">
                    4. Third-Party Cookies
                  </h3>
                  <p>
                    We may partner with third-party providers for analytics.
                    These providers may use cookies as described in their
                    respective privacy policies.
                  </p>

                  <h3 className="mt-4 text-[22px] font-bold">Contact Us</h3>
                  <p>
                    For questions regarding our Cookies Policy, email us at{" "}
                    <a
                      href="mailto:contact@theprize.io"
                      className="text-blue-400 underline"
                    >
                      contact@theprize.io
                    </a>
                    .
                  </p>
                </>
              }
            />
          ) : policy === "privacy" ? (
            <Policy
              title={
                <h1
                  className="text-[40px] font-extrabold text-white text-center"
                  style={{
                    padding: "20px 0", // Add padding to top and bottom
                  }}
                >
                  PRIVACY POLICY
                </h1>
              }
              content={
                <div className="terms-and-conditions">
                  <p>Introduction</p>
                  <p>Welcome to MVT Management's privacy policy.</p>
                  <p>
                    MVT Management LTD respects your privacy and is committed to
                    protecting your personal data. This privacy policy will
                    inform you as to how we process and look after your personal
                    data when you visit our website (regardless of where you
                    visit it from).
                  </p>
                  <p>
                    It also tells you about your privacy rights and how the law
                    protects you.
                  </p>
                  <p>
                    You can download a pdf version of the policy here:{" "}
                    <a
                      href="https://www.google.com/url?q=https://www.google.com/url?q%3Dhttps://drive.google.com/file/d/1pWJiYLw0PpfSCnR5SyDnGHt-LogHw8L8/view"
                      className="text-blue-400 underline"
                    >
                      The Prize Privacy policy.pdf
                    </a>
                  </p>
                  <p>
                    Please also use the Glossary to understand the meaning of
                    some of the terms used in this privacy policy.
                  </p>
                  <p>1. Important information and who we are</p>
                  <p>Purpose of this privacy policy</p>
                  <p>
                    This privacy policy aims to give you information on how MVT
                    Management LTD collects and processes your personal data
                    through your use of this website, including any data you may
                    provide through this website when you sign up to our
                    newsletter, purchase any products or services or take part
                    in a prize draw or competition.
                  </p>
                  <p>
                    This website is not intended for children and we do not
                    knowingly collect data relating to children.
                  </p>
                  <p>
                    It is important that you read this privacy policy together
                    with any other privacy policy or fair processing policy we
                    may provide on specific occasions when we are collecting or
                    processing personal data about you so that you are fully
                    aware of how and why we are using your data. This privacy
                    policy supplements other notices and privacy policies and is
                    not intended to override them.
                  </p>
                  <p>Controller</p>
                  <p>
                    MVT Management LTD is the controller and responsible for
                    your personal data (collectively referred to as MVT
                    Management LTD, "we", "us" or "our" in this privacy policy).
                  </p>
                  <p>
                    We have appointed a data privacy manager who is responsible
                    for overseeing questions in relation to this privacy policy.
                    If you have any questions about this privacy policy,
                    including any requests to exercise your legal rights, please
                    contact the data privacy manager using the details set out
                    below.
                  </p>
                  <p>Contact details</p>
                  <p>
                    If you have any questions about this privacy policy or our
                    privacy practices, please contact our data privacy manager
                    in the following ways:
                  </p>
                  <p>Full name of legal entity: MVT Management LTD</p>
                  <p>Name of data privacy manager: Jackson Mudge</p>
                  <p>Email address: jackson@theprize.io</p>
                  <p>Postal address: 483 Green Lanes, London, N13 4BS</p>
                  <p>
                    You have the right to make a complaint at any time to the
                    Information Commissioner's Office (ICO), the UK supervisory
                    authority for data protection issues (www.ico.org.uk). We
                    would, however, appreciate the chance to deal with your
                    concerns before you approach the ICO so please contact us in
                    the first instance.
                  </p>
                  <p>
                    Changes to the privacy policy and your duty to inform us of
                    changes
                  </p>
                  <p>
                    We keep our privacy policy under regular review. This
                    version was last updated on 03/01/2025.
                  </p>
                  <p>
                    It is important that the personal data we hold about you is
                    accurate and current. Please keep us informed if your
                    personal data changes during your relationship with us.
                  </p>
                  <p>Third-party links</p>
                  <p>
                    This website may include links to third-party websites,
                    plug-ins and applications. Clicking on those links or
                    enabling those connections may allow third parties to
                    collect or share data about you. We do not control these
                    third-party websites and are not responsible for their
                    privacy statements. When you leave our website, we encourage
                    you to read the privacy policy of every website you visit.
                  </p>
                  <p>2. The data we collect about you</p>
                  <p>
                    Personal data, or personal information, means any
                    information about an individual from which that person can
                    be identified. It does not include data where the identity
                    has been removed (anonymous data).
                  </p>
                  <p>
                    We may collect, use, store and transfer different kinds of
                    personal data about you which we have grouped together as
                    follows:
                  </p>
                  <p>
                    • <b>Identity Data </b>includes first name, maiden name,
                    last name, username or similar identifier, title, date of
                    birth and gender.
                  </p>
                  <p>
                    • <b>Contact Data </b>includes delivery address, email
                    address and telephone numbers.
                  </p>
                  <p>
                    • <b>Transaction Data</b> includes internet protocol (IP)
                    address tx hashes outlining blockchain transactions taken
                    place along with relevant wallet address
                  </p>
                  <p>
                    • <b>Profile Data</b> includes your username and password,
                    purchases or orders made by you, your feedback and survey
                    responses.
                  </p>
                  <p>
                    • <b>Usage Data</b> includes information about how you use
                    our website, products and services.
                  </p>
                  <p>
                    • <b>Marketing and Communications Data</b> includes your
                    preferences in receiving marketing from us and our third
                    parties and your communication preferences.
                  </p>
                  <p>
                    We also collect, use and share Aggregated Data such as
                    statistical or demographic data for any purpose. Aggregated
                    Data could be derived from your personal data but is not
                    considered personal data in law as this data will not
                    directly or indirectly reveal your identity. For example, we
                    may aggregate your Usage Data to calculate the percentage of
                    users accessing a specific website feature. However, if we
                    combine or connect Aggregated Data with your personal data
                    so that it can directly or indirectly identify you, we treat
                    the combined data as personal data which will be used in
                    accordance with this privacy policy.
                  </p>
                  <p>
                    We do not collect any Special Categories of Personal Data
                    about you (this includes details about your race or
                    ethnicity, religious or philosophical beliefs, sex life,
                    sexual orientation, political opinions, trade union
                    membership, information about your health, and genetic and
                    biometric data). Nor do we collect any information about
                    criminal convictions and offences.
                  </p>
                  <p>
                    We may collect the minimum amount of personal data to enable
                    you to enter into any prize draw and competition and allow
                    us to run the promotion. If you are a winner, it may be
                    necessary to collect more detailed information from you in
                    order to award your prize to you. You will be notified of
                    this at the time we notify you if you have won.
                  </p>
                  <p>If you fail to provide personal data</p>
                  <p>
                    Where we need to collect personal data by law, or under the
                    terms of a contract we have with you, and you fail to
                    provide that data when requested, we may not be able to
                    perform the contract we have or are trying to enter into
                    with you (for example, to provide you with goods or services
                    or to enter you into a competition). In this case, we may
                    have to cancel a product or service you have with us or
                    refuse your entry to a competition. We will notify you if
                    this is the case at the time.
                  </p>
                  <p>3. How is your personal data collected?</p>
                  <p>
                    We use different methods to collect data from and about you
                    including through:
                  </p>
                  <p>
                    • <b>Direct interactions.</b> You may give us your Contact
                    Data by filling in forms or by corresponding with us by
                    post, phone, email or otherwise. This includes personal data
                    you provide when you:
                  </p>
                  <p>• create an account on our website;</p>
                  <p>• subscribe to our service or publications;</p>
                  <p>• enter a competition, promotion or survey; or</p>
                  <p>• give us feedback or contact us.</p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `• <b>Automated technologies or interactions.</b> As you interact with our website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies.We may also receive Technical Data about you if you visit other websites employing our cookies. Please see our cookie policy <a href="/policies/cookies" class="text-blue-500 underline">Cookie Policy</a>
                    for further details.`,
                    }}
                  />

                  <p>
                    • <b>Third parties or publicly available sources.</b> We
                    will receive personal data about you from various third
                    parties and public sources as set out below:
                  </p>
                  <p>• Technical Data from the following parties:</p>
                  <p>
                    (a) analytics providers such as Google based outside the UK;
                  </p>
                  <p>
                    (b) advertising networks such as Facebook based outside the
                    UK; and
                  </p>
                  <p>
                    (c) search information providers such as Google based inside
                    OR outside the UK.
                  </p>
                  <p>4. How we use your personal data</p>
                  <p>
                    We will only use your personal data when the law allows us
                    to. Most commonly, we will use your personal data in the
                    following circumstances:
                  </p>
                  <p>
                    • Where we need to perform the contract we are about to
                    enter into or have entered into with you. (A legally binding
                    contract is formed between us when you purchase goods or
                    services from us or you enter into a competition.)
                  </p>
                  <p>
                    • Where it is necessary for our legitimate interests (or
                    those of a third party) and your interests and fundamental
                    rights do not override those interests.
                  </p>
                  <p>• Where we need to comply with a legal obligation.</p>
                  <p>
                    To find out more about the types of lawful basis that we
                    will rely on to process your personal data, please see the
                    Glossary below.
                  </p>
                  <p>
                    Generally, we do not rely on consent as a legal basis for
                    processing your personal data although we will get your
                    consent before sending third party direct marketing
                    communications to you via email or text message. You have
                    the right to withdraw consent to marketing at any time by
                    contacting us.
                  </p>
                  <p>Purposes for which we will use your personal data</p>
                  <p>
                    We have set out below, in a table format, a description of
                    all the ways we plan to use your personal data, and which of
                    the legal bases we rely on to do so. We have also identified
                    what our legitimate interests are where appropriate.
                  </p>
                  <p>
                    Note that we may process your personal data for more than
                    one lawful ground depending on the specific purpose for
                    which we are using your data. Please contact us if you need
                    details about the specific legal ground we are relying on to
                    process your personal data where more than one ground has
                    been set out in the table below.
                  </p>
                  <table className="table-auto border-collapse border border-gray-400 w-full">
                    <tr>
                      <td className="border border-gray-400 p-2">
                        Purpose/Activity
                      </td>
                      <td className="border border-gray-400 p-2">
                        Type of data
                      </td>
                      <td className="border border-gray-400 p-2">
                        Lawful basis for processing including basis of
                        legitimate interest
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-400 p-2">
                        To register you as a new customer
                      </td>
                      <td className="border border-gray-400 p-2">
                        (a) Identity (b) Contact
                      </td>
                      <td className="border border-gray-400 p-2">
                        Performance of a contract with you
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-400 p-2">
                        To process and deliver your order including:(a) Manage
                        payments, fees and charges(b) Collect and recover money
                        owed to us
                      </td>
                      <td className="border border-gray-400 p-2">
                        (a) Identity (b) Contact (c) Financial (d) Transaction
                        (e) Marketing and Communications
                      </td>
                      <td className="border border-gray-400 p-2">
                        (a) Performance of a contract with you (b) Necessary for
                        our legitimate interests (to recover debts due to us)
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-400 p-2">
                        To manage our relationship with you which will
                        include:(a) Notifying you about changes to our terms or
                        privacy policy(b) Asking you to leave a review or take a
                        survey
                      </td>
                      <td className="border border-gray-400 p-2">
                        (a) Identity (b) Contact (c) Profile (d) Marketing and
                        Communications
                      </td>
                      <td className="border border-gray-400 p-2">
                        (a) Performance of a contract with you (b) Necessary to
                        comply with a legal obligation(c) Necessary for our
                        legitimate interests (to keep our records updated and to
                        study how customers use our products/services)
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-400 p-2">
                        To enable you to partake in a prize draw, competition or
                        complete a survey
                      </td>
                      <td className="border border-gray-400 p-2">
                        (a) Identity (b) Contact (c) Profile (d) Usage (e)
                        Marketing and Communications
                      </td>
                      <td className="border border-gray-400 p-2">
                        (a) Performance of a contract with you (b) Necessary for
                        our legitimate interests (to study how customers use our
                        products/services, to develop them and grow our
                        business)
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-400 p-2">
                        To administer and protect our business and this website
                        (including troubleshooting, data analysis, testing,
                        system maintenance, support, reporting and hosting of
                        data)
                      </td>
                      <td className="border border-gray-400 p-2">
                        (a) Identity(b) Contact(c) Technical
                      </td>
                      <td className="border border-gray-400 p-2">
                        (a) Necessary for our legitimate interests (for running
                        our business, provision of administration and IT
                        services, network security, to prevent fraud and in the
                        context of a business reorganisation or group
                        restructuring exercise)(b) Necessary to comply with a
                        legal obligation
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-400 p-2">
                        To deliver relevant website content and advertisements
                        to you and measure or understand the effectiveness of
                        the advertising we serve to you
                      </td>
                      <td className="border border-gray-400 p-2">
                        (a) Identity (b) Contact (c) Profile (d) Usage (e)
                        Marketing and Communications (f) Technical
                      </td>
                      <td className="border border-gray-400 p-2">
                        Necessary for our legitimate interests (to study how
                        customers use our products/services, to develop them, to
                        grow our business and to inform our marketing strategy)
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-400 p-2">
                        To use data analytics to improve our website,
                        products/services, marketing, customer relationships and
                        experiences
                      </td>
                      <td className="border border-gray-400 p-2">
                        (a) Technical (b) Usage
                      </td>
                      <td className="border border-gray-400 p-2">
                        Necessary for our legitimate interests (to define types
                        of customers for our products and services, to keep our
                        website updated and relevant, to develop our business
                        and to inform our marketing strategy)
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-400 p-2">
                        To make suggestions and recommendations to you about
                        goods or services that may be of interest to you
                      </td>
                      <td className="border border-gray-400 p-2">
                        (a) Identity (b) Contact (c) Technical (d) Usage (e)
                        Profile (f) Marketing and Communications
                      </td>
                      <td className="border border-gray-400 p-2">
                        Necessary for our legitimate interests (to develop our
                        products/services and grow our business)
                      </td>
                    </tr>
                  </table>
                  <p>
                    If you are the winner of the competition you will be invited
                    to participate in publicity exercises relating to the
                    competition. Your participation is not compulsory but would
                    be greatly appreciated by us. If you do not wish to
                    participate your participation in the competition or chances
                    of winning will not be affected in any way.
                  </p>
                  <p>
                    However, if you do not wish to participate in any publicity
                    and decline to be named as the winner, we may still have to
                    provide your details to the Advertising Standards Authority
                    (ASA) as proof that the competition has been properly
                    administered and the prize awarded. This is a legal
                    requirement we must comply with. If you require detail of
                    how the ASA will use and process your personal data, please
                    let us know.
                  </p>
                  <p>Marketing</p>
                  <p>
                    We strive to provide you with choices regarding certain
                    personal data uses, particularly around marketing and
                    advertising.
                  </p>
                  <p>Promotional offers from us</p>
                  <p>
                    We may use your Identity, Contact, Technical, Usage and
                    Profile Data to form a view on what we think you may want or
                    need, or what may be of interest to you. This is how we
                    decide which products, services and offers may be relevant
                    for you (we call this marketing).
                  </p>
                  <p>
                    You will receive marketing communications from us if you
                    have requested information from us or purchased goods or
                    services from us, entered a competition or prize draw and
                    you have not opted out of receiving that marketing.
                  </p>
                  <p>Third-party marketing</p>
                  <p>
                    We will get your express opt-in consent before we share your
                    personal data with any third party for marketing purposes.
                  </p>
                  <p>Opting out</p>
                  <p>
                    You can ask us or third parties to stop sending you
                    marketing messages at any time by following the opt-out
                    links on any marketing message sent to you or by contacting
                    us at any time.
                  </p>
                  <p>
                    Where you opt out of receiving these marketing messages,
                    this will not apply to personal data provided to us as a
                    result of a product/service purchase, warranty registration,
                    product/service experience or other transactions.
                  </p>
                  <p>Cookies</p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `  You can set your browser to refuse all or some browser
                    cookies, or to alert you when websites set or access
                    cookies. If you disable or refuse cookies, please note that
                    some parts of this website may become inaccessible or not
                    function properly. For more information about the cookies we
                    use, please see <a href="/policies/cookies" class="text-blue-500 underline"> Cookie Policy</a>.`,
                    }}
                  ></p>
                  <p>Change of purpose</p>
                  <p>
                    We will only use your personal data for the purposes for
                    which we collected it, unless we reasonably consider that we
                    need to use it for another reason and that reason is
                    compatible with the original purpose. If you wish to get an
                    explanation as to how the processing for the new purpose is
                    compatible with the original purpose, please contact us.
                  </p>
                  <p>
                    If we need to use your personal data for an unrelated
                    purpose, we will notify you and we will explain the legal
                    basis which allows us to do so.
                  </p>
                  <p>
                    Please note that we may process your personal data without
                    your knowledge or consent, in compliance with the above
                    rules, where this is required or permitted by law.
                  </p>
                  <p>5. Disclosures of your personal data</p>
                  <p>
                    We may share your personal data with the parties set out
                    below for the purposes set out in the table “Purposes for
                    which we will use your personal data” above.
                  </p>
                  <p>• External Third Parties as set out in the Glossary.</p>
                  <p>
                    • Specific third parties listed in the table “Purposes for
                    which we will use your personal data” above.
                  </p>
                  <p>
                    • Third parties to whom we may choose to sell, transfer or
                    merge parts of our business or our assets. Alternatively, we
                    may seek to acquire other businesses or merge with them. If
                    a change happens to our business, then the new owners may
                    use your personal data in the same way as set out in this
                    privacy policy.
                  </p>
                  <p>
                    We require all third parties to respect the security of your
                    personal data and to treat it in accordance with the law. We
                    do not allow our third-party service providers to use your
                    personal data for their own purposes and only permit them to
                    process your personal data for specified purposes and in
                    accordance with our instructions.
                  </p>
                  <p>6. International transfers</p>
                  <p>
                    Many of our external third parties are based outside the UK
                    so their processing of your personal data will involve a
                    transfer of data outside the UK.
                  </p>
                  <p>
                    Whenever we transfer your personal data out of the UK, we
                    ensure a similar degree of protection is afforded to it by
                    ensuring at least one of the following safeguards is
                    implemented:
                  </p>
                  <p>
                    • We will only transfer your personal data to countries that
                    have been deemed to provide an adequate level of protection
                    for personal data.
                  </p>
                  <p>
                    • Where we use certain service providers, we may use
                    specific contracts approved for use in the UK which give
                    personal data the same protection it has in the UK.
                  </p>
                  <p>
                    Please contact us if you want further information on the
                    specific mechanism used by us when transferring your
                    personal data out of the UK.
                  </p>
                  <p>7. Data security</p>
                  <p>
                    We have put in place appropriate security measures to
                    prevent your personal data from being accidentally lost,
                    used or accessed in an unauthorised way, altered or
                    disclosed. In addition, we limit access to your personal
                    data to those employees, agents, contractors and other third
                    parties who have a business need to know. They will only
                    process your personal data on our instructions and they are
                    subject to a duty of confidentiality.
                  </p>
                  <p>
                    We have put in place procedures to deal with any suspected
                    personal data breach and will notify you and any applicable
                    regulator of a breach where we are legally required to do
                    so.
                  </p>
                  <p>8. Data retention</p>
                  <p>How long will you use my personal data for?</p>
                  <p>
                    We will only retain your personal data for as long as
                    reasonably necessary to fulfil the purposes we collected it
                    for, including for the purposes of satisfying any legal,
                    regulatory, tax, accounting or reporting requirements. We
                    may retain your personal data for a longer period in the
                    event of a complaint or if we reasonably believe there is a
                    prospect of litigation in respect to our relationship with
                    you.
                  </p>
                  <p>
                    To determine the appropriate retention period for personal
                    data, we consider the amount, nature and sensitivity of the
                    personal data, the potential risk of harm from unauthorised
                    use or disclosure of your personal data, the purposes for
                    which we process your personal data and whether we can
                    achieve those purposes through other means, and the
                    applicable legal, regulatory, tax, accounting or other
                    requirements.
                  </p>
                  <p>
                    Details of retention periods for different aspects of your
                    personal data are available in our retention policy which
                    you can request from us.
                  </p>
                  <p>
                    In some circumstances you can ask us to delete your data:
                    see “Your legal rights” below for further information.
                  </p>
                  <p>
                    In some circumstances we will anonymise your personal data
                    (so that it can no longer be associated with you) for
                    research or statistical purposes, in which case we may use
                    this information indefinitely without further notice to you.
                  </p>
                  <p>9. Your legal rights</p>
                  <p>
                    Under certain circumstances, you have rights under data
                    protection laws in relation to your personal data.
                  </p>
                  <p>
                    If you wish to exercise any of these rights, please contact
                    us.
                  </p>
                  <p>No fee usually required</p>
                  <p>
                    You will not have to pay a fee to access your personal data
                    (or to exercise any of the other rights). However, we may
                    charge a reasonable fee if your request is clearly
                    unfounded, repetitive or excessive. Alternatively, we could
                    refuse to comply with your request in these circumstances.
                  </p>
                  <p>What we may need from you</p>
                  <p>
                    We may need to request specific information from you to help
                    us confirm your identity and ensure your right to access
                    your personal data (or to exercise any of your other
                    rights). This is a security measure to ensure that personal
                    data is not disclosed to any person who has no right to
                    receive it. We may also contact you to ask you for further
                    information in relation to your request to speed up our
                    response.
                  </p>
                  <p>Time limit to respond</p>
                  <p>
                    We try to respond to all legitimate requests within one
                    month. Occasionally it could take us longer than a month if
                    your request is particularly complex or you have made a
                    number of requests. In this case, we will notify you and
                    keep you updated.
                  </p>
                  <p>10. Glossary</p>
                  <p>LAWFUL BASIS</p>
                  <p>
                    Legitimate Interest means the interest of our business in
                    conducting and managing our business to enable us to give
                    you the best service/product and the best and most secure
                    experience. We make sure we consider and balance any
                    potential impact on you (both positive and negative) and
                    your rights before we process your personal data for our
                    legitimate interests. We do not use your personal data for
                    activities where our interests are overridden by the impact
                    on you (unless we have your consent or are otherwise
                    required or permitted to by law). You can obtain further
                    information about how we assess our legitimate interests
                    against any potential impact on you in respect of specific
                    activities by contacting us.
                  </p>
                  <p>
                    Performance of Contract means processing your data where it
                    is necessary for the performance of a contract to which you
                    are a party or to take steps at your request before entering
                    into such a contract.
                  </p>
                  <p>
                    Comply with a legal obligation means processing your
                    personal data where it is necessary for compliance with a
                    legal obligation that we are subject to.
                  </p>
                  <p>THIRD PARTIES</p>
                  <p>External Third Parties</p>
                  <p>
                    • Service providers acting as processors based outside the
                    United Kingdom who provide IT and system administration
                    services.
                  </p>
                  <p>
                    • Professional advisers acting as processors or joint 
                    controllers including lawyers, bankers, auditors and 
                    insurers based in the United Kingdom who provide consultancy,
                    banking, legal, insurance and accounting services.
                  </p>
                  <p>
                    • HM Revenue & Customs, regulators and other authorities
                    such as the Advertising Standards Authority acting as
                    processors or joint controllers based in the United Kingdom
                    who require reporting of processing activities in certain
                    circumstances.
                  </p>
                  <p>YOUR LEGAL RIGHTS</p>
                  <p>You have the right to:</p>
                  <p>
                    Request access to your personal data (commonly known as a
                    "data subject access request"). This enables you to receive
                    a copy of the personal data we hold about you and to check
                    that we are lawfully processing it.
                  </p>
                  <p>
                    Request correction of the personal data that we hold about
                    you. This enables you to have any incomplete or inaccurate
                    data we hold about you corrected, though we may need to
                    verify the accuracy of the new data you provide to us.
                  </p>
                  <p>
                    Request erasure of your personal data. This enables you to
                    ask us to delete or remove personal data where there is no
                    good reason for us continuing to process it. You also have
                    the right to ask us to delete or remove your personal data
                    where you have successfully exercised your right to object
                    to processing (see below), where we may have processed your
                    information unlawfully or where we are required to erase
                    your personal data to comply with local law. Note, however,
                    that we may not always be able to comply with your request
                    of erasure for specific legal reasons which will be notified
                    to you, if applicable, at the time of your request.
                  </p>
                  <p>
                    Object to processing of your personal data where we are
                    relying on a legitimate interest (or those of a third party)
                    and there is something about your particular situation which
                    makes you want to object to processing on this ground as you
                    feel it impacts on your fundamental rights and freedoms. You
                    also have the right to object where we are processing your
                    personal data for direct marketing purposes. In some cases,
                    we may demonstrate that we have compelling legitimate
                    grounds to process your information which override your
                    rights and freedoms.
                  </p>
                  <p>
                    Request restriction of processing of your personal data.
                    This enables you to ask us to suspend the processing of your
                    personal data in the following scenarios:
                  </p>
                  <p>• If you want us to establish the data's accuracy.</p>
                  <p>
                    • Where our use of the data is unlawful but you do not want
                    us to erase it.
                  </p>
                  <p>
                    • Where you need us to hold the data even if we no longer
                    require it as you need it to establish, exercise or defend
                    legal claims.
                  </p>
                  <p>
                    • You have objected to our use of your data but we need to
                    verify whether we have overriding legitimate grounds to use
                    it.
                  </p>
                  <p>
                    Request the transfer of your personal data to you or to a
                    third party. We will provide to you, or a third party you
                    have chosen, your personal data in a structured, commonly
                    used, machine-readable format. Note that this right only
                    applies to automated information which you initially
                    provided consent for us to use or where we used the
                    information to perform a contract with you.
                  </p>
                  <p>
                    Withdraw consent at any time where we are relying on consent
                    to process your personal data. However, this will not affect
                    the lawfulness of any processing carried out before you
                    withdraw your consent. If you withdraw your consent, we may
                    not be able to provide certain products or services to you.
                    We will advise you if this is the case at the time you
                    withdraw your consent.
                  </p>
                </div>
              }
            />
          ) : policy === "t&c" ? (
            <Policy
              title={
                <h1
                  className="text-[40px] font-extrabold text-white text-center"
                  style={{
                    padding: "20px 0", // Add padding to top and bottom
                  }}
                >
                  TERMS & CONDITIONS
                </h1>
              }
              content={
                <>
                  <p>
                    By accessing and using THEPRIZE.IO platform, you agree to
                    comply with these Terms and Conditions. Please read them
                    carefully before entering our competitions.
                  </p>

                  <div className="terms-and-conditions">
                    <h2 className="mb-4">1. The Promoter</h2>
                    <p>
                      The promoter is: MVT Management LTD trading as The Prize,
                      Company Number 14651255 and whose registered office is at
                      6-7 Waterside Station Road, Harpenden, AL5 4US, England
                    </p>
                    <p>
                      If you wish to contact us for any reason, please email
                      <a
                        href="mailto:contact@theprize.io"
                        className="text-blue-400 underline"
                      >
                        contact@theprize.io
                      </a>
                    </p>
                    <h2 className="mb-4">2. The competition</h2>
                    <h2
                      className="mb-4"
                      dangerouslySetInnerHTML={{
                        __html: `2.1 These terms and conditions apply to all competitions
                      listed on the Promoter’s website at <a href="https://theprize.io/" class="text-blue-500 underline">https://theprize.io/</a>.
                      `,
                      }}
                    ></h2>
                    <h2 className="mb-4">
                      2.2 The Promoter may offer different formats to the
                      competitions. Subject to clause 2.3, an entry fee is
                      payable each time you enter.
                    </h2>
                    <h2 className="mb-4">
                      2.3 To be in with a chance of winning, everyone who enters
                      the competition (an “Entrant”) will be required to
                      correctly answer a question or solve a problem set by the
                      Promoter (the “Competition Question”). Where the Promoter
                      offers an easy or multiple-choice question or, where the
                      Promoter does not ask a Competition Question, a free entry
                      route is available.
                    </h2>
                    <h2 className="mb-4">3. How to enter</h2>
                    <h2 className="mb-4">
                      3.1 The competition will run from and including the
                      opening and closing dates specified on the Website. These
                      dates shall be referred to as the "Opening Date" and
                      "Closing Date"respectively. All times and dates referred
                      to are the times and dates in London, England.
                    </h2>
                    <h2 className="mb-4">
                      3.2 If it is absolutely necessary to do so, the Promoter
                      reserves the right to change the Opening and Closing
                      Dates. If the Promoter does change the Opening Date and/or
                      the Closing Date of a competition, the new details will be
                      displayed on the Website. The Promoter will not extend the
                      Closing Date simply to sell more entries.
                    </h2>
                    <h2 className="mb-4">
                      3.3 All competition entries must be received by the
                      Promoter by no later than the specified time on the
                      Closing Date. All competition entries received after the
                      specified time on the Closing Date are automatically
                      disqualified and no refunds will be given.
                    </h2>
                    <h2 className="mb-4">
                      3.4 If there is a maximum number entries to a competition,
                      this will be stated on the Website. The number of entries
                      you are able to make may be limited if the maximum number
                      of entries is reached.
                    </h2>
                    <h2 className="mb-4">
                      3.5 Entrants can enter the competition as many times as
                      they wish until either the maximum number of entries have
                      been received or any maximum number of entries per Entrant
                      has been reached. Entrants submitting free entries must
                      submit each entry separately. Bulk entries, if received,
                      will not be accepted and will only be counted as one
                      single entry.
                    </h2>
                    <h2 className="mb-4">
                      3.6 All Entrants (including those entering for free) must
                      open an account on the Website prior to entering any of
                      our competitions. Only one account per person is
                      permitted. If any Entrant opens or tries to open multiple
                      accounts using different email addresses, all of their
                      accounts will be closed and the Entrant will not be
                      permitted to enter any of the Promoter’s competitions.
                      Once an account has been opened, to enter the competition:
                    </h2>
                    <p>
                      (a) go to the Website and view the Competition Question,
                      if there is one;
                    </p>
                    <p>
                      (b) complete and submit the online entry form or follow
                      the instructions on how to enter for free; then
                    </p>
                    <p>(c) purchase the required number of entries; then</p>
                    <p>
                      (d) when you have purchased your entries, submit your
                      answer to the Competition Question, if there is one.
                    </p>
                    <h2 className="mb-4">
                      3.7 All entries must be submitted in the English language.
                      Entries in languages other than English will automatically
                      be disqualified and no refund will be given.
                    </h2>
                    <h2 className="mb-4">
                      3.8 Unless you are using the free entry method, the Promoter
                      will send confirmation that your entry has been received 
                      and confirm whether or not your answer to the Competition 
                      Question is correct.
                    </h2>
                    <h2 className="mb-4">
                      3.9 The Promoter will not accept responsibility for
                      competition entries that are not successfully completed,
                      are lost or are delayed regardless of cause, including,
                      for example, as a result of any equipment failure,
                      technical malfunction, systems, satellite, network,
                      server, computer hardware or software failure of any kind.
                    </h2>
                    <h2 className="mb-4">
                      3.10 By purchasing entries and submitting a competition
                      entry, you are entering into a contract with the Promoter
                      and are agreeing to be bound by these terms and
                      conditions.
                    </h2>
                    <h2 id="targetSection" className="mb-4">
                      3.11 You may enter the competition for free by complying 
                      with the following conditions (a free entry covers the total 
                      entry cost of the competition not a single ticket:
                    </h2>
                    <div>
                      <p>
                        (a) send your entry by first or second class post to the
                        Promoter at the following address: 483 Green Lanes,
                        London, N13 4BS
                      </p>
                      <p>
                        (b) hand delivered entries will not be accepted and will
                        not be entered into the random draw;
                      </p>
                      <p>
                        (c) include with your entry the following information
                        (all details must match the details on your account):
                      </p>
                      <p>
                        (i) the name or details of the competition you wish to
                        enter;
                      </p>
                      <p>(ii) your full name;</p>
                      <p>(iii) your address;</p>
                      <p>
                        (iv) a contact telephone number and email address; and
                      </p>
                      <p>
                        (v) your answer to the Competition Question (if there is
                        one).
                      </p>
                      <p>
                        (d) incomplete or illegible entries will be
                        disqualified;
                      </p>
                      <p>
                        (e) you may make multiple free entries for any
                        competition (up to any limit placed on entries by the
                        Promoter) but each free entry must be submitted and
                        posted to the Promoter separately. Bulk entries in one
                        envelope will not be accepted as multiple entries and if
                        a bulk entry is received, it will be counted as one
                        single entry;
                      </p>
                      <p>
                        (f) by entering the competition, you are confirming that
                        you are eligible to enter and accept these terms and
                        conditions;
                      </p>
                      <p>
                        (g) your entry must be received by the Promoter prior to
                        the Closing Date. Entries received after the Closing
                        Date will not be entered into the random draw. Proof of
                        posting does not guarantee that you will be entered into
                        the random draw;
                      </p>
                      <p>
                        (h) the Promoter will not acknowledge receipt of your
                        entry nor confirm if your answer to the Competition
                        Question is correct;
                      </p>
                      <p>
                        (i) if the number of entries received reaches any cap or
                        limit before your free entry is received, you will not
                        be entered into the random draw.
                      </p>
                    </div>
                    <p>4. Choosing a winner</p>
                    <p>
                      4.1 The Promoter may use different methods of choosing the
                      winner, depending on the format of the competition:
                    </p>
                    <p>
                      (a) Main draw: All Entrants who correctly answer the
                      Competition Question will be placed into a draw and the
                      winner will be chosen by random draw. If no Competition
                      Question is asked, all Entrants will be entered into the
                      random draw. The random draw will take place as soon as
                      reasonably possible and, in any event, within [7] days of
                      the Closing Date ("Draw Date");
                    </p>
                    <p>
                      (b) Instant wins: All Entrants meeting the entry
                      requirements will be randomly allocated an entry number on
                      completion and payment of their entry. The Promoter will
                      use a random number generator to select an entry number
                      for any Entrants entering via the free entry route and
                      meeting the entry requirements. If the entry number
                      corresponds with the instant win number stated on the
                      Website, the Entrant will win that prize;
                    </p>
                    <p>
                      (c) Auto draws: The Promoter may use an auto draw system
                      for choosing the winner or winners of the Main draw and
                      any Instant wins. For the Main draw, the system will be
                      set to randomly select a winner from all valid entries as
                      soon as the Closing Date is reached or all entries have
                      been sold (including allocation of free entries),
                      whichever is sooner. For Instant wins, the auto draw
                      system will automatically allocate the relevant prize to a
                      winner if their entry number matches the instant win
                      number.
                    </p>
                    <p>
                      4.2 All Entrants will have their names and entry numbers
                      include on a spreadsheet which may be published on the
                      Website and may be visible during the live draw. Names may
                      also be posted on the website to indicate that an instant
                      win prize has been won. If you do not wish to have your
                      name included on this spreadsheet or posted on the website
                      you must contact the Promoter via email at
                      <a
                        href="mailto:contact@theprize.io"
                        className="text-blue-400 underline"
                      >
                        contact@theprize.io
                      </a>{" "}
                      as soon as reasonably possible after you have completed
                      your entry and in any event, at least 48 hours before the
                      live draw takes place.
                    </p>
                    <p>
                      4.3 [For help with entries, please email us at
                      <a
                        href="mailto:contact@theprize.io"
                        className="text-blue-400 underline"
                      >
                        contact@theprize.io
                      </a>
                      ]
                    </p>
                    <p>5. Eligibility</p>
                    <p>
                      5.1 The competition is only open to all residents in the
                      United Kingdom aged 18 years or over, except:
                    </p>
                    <p>(a) employees of the Promoter;</p>
                    <p>
                      (b) employees of agents or suppliers of the Promoter, who
                      are professionally connected with the competition or its
                      administration; or
                    </p>
                    <p>
                      (c) members of the immediate families or households of (a)
                      and (b) above.
                    </p>
                    <p>
                      5.2 By entering the competition, you confirm that you are
                      eligible to do so and eligible to claim any prize you may
                      win. The Promoter may require you to provide proof that
                      you are eligible to enter the competition and claim the
                      prize. If you fail to provide the Promoter with any such
                      proof or other information that they may require within a
                      reasonable time, you may be disqualified from the
                      competition.
                    </p>
                    <p>
                      5.3 The Promoter will not accept competition entries that
                      are:
                    </p>
                    <p>(a) automatically generated by computer; or</p>
                    <p>(b) incomplete.</p>
                    <p>
                      5.4 The Promoter reserves all rights to disqualify you if
                      your conduct is contrary to the spirit or intention of the
                      prize competition. This includes if you are rude or
                      abusive to the Promoter or anyone associated with them.
                    </p>
                    <p>
                      5.5 No refunds of the entry fee will be given in any
                      event, including;
                    </p>
                    <p>
                      (a) if, following your entry into the competition, you
                      subsequently find out that you are not eligible to enter
                      the competition or claim the Prize;
                    </p>
                    <p>
                      (b) if, following your entry into the competition the
                      eligibility criteria for entering the competition or
                      claiming the Prize changes and you are no longer eligible;
                      or
                    </p>
                    <p>
                      (c) if you are disqualified from the competition by the
                      Promoter for any reason.
                    </p>
                    <p>5.6 If the Entrant engages in:</p>
                    <p>(a) any form of fraud (actual or apparent);</p>
                    <p>(b) fraudulent misrepresentation;</p>
                    <p>(c) fraudulent concealment;</p>
                    <p>
                      (d) hacking or interference with the proper functioning of
                      the Website; or
                    </p>
                    <p>
                      (e) amending, or unauthorised use of, any of the code that
                      constitutes the website
                    </p>
                    <p>
                      all of their entries will be declared void, no refunds
                      will be given and they may be prevented from participating
                      in any future competitions.
                    </p>
                    <p>6. The prize</p>
                    <p>
                      6.1 The prize for each competition is described on the
                      Website (the “Prize”). Details of the Prize are, to the
                      best of the Promoter’s knowledge, information and belief,
                      correct as at the Opening Date.
                    </p>
                    <p>6.2 If the Prize is a vehicle:</p>
                    <p>
                      (a) the Promoter will, unless otherwise stated, ensure it
                      comes with a valid MOT (if required);
                    </p>
                    <p>
                      (b) no insurance is included with the Prize and it is the
                      Winner’s responsibility to ensure the vehicle is
                      adequately insured prior to taking it on the public roads
                      (if it is legal to do so);
                    </p>
                    <p>
                      (c) the Promoter has no responsibility for the Prize once
                      it has been delivered. The Winner is solely responsible
                      for complying with all relevant laws and regulations
                      relating to the vehicle, its operation and ensuring they
                      operate it in a safe and responsible manner;
                    </p>
                    <p>(d) no vehicle/road tax is included;</p>
                    <p>
                      (e) the Winner is responsible for ensuring they have the
                      necessary licences, qualification, knowledge and
                      experience to operate the vehicle safely and legally;
                    </p>
                    <p>
                      (f) the Winner is solely responsible for ensuring they
                      have all necessary safety equipment and clothing (for
                      example, helmets, boots and gloves) and for wearing them
                      whilst operating the vehicle.
                    </p>
                    <p>
                      6.3 The Promoter makes no representations and gives no
                      warranties about the Prize, its value, its condition or
                      any other information provided on the Website. The
                      Promoter makes no representations and gives no warranties
                      that the information provide on the Website is accurate,
                      complete or up to date.
                    </p>
                    <p>
                      6.4 The Prize may be supplied by a third-party supplier
                      (the “Supplier”). Details of the Supplier (if any) will be
                      provided on the Website.
                    </p>
                    <p>
                      6.5 The Promoter may offer an alternative cash prize
                      (“Cash Prize”) for some competitions. If an alternative
                      Cash Prize is offered, the amount of the Cash Prize will
                      be stated in the prize description. It is the winner’s
                      choice as to whether or not they take the Prize or the
                      Cash Prize. The Promoter also reserves the right, at its
                      discretion, to substitute the Prize for a Cash Prize a in
                      the following circumstances:
                    </p>
                    <p>(a) the Prize becomes unavailable;</p>
                    <p>
                      (b) other circumstances beyond the reasonable control of
                      the Promoter make it necessary to do so.
                    </p>
                    <p>6.6 The prize is not negotiable or transferable.</p>
                    <p>7. Winners</p>
                    <p>
                      7.1 The decision of the Promoter is final and no
                      correspondence or discussion will be entered into.
                    </p>
                    <p>
                      7.2 The Promoter will contact the winner personally as
                      soon as practicable after the Draw Date, using the
                      telephone number or email address provided with the
                      competition entry. If the winner cannot be contacted or is
                      not available, or has not claimed the Prize within [14] of
                      days of the Draw Date, the Promoter reserves the right to
                      offer the Prize to the next eligible Entrant selected from
                      the correct entries that were received before the Closing
                      Date.
                    </p>
                    <p>
                      7.3 The Promoter must either publish or make available
                      information that indicates that a valid award took place.
                      To comply with this obligation the Promoter will either
                      publish the surname and county of major prize winners on
                      the Website or send the surname and county of major prize
                      winners to anyone who writes to the address set out in
                      clause 1(enclosing a self-addressed envelope) within one
                      month after the Closing Date of the competition.
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: ` 7.4 If you object to any or all of your surname, county
                      and winning entry being published or made available,
                      please contact the Promoter at <a
                      href="mailto:contact@theprize.io"
                      class="text-blue-500 underline"
                    >
                      contact@theprize.io
                    </a> prior
                      to the Closing Date. In such circumstances, the Promoter
                      must still provide the information to the Advertising
                      Standards Authority on request.`,
                      }}
                    />

                    <p>8. Claiming the prize</p>
                    <p>
                      8.1 You must claim the Prize personally. The Prize may not
                      be claimed by a third party on your behalf. Details of how
                      the Prize will be delivered to you (or made available for
                      collection) are published on the Website.
                    </p>
                    <p>
                      8.2 If your personal details, including contact
                      information, changes at any time you should notify the
                      Promoter as soon as reasonably possible. Notifications
                      should be sent to the Promoter via email to
                      <a
                        href="mailto:contact@theprize.io"
                        className="text-blue-400 underline"
                      >
                        contact@theprize.io
                      </a>{" "}
                      Notifications must include details of the competition you
                      have entered, your old details and your new details. If
                      your details change within 5 days of the Closing Date, the
                      Promoter will use your old details if it needs to try to
                      contact you.
                    </p>
                    <p>
                      8.3 Any Cash Prize will be transferred directly to the
                      winners nominated bank account. The winner must provide
                      evidence that it is the sole or joint beneficiary of the
                      bank account. Failure to do so within [14] days will
                      result in disqualification from the competition and the
                      winner forfeiting the prize. In such circumstances, the
                      Promoter reserves the right to offer the prize to the next
                      eligible Entrant selected from the correct entries that
                      were received before the Closing Date.
                    </p>
                    <p>
                      8.4 The Promoter does not accept any responsibility and is
                      not liable to pay any compensation if you are unable to or
                      do not take up the prize.
                    </p>
                    <p>9. Limitation of liability</p>
                    <p>
                      Insofar as is permitted by law, the Promoter, its agents
                      or distributors will not in any circumstances be
                      responsible or liable to compensate the winner or accept
                      any liability for any loss, damage, personal injury or
                      death occurring as a result of taking up the prize except
                      where it is caused by the negligence of the Promoter, its
                      agents or distributors or that of their employees. Your
                      statutory rights are not affected.
                    </p>
                    <p>10. Data protection and publicity</p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: `10.1 By entering the competition, you agree that any
                      personal information provided by you with the competition
                      entry may be held and used only by the Promoter or its
                      agents and suppliers to administer the competition or as
                      otherwise set out in the Promoter’s <a href="/policies/privacy" class="text-blue-500 underline">Privacy Policy</a>, a copy of which is available on the Website.`,
                      }}
                    />

                    <p>
                      10.2 If you are the winner of the competition, you agree
                      that the Promoter may use your name, image and town or
                      county of residence to announce the winner of this
                      competition. You further agree to participate in any
                      reasonable publicity required by the Promoter.
                    </p>
                    <p>
                      10.3 If you do not wish to participate in any publicity,
                      you must notify the Promoter prior to the Closing Date.
                      This will not affect your chances of winning the Prize. If
                      you do not agree to participate in any publicity about the
                      competition we may still provide your details to the
                      Advertising Standards Authority. This is a legal
                      requirement that we must comply with to prove that the
                      competition has been properly administered and the Prize
                      awarded.
                    </p>
                    <p>
                      10.4 If you are the winner of the competition, you may be
                      required to provide further personal information and proof
                      of your identity in order to confirm your eligibility to
                      claim the Prize and transfer ownership of the Prize to
                      you. You consent to the use of your information in this
                      way. You are entitled to request further details about how
                      your personal information is being used. You may also
                      withdraw your consent to your personal information being
                      used in such way but by doing so you may prevent the Prize
                      being transferred to you. In such circumstances, you will
                      be deemed to have withdrawn from the competition and
                      forfeit the Prize. You will not be entitled to any refund
                      of your entry fee. The Promoter reserves the right to
                      offer the Prize to the next eligible Entrant selected from
                      the correct entries that were received before the Closing
                      Date.
                    </p>
                    <p>
                      10.5 Please note that under data protection laws you are
                      entitled to request that the Promoter does not contact you
                      and removes your details from its database. If you make
                      such a request you will be withdrawing from the
                      competition as it will not be possible to contact you in
                      the event that you are the winner. You will not be
                      entitled to any refund of any entry fee if you withdraw
                      from the competition. If you do not wish any of your
                      personal details to be used by the Promoter for
                      promotional purposes, please email the Promoter at
                      <a
                        href="mailto:contact@theprize.io"
                        className="text-blue-400 underline"
                      >
                        contact@theprize.io
                      </a>{" "}
                      prior to the Closing Date.
                    </p>
                    <p>11. General</p>
                    <p>
                      11.1 The Promoter reserves the right to amend these terms
                      and conditions from time to time. The latest version of
                      these terms and conditions will be available on the
                      Website.
                    </p>
                    <p>
                      11.2 If there is any reason to believe that there has been
                      a breach of these terms and conditions, the Promoter may,
                      at its sole discretion, reserve the right to exclude you
                      from participating in the competition and any future
                      competitions.
                    </p>
                    <p>
                      11.3 The Promoter reserves the right to hold void,
                      suspend, cancel, or amend the prize competition where it
                      becomes necessary to do so. There is no minimum number of
                      entries and the Promoter will not hold void, suspend,
                      cancel, or amend the prize competition due to a lack of
                      entries.
                    </p>
                    <p>
                      11.4 The competitions on the Website are in no way
                      sponsored, endorsed, administered by or associated with
                      Facebook. By entering the competitions, Entrants agree
                      that Facebook has no liability and is not responsible for
                      the administration or promotion of the competitions.
                    </p>
                    <p>
                      11.5 These terms and conditions shall be governed by
                      English law, and the parties submit to the exclusive
                      jurisdiction of the courts of England and Wales.
                    </p>
                    <p>
                      11.6 You should print a copy of these terms and conditions
                      and keep them for your records.
                    </p>
                  </div>

                  {/* <ol className="list-decimal pl-5 mt-4">
                    <li className="mb-3">
                      <strong>Eligibility</strong>
                      <p>
                        Participation in competitions is open to users who meet
                        the legal age requirements in their jurisdiction. Users
                        are responsible for complying with all local laws
                        regarding online competitions.
                      </p>
                    </li>

                    <li className="mb-3">
                      <strong>Competitions and Prizes</strong>
                      <ul className="list-disc pl-5">
                        <li>
                          <strong>Entry:</strong> To enter, users must purchase 
                          a ticket for the desired competition. Once purchased, 
                          tickets are non-refundable. Alternatively, users can
                          enter for free by sending a postal entry to: 483 Green
                          Lanes, London, N13 4BS. The postal entry must include
                          the entrant’s full name, address, and contact details,
                           along with the specific competition they wish to enter.

                        </li>
                        <li>
                          <strong>Winner Selection:</strong> Winners are
                          selected through Chainlink VRF RNG for transparency
                          and fairness. Results are final and binding.
                        </li>
                        <li>
                          <strong>Prize Distribution:</strong> Winners will
                          receive their prizes in cryptocurrency. Luxury item
                          winners will be contacted for delivery arrangements.
                        </li>
                        <li>
                          <strong>Transparency:</strong> Transaction hashes for
                          prize distribution and RNG processes are available for
                          verification.
                        </li>
                        <li>
                          <strong>What if the competition doesn’t sell out?:</strong>  
                          If a competition doesn't sell out by the end of the timer, 
                          a winner will still be drawn from the total number of 
                          tickets available. If after 20 RNG draws it hasn’t 
                          landed on a purchased ticket, the prize will be rolled 
                          over to the following draw.
                        </li>
                      </ul>
                    </li>

                    <li className="mb-3">
                      <strong>No Refund Policy</strong>
                      <p>
                        All purchases made on THEPRIZE.IO platform, including
                        competition entries, are final and non-refundable. Users
                        are encouraged to review their participation carefully
                        before proceeding with any transaction.
                      </p>
                    </li>

                    <li className="mb-3">
                      <strong>User Conduct</strong>
                      <p>
                        You agree to use our platform lawfully and refrain from
                        actions that harm other users, the platform, or its
                        operations. Breach of these terms may result in
                        suspension or termination of your account.
                      </p>
                    </li>

                    <li className="mb-3">
                      <strong>Limitation of Liability</strong>
                      <p>
                        THEPRIZE.IO is not liable for any loss or damage
                        resulting from competition participation, including but
                        not limited to the value fluctuation of digital
                        currencies. All transactions are made at the user’s
                        risk.
                      </p>
                    </li>

                    <li className="mb-3">
                      <strong>Intellectual Property</strong>
                      <p>
                        All content, including text, graphics, logos, and
                        software, is the property of THEPRIZE.IO or its
                        licensors and is protected by applicable copyright laws.
                      </p>
                    </li>

                    <li className="mb-3">
                      <strong>Modifications</strong>
                      <p>
                        We may amend these Terms at any time. Continued use of
                        the site after changes constitute acceptance of the new
                        terms.
                      </p>
                    </li>
                  </ol>

                  <h3 className="mt-4 text-[22px] font-bold">Contact Us</h3>
                  <p>
                    For inquiries about these Terms & Conditions, reach us at{" "}
                    <a
                      href="mailto:contact@theprize.io"
                      className="text-blue-400 underline"
                    >
                      contact@theprize.io
                    </a>
                    .
                  </p> */}
                </>
              }
            />
          ) : policy === "termsofuse" ? (
            <Policy
              title={
                <h1
                  className="text-[40px] font-extrabold text-white text-center"
                  style={{
                    padding: "20px 0", // Add padding to top and bottom
                  }}
                >
                  TERMS OF USE
                </h1>
              }
              content={
                <div className="terms-and-conditions">
                  <p>
                    PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE
                    USING THIS SITE
                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `
                    These terms tell you the rules for using our website
                    <a href="https://theprize.io/" class="text-blue-500 underline">https://theprize.io/</a> (our site).`,
                    }}
                  ></p>
                  <p>Who we are and how to contact us</p>
                  <p>
                    Our site is a site operated by MVT Management LTD ("We"). We
                    are registered in England and Wales under company number
                    14651255 and have our registered office at 6-7 Waterside
                    Station Road, Harpenden, AL5 4US, England. Our main trading
                    address is 483 Green Lanes, London, N13 4BS. We are a
                    limited company.
                  </p>
                  {/* <p>We are a limited company.]</p> */}
                  <p>
                    To contact us, please email{" "}
                    <a
                      href="mailto:contact@theprize.io"
                      className="text-blue-400 underline"
                    >
                      contact@theprize.io
                    </a>
                  </p>
                  <p>By using our site you accept these terms</p>
                  <p>
                    By using our site, you confirm that you accept these terms
                    of use and that you agree to comply with them.
                  </p>
                  <p>
                    If you do not agree to these terms, you must not use our
                    site.
                  </p>
                  <p>
                    We recommend that you print a copy of these terms for future
                    reference.
                  </p>
                  <p>There are other terms that may apply to you</p>
                  <p>
                    These terms of use refer to the following additional terms,
                    which also apply to your use of our site:
                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: ` • Our Privacy Policy <a href="/policies/privacy" class="text-blue-500 underline">Privacy Policy</a>
                    , which sets out the terms on which we process any
                    personal data we collect from you, or that you provide to
                    us. By using our site, you consent to such processing and
                    you warrant that all data provided by you is accurate.`,
                    }}
                  ></p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `• Our Acceptable Use Policy <a href="/policies/acceptableuse" class="text-blue-500 underline">Acceptable Use Policy</a>,
                    which sets out the permitted uses and
                    prohibited uses of our site. When using our site, you must
                    comply with this Acceptable Use Policy.`,
                    }}
                  />

                  <p>
                    If you purchase goods or services from our site, participate
                    in any promotions or enter any of our competitions, other
                    terms and conditions will apply and which you must accept
                    and abide by.
                  </p>
                  <p>We may make changes to these terms</p>
                  <p>
                    We may amend these terms from time to time. Every time you
                    wish to use our site, please check these terms to ensure you
                    understand the terms that apply at that time.
                  </p>
                  <p>We may make changes to our site</p>
                  <p>
                    We may update and change our site from time to time to
                    reflect changes to our products, services, our users' needs
                    and our business priorities.
                  </p>
                  <p>We may suspend or withdraw our site</p>
                  <p>
                    Our site is made available free of charge but you may have
                    to pay to enter our competitions.
                  </p>
                  <p>
                    We do not guarantee that our site, or any content on it,
                    will always be available or be uninterrupted. We may suspend
                    or withdraw or restrict the availability of all or any part
                    of our site for business and operational reasons. We will
                    try to give you reasonable notice of any suspension or
                    withdrawal.
                  </p>
                  <p>
                    You are also responsible for ensuring that all persons who
                    access our site through your internet connection are aware
                    of these terms of use and other applicable terms and
                    conditions, and that they comply with them.
                  </p>
                  <p>Who can use our site?</p>
                  <p>Our site is only for users in the United Kingdom</p>
                  <p>
                    Our site is directed to people residing in United Kingdom.
                    We do not represent that the content available on or through
                    our site is appropriate for use or available in other
                    locations.
                  </p>
                  <p>You must keep your account details safe</p>
                  <p>
                    If you choose, or you are provided with, a user
                    identification code, password or any other piece of
                    information as part of our security procedures, you must
                    treat such information as confidential. You must not
                    disclose it to any third party.
                  </p>
                  <p>
                    We have the right to disable any user identification code or
                    password, whether chosen by you or allocated by us, at any
                    time, if in our reasonable opinion you have failed to comply
                    with any of the provisions of these terms of use.
                  </p>
                  <p>
                    If you know or suspect that anyone other than you knows your
                    user identification code or password, you must promptly
                    notify us at{" "}
                    <a
                      href="mailto:contact@theprize.io"
                      className="text-blue-400 underline"
                    >
                      contact@theprize.io
                    </a>
                  </p>
                  <p>How you may use material on our site</p>
                  <p>
                    We are the owner or the licensee of all intellectual
                    property rights in our site, and in the material published
                    on it. Those works are protected by copyright laws and
                    treaties around the world. All such rights are reserved.
                  </p>
                  <p>
                    You may print off one copy, and may download extracts, of
                    any page(s) from our site for your personal use and you may
                    draw the attention of others within your organisation to
                    content posted on our site.
                  </p>
                  <p>
                    You must not modify the paper or digital copies of any
                    materials you have printed off or downloaded in any way, and
                    you must not use any illustrations, photographs, video or
                    audio sequences or any graphics separately from any
                    accompanying text.
                  </p>
                  <p>
                    Our status (and that of any identified contributors) as the
                    authors of content on our site must always be acknowledged.
                  </p>
                  <p>
                    You must not use any part of the content on our site for
                    commercial purposes without obtaining a licence to do so
                    from us or our licensors.
                  </p>
                  <p>
                    If you print off, copy or download any part of our site in
                    breach of these terms of use, your right to use our site
                    will cease immediately and you must, at our option, return
                    or destroy any copies of the materials you have made.
                  </p>
                  <p>Do not rely on information on this site</p>
                  <p>
                    The content on our site is provided for general information
                    only. It is not intended to amount to advice on which you
                    should rely. You must obtain professional or specialist
                    advice before taking, or refraining from, any action on the
                    basis of the content on our site.
                  </p>
                  <p>
                    Although we make reasonable efforts to update the
                    information on our site, we make no representations,
                    warranties or guarantees, whether express or implied, that
                    the content on our site is accurate, complete or up to date.
                  </p>
                  <p>We are not responsible for websites we link to</p>
                  <p>
                    Where our site contains links to other sites and resources
                    provided by third parties, these links are provided for your
                    information only. Such links should not be interpreted as
                    approval by us of those linked websites or information you
                    may obtain from them.
                  </p>
                  <p>
                    We have no control over the contents of those sites or
                    resources.
                  </p>
                  <p>User-generated content is not approved by us</p>
                  <p>
                    This website may include information and materials uploaded
                    by other users of the site, including posts made to our
                    social media accounts. This information and these materials
                    have not been verified or approved by us. The views
                    expressed by other users on our site do not represent our
                    views or values.
                  </p>
                  <p>
                    If you wish to complain about information and materials
                    uploaded by other users please contact us at
                    <a
                      href="mailto:contact@theprize.io"
                      className="text-blue-400 underline"
                    >
                      contact@theprize.io
                    </a>
                  </p>
                  <p>Information about our use of cookies</p>
                  <p>
                    Our website uses cookies to distinguish you from other users
                    of our website. This helps us to provide you with a good
                    experience when you browse our website and also allows us to
                    improve our site.
                  </p>
                  <p>
                    By continuing to browse the site, you are agreeing to our
                    use of cookies.
                  </p>
                  <p>
                    A cookie is a small file of letters and numbers that we
                    store on your browser or the hard drive of your computer if
                    you agree. Cookies contain information that is transferred
                    to your computer's hard drive.
                  </p>
                  <p>We use the following cookies:</p>
                  <p>
                    • Strictly necessary cookies. These are cookies that are
                    required for the operation of our website. They include, for
                    example, cookies that enable you to log into secure areas of
                    our website, use a shopping cart or make use of e-billing
                    services.
                  </p>
                  <p>
                    • Analytical/performance cookies. They allow us to recognise
                    and count the number of visitors and to see how visitors
                    move around our website when they are using it. This helps
                    us to improve the way our website works, for example, by
                    ensuring that users are finding what they are looking for
                    easily.
                  </p>
                  <p>
                    • Functionality cookies. These are used to recognise you
                    when you return to our website. This enables us to
                    personalise our content for you, greet you by name and
                    remember your preferences (for example, your choice of
                    language or region).
                  </p>
                  <p>
                    • Targeting cookies. These cookies record your visit to our
                    website, the pages you have visited and the links you have
                    followed. We will use this information to make our website
                    and the advertising displayed on it more relevant to your
                    interests. We may also share this information with third
                    parties for this purpose.
                  </p>
                  <p>
                    Please note that third parties (including, for example,
                    advertising networks and providers of external services like
                    web traffic analysis services) may also use cookies, over
                    which we have no control. These cookies are likely to be
                    analytical/performance cookies or targeting cookies.
                  </p>
                  <p>
                    You can block cookies by activating the setting on your
                    browser that allows you to refuse the setting of all or some
                    cookies. However, if you use your browser settings to block
                    all cookies (including essential cookies) you may not be
                    able to access all or parts of our site.
                  </p>
                  <p>Our responsibility for loss or damage suffered by you</p>
                  <p>
                    • We do not exclude or limit in any way our liability to you
                    where it would be unlawful to do so. This includes liability
                    for death or personal injury caused by our negligence or the
                    negligence of our employees, agents or subcontractors and
                    for fraud or fraudulent misrepresentation.
                  </p>
                  <p>
                    • Different limitations and exclusions of liability will
                    apply to liability arising as a result of the supply of any
                    products or services to you or if you enter our
                    competitions, which will be set out in our Terms and
                    Conditions.
                  </p>
                  <p>
                    • Please note that we only provide our site for domestic and
                    private use. You agree not to use our site for any
                    commercial or business purposes, and we have no liability to
                    you for any loss of profit, loss of business, business
                    interruption, or loss of business opportunity.
                  </p>
                  <p>Uploading content to our site</p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: ` Whenever you make use of a feature that allows you to upload
                    content to our site, post to our social media accounts or to
                    make contact with other users of our site, you must comply
                    with the content standards set out in our Acceptable Use
                    Policy <a href="/policies/acceptableuse" class="text-blue-500 underline">Acceptable Use Policy</a>.`,
                    }}
                  ></p>
                  <p>
                    You warrant that any such contribution does comply with
                    those standards, and you will be liable to us and indemnify
                    us for any breach of that warranty. This means you will be
                    responsible for any loss or damage we suffer as a result of
                    your breach of warranty.
                  </p>
                  <p>
                    Any content you upload to our site will be considered
                    non-confidential and non-proprietary. You retain all of your
                    ownership rights in your content, but you are required to
                    grant us a limited licence to use, store and copy that
                    content and to distribute and make it available to third
                    parties. The rights you license to us are described below.
                  </p>
                  <p>
                    We also have the right to disclose your identity to any
                    third party who is claiming that any content posted or
                    uploaded by you to our site constitutes a violation of their
                    intellectual property rights, or of their right to privacy.
                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: ` We have the right to remove any posting you make on our site
                    if, in our opinion, your post does not comply with the
                    content standards set out in our Acceptable Use Policy
                    <a href="/policies/acceptableuse" class="text-blue-500 underline">Acceptable Use Policy</a>.`,
                    }}
                  ></p>
                  <p>
                    You are solely responsible for securing and backing up your
                    content.
                  </p>
                  <p>Rights you are giving us to use material you upload</p>
                  <p>
                    When you upload or post content to our site, you grant us a
                    perpetual, worldwide, non-exclusive, royalty-free,
                    transferable licence to use, reproduce, distribute, prepare
                    derivative works of, display, and perform that
                    user-generated content. We may also share it, quote from it
                    and use it to promote our site, products and services,
                    particularly via social media.
                  </p>
                  <p>
                    We are not responsible for viruses and you must not
                    introduce them
                  </p>
                  <p>
                    We do not guarantee that our site will be secure or free
                    from bugs or viruses.
                  </p>
                  <p>
                    You are responsible for configuring your information
                    technology, computer programmes and platform to access our
                    site. You should use your own virus protection software.
                  </p>
                  <p>
                    You must not misuse our site by knowingly introducing
                    viruses, trojans, worms, logic bombs or other material that
                    is malicious or technologically harmful. You must not
                    attempt to gain unauthorised access to our site, the server
                    on which our site is stored or any server, computer or
                    database connected to our site. You must not attack our site
                    via a denial-of-service attack or a distributed denial-of
                    service attack. By breaching this provision, you would
                    commit a criminal offence under the Computer Misuse Act
                    1990. We will report any such breach to the relevant law
                    enforcement authorities and we will co-operate with those
                    authorities by disclosing your identity to them. In the
                    event of such a breach, your right to use our site will
                    cease immediately.
                  </p>
                  <p>Rules about linking to our site</p>
                  <p>
                    You may link to our home page, provided you do so in a way
                    that is fair and legal and does not damage our reputation or
                    take advantage of it.
                  </p>
                  <p>
                    You must not establish a link in such a way as to suggest
                    any form of association, approval or endorsement on our part
                    where none exists.
                  </p>
                  <p>
                    You must not establish a link to our site in any website
                    that is not owned by you.
                  </p>
                  <p>
                    Our site must not be framed on any other site, nor may you
                    create a link to any part of our site other than the home
                    page.
                  </p>
                  <p>
                    We reserve the right to withdraw linking permission without
                    notice.
                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `The website in which you are linking must comply in all
                    respects with the content standards set out in our
                    Acceptable Use Policy <a href="/policies/acceptableuse" class="text-blue-500 underline">Acceptable Use Policy</a>.`,
                    }}
                  ></p>
                  <p>
                    If you wish to link to or make any use of content on our
                    site other than that set out above, please contact
                    <a
                      href="mailto:contact@theprize.io"
                      className="text-blue-400 underline"
                    >
                      contact@theprize.io
                    </a>
                  </p>
                  <p>Which country's laws apply to any disputes?</p>
                  <p>
                    These terms of use, their subject matter and their
                    formation, are governed by English law. You and we both
                    agree that the courts of England and Wales will have
                    exclusive jurisdiction to deal with any disputes between us.
                  </p>
                </div>
              }
            />
          ) : policy === "acceptableuse" ? (
            <Policy
              title={
                <h1
                  className="text-[40px] font-extrabold text-white text-center"
                  style={{
                    padding: "20px 0", // Add padding to top and bottom
                  }}
                >
                  ACCEPTABLE USE POLICY
                </h1>
              }
              content={
                <div className="acceptable-use-policy">
                  <p>About us</p>
                  <p>
                    This acceptable use policy sets out the terms between you
                    and us under which you may access our website
                    <a
                      href="https://theprize.io/"
                      className="text-blue-500 underline"
                    >
                      theprize.io
                    </a>{" "}
                    This acceptable use policy applies to all users of, and
                    visitors to, our site.
                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `
                    Your use of our site means that you accept, and agree to
                    abide by, all the policies in this acceptable use policy,
                    which supplement our terms of website use here 
                    <a href="/policies/termsofuse" class="text-blue-500 underline">Terms of Use</a>.`,
                    }}
                  ></p>
                  <p>
                    Our site is a site operated by MVT Management LTD ("We"). We
                    are registered in England and Wales under company number
                    14651255 and have our registered office at 6-7 Waterside
                    Station Road, Harpenden, AL5 4US, England
                  </p>
                  <p>
                    To contact us, please email{" "}
                    <a
                      href="mailto:contact@theprize.io"
                      className="text-blue-400 underline"
                    >
                      contact@theprize.io
                    </a>
                  </p>
                  <p>Prohibited uses</p>
                  <p>
                    You may use our site only for lawful purposes. You may not
                    use our site:
                  </p>
                  <p>
                    • In any way that breaches any applicable local, national or
                    international law or regulation.
                  </p>
                  <p>
                    • In any way that is unlawful or fraudulent, or has any
                    unlawful or fraudulent purpose or effect.
                  </p>
                  <p>
                    • For the purpose of harming or attempting to harm minors in
                    any way.
                  </p>
                  <p>
                    • To send, knowingly receive, upload, download, use or
                    re-use any material which does not comply with our content
                    standards as set out below.
                  </p>
                  <p>
                    • To transmit, or procure the sending of, any unsolicited or
                    unauthorised advertising or promotional material or any
                    other form of similar solicitation (spam).
                  </p>
                  <p>
                    • To knowingly transmit any data, send or upload any
                    material that contains viruses, Trojan horses, worms,
                    time-bombs, keystroke loggers, spyware, adware or any other
                    harmful programs or similar computer code designed to
                    adversely affect the operation of any computer software or
                    hardware.
                  </p>
                  <p>You also agree:</p>
                  <p>
                    • Not to reproduce, duplicate, copy or re-sell any part of
                    our site in contravention of the provisions of our terms of
                    website use here{" "}
                    <a
                      href="/policies/termsofuse"
                      className="text-blue-500 underline"
                    >
                      Terms of Use
                    </a>
                    .
                  </p>
                  <p>
                    • Not to access without authority, interfere with, damage or
                    disrupt:
                  </p>
                  <p>• any part of our site;</p>
                  <p>• any equipment or network on which our site is stored;</p>
                  <p>• any software used in the provision of our site; or</p>
                  <p>
                    • any equipment or network or software owned or used by any
                    third party.
                  </p>
                  <p>Interactive services</p>
                  <p>
                    We may from time to time provide interactive services on our
                    site, for example, the ability to participate in games,
                    competitions, comment on blog posts or post to our social
                    media accounts (interactive services).
                  </p>
                  <p>
                    Where we do provide any interactive service, we will provide
                    clear information to you about the kind of service offered,
                    if it is moderated and what form of moderation is used
                    (including whether it is human or technical).
                  </p>
                  <p>
                    We will do our best to assess any possible risks for users
                    (and in particular, for children) from third parties when
                    they use any interactive service provided on our site, and
                    we will decide in each case whether it is appropriate to use
                    moderation of the relevant service (including what kind of
                    moderation to use) in the light of those risks. However, we
                    are under no obligation to oversee, monitor or moderate any
                    interactive service we provide on our site, and we expressly
                    exclude our liability for any loss or damage arising from
                    the use of any interactive service by a user in
                    contravention of our content standards, whether the service
                    is moderated or not.
                  </p>
                  <p>
                    The use of any of our interactive services by a minor is
                    subject to the consent of their parent or guardian. We
                    advise parents who permit their children to use an
                    interactive service that it is important that they
                    communicate with their children about their safety online,
                    as moderation is not fool proof. Minors who are using any
                    interactive service should be made aware of the potential
                    risks to them.
                  </p>
                  <p>
                    Where we do moderate an interactive service, we will
                    normally provide you with a means of contacting the
                    moderator, should a concern or difficulty arise.
                  </p>
                  <p>Content standards</p>
                  <p>
                    These content standards apply to any and all material which
                    you contribute to our site (contributions), and to any
                    interactive services associated with it.
                  </p>
                  <p>
                    You must comply with the spirit and the letter of the
                    following standards. The standards apply to each part of any
                    contribution as well as to its whole.
                  </p>
                  <p>Contributions must:</p>
                  <p>• Be accurate (where they state facts).</p>
                  <p>• Be genuinely held (where they state opinions).</p>
                  <p>
                    • Comply with applicable law in the UK and in any country
                    from which they are posted.
                  </p>
                  <p>Contributions must not:</p>
                  <p>
                    • Contain any material which is defamatory of any person.
                  </p>
                  <p>
                    • Contain any material which is obscene, offensive, hateful
                    or inflammatory.
                  </p>
                  <p>• Promote sexually explicit material.</p>
                  <p>• Promote violence.</p>
                  <p>
                    • Promote discrimination based on race, sex, religion,
                    nationality, disability, sexual orientation or age.
                  </p>
                  <p>
                    • Infringe any copyright, database right or trade mark of
                    any other person.
                  </p>
                  <p>• Be likely to deceive any person.</p>
                  <p>
                    • Be made in breach of any legal duty owed to a third party,
                    such as a contractual duty or a duty of confidence.
                  </p>
                  <p>• Promote any illegal activity.</p>
                  <p>
                    • Be threatening, abuse or invade another’s privacy, or
                    cause annoyance, inconvenience or needless anxiety.
                  </p>
                  <p>
                    • Be likely to harass, upset, embarrass, alarm or annoy any
                    other person.
                  </p>
                  <p>
                    • Be used to impersonate any person, or to misrepresent your
                    identity or affiliation with any person.
                  </p>
                  <p>
                    • Give the impression that they emanate from us, if this is
                    not the case.
                  </p>
                  <p>
                    • Advocate, promote or assist any unlawful act such as (by
                    way of example only) copyright infringement or computer
                    misuse.
                  </p>
                  <p>Suspension and termination</p>
                  <p>
                    We will determine, in our discretion, whether there has been
                    a breach of this acceptable use policy through your use of
                    our site. When a breach of this policy has occurred, we may
                    take such action as we deem appropriate.
                  </p>
                  <p>
                    Failure to comply with this acceptable use policy
                    constitutes a material breach of the terms of use
                    <a
                      href="/policies/termsofuse"
                      className="text-blue-500 underline"
                    >
                      Terms of Use
                    </a>{" "}
                    upon which you are permitted to use our site, and may result
                    in our taking all or any of the following actions:
                  </p>
                  <p>
                    • Immediate, temporary or permanent withdrawal of your right
                    to use our site.
                  </p>
                  <p>
                    • Immediate, temporary or permanent removal of any posting
                    or material uploaded by you to our site.
                  </p>
                  <p>• Issue of a warning to you.</p>
                  <p>
                    • Legal proceedings against you for reimbursement of all
                    costs on an indemnity basis (including, but not limited to,
                    reasonable administrative and legal costs) resulting from
                    the breach.
                  </p>
                  <p>• Further legal action against you.</p>
                  <p>
                    • Disclosure of such information to law enforcement
                    authorities as we reasonably feel is necessary.
                  </p>
                  <p>
                    We exclude liability for actions taken in response to
                    breaches of this acceptable use policy. The responses
                    described in this policy are not limited, and we may take
                    any other action we reasonably deem appropriate.
                  </p>
                  <p>Changes to the acceptable use policy</p>
                  <p>
                    We may revise this acceptable use policy at any time by
                    amending this page. You are expected to check this page from
                    time to time to take notice of any changes we make, as they
                    are legally binding on you. Some of the provisions contained
                    in this acceptable use policy may also be superseded by
                    provisions or notices published elsewhere on our site.
                  </p>
                </div>
              }
            />
          ) : null}
        </div>
        <AdvertBox />
        <PhoneStores background="bg-gradient-to-r from-[#a51269] to-[#2b2b2b] h-screen " />
      </div>
    </>
  );
};

export default WithNavbarAndFooter(Policies);
