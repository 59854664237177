import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { useWallet } from "../Contexts/WalletContext";
import Auth from "../Pages/Auth";
import CompetitionDetailPage from "../Pages/CompetitionDetailPage";
import CompetitionPage from "../Pages/CompetitionPage";
import CompetitionResultPage from "../Pages/CompetitionResultPage";
import Competitions from "../Pages/Dashboard/Competitions";
import EnteredCompetitions from "../Pages/Dashboard/EnteredCompetitions";
import Home from "../Pages/Dashboard/Home";
import ResetPassword from "../Pages/Dashboard/ResetPassword";
import User from "../Pages/Dashboard/User";
import FinishedCompetitionDetailPage from "../Pages/FinishedCompetitionDetailPage";
import HowToPlayPage from "../Pages/HowToPlayPage";
import InstantWinCompetitionPage from "../Pages/InstantWinCompetitionPage";
import LandingPage from "../Pages/LandingPage";
import LiveCompetitionDetailPage from "../Pages/LiveCompetitionDetailPage";
import Policies from "../Pages/Policies";
import PurchaseTickets from "../Pages/PurchaseTickets";
import RandomNumberGeneratorPage from "../Pages/RandomNumberGeneratorPage";
import ResetPasswordScreen from "../Pages/ResetPasswordScreen";
import BuyTickets from "../Pages/User/BuyTickets/BuyTickets";
import EditUser from "../Pages/User/EditUser/EditUser";
import MyAccount from "../Pages/User/MyAccount/MyAccount";
import MyEntries from "../Pages/User/MyEntries/MyEntries";
import MyOrders from "../Pages/User/MyOrders/MyOrders";
import MyWallet from "../Pages/User/MyWallet/MyWallet";
import ViewEntry from "../Pages/User/ViewEntry/ViewEntry";
import ViewOrder from "../Pages/User/ViewOrder/ViewOrder";
import ViewResult from "../Pages/User/ViewResult/ViewResult";
import WinnersPage from "../Pages/WinnersPage";
import { ProtectedRoute } from "./ProtectedRoute";

// Define a type for route configuration
type Route = RouteObject & { children?: RouteObject[] };

const Routes = () => {
  const { token } = useWallet();
  const storedToken = localStorage.getItem("token");

  // Define public routes accessible to all users
  const routesForPublic: Route[] = [
    { path: "/", element: <LandingPage /> },
    // { path: "/resetpassword", element: <ResetPasswordScreen /> },
    { path: "/competitions/:all/*", element: <CompetitionPage /> },
    { path: "/competition/:id", element: <CompetitionDetailPage /> },
    {
      path: "/finished-competition/:id",
      element: <FinishedCompetitionDetailPage />,
    },
    {
      path: "/live-competition/:id",
      element: <LiveCompetitionDetailPage />,
    },
    { path: "/competitionResult/:id", element: <CompetitionResultPage /> },
    { path: "/howtoplay", element: <HowToPlayPage /> },
    { path: "/winners", element: <WinnersPage /> },
    { path: "/policies/:policy", element: <Policies /> },
    { path: "/wallet", element: <MyWallet /> },
    { path: "/entries/live", element: <MyEntries type="live" /> },
    { path: "/entries/finished", element: <MyEntries type="finished" /> },
    { path: "/orders", element: <MyOrders /> },
    { path: "/account", element: <MyAccount /> },
    { path: "/buytickets", element: <BuyTickets /> },
    { path: "/edituser", element: <EditUser /> },
    {
      path: "/view/order/:id",
      element: <ViewOrder />,
    },
    {
      path: "/view/entry/:id",
      element: <ViewEntry />,
    },
    {
      path: "/view/result/:id",
      element: <ViewResult />,
    },

    {
      path: "/view/competition/instant-win/:id",
      //   element: <InstantWinCompetitionPage />,
      element: <InstantWinCompetitionPage />,
    },
    {
      path: "/rng",
      element: <RandomNumberGeneratorPage />,
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly: Route[] = [
    {
      path: "/user",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        { path: "dashboard", element: <Home /> },
        {
          path: "dashboard/entered_competitions",
          element: <EnteredCompetitions />,
        },
        { path: "dashboard/user", element: <User /> },
        {
          path: "dashboard/reset_password",
          element: <ResetPassword />,
        },
        {
          path: "dashboard/competition/:id",
          element: <Competitions />,
        },
        { path: "resetpassword", element: <ResetPasswordScreen /> },
        { path: "purchase_tickets", element: <PurchaseTickets /> },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly: Route[] = [
    { path: "/", element: <LandingPage /> },
    { path: "/auth/:portal", element: <Auth /> },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  const publicRouter = createBrowserRouter(routesForPublic);

  if (storedToken && !token) {
    return (
      // <OverlayLoader message="Reconnecting to wallet ..." />
      <RouterProvider router={publicRouter}/>
    );
    // return <OverlayLoader message="Reconnecting to wallet ..." />; // Placeholder during loading
  }

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
