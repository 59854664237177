import React from "react";
import { useWallet } from "../../../Contexts/WalletContext";
import { Common } from "../../common/Common";
import {
  copyToClipboard,
  copyToClipboardTrx,
} from "../../../utils/CopyToClipboard";

interface ITableSmallValue {
  text: string;
  copyable?: boolean;
  trxHash?: boolean;
}

export default function TableSmallValue(props: ITableSmallValue) {
  const { network } = useWallet();

  const truncatedTitle =
    props.text.length > 12 ? props.text.slice(0, 10) + "..." : props.text;
  return (
    <div className="flex justify-end items-start gap-4">
      <Common.typography
        fontFamily="Sequel100Wide95"
        fontSize="14px"
        fontSizeSmall="14px"
        color="#ffffff"
      >
        {truncatedTitle}
      </Common.typography>
      {props.copyable && (
        <Common.image
          src="/Images/copy-white.svg"
          alt="copy"
          width="24px"
          height="24px"
          heightSmall="24px"
          widthSmall="24px"
          objectFit="contain"
          objectPosition="center"
          onClick={() => {
            if (props.trxHash) {
              const isProduction = process.env.REACT_APP_ENV === "production";
              let preText;

              if (!isProduction) {
                preText = `https://sepolia.etherscan.io/tx/`;
              } else {
                preText = `https://basescan.org/tx/`;
              }
              copyToClipboard(preText + props.text);
            } else {
              copyToClipboard(props.text);
            }
          }}
        />
      )}
    </div>
  );
}
