/**
 * This component is used to render the input field for the wallet page.
 * Structure:
 * - Main container
 * - Label = 1
 * - Small Save button = 2
 * - Input field = 3
 * - Save button = 4
 * @param {WalletFieldProps} props - The properties object.
 * @param {string} props.label - The label for the input field.
 * @param {string} props.type - The type of the input field.
 * @param {Function} props.onSave - The function to call when the value is saved.
 * @returns {JSX.Element} The rendered WalletField component.
 */
interface WalletFieldProps {
  label: string;
  type: string;
  value: string | null;
  onChange: (value: string, label: string) => void;
  onSave: () => void;
}
const sizeManupulation = (size: string) =>
  size === "small" ? "sm:block md:block hidden" : "sm:hidden md:hidden block";
const reusables = {
  button: ({ size, onClick }: { size: string; onClick: () => void }) => {
    return (
      <button
        className={`${sizeManupulation(
          size
        )} bg-primary uppercase w-full max-w-[101.66px] sm_md:min-w-[108px] sm_md:text-[12px] h-[42px] sm_md:h-[31px] font-[Sequel100Wide95] rounded-full`}
        onClick={onClick}
      >
        Save
      </button>
    );
  },
};
export default function WalletField(props: WalletFieldProps) {
  return (
    // Main container
    <div className="flex gap-5 sm_md:gap-[10px] items-center sm_md:flex-wrap justify-start">
      {/* 1 - Label */}
      <p className="font-[Sequel100Wide95] text-[16px] text-primary min-w-[147px] h-[31px] flex items-center sm_md:min-w-0 overflow-hidden flex-1 sm_md:border-[1px] sm_md:border-primary sm_md:rounded-full sm_md:px-[17px]">
        {props.label}
      </p>
      {/* 2 Small Save button */}
      {reusables.button({
        size: "small",
        onClick: () => {
          props.onSave();
        },
      })}
      {/* 3 - Input field */}
      <input
        className="w-full flex justify-center items-center text-white text-[16px] sm_md:text-[12px] //max-w-[557.24px] sm_md:max-w-full h-[42px] sm_md:h-[31px] bg-[#505050] rounded-full font-[MontBlancSemiBold] px-[16.3px] sm_md:px-[17px] placeholder:text-white placeholder:text-[16px]  placeholder:h-fit placeholder:flex placeholder:justify-center placeholder:items-center sm_md:placeholder:text-[12px] placeholder:leading-[42px] sm_md:placeholder:leading-[31px]"
        placeholder="Enter Wallet Address"
        type={props.type}
        value={props.value || ""}
        onChange={(e) => props.onChange(e.target.value, props.label)}
      />
      {/* 4 - Save button */}
      {reusables.button({
        size: "big",
        onClick: () => {
          props.onSave();
        },
      })}
    </div>
  );
}
