/* eslint-disable @typescript-eslint/no-explicit-any */
import EventEmitter from "events";
import { MetamaskConnector } from "../Connectors/MetamaskConnector";
import { PhantomConnector } from "../Connectors/PhantomConnector";
import { WalletConnectConnector } from "../Connectors/WalletConnectConnector";
import { PublicKey } from "@solana/web3.js";
import { Eip1193Provider } from "ethers";

export interface WalletConnector extends EventEmitter {
  connect(chain: string): Promise<void>;
  reconnect(chain: string, account: string): Promise<void>;
  disconnect(): Promise<void>;
  authenticate(): Promise<string>;
  callContractMethod(
    contractAddress: string,
    abi: any[],
    methodName: string,
    args: any[],
    isWrite: boolean,
    value?: string,
    additionalKeys?: Array<{
      pubkey: PublicKey;
      propertyName: string;
    }>
  ): Promise<any>;
  sendTransaction(to: string, value: string, data?: string): Promise<any>;
  getBalance?: (address: string) => Promise<string>;
  listenToContractEvent(
    contractAddress: string,
    abi: any[],
    eventName: string,
    callback: (eventData: any) => void
  ): Promise<void>;
  stopListeningToContractEvent(
    contractAddress: string,
    abi: any[],
    eventName: string
  ): Promise<void>;
}

export class WalletConnectorFactory {
  static createConnector(
    provider: string,
    metamaskProvider?: Eip1193Provider
  ): WalletConnector {
    switch (provider) {
      case "metamask":
        return new MetamaskConnector(metamaskProvider);
      case "phantom":
        return new PhantomConnector();
      case "walletconnect":
        return new WalletConnectConnector();
      default:
        throw new Error("Unsupported wallet provider.");
    }
  }
}
