import React, { useEffect } from "react";
import { Container, getSize, Text } from "../react-responsive-utilities/src";
import moment from "moment";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
import VSpacer from "../common/Spacer/VSpacer";
export interface CompetitionImages {
  UID: string;
  competitionID: string;
  imageurl: string;
}
export interface CompetitionProps {
  live?: boolean;
  time?: { days: number; hours: number; minutes: number; seconds: number };
  prizeName?: string;
  price?: number;
  ticketsLeft?: number;
  prizeIcon?: string;
  onClick?: (ticket: string) => void;
  timerBg?: string;
  borderColor?: string;
  finished?: boolean;
  width?: string;
  overlay?: boolean;
  UID?: string;
  categroy?: string;
  category?: string;
  competitionenddate?: Date | undefined;
  competitioninformation?: string;
  competitionname?: string | undefined;
  competitionticketsize?: number | undefined;
  featured?: number;
  imageurl?: string;
  legalinformation?: string;
  manual?: string;
  totalticketsbought?: number | undefined;
  numberofticketsbought?: number | undefined;
  minnumberoftickets?: number | undefined;
  isHighRoller?: boolean;
  instant?: boolean;
  isCompetitionFinished?: boolean;
  imageUrls?: CompetitionImages[];
}
export const Competitions: React.FC<CompetitionProps> = ({
  live,
  time,
  prizeName,
  price,
  prizeIcon,
  onClick,
  borderColor,
  finished,
  overlay,
  UID,
  categroy,
  competitionenddate,
  competitioninformation,
  competitionname,
  competitionticketsize,
  featured,
  imageurl = "/Images/No Image.svg",
  legalinformation,
  manual,
  totalticketsbought,
  minnumberoftickets,
  isHighRoller,
  instant,
  isCompetitionFinished = false,
  imageUrls,
}) => {
  // const [competitionImages, setCompetitionImages] = React.useState<
  //   CompetitionImages[]
  // >([]);

  // const [status, setStatus] = React.useState<string | null>("");
  // useEffect(() => {
  //   async function fetchCompetitionStatus() {
  //     if (UID) {
  //       const res = await getCompetitionStatus(UID);
  //       if (res && res.status) {
  //         console.log("statusabc", res.status);
  //         setStatus(res.status);
  //       }
  //     }
  //   }
  //   const isLogged = localStorage.getItem("token");
  //   if (isLogged) fetchCompetitionStatus();
  // }, [UID]);

  const sold = 700;

  // const getImages = async (uid: string) => {
  //   const images = await getCompetitionImages({ competitionid: uid ?? "" });
  //   setCompetitionImages(images);
  // };

  // useEffect(() => {
  //   if (UID) {
  //     getImages(UID);
  //   }
  // }, [UID]);
  // check if competition is finished
  const finishedCompetition = () => {
    const currentDate = new Date();
    const competitionEndDate = new Date(competitionenddate ?? new Date());
    if (currentDate > competitionEndDate) {
      return true;
    } else {
      return false;
    }
  };

  const ticketSold = () => {
    if (totalticketsbought && competitionticketsize) {
      const sold = ((totalticketsbought / competitionticketsize) * 100).toFixed(
        0
      );
      return sold;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    // console.log(
    //   "competition name",
    //   competitionname,
    //   "sold",
    //   ticketSold(),
    //   "tickets bought",
    //   totalticketsbought,
    //   "ticket size",
    //   competitionticketsize
    // );

    //creating table of above data

    const data = [
      {
        UID,
        competitionname,
        ticketSold,
        totalticketsbought,
        competitionticketsize,
      },
    ];
  }, [totalticketsbought, competitionticketsize]);

  const navigate = useNavigate();

  const navigateToResult = (competition?: any, UID?: string) => {
    navigate(`/competitionResult/${UID}`);
  };

  function loadCompetition(UID?: string) {
    const isLogged = localStorage.getItem("token");
    // if (isLogged) {
    // if (instant && isCompetitionFinished == false) {
    //     navigate(`/view/competition/instant-win/${UID}`);
    // } else if (isCompetitionFinished) {
    //     navigate(`/finished-competition/${UID}`);
    //     // navigate(`/competition/${UID}`);
    // } else {
    //     navigate(`/live-competition/${UID}`);
    // }
    // }
  }
  const handleClick = (ticket: string) => {
    const isLogged = localStorage.getItem("token");
    console.log("consition", isLogged && finishedCompetition());
    if (isLogged !== null && finishedCompetition()) {
      navigateToResult(competitionname, UID);
    } else {
      loadCompetition(UID);
    }
  };

  const getTimeLeft = () => {
    const now = moment();
    const end = moment(competitionenddate);
    const duration = moment.duration(end.diff(now));

    if (duration.asSeconds() > 0) {
      const days = Math.floor(duration.asDays());
      const hours = Math.floor(duration.hours());
      const minutes = Math.floor(duration.minutes());
      const seconds = Math.floor(duration.seconds());

      // Formatting each unit with leading zeros if needed
      const formattedDays = String(days).padStart(2, "0");
      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return "00:00:00:00";
    }
  };

  return (
    <Container
      width={[333.72, 333.72, 425.39]}
      height={[474.34, 474.34, 604.63]}
      className=" flex flex-col justify-end relative overflow-hidden max-w-[100%] sm:min-w-[80%] sm:!h-[120vw] sm:!box-border "
    >
      <Container
        width={[0, 140.34, 243.31]}
        height={[19.42, 19.42, 33.67]}
        className={
          "absolute top-0 !h-[45.04px] !w-[calc(100%-51.76px)] rounded-full flex flex-row justify-between *:m-auto left-2/4 -translate-x-2/4 z-20 sm:!w-[80%] sm:!h-[8%] " +
          (finishedCompetition() ? "bg-[#EF008F]" : "bg-white")
        }
      >
        {finishedCompetition() ? (
          // between-200-299:text-[0.2rem] sm:text-[0.3rem]  text-[11.8px] between-362-499:text-[0.3rem]
          // <h3 className="truncate font-extrabold text[#1A1A1A]  font-[Sequel100Wide] text-[0.8vw] lg:text-[1vw] md:text-[1.1vw] //sm:text-[1.5vw] above-1821:text-[0.5vw] above-2732:text-[0.3vw] 1366:text-[0.9vw] sm:!text-[4vw]">
          //   COMPETITION FINISHED
          // </h3>
          <Text
            textSize={[0, 12.59, 16]}
            className="text-black font-[Sequel100Wide95] //sm:!text-[3vw] text-nowrap"
          >
            COMPETITION FINISHED
          </Text>
        ) : (
          <>
            {/* timer */}
            <div>
              {/* clock */}
              <img
                src="/Images/clock.svg"
                alt=""
                className="w-[25.62px] h-[28.96px]"
              />
            </div>
            <div>
              {/* counter */}
              <Text
                textSize={[0, 27.07, 29.6]}
                className="block font-[MontBlancBlack]"
              >
                <Countdown
                  //   style={{
                  //     fontSize: getSize([0, 12.76, 22.13]),
                  //   }}
                  className="block font-[MontBlancBlack] //sm:!text-[6vw]"
                  date={competitionenddate}
                />
              </Text>
              {/* <Text
            textSize={[0, 12.76, 22.13]}
            className="block font-[MontBlancBlack]"
          >
            {competitionenddate.split("T")[1].split(".")[0]}
          </Text> */}
            </div>
            <div className="*:leading-3 font-[MontBlancBlack]">
              <img src="/Images/clock.svg" alt="" className="w-auto" />
            </div>
          </>
        )}
      </Container>

      <Container
        height={[474.34 + 20.67, 474.34 + 20.67, 604.63 - 45.04 / 2]}
        width={[0, "full", "full"]}
        doNotResizeWidth
        className="w-full relative z-10  rounded-[20px] border-2 border-white overflow-hidden sm:!h-[95%]"
      >
        {/* sub container */}
        <Container
          height={[474.34, 474.34, 604.63 - 254.63 + 18]}
          width={[0, "full", "full"]}
          doNotResizeWidth
          className=" w-full overflow-hidden relative sm:!h-[calc((100%-254.63px)+50px)] !h-[calc((100%-254.63px)+28px)]"
        >
          {/* image */}
          <img
            src={
              imageUrls && imageUrls[0]?.imageurl?.includes("http")
                ? imageUrls[0]?.imageurl
                : "/Images/No Image.svg"
            }
            onError={(e) => {
              e.currentTarget.src = "/Images/No Image.svg";
            }}
            onEnded={(e) => {
              e.currentTarget.src = "/Images/No Image.svg";
            }}
            alt=""
            className="w-full object-cover object-center absolute top-0 h-full"
          />
        </Container>
        <div className="absolute bottom-0 left-0 w-full">
          {/* This is high roller container */}
          {isHighRoller && (
            <div className="grid grid-cols-3 gap-16 grid-rows-1 w-[calc(100%-29.1px)] place-content-center place-items-center m-auto bg-[#EF008F] rounded-t-[26.75px] h-[40.68px]">
              <img
                src="/Images/high_roller_dice.svg"
                alt=""
                className="h-[25.42px]"
              />
              <p className="font-[Sequel100Wide95] text-nowrap text-white uppercase text-[20.07] sm:text-[clamp(0px,14px,15.72px)]">
                High rollers
              </p>
              <img
                src="/Images/high_roller_dice.svg"
                alt=""
                className="h-[25.42px]"
              />
            </div>
          )}
          {Boolean(instant) && (
            <div className="grid grid-cols-3 gap-16 grid-rows-1 w-[calc(100%-29.1px)] place-content-center place-items-center m-auto bg-white rounded-t-[26.75px] h-[40.68px]">
              <img src="/Images/gift.svg" alt="" className="h-[25.42px]" />
              <p className="font-[Sequel100Wide95] text-nowrap text-black uppercase text-[20.07] sm:text-[clamp(0px,14px,15.72px)]">
                Instant Win
              </p>
              <img src="/Images/gift.svg" alt="" className="h-[25.42px]" />
            </div>
          )}
          <Container
            width={[0, "full", "full"]}
            doNotResizeWidth
            height={[254.63, 254.63, 474.34]}
            className=" bg-[#343434] px-[13.44px] rounded-[20px] //sm:!h-[40%] sm:!flex sm:!flex-col sm:!justify-between //sm:!py-4 !h-fit"
          >
            <VSpacer small={19.21} big={21.72} />
            {/* container for details */}
            <Container
              // width={[0, 197.93, 291.13]}
              width={[0, "full", "full"]}
              doNotResizeWidth
              height={[26.37, 26.37, 45.72]}
              className="rounded-full bg-[#dde404] m-auto //mt-[9.36px] flex items-center justify-center //sm:!h-1/4 sm:!my-0 !h-[54.08px]" /////////////////////////////////////////////////////////////////////////////
              // style={{
              //   marginTop: "clamp(9.37px, 0vw, 16.23px)",
              // }}
            >
              {/* competition name */}
              <Text
                textSize={[0, 23.66, 24.08]}
                className="text-center text-black block font-[Sequel100Wide95] truncate px-[19.41px] sm:!text-[3.5vw] "
              >
                {competitionname}
              </Text>
            </Container>
            <VSpacer small={14.65} big={16.58} />
            <div
              className="w-full flex flex-col gap-[5.21px] //sm:!mt-[14%]"
              // style={{
              //   marginTop: "clamp(7.14px, 0vw, 12.4px)",
              // }}
            >
              {/* progressbar */}

              <Container
                //width={[0, 161.51, 280]}
                width={[0, "full", "full"]}
                doNotResizeWidth
                height={[2.88, 2.88, 5]}
                className="progressbar"
              >
                <div
                  style={{
                    height: "100%",
                    width: `${ticketSold()}%`,
                    transition: "width 0.5s",
                  }}
                  className="bg-secondary"
                ></div>
              </Container>
              {/* <span className="text-white text-[15px]  //text-[11px] sm:text-[9px] between-200-299:text-[0.3rem] font-[MontBlancRegular] uppercase">
              {ticketSold()} <span className="font-[MontBlancRegular]">%</span>{" "}
              tickets sold
            </span> */}
              <Text
                className="text-white font-[Sequel100Wide-65] uppercase sm:!text-[4vw] text-center"
                textSize={[0, 18, 20.07]}
              >
                {ticketSold()}{" "}
                <span className="font-[Sequel100Wide-65]">%</span> tickets sold
              </Text>
            </div>
            <VSpacer small={14.79} big={16.13} />
            {finishedCompetition() ? (
              <>
                <Container
                  height={[34.03, 61.92, 78.69]}
                  //width={[0, 135.55 + 32.14, 235 + 55.72]}
                  width={[0, "full", "full"]}
                  // style={{
                  //   marginTop: "clamp(6.91px, 0vw, 12.79px)",
                  // }}
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   handleClick(UID ?? "");
                  // }}
                  className="flex items-center w-full mt-[-0.3em] p-3 ticketPriceBox rounded-[15px] //hover:cursor-pointer between-200-299:h-5 "
                >
                  {/* <h3 className="text-[25px] //sm:text-[12px]  font-['Sequel100Wide'] text-black //text-[#373635] mx-auto  sm:!text-[5vw]">
                  FINISHED
                </h3> */}
                  <Text
                    textSize={[0, 26.24, 33.04]}
                    className=" font-['Sequel100Wide'] text-black //text-[#373635] mx-auto"
                  >
                    FINISHED
                  </Text>
                </Container>
                <Container
                  height={[0, 26.24, 48.01]}
                  //width={[0, 305.38, 388.1]}
                  width={[0, "full", "full"]}
                  className="mt-[12px] !w-full bg-[#767676] flex justify-center items-center rounded-[13.34px] cursor-pointer"
                  onClick={(e) => {
                    loadCompetition(UID ?? "");
                  }}
                >
                  <Text
                    textSize={[0, 12.24, 16]}
                    className="uppercase font-[Sequel100WideVF55]"
                  >
                    CLICK HERE FOR DETAILS
                  </Text>
                </Container>
              </>
            ) : (
              <Container
                height={[34.03, 34.03, 59]}
                //width={[0, 135.55 + 32.14, 235 + 55.72]}
                width={[0, "full", "full"]}
                className="bg-[#161616] flex rounded-full //sm:!h-1/3 !h-[69.78px]"
                // style={{
                //   marginTop: "clamp(6.91px, 0vw, 12.79px)",
                // }}
              >
                {/* buying */}
                <div className="w-1/2 flex justify-center items-center flex-col">
                  {/* price */}
                  <Text
                    textSize={[0, 31.99, 34.78]}
                    className="text-[#dde404] leading-none font-[MontBlancBlack] sm:!text-[5.6vw]"
                  >
                    {/* $0.99 */}
                    {minnumberoftickets}
                  </Text>
                  <Text
                    textSize={[0, 6.92, 12]}
                    className="text-white uppercase leading-none  font-[MontBlancRegular] sm:!text-[2.5vw]"
                  >
                    min tickets
                  </Text>
                </div>
                <Container
                  width={[206.65, 206.65, 233.71]}
                  height={[0, "full", "full"]}
                  doNotResizeHeight
                  className="w-full sm:!w-full"
                  // className="w-1/2"
                >
                  {/* button */}
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick(UID ?? "");
                    }}
                    className="w-full h-full bg-[#dde404]  rounded-full shadow-[6px_6px_16px_rgba(0,0,0,1)] "
                  >
                    <Text
                      textSize={[0, 23.66, 26.67]}
                      className="text-black uppercase text-[12px] font-[MontBlancBlack] block sm:!text-[4vw] "
                    >
                      Buy Now
                    </Text>
                  </button>
                </Container>
              </Container>
            )}
            <VSpacer small={14.65} big={16.56} />
          </Container>
        </div>
      </Container>
    </Container>
  );
};
