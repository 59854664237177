import { Common } from "../../../common/Common";

interface ITableSmallTitle {
  text: string;
}
export default function TableSmallTitle(props: ITableSmallTitle) {
  return (
    <Common.typography
      fontFamily="Sequel100WideVF45"
      fontSize="14px"
      fontSizeSmall="14px"
      color="#ffffff"
    >
      {props.text}
    </Common.typography>
  );
}
