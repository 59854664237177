/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { copyToClipboard } from "../../../utils/CopyToClipboard";
import { TicketData } from "../../NumberPicker";
import { Text } from "../../react-responsive-utilities/src";

interface Entry {
  pageContainer: () => React.ReactNode;
  entries: TicketData[];
  iteration: number;
  handlePrev: () => void;
  handleNext: () => void;
  page: number;
}

export default function Entries({
  pageContainer,
  entries,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  iteration,
  handlePrev,
  handleNext,
  page,
}: Entry) {
  return (
    <div className="w-auto relative  h-auto flex flex-col align-center items-center //bg-[rgba(35,35,35,0.6)]  rounded-3xl py-[48px]">
      <div className="absolute w-full h-full left-0 top-0 bg-[rgba(35,35,35,0.6)] mix-blend-multiply rounded-[30px] z-[5]"></div>
      <div className="z-10 relative flex flex-col justify-center items-center w-full">
        <Text
          textSize={[30, 30.0, 50.0]}
          className="text-primary text-center font-['Sequel100Wide95'] mb-[46.78px]"
        >
          ENTRIES
        </Text>
        {pageContainer()}
        {/* Entries Mobile Box */}
        <div className="relative w-[90%] xl:hidden lg:hidden h-[553px] border border-primary rounded-3xl overflow-hidden my-[2%]">
          <div className="md:p-8 lg:p-8 xl:p-8 sm:p-0 h-auto max-h-[483px] overflow-y-auto custom-scrollbar">
            {entries.map((item, index) => (
              <div className="my-[3%] mx-[5%]" key={index}>
                <div className="flex flex-row justify-between">
                  <p className="font-['Sequel100WideVF45'] text-[12px] text-left text-white text-opacity-50">
                    Ticket Number
                  </p>
                  <p className="font-['Sequel100Wide-65'] text-[12px] text-right text-white">
                    {item.Ticket}
                  </p>
                </div>
                <div className="flex flex-row justify-between">
                  <p className="font-['Sequel100WideVF45'] text-[12px] text-left text-white text-opacity-50">
                    Date
                  </p>
                  <p className="font-['Sequel100Wide-65'] text-[12px] text-right text-white">
                    {item.Date}
                  </p>
                </div>
                <div className="flex flex-row justify-between">
                  <p className="font-['Sequel100WideVF45'] text-[12px] text-left text-white text-opacity-50">
                    Wallet Address
                  </p>
                  <div
                    className="flex items-center"
                    style={{ justifyContent: "center" }}
                  >
                    <p className="font-['Sequel100Wide-65'] text-[12px] text-right text-white">
                      {item.Name?.length && item.Name?.length > 15
                        ? item.Name?.slice(0, 15) + "..."
                        : item.Name}
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4 text-white cursor-pointer hover:text-gray-300 mr-2"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      onClick={() => {
                        const textToCopy = item.Name;

                        if (textToCopy) {
                          copyToClipboard(textToCopy);
                        }
                      }}
                    >
                      <path d="M16 1H4a2 2 0 00-2 2v14h2V3h12V1zm3 4H8a2 2 0 00-2 2v14a2 2 0 002 2h11a2 2 0 002-2V7a2 2 0 00-2-2zm0 16H8V7h11v14z" />
                    </svg>
                  </div>
                </div>
                <hr className="max-w-full w-full border-b-[1px] border-t-0 border-primary mt-[10px] sm:block md:block h-[3.14px] rounded-full" />
              </div>
            ))}
          </div>
          <div className="h-[15px]"></div>
          <div className="flex flex-row align-center justify-center ml-[12px] mr-[12px]">
            <img
              src="/Images/left_arrow.svg"
              alt=""
              className="h-[21px] w-[16px]"
              onClick={handlePrev}
            />
            <p className="font-['Sequel100WideVF45'] text-[15px] text-center text-white text-opacity-50 ml-8 mr-8">
              PAGE {page}
            </p>
            <img
              src="/Images/right_arrow.svg"
              alt=""
              className="h-[21px] w-[16px]"
              onClick={handleNext}
            />
          </div>
          <div className="h-[15px]"></div>
        </div>
        {/* Entries Desktop Box */}
        <div className="relative w-[90%] md:hidden sm:hidden border border-primary rounded-3xl my-[2%]">
          {/* <div className="flex flex-row justify-between my-[23px]"> */}
          <div className="grid grid-cols-3 gap-3 //mx-[100px] my-[23px]">
            <p className="font-['Sequel100Wide'] text-[20px] text-center text-white ">
              Ticket Number
            </p>
            <p className="font-['Sequel100Wide'] text-[20px] text-center text-white ">
              Date
            </p>
            <p className="font-['Sequel100Wide'] text-[20px] text-center text-white ">
              Wallet Address
            </p>
            <p className="font-['Sequel100Wide'] text-[20px] text-center text-white l"></p>
          </div>
          <hr className="max-w-full w-full border-b-[1px] border-t-0 border-primary sm:block md:block h-[3.14px] rounded-full" />
          <div className=" overflow-y-auto custom-scrollbar max-h-[483px]">
            {entries.map((item, index) => (
              <div
                // className="flex flex-row //justify-between mx-[100px] my-[23px]"
                className="grid grid-cols-3 gap-3 //mx-[100px] my-[23px]"
                key={index}
              >
                <p className="font-['Sequel100WideVF45'] text-[15px] text-center text-white text-opacity-50">
                  {"             " + item.Ticket}
                </p>
                <p className="font-['Sequel100WideVF45'] text-[15px] text-center text-white text-opacity-50">
                  {item.Date}
                </p>
                <div
                  className="flex items-center"
                  style={{ justifyContent: "center" }}
                >
                  <p className="font-['Sequel100WideVF45'] text-[15px] text-center text-white text-opacity-50">
                    {item.Name?.length && item.Name?.length > 20
                      ? item.Name?.slice(0, 20) + "..."
                      : item.Name}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 text-white cursor-pointer hover:text-gray-300 mr-2"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    onClick={() => {
                      const textToCopy = item.Name;

                      if (textToCopy) {
                        copyToClipboard(textToCopy);
                      }
                    }}
                  >
                    <path d="M16 1H4a2 2 0 00-2 2v14h2V3h12V1zm3 4H8a2 2 0 00-2 2v14a2 2 0 002 2h11a2 2 0 002-2V7a2 2 0 00-2-2zm0 16H8V7h11v14z" />
                  </svg>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-row justify-center"></div>
          <hr className="max-w-[1140px] w-full border-b-[1px] border-t-0 border-primary h-[3.14px] mx-auto" />
          <div className="flex flex-row align-center justify-between p-4 sm:pl-[6px] sm:pr-[6px] md:pl-[10px] md:pr-[10px] lg:pl-[32px] lg:pr-[32px] xl:pl-[45px] xl:pr-[45px] mt-[20px] mb-[40px]">
            <img
              src="/Images/left_arrow.svg"
              alt=""
              className="h-[21px] w-[16px]"
              onClick={handlePrev}
            />
            <p className="font-['Sequel100WideVF45'] text-[15px] text-center text-white text-opacity-50 ml-8 mr-8">
              PAGE {page}
            </p>
            <img
              src="/Images/right_arrow.svg"
              alt=""
              className="h-[21px] w-[16px]"
              onClick={handleNext}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
