import React from "react";
import WithNonAbsolutedSidebarNavbarFooter from "../Layout/WithNonAbsolutedSidebarNavbarFooter";
import { useParams } from "react-router-dom";
import {
  getCompetitionById,
  getCompetitionStatus,
} from "../Services/userService";

function PageHeading() {
  return (
    <h1
      className="font-[Sequel100Wide95] text-white text-center uppercase"
      style={{ fontSize: "clamp(1px,6.52vw,40px)" }}
    >
      Entered Competition
    </h1>
  );
}

function TextWithLabel({ text, label }: { text: string; label: string }) {
  return (
    <div className="flex flex-col items-start w-full">
      <p
        className="font-[MontBlancRegular] font-semibold text-white mb-[12px]"
        style={{
          fontSize: "clamp(1px,3.19183371vw,20px)",
        }}
      >
        {label}
      </p>
      <div className="w-full rounded-[30px] font-[MontBlancRegular] text-white flex justify-start items-center bg-[#585858] min-h-[46px] h-full max-h-[100%] //font-[MontBlancRegular] p-6">
        <p>{text}</p>
      </div>
    </div>
  );
}

function DoubleTextWithLabel({
  text1,
  text2,
  label,
}: {
  text1: string;
  text2: string;
  label: string;
}) {
  return (
    <div className="flex flex-col items-start w-full">
      <p
        className="font-[MontBlancRegular] font-semibold text-white mb-[12px]"
        style={{
          fontSize: "clamp(1px,3.19183371vw,20px)",
        }}
      >
        {label}
      </p>
      <div className="w-full rounded-[30px] text-white flex justify-start items-center bg-[#585858] min-h-[46px] h-full max-h-[100%] font-[MontBlancRegular] p-6">
        <p>{text1}</p>
      </div>
      <div className="w-full mt-[14px] text-white rounded-[30px] flex justify-start items-center bg-[#585858] min-h-[46px] h-full max-h-[100%] font-[MontBlancRegular] p-6">
        <p>{text2}</p>
      </div>
    </div>
  );
}

function Result({ victory }: { victory: boolean }) {
  return (
    <div>
      <h1
        className="font-[MontBlancBlack] text-white mb-[12px] uppercase"
        style={{
          fontSize: "clamp(1px,4.388114541vw,45px)",
        }}
      >
        Result:
      </h1>
      <div
        className={`gap-[30px] flex flex-col xl:flex-row justify-center items-center p-4 ${
          victory ? "bg-primary" : "bg-[#EF008F]"
        } rounded-[30px]`}
      >
        {victory ? (
          <img src="/Images/trophy.svg" alt="trophy" />
        ) : (
          <img src="/Images/flowers.svg" alt="trophy" />
        )}

        <h1
          className="uppercase font-[MontBlancBlack]"
          style={{
            fontSize: "clamp(1px,4.545454545vw,39.32px)",
          }}
        >
          {victory ? "Competition Won" : "Competition Lost"}
        </h1>
        <p className="text-center xl:text-left xl:text-[15px] xl:w-[274px]">
          {victory
            ? "A member of our team will reach out to you to give you your prize"
            : "Sadly you didn't win it this time, but see our current competitions to enter more!"}
        </p>
      </div>
    </div>
  );
}

export default function CompetitionResultPage() {
  const params = useParams();
  const [loading, setLoading] = React.useState(false);
  const [competition, setCompetition] = React.useState<any>();
  const [won, setWon] = React.useState<boolean>(false);
  const [image, setImage] = React.useState<string>("");

  const getCompetition = async (competitionid: string) => {
    try {
      setLoading(true);
      const result = await getCompetitionById({ competitionid });
      console.log(result);
      setCompetition(result.data);
      setImage(result.data?.imageUrls[0].imageurl);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    // fetch competition data
    getCompetition(params?.id || "");
  }, []);
  React.useEffect(() => {
    const fetchCompetitionStatus = async () => {
      const status = await getCompetitionStatus(competition?.UID);
      console.log("result: ", status);

      if (status?.status.toLowerCase() === "won") {
        setWon(true);
      } else {
        setWon(false);
      }
    };

    if (competition?.UID && localStorage.getItem("token")) {
      fetchCompetitionStatus();
    }
  }, [competition]);
  return (
    <WithNonAbsolutedSidebarNavbarFooter
      Component={
        <div className=" h-full xl:mx-[49px] xl:p-0 p-4">
          <PageHeading />
          <div className="w-full h-full  xl:mt-[20px] p-0 pb-[44px] bg-[#232323] rounded-[30px]">
            <img
              className="w-full h-[406.24px] object-cover rounded-[30px]"
              //src="/images/threebitcoins.svg"\
              src={image || "/Images/No Image.svg"}
              alt="competition result"
            />
            <div className="xl:mx-[51px] mx-[4vw] *:mt-[27.09px] ">
              <TextWithLabel
                text={competition?.competitionname}
                label="Competition Name"
              />
              <TextWithLabel
                text={competition?.competitionprize}
                label="Competition Prize"
              />
              <TextWithLabel
                text={competition?.competitionticketsize.toString()}
                label="Minimum Tickets To Enter"
              />
              <DoubleTextWithLabel
                text1={competition?.competitioninformation}
                text2={competition?.legalinformation}
                label="Competition Information"
              />
              <TextWithLabel
                text={competition?.competitionenddate.toString()}
                label="End Date"
              />

              <Result victory={won} />
            </div>
          </div>
        </div>
      }
    />
  );
}
