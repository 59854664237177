import React from "react";
import { rngData } from "../../../Pages/RandomNumberGeneratorPage";
import VSpacer from "../../common/Spacer/VSpacer";
import TableSmallTitle from "../texts/TableSmallTitle";
import TableSmallValue from "../texts/TableSmallValue";

const Data = ({
  walletAddress,
  txHash,
  min,
  max,
  winningNumber,
}: {
  walletAddress: string;
  txHash: string;
  min: string;
  max: string;
  winningNumber: string;
}) => (
  <>
    <tr>
      <th className="text-left w-full">
        <TableSmallTitle text="WALLET ADDRESS" />
      </th>
      <td className="text-right w-full">
        <TableSmallValue text={walletAddress} copyable />
      </td>
    </tr>
    <tr>
      <th className="text-left w-full">
        <TableSmallTitle text="TX HASH" />
      </th>
      <td className="text-right w-full">
        <TableSmallValue text={txHash} copyable trxHash={true} />
      </td>
    </tr>
    <tr>
      <th className="text-left w-full">
        <TableSmallTitle text="MIN" />
      </th>
      <td className="text-right w-full">
        <TableSmallValue text={min} />
      </td>
    </tr>
    <tr>
      <th className="text-left w-full">
        <TableSmallTitle text="MAX" />
      </th>
      <td className="text-right w-full">
        <TableSmallValue text={max} />
      </td>
    </tr>
    <tr>
      <th className="text-left w-full">
        <TableSmallTitle text="WINNING NUMBER" />
      </th>
      <td className="text-right w-full">
        <TableSmallValue text={winningNumber} />
      </td>
    </tr>

    <VSpacer treatLgAsSmall big={0} small={29} />
  </>
);
export default function TableSmall({ tableData }: { tableData: rngData[] }) {
  return (
    <table className="hidden sm_md_lg:block w-full px-[29px]">
      {/* <Data
        walletAddress="ASKCJA28189SQWERTYQWERTYQWERTYQW"
        txHash="ASKCJA28189SQWERTYQWERTYQWERTYQWASKCJA28189SQWERTYQWERTYQWERTYQWqw"
        min="1"
        max="999,999,999,999"
        winningNumber="432,329,000"
      /> */}
      {tableData?.map((data, index) => (
        <Data
          key={index}
          walletAddress={data?.walletaddress}
          txHash={data?.transactionhash}
          min={data?.minnumber?.toString()}
          max={data?.maxnumber?.toString()}
          winningNumber={data?.numbergenerated?.toString()}
        />
      ))}
      <hr className="m-auto  border-0 h-[3px] w-full bg-primary" />
    </table>
  );
}
