import moment from "moment";
import React, { useEffect } from "react";

export interface WinnerBoxProps {
  itemWon?: string;
  name?: string;
  location?: string;
  date?: string;
  backgroundImg?: string;
  hover?: boolean;
  winnerFont?: string;
  homePage?: boolean;
  className?: string;
  winnerRadius?: string;
  competitionprize?: string;
  competitionname?: string;
  imageurl?: string;
  competitionenddate?: Date;
  promouteWinnerUrl?: string;
  winner?: string;
  rngtrxhash?: string;
  prizetransferhash?: string;
  index?: number;
  instant?: number;
}

const WinnerBox: React.FC<WinnerBoxProps> = ({
  itemWon,
  name,
  location,
  date,
  backgroundImg,
  hover,
  winnerFont,
  homePage,
  className,
  winnerRadius,
  competitionprize,
  competitionname,
  imageurl,
  competitionenddate,
  winner,
  rngtrxhash,
  prizetransferhash,
  instant,
}) => {
  return (
    <>
      {hover ? (
        <div
          style={{
            backgroundImage: backgroundImg?.includes("http")
              ? `url(${backgroundImg})`
              : "url('/Images/No Image.svg')",
          }}
          className={`overflow-hidden 
             h-[480px] bg-no-repeat bg-center bg-cover flex flex-col xl:rounded-none lg:rounded-none rounded-[20px] justify-between winner md:h-[700px] ${winnerRadius} ${className} ${
            homePage
              ? "sm:w-[calc(100%-39.27px)]"
              : "sm:w-[calc(100%-39.27px)] md:w-[calc(100%-39.27px)]"
            // homePage ? "sm:w-[326px]" : "sm:w-[359px] md:w-[calc(100%-39.27px)]"
          } `}
        >
          <h3
            className={`bg-secondary text-white flex justify-center items-center text-[26px] sm:rounded-tl-[20px] md:rounded-tl-[20px] xl:rounded-br-[42px] rounded-br-[21.4421px] font-[Sequel100WideVF] ${winnerFont} //w-[200px] h-[56px] xl:px-[38.42px] px-[22.96px] sm:text-[25px] //w-56 w-fit  //sm:w-[146px] sm:h-[40px]`}
          >
            WINNER
          </h3>
          <div>
            {Boolean(instant) && (
              <div className="grid grid-cols-3 gap-16 grid-rows-1 w-[calc(100%-29.1px)] place-content-center place-items-center m-auto bg-white rounded-t-[26.75px] h-[40.68px]">
                <img src="/Images/gift.svg" alt="" className="h-[25.42px]" />
                <p className="font-[Sequel100Wide95] text-nowrap text-black uppercase text-[20.07] sm:text-[clamp(0px,14px,15.72px)]">
                  Instant Win
                </p>
                <img src="/Images/gift.svg" alt="" className="h-[25.42px]" />
              </div>
            )}
            <div
              className={`p-4 font-[Sequel100WideVF] xl:rounded-none lg:rounded-none rounded-b-[20px] xl:rounded-t-none rounded-t-[21.44px] winnerDetails transition-all sm:h-[171px] sm:pt-2 md:w-full ${className} `}
            >
              <h1 className="truncate border-b-4 border-b-secondary font-[Sequel100Wide95] pb-[0.3em] mb-[0.3em] text-[30px] font-extrabold text-white w-full sm:text-[21px] //sm:w-[310px] //md:w-[310px]">
                WON THE {competitionname} GIVEAWAY!
              </h1>
              <div className="text-[20px] font-[Sequel100WideVF45] text-white sm:text-[15px] sm:w-[310px]">
                {/* <p className="truncate">Winner: {}</p>
              <p className="truncate">Winner: {name}</p> */}
                <p className="truncate">Winner: {winner}</p>
                <p className="truncate">
                  {/* RNG TX HASH: 0x063834efe......866da7c89e86ee629cfb */}
                  RNG TX HASH: {rngtrxhash}
                </p>
                <p className="truncate">
                  {/* PRIZE TX HASH: 0x063834ef......e214f4199b1ad7181ce8 */}
                  PRIZE TX HASH: {prizetransferhash}
                </p>
                <p className="truncate">
                  Date: {moment(competitionenddate).format("MMMM Do, YYYY")}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              backgroundImage: backgroundImg?.includes("http")
                ? `url(${backgroundImg})`
                : "url('/Images/No Image.svg')",
            }}
            className={`h-[546px] bg-no-repeat bg-cover flex flex-col justify-between winner ${
              homePage && "sm:w-[326px]"
            }`}
          >
            <h3
              className={`bg-[#FF3500] text-[35px] font-extrabold ${winnerFont}w-[200px] h-[56px] px-[5%]`}
            >
              WINNER
            </h3>
          </div>
          <div className="p-7 transition-all">
            <h1 className=" pb-[0.3em] mb-[0.3em] text-[30px] font-extrabold text-white w-full">
              {itemWon}
            </h1>
            <div className="text-[20px] font-bold text-white">
              <p>Prize: {competitionprize}</p>
              <p>username: {name}</p>
              <p>Date: {date}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WinnerBox;
{
  /* <span className="w-[20px] h-[]"></span>;  */
}
