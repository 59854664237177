/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnchorProvider, Program, Wallet } from "@project-serum/anchor";
import {
  createAssociatedTokenAccountInstruction,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import {
  Connection,
  Keypair,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import { BrowserProvider, Contract, ethers, parseEther } from "ethers";
import EventEmitter from "events";
import {
  baseConfigs,
  ethereumConfigs,
  solanaConfigs,
} from "../Config/blockchain.config";
import { WalletConnector } from "../factories/WalletConnector";
import idl from "./idl.json";

import { bs58 } from "@coral-xyz/anchor/dist/cjs/utils/bytes";
import { signConfirm } from "../Services/userService";

export class PhantomConnector extends EventEmitter implements WalletConnector {
  private walletState:
    | {
        connected: boolean;
        provider: any | null;
        account: string | null;
        network: string | null;
        providerType: string | null;
      }
    | undefined;
  private intervalMap: Map<string, NodeJS.Timeout>;
  private eventListeners: Map<string, () => void> = new Map();

  constructor() {
    super();
    this.intervalMap = new Map();
  }

  async connectToSolana() {
    const provider = (window as any).phantom?.solana;
    const response = await provider.connect();
    const account = response.publicKey.toString();

    return {
      provider,
      account,
    };
  }

  async connectToBTC() {
    const provider = (window as any).phantom?.bitcoin;
    const response = await provider.requestAccounts();
    const account = response[0].address;

    return {
      provider,
      account,
    };
  }

  async connectToEthereum(storedAccount?: string, chainId: number = 1) {
    console.log(
      "Connecting to Phantom Ethereum-based network, ",
      storedAccount
    );

    const ethereum = (window as any).phantom?.ethereum;

    const provider = new BrowserProvider(ethereum);
    const connectedNetwork = await provider._detectNetwork();

    if (Number(connectedNetwork.chainId) !== chainId) {
      try {
        const chainIdHex = "0x" + chainId.toString(16);
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: chainIdHex }],
        });
      } catch (error) {
        console.error(error);
        throw new Error(`Switching to ${chainId} failed.`);
      }
    }

    // Request account connection.
    await ethereum.request({
      method: "eth_requestAccounts",
    });

    const account = ethereum.selectedAddress;

    if (storedAccount !== account) {
      console.log("Account changed");
      this.emit("accountChanged", account);
    }

    return {
      provider,
      account,
    };
  }

  async connect(network: string, storedAccount?: string): Promise<void> {
    const windowObj = window as any;

    // Check if running inside Phantom's in-app browser
    const isPhantomBrowser = windowObj.phantom?.solana?.isPhantom;

    if (
      !isPhantomBrowser &&
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        navigator.userAgent
      )
    ) {
      const url = encodeURIComponent("https://theprize.io");
      window.location.href = `https://phantom.app/ul/browse/${url}?ref=${url}`;
      return;
    }

    try {
      console.log("Connecting to Phantom Wallet...");

      if (
        network !== "ethereum" &&
        network !== "base" &&
        network !== "solana" &&
        network != "btc"
      ) {
        throw new Error("Phantom does not support this network.");
      }

      const windowObj = window as any;
      // const isPhantomInstalled = windowObj?.phantom?.ethereum?.isPhantom;
      const isPhantomInstalled = windowObj.phantom?.solana?.isPhantom;
      if (isPhantomInstalled) {
        let connectionObj = null;

        if (network === "solana") {
          connectionObj = await this.connectToSolana();
        } else if (network === "ethereum" || network === "base") {
          const chainId =
            network === "base"
              ? baseConfigs.CHAIN_ID
              : ethereumConfigs.CHAIN_ID; // Use Base chain ID.
          connectionObj = await this.connectToEthereum(storedAccount, chainId);
        } else if (network === "btc") {
          connectionObj = await this.connectToBTC();
        }

        this.walletState = {
          provider: connectionObj?.provider,
          account: connectionObj?.account,
          connected: true,
          network: network,
          providerType: "phantom",
        };

        this.emit("stateChange", this.walletState);
      } else {
        throw new Error("Phantom Wallet is not installed");
      }
    } catch (err) {
      console.error("Error connecting to wallet:", err);
      throw new Error(err as any);
    }
  }

  async reconnect(network: string, account: string): Promise<void> {
    console.log("Reconnecting to Phantom Wallet...", account);
    await this.connect(network, account); // Phantom doesn't have separate reconnect logic.
  }

  async authenticate(): Promise<string> {
    try {
      if (this.walletState) {
        const message = this.walletState.account ?? "";
        let signature = null;

        if (this.walletState.network === "solana") {
          const encodedMessage = new TextEncoder().encode(message);

          const signedMessage = await this.walletState.provider.signMessage(
            encodedMessage,
            "utf8"
          );
          signature = bs58.encode(signedMessage.signature);
        } else if (
          this.walletState.network === "ethereum" ||
          this.walletState.network === "base"
        ) {
          const ethereum = (window as any).phantom?.ethereum;
          const encodedMessage = new TextEncoder().encode(message);
          const msg = `0x${Array.from(encodedMessage)
            .map((byte) => byte.toString(16).padStart(2, "0"))
            .join("")}`;
          signature = await ethereum.request({
            method: "personal_sign",
            params: [msg, this.walletState.account, "Example password"],
          });
        } else if (this.walletState.network === "btc") {
          const encodedMessage = new TextEncoder().encode(message);
          const { signature: _signature } =
            await this.walletState.provider.signMessage(
              this.walletState.account,
              encodedMessage
            );
          console.log(_signature, "signature");
          const binString = String.fromCodePoint(..._signature);
          signature = btoa(binString);
        }

        const accessToken = await signConfirm(
          this.walletState.account ?? "",
          this.walletState.account ?? "",
          signature,
          this.walletState.network!
        );

        return accessToken;
      } else {
        throw new Error("Wallet not initialized");
      }
    } catch (err) {
      console.error("Authentication failed:", err);
      throw new Error(err as any);
    }
  }

  async disconnect(): Promise<void> {
    console.log("Disconnected from Phantom Wallet.");
    if (this.walletState?.network === "solana")
      this.walletState?.provider.disconnect();
    this.walletState = undefined;
    this.emit("stateChange", this.walletState);
  }

  async isConnected(): Promise<boolean> {
    return this.walletState?.connected ?? false;
  }

  handleAccountChange = (publicKey: any) => {
    if (this.walletState) {
      this.walletState.account = publicKey ? publicKey.toString() : null;
      this.emit("stateChange", this.walletState);
    }
  };

  private buildAccounts(
    accounts: { pubkey: PublicKey; propertyName: string }[]
  ): Record<string, PublicKey> {
    const accountMap: Record<string, PublicKey> = {};
    accounts.forEach(({ pubkey, propertyName }) => {
      accountMap[propertyName] = pubkey;
    });
    return accountMap;
  }

  private createAnchorWallet(walletProvider: any): Wallet {
    const dummyKeypair = Keypair.generate(); // using as placeholder for compatibility with Wallet Adapter from anchor
    return {
      payer: dummyKeypair,
      publicKey: new PublicKey(this.walletState?.account ?? ""), // Assumes a single connected account
      signTransaction: async (tx: any) => {
        const signedTx = await walletProvider.signTransaction(tx, {
          accounts: [tx.feePayer?.toString()],
        });
        return signedTx;
      },
      signAllTransactions: async (txs: any[]) => {
        const signedTxs = await Promise.all(
          txs.map((tx) =>
            walletProvider.signTransaction(tx, {
              accounts: [tx.feePayer?.toString()],
            })
          )
        );
        return signedTxs;
      },
    };
  }

  async createTokenAddressAccount(splTokenAddress: string) {
    try {
      if (!this.walletState || !this.walletState.provider) {
        throw new Error("Wallet is not connected");
      }
      const { RPC } = solanaConfigs;

      const connection = new Connection(RPC);
      const { blockhash } = await connection.getLatestBlockhash();
      const wallet = this.createAnchorWallet(this.walletState.provider);
      const splMintAddress = new PublicKey(splTokenAddress);
      const associatedTokenAccount = await getAssociatedTokenAddress(
        splMintAddress,
        wallet.publicKey
      );

      const ix = await createAssociatedTokenAccountInstruction(
        wallet.publicKey,
        associatedTokenAccount,
        wallet.publicKey,
        splMintAddress
      );

      const tx = new Transaction().add(ix);

      tx.feePayer = wallet.publicKey || undefined;
      tx.recentBlockhash = blockhash;

      // Sign the transaction using the wallet
      const signedTx = await wallet.signTransaction(tx);

      // Send the serialized transaction to the network
      const signature = await connection.sendRawTransaction(
        signedTx.serialize()
      );
      console.log(
        signature,
        "Trx for creating token address account for user on mint address: ",
        splTokenAddress
      );

      return signature;
    } catch (err) {
      throw new Error(err as any);
    }
  }

  async callContractMethod(
    contractAddress: string,
    abi: any[],
    methodName: string,
    args: any[] = [],
    isWrite: boolean = false,
    value: string = "0",
    additionalKeys: {
      pubkey: PublicKey;
      propertyName: string;
    }[] = []
  ): Promise<any> {
    try {
      if (!this.walletState || !this.walletState.provider) {
        throw new Error("Wallet is not connected");
      }

      if (
        this.walletState.network === "ethereum" ||
        this.walletState.network === "base"
      ) {
        // Ethereum or Base network
        const provider = this.walletState.provider;

        const signer = await provider.getSigner();
        console.log(signer, "signer");
        const contract = new ethers.Contract(contractAddress, abi, signer);

        if (isWrite) {
          // Writing to the contract (e.g., transactions)
          const tx = await contract[methodName](...args, {
            value: parseEther(value),
          });
          return tx;
        } else {
          // Reading from the contract (e.g., view functions)
          const result = await contract[methodName](...args);
          return result;
        }
      } else if (this.walletState.network === "solana") {
        console.log("Solana contract calling initialized");

        if (isWrite) {
          const { RPC } = solanaConfigs;
          const connection = new Connection(RPC);

          // Wrap WalletConnectProvider as an Anchor-compatible wallet
          const wallet = this.createAnchorWallet(this.walletState.provider);

          // Initialize the Anchor provider
          const anchorProvider = new AnchorProvider(connection, wallet, {
            commitment: "confirmed",
          });

          const programPublicKey = new PublicKey(contractAddress);
          const signerPublicKey = new PublicKey(this.walletState.account ?? "");
          // @ts-ignore
          const program = new Program(idl, programPublicKey, anchorProvider);

          const txId = await program.methods[methodName](...args)
            .accounts({
              payer: signerPublicKey,
              systemProgram: SystemProgram.programId,
              ...this.buildAccounts(additionalKeys),
            })
            .rpc();

          console.log("Transaction successful:", txId);
          return txId;
        } else {
          // Todo: implement read from solana contract
        }
      } else {
        throw new Error("Network not supported");
      }
    } catch (error) {
      console.error("Error calling contract method:", error);
      throw new Error(error as any);
    }
  }

  async sendTransaction(
    to: string,
    value: string,
    data?: string
  ): Promise<any> {
    try {
      if (!this.walletState) {
        throw new Error("Wallet not initialized");
      }

      if (
        this.walletState.network === "ethereum" ||
        this.walletState.network === "base"
      ) {
        const ethereum = (window as any).phantom?.ethereum;
        // Ethereum or compatible chain transaction
        const tx = {
          to,
          value: parseEther(value).toString(16), // Convert value to hexadecimal
          data: data || "0x", // Fallback to empty data if none provided
          from: this.walletState.account,
        };

        // Estimate gas
        const gasLimit = await this.walletState.provider.estimateGas(tx);
        // @ts-ignore
        tx["gasLimit"] = gasLimit;

        // Send transaction
        const transactionHash = await ethereum.request({
          method: "eth_sendTransaction",
          params: [tx],
        });

        console.log("Transaction sent:", transactionHash);
        return transactionHash;
      } else if (this.walletState.network === "solana") {
        // Solana transaction
        const { RPC } = solanaConfigs;
        const connection = new Connection(RPC);
        const { blockhash } = await connection.getLatestBlockhash();

        const transaction = new Transaction({
          recentBlockhash: blockhash,
          feePayer: new PublicKey(this.walletState.account ?? ""),
        }).add(
          SystemProgram.transfer({
            fromPubkey: new PublicKey(this.walletState.account ?? ""),
            toPubkey: new PublicKey(to),
            lamports: LAMPORTS_PER_SOL * parseFloat(value),
          })
        );

        // Sign and send
        const signedTransaction =
          await this.walletState.provider.signTransaction(transaction);
        const signature = await connection.sendRawTransaction(
          signedTransaction.serialize()
        );

        console.log("Solana Transaction sent:", signature);
        return signature;
      } else {
        throw new Error("Network not supported on Phantom");
      }
    } catch (error) {
      console.error("Error sending transaction:", error);
      throw new Error(error as any);
    }
  }

  async listenToContractEvent(
    contractAddress: string,
    abi: any[],
    eventName: string,
    callback: (...args: any[]) => void
  ): Promise<void> {
    if (
      this.walletState?.network !== "ethereum" &&
      this.walletState?.network !== "base"
    ) {
      throw new Error(
        "Event listening is only supported on Ethereum-based networks."
      );
    }

    if (!this.walletState?.provider) {
      throw new Error("Wallet is not connected");
    }

    try {
      const provider = this.walletState.provider;
      const signer = await provider.getSigner();
      const contract = new Contract(contractAddress, abi, signer);

      const eventCallback = (...args: any[]) => {
        const eventData = args.slice(0, args.length - 1); // Exclude the event metadata
        const event = args[args.length - 1]; // Extract event metadata
        console.log(`Event "${eventName}" detected:`, { eventData, event });

        // Invoke the callback with event data
        callback({ eventData, event });
      };

      contract.on(eventName, eventCallback);
      // Store the listener for cleanup
      this.eventListeners.set(eventName, () =>
        contract.off(eventName, eventCallback)
      );

      // Set up the interval as a fallback for missed events
      const intervalId = setInterval(async () => {
        const currentBlock = await provider.getBlockNumber();
        const filter = contract.filters[eventName]();
        const logs = await provider.getLogs({
          topics: await filter.getTopicFilter(),
          fromBlock: Math.max(currentBlock - 10, 0), // Adjust block range as needed
          toBlock: "latest",
        });

        logs?.forEach((log: any) => {
          const parsedLog = contract.interface.parseLog(log);
          if (parsedLog) {
            // Extract event arguments and convert to array
            const eventData = Object.values(parsedLog.args);

            // Create event metadata similar to contract.on
            const event = {
              blockNumber: log.blockNumber,
              transactionHash: log.transactionHash,
              logIndex: log.index,
              address: log.address,
              data: log.data,
              topics: log.topics,
            };

            console.log(`Event "${eventName}" detected:`, { eventData, event });

            // Invoke the callback with formatted event data and metadata
            callback({ eventData, event });

            // Emit the event locally (optional)
            this.emit(eventName, { eventData, event });
          }
        });
      }, 5_000); // Every 5 seconds

      // Store the interval ID in the map
      const key = `${contractAddress}-${eventName}`;
      this.intervalMap.set(key, intervalId);

      console.log(`Listening to event: ${eventName}`);
    } catch (error) {
      console.error("Error setting up event listener:", error);
      throw error;
    }
  }

  async stopListeningToContractEvent(
    contractAddress: string,
    abi: any[],
    eventName: string
  ): Promise<void> {
    if (!this.eventListeners.has(eventName)) {
      console.warn(`No listener found for event: ${eventName}`);
      return;
    }

    try {
      // Call the stored cleanup function to remove the listener
      const removeListener = this.eventListeners.get(eventName);
      removeListener?.();
      this.eventListeners.delete(eventName);
      // Clear the interval
      const key = `${contractAddress}-${eventName}`;
      const intervalId = this.intervalMap.get(key);
      if (intervalId) {
        clearInterval(intervalId);
        this.intervalMap.delete(key);
      }
      console.log(`Stopped listening to event: ${eventName}`);
    } catch (error) {
      console.error(`Error stopping listener for event ${eventName}:`, error);
      throw error;
    }
  }
}
