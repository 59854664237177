import React from "react";
import { copyToClipboard } from "../../../../utils/CopyToClipboard";
interface DataInfoProps {
  text: string;
  rng?: boolean;
}
export default function DataInfo(props: DataInfoProps) {
  const copyToClipboardFn = () => {
    if (props.rng) {
      const isProduction = process.env.REACT_APP_ENV === "production";
      let preText;

      if (!isProduction) {
        preText = `https://sepolia.etherscan.io/tx/`;
      } else {
        preText = `https://basescan.org/tx/`;
      }
      copyToClipboard(preText + props.text);
    } else {
      copyToClipboard(props.text);
    }
  };
  return (
    <div className="flex justify-between">
      <p className="font-[Sequel100WideVF45] sm_md:text-[14px] text-[20px] text-white leading-none h-auto break-all">
        {props.text}
      </p>
      <img
        src="/Images/copy.svg"
        alt="copy"
        className="cursor-pointer w-[25.39px] h-[25.39px]"
        onClick={copyToClipboardFn}
      />
    </div>
  );
}
