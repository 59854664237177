import React, { useEffect, useState } from "react";
import { poundSign } from "../LandingPage";
import Button from "../../Components/Button";
import FAQ from "../../Components/Page Components/FAQ";
import Popup from "../../Components/Dashboard Components/Popup";
import WithNavbarFooterAndSidebar from "../../Layout/WithNavbarFooterAndSidebar";
import Sidebar from "../../Components/Sidebar";
import RangeSlider from "../../Components/RangeSlider";
import Countdown from "react-countdown";
import {
  getCompetitionById,
  // getCompetitionImages,
  joinCompetition,
} from "../../Services/userService";
import { useParams } from "react-router-dom";
import { LinearProgress, LinearProgressProps, styled } from "@mui/material";
import { useAppDispatch } from "../../redux/hooks";
import { setSideNavHeight } from "../../redux/reducers/sidebarSlice";
import moment from "moment";
import BuyTicketsPopup from "../../Components/Dashboard Components/BuyTicketsPopup";
import { useAuth } from "../../Contexts/AuthContext";
import { CompetitionImages } from "../../Components/Page Components/Competitions";
import CompetitionDetailsLayout from "../../Layout/CompetitionDetailsLayout";
import WithNonAbsolutedSidebarNavbarFooter from "../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import { Text } from "../../Components/react-responsive-utilities/src";
import Counter from "../../Components/common/Counter/Counter";
import { useWallet } from "../../Contexts/WalletContext";

const TicketLinearProgress = styled(LinearProgress)<LinearProgressProps>(
  ({ theme }) => ({
    height: "10px",
    borderRadius: 20,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    ".MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#EF008F",
      borderRadius: 20,
    },
  })
);

const sold = (200 / 460) * 100;
const small_chicken = "/Images/small_yellow_chicken.png";

const Competitions: React.FC<any> = () => {
  const { account } = useWallet();
  const [currentImageIndex] = useState(0);
  const params = useParams();
  const [buying, setBuying] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const competitionid = params?.id;
  const [showPopUp, setShowPopUp] = useState(false);
  const [noOfTicket, setNoOfTicket] = useState(0);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [competition, setCompetition] = useState<any>();
  const { fetchUser } = useWallet();

  const dispatch = useAppDispatch();
  const getCompetition = async (competitionid: string) => {
    try {
      setLoading(true);
      const result = await getCompetitionById({ competitionid });
      setCompetition(result.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (competition) {
      dispatch(setSideNavHeight("1100px"));
    }
  }, [competition]);

  useEffect(() => {
    if (competition) {
      setValue(competition?.minnumberoftickets);
    }
  }, [competition]);

  useEffect(() => {
    getCompetition(competitionid || "");
  }, [competitionid]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [competitionImages, setCompetitionImages] = React.useState<
    CompetitionImages[]
  >([]);
  const [featuredImage, setFeaturedImage] = useState<CompetitionImages>();

  const images = [
    { imgSrc: "/Imagessupra.png" },
    { imgSrc: "/Imagessupra.png" },
    { imgSrc: "/Imagessupra.png" },
    { imgSrc: "/Imagessupra.png" },
    { imgSrc: "/Imagessupra.png" },
    { imgSrc: "/Imagessupra.png" },
    { imgSrc: "/Imagessupra.png" },
    { imgSrc: "/Imagessupra.png" },
    { imgSrc: "/Imagessupra.png" },
    { imgSrc: "/Imagessupra.png" },
    { imgSrc: "/Imagessupra.png" },
    { imgSrc: "/Imagessupra.png" },
  ];

  async function buyTicket() {
    await setNoOfTicket(value);
    setShowPopUp(true);
  }

  function popUpFunctions(action: string) {
    if (action === "reject") {
      setShowPopUp(false);
      setSuccess("");
      setError("");
      setBuying(false);
    }

    if (action === "accept") {
      buyTicketFunction();
    }
  }

  const buyTicketFunction = async () => {
    try {
      setBuying(true);
      const result = await joinCompetition({
        competitionid: competitionid,
        numberoftickets: noOfTicket,
        walletaddress: account || "",
      });
      setBuying(false);
      setSuccess(result?.message);
      setTimeout(() => {
        setShowPopUp(false);
        setSuccess("");
      }, 3000);
      //setShowPopUp(false);
    } catch (error: any) {
      console.log(error);
      setBuying(false);
      setError(error?.response?.data?.error);
      //setShowPopUp(false);
    }
  };

  const changeNumberOfTickets = (number: number) => {
    if (number < competition?.minnumberoftickets) {
      return;
    } else if (
      number >
      competition?.competitionticketsize - competition?.totalticketsbought
    ) {
      return;
    } else {
      setValue(number);
    }
  };

  const ticketSold = (
    totalticketsbought?: number,
    competitionticketsize?: number
  ) => {
    if (totalticketsbought && competitionticketsize) {
      const sold = ((totalticketsbought / competitionticketsize) * 100).toFixed(
        0
      );
      return sold;
    } else {
      return 0;
    }
  };

  const formattedDateTime = moment(competition?.competitionenddate ?? "")
    .utcOffset("+00:00")
    .format("DD-MM-YYYY @ h:mmA");

  const getImages = async () => {
    // const images = await getCompetitionImages({
    //   competitionid: competition?.UID ?? "",
    // });
    const images = competition?.imageUrls;
    console.log(images);
    if (images.length > 0) {
      setFeaturedImage(images[0]);
      setCompetitionImages(images);
    }
  };

  useEffect(() => {
    if (competition?.UID) {
      getImages();
    }
  }, [competition?.UID]);

  const Title = ({ title }: { title: string }) => (
    <Text
      textSize={[30, 30.0, 50.0]}
      className="text-[#FFFFFF] font-['Sequel100Wide95'] //leading-[35px] leading-normal font-[60] block sm:text-center md:text-center"
    >
      {title}
    </Text>
  );

  const Description = ({ description }: { description: string }) => (
    <Text
      textSize={[14, 14.0, 19.96]}
      className="
      text-[#FFFFFF] font-['Sequel100WideVF45'] leading-[17px] font-[10] block sm:text-center md:text-center"
    >
      {description}
    </Text>
  );

  return (
    <div className="hero">
      <WithNonAbsolutedSidebarNavbarFooter
        sidebarContainerClassName="mb-[15px]"
        Component={
          <div className="flex flex-col items-center liveCompetitionPage p-0 pt-0 sm:ml-0 md:ml-0 lg:ml-0 sm:p-3 md:p-3 lg:p-3 flex-1">
            <h1
              className="pageHeading"
              // ref={liveComp}
              style={{
                fontSize: "clamp(1px,5.83090379vw,45px)",
              }}
            >
              LIVE COMPETITIONS
            </h1>

            <style>
              {`
            .slider{
              height: 100%;
              width: ${sold}%;
              z-index: -1;
            }
  
            #sales::after{
              position: absolute;
              content: "";
              height: 50px;
              width: 50px;
              top: 21%;
              left: ${sold - sold / 4 + 1}%;
              right: 0;
              background-repeat: no-repeat;
              z-index: 100000001;
              cursor: pointer;
            }
          `}
            </style>
            <div className="sm:w-full md:w-full lg:w-full flex flex-col gap-[2em]">
              <div className="flex w-full rounded-3xl flex-col xl:flex-row bg-secondary_bg p-4">
                <div
                  className=" ticketBox flex flex-col gap-5 sm:!w-full md:!w-full lg:!w-full"
                  style={{
                    width: "clamp(1px,84.941516242vw, 468.64px)",
                  }}
                >
                  <div
                    className="h-[470px] rounded-3xl sm:h-[323px] sm:!w-full md:!w-full lg:!w-full"
                    style={{
                      width: "clamp(1px,84.941516242vw, 468.64px)",
                      height: "clamp(1px,70.95798941vw, 402.2px)",
                    }}
                  >
                    <img
                      src={featuredImage?.imageurl ?? "/Images/No Image.svg"}
                      alt=""
                      className="w-full h-full rounded-3xl object-cover"
                      onError={(e) => {
                        e.currentTarget.src = "/Images/No Image.svg";
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-4 gap-4 w-auto sm:hidden md:hidden lg:hidden">
                    {competitionImages.map((image, index) => (
                      <div
                        key={index}
                        className={`${
                          featuredImage === image ? "" : "smallImgs"
                        } w-full h-[110px]`}
                        onClick={() => setFeaturedImage(image)}
                      >
                        <img
                          src={image.imageurl}
                          alt=""
                          className={`w-full rounded-2xl h-full object-cover `}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="grid grid-cols-4 gap-4 w-auto xl:hidden">
                    {competitionImages.slice(0, 4).map((image, index) => (
                      <div
                        key={index}
                        className={`${
                          featuredImage === image ? "" : "smallImgs"
                        } w-full h-[110px] sm:h-[71px]`}
                        onClick={() => setFeaturedImage(image)}
                      >
                        <img
                          src={image.imageurl}
                          alt=""
                          className={`w-full h-full rounded-2xl object-cover `}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div className=" flex flex-col gap-3 ticketBox ">
                  <div className="flex flex-col gap-5 font-['Sequel100Wide95'] m-[40px] items-center">
                    <Title title={competition?.competitionname} />
                    {/* <VSpacer small={11.96} big={24} /> */}
                    <hr className="max-w-[320.18px] w-full border-b-[5px] border-t-0 border-[#EF008F] hidden sm:block md:block h-[3.14px] rounded-full" />
                    {/* <VSpacer small={12.9} big={0} /> */}
                    <Description
                      description={competition?.competitioninformation}
                    />
                  </div>
                  <div className="border-t-4 w-[50%] mx-10 sm:hidden md:hidden lg:hidden border-t-secondary"></div>
                  <div className="flex w-full relative md:flex-col sm:flex-col lg:flex-col">
                    <div className="w-full">
                      <div className="m-[40px] mt-[-20px] pt-[15px] mb-0 flex flex-col sm:items-center md:items-center lg:items-center gap-3 sm:border-t-0 md:border-t-0 lg:border-t-0 sm:pt-0 md:pt-0 lg:pt-0">
                        <Counter
                          competitionEndDate={competition?.competitionenddate}
                          title={"Time Remaining"}
                        />
                        <p className="text-secondary font-['Sequel100Wide95'] text-[10px] font-semibold sm:hidden md:hidden lg:hidden">
                          BUY TICKETS AND SPIN TO WIN!
                        </p>
                      </div>
                      <div className="xl:gap-2 flex flex-col">
                        <div className="p-4 xl:m-[20px] h-auto mb-0 //xl:w-[440px] xl:w-[95%] w-full relative sm:mt-4 md:mt-4 lg:mt-4  sm:w-full md:w-full lg:w-full sm:h-auto  xl:rounded-3xl sm:rounded-t-3xl md:rounded-t-3xl lg:rounded-t-3xl sm:rounded-b-0 md:rounded-b-0 lg:rounded-b-0 bg-black z-[1]">
                          <div className="flex items-center gap-4 ">
                            <div className="w-[80%] mt-5 md:w-full lg:w-full">
                              <TicketLinearProgress
                                variant="determinate"
                                value={Number(
                                  ticketSold(
                                    competition?.totalticketsbought,
                                    competition?.competitionticketsize
                                  )
                                )}
                              />

                              <span
                                className="text-white font-['MontBlancRegular'] uppercase text-[12px] md:text-sm"
                                style={{
                                  fontSize:
                                    "clamp(11px, 2.441720196vw, 11.19px)",
                                }}
                              >
                                {Number(
                                  ticketSold(
                                    competition?.totalticketsbought,
                                    competition?.competitionticketsize
                                  )
                                )}
                                % tickets sold
                              </span>
                            </div>
                            <div>
                              <h3
                                className="font-['MontBlancBlack'] text-[18px] text-primary m-0 p-0 mb-[-0.4em] sm:text-[21px]"
                                style={{
                                  fontSize:
                                    "clamp(22px, 4.815615702vw, 23.77px)",
                                }}
                              >
                                {poundSign}0.99
                              </h3>
                              <span
                                className="text-[10px] text-white "
                                style={{
                                  fontSize:
                                    "clamp(11px, 2.418197682vw, 11.19px)",
                                }}
                              >
                                PER TICKET
                              </span>
                            </div>
                          </div>
                          <div className="p-5 sm:hidden md:hidden lg:hidden">
                            <div className="flex flex-col w-full font-[MontBlancBold]">
                              <div className="flex w-full justify-between pb-[1em] border-b-2 border-primary">
                                <div className="flex items-center gap-4 text-white text-[8px] font-bold">
                                  <img
                                    src="/Images/trophy.png"
                                    alt=""
                                    className="h-[16px] w-auto"
                                  />
                                  <p>ENTRIES ONLY £0.99</p>
                                </div>
                                {/* <div className="flex items-center gap-4 text-white text-[8px] font-bold">
                              <img
                                src="/Images/trend.png"
                                alt=""
                                className="h-[16px] w-auto"
                              />
                              <p>MAX ENTRIES 199,999</p>
                            </div> */}
                                <div className="flex items-center gap-4 text-white text-[8px] font-bold">
                                  <img
                                    src="/Images/crown.png"
                                    alt=""
                                    className="h-[16px] w-auto"
                                  />
                                  <p>
                                    TOTAL VALUE $
                                    {(competition?.competitionticketsize ?? 0) *
                                      0.99}
                                  </p>
                                </div>
                              </div>
                              <div className="flex gap-[5em] mx-auto pt-[1em]">
                                <div className="flex items-center gap-4 text-white text-[8px] font-bold">
                                  <img
                                    src="/Images/people.png"
                                    alt=""
                                    className="h-[16px] w-auto"
                                  />
                                  <p>
                                    MAX {competition?.competitionticketsize} PER
                                    PERSON
                                  </p>
                                </div>
                                {/* <div className="flex items-center gap-4 text-white text-[8px] font-bold">
                              <img
                                src="/Images/gift.png"
                                alt=""
                                className="h-[16px] w-auto"
                              />
                              <p>TOTAL PRIZES 2,001</p>
                            </div> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mt-[-1em] ml-[-1em] sm:hidden md:hidden lg:hidden">
                          <img
                            src="/Images/wheel_big.png"
                            alt=""
                            className="mb-[-2em] z-1 absolute opacity-[0.1] top-[-50px] right-0 w-[216px] md:hidden sm:hidden lg:hidden"
                          />
                        </div>

                        <div className="xl:ml-[20px] sales //xl:w-[440px] xl:w-[95%] lg:rounded-3xl xl:rounded-3xl rounded-b-3xl rounded-t-0 w-full">
                          <div className="p-5">
                            <h2
                              className="text-white text-[20px] font-['Sequel100Wide'] sm:text-[16px] sm:text-center md:text-center lg:text-center"
                              style={{
                                fontSize: "clamp(1px,3.539508620vw,25.78px)",
                              }}
                            >
                              HOW MANY TICKETS?
                            </h2>
                            <div
                              className="mt-[2em] relative sm:mt-[2em] md:mt-[2em] lg:mt-[2em] "
                              id="sales"
                            >
                              <RangeSlider
                                min={competition?.minnumberoftickets}
                                max={
                                  competition?.maxticketsperuser >
                                  competition?.competitionticketsize -
                                    competition?.totalticketsbought
                                    ? competition?.competitionticketsize -
                                      competition?.totalticketsbought
                                    : competition?.maxticketsperuser
                                }
                                value={value}
                                onChange={changeNumberOfTickets}
                                buffered={1}
                                className="//xl:w-[400px] w-full"
                              />

                              <p
                                className="text-white font-bold text-[15px] //w-[400px] w-full sm:w-full flex items-center justify-between mt-[1em] md:w-full lg:w-full"
                                style={{
                                  fontSize: "clamp(10px,1.577387725vw,10.89px)",
                                }}
                              >
                                <span>{value}</span>
                                <span>
                                  {competition?.competitionticketsize -
                                    competition?.totalticketsbought}
                                </span>
                              </p>
                            </div>
                            <div className="//w-[400px] w-full sm:w-full md:w-full lg:w-full flex font-['Sequel100Wide95'] items-center justify-between mt-[1.5em]">
                              <img
                                src="/Images/minus.png"
                                alt=""
                                className="sm:w-[20px] cursor-pointer rounded-full"
                                onClick={() => changeNumberOfTickets(value - 1)}
                              />
                              <h4
                                className="text-white text-[14px] sm:text-[11px] sm:text-center font-bold md:text-center lg:text-center"
                                style={{
                                  fontSize: "clamp(11px,2.59347181vw,14.52px)",
                                }}
                              >
                                NUMBER OF TICKETS: {value}
                              </h4>
                              <img
                                src="/Images/plus.png"
                                alt=""
                                className="sm:w-[20px] cursor-pointer rounded-full"
                                onClick={() => changeNumberOfTickets(value + 1)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end">
                    <Button
                      className={
                        "bg-primary xl:h-[50px] h-[80px] xl:w-[95%] w-full text-black text-[20px] font-['Sequel100Wide'] sm:text-[27px] sm:p-0 md:p-0 lg:p-0 border-2 border-white"
                      }
                      customStyle={{
                        fontSize: "clamp(1px,6.122393821vw,21.78px)",
                      }}
                      content={"BUY TICKETS"}
                      onClick={buyTicket}
                    />
                  </div>
                  {/* <br className="xl:hidden" /> */}
                  <p className="text-secondary font-['Sequel100Wide95'] text-[12px] text-center font-semibold xl:hidden">
                    BUY TICKETS AND SPIN TO WIN!
                  </p>
                </div>
              </div>
            </div>

            <BuyTicketsPopup
              buying={buying}
              success={success}
              error={error}
              competitionName={competition?.competitionname}
              noOfTickets={noOfTicket}
              open={showPopUp}
              handleClose={() => {
                getCompetition(competitionid || "");
                setShowPopUp(false);
                setSuccess("");
                setError("");
                setBuying(false);
                fetchUser();
              }}
              rejectClick={() => popUpFunctions("reject")}
              acceptClick={() => popUpFunctions("accept")}
            />
          </div>
        }
        ComponentBeforeFooter={
          <>
            {/* competition details */}
            <div className="bg-black rounded-3xl m-4">
              <h1
                className="flex text-[45px] justify-center font-['Sequel100Wide'] sm:text-[28px] items-center font-[900] text-black uppercase bg-primary sm:rounded-b-none md:rounded-b-none lg:rounded-none h-[180px] w-full  pt-[-1em] sm:pl-0 md:pl-0 lg:pl-0 sm:text-center md:text-center lg:text-center rounded-3xl"
                style={{
                  fontSize: "clamp(1px,6.52vw,45px)",
                }}
              >
                Live Draw {formattedDateTime} UTC
              </h1>
              <div className="sm:border-2 md:border-2 lg:border-2 competitionDetails sm:rounded-t-none md:rounded-t-none lg:rounded-t-none rounded-3xl sm:border-primary font-[Sequel100Wide] w-full p-[5em] px-[10%] text-white flex flex-col gap-[3em] sm:text-center md:text-center lg:text-center">
                <div>
                  <h4
                    className="text-[40px] font-bold text-white sm:text-[22px]"
                    style={{
                      fontSize: "clamp(1px,4.34vw,40px)",
                    }}
                  >
                    Competition Details
                  </h4>
                  <h1
                    className="text-[50px] font-[900] sm:text-[28px]"
                    style={{
                      fontSize: "clamp(1px,5.43vw,50px)",
                    }}
                  >
                    {competition?.competitionname}
                  </h1>
                </div>
                <div className="flex flex-col gap-4 font-[MontBlancRegular] text-[20px] sm:text-[16px]">
                  <span
                    style={{
                      fontSize: "clamp(1px,3.47vw,19.96px)",
                    }}
                  >
                    {competition?.competitioninformation}
                  </span>{" "}
                </div>
              </div>
            </div>
            <div className="">
              <FAQ className={"detailsFaq"} fontSize={"text-[45px]"} />
            </div>
          </>
        }
      />
    </div>
  );
};
//872px;
export default Competitions;
