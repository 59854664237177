import Countdown from "react-countdown";
import { Common } from "../../../common/Common";

const Counter = ({ text, winner }: { text: string; winner?: string }) => {
  const isFinished = (new Date(text) < new Date()) || winner;
  return (
    <div
      className={`flex flex-row items-center px-[16px] h-full ${
        !isFinished ? "justify-between" : "justify-center"
      }`}
    >
      {!isFinished && (
        <img
          src="/Images/clock.svg"
          alt=""
          className="w-[25.62px] h-[28.96px]"
        />
      )}
      <Common.typography
        fontSize={!isFinished ? "23.95px" : "16px"}
        fontSizeSmall={!isFinished ? "18.91px" : "12.59"}
        fontFamily="Sequel100Wide95"
        color="#000000"
        className="text-center"
      >
        {isFinished ? "COMPETITION FINISHED" : <Countdown date={text} />}
      </Common.typography>
      {!isFinished && (
        <img
          src="/Images/clock.svg"
          alt=""
          className="w-[25.62px] h-[28.96px]"
        />
      )}
    </div>
  );
};

export default Counter;
