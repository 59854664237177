export const getExplorerTrxLink = (network: string, trxHash: string) => {
  if (network === "ethereum") {
    return `https://etherscan.io/tx/${trxHash}`;
  }
  if (network === "solana") {
    return `https://solscan.io/tx/${trxHash}`;
  }
  if (network === "base") {
    return `https://basescan.org/tx/${trxHash}`;
  }
  if (network === "tron") {
    return `https://tronscan.org/#/transaction/${trxHash}`;
  }
};
