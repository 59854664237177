import { Dispatch, SetStateAction } from "react";
import { Common } from "../../../../common/Common";

interface LoadmoreProps {
  setPage: Dispatch<SetStateAction<number>>;
}

export default function Loadmore({ setPage }: LoadmoreProps) {
  const handleClick = () => {
    setPage((prev) => prev + 1);
  };
  return (
    <Common.button
      heightBig="32px"
      heightSmall="32px"
      maxWidthBig="100%"
      maxWidthSmall="100%"
      backgroundColor="transparent"
      border="0px"
      fontFamily="Sequel100WideVF45"
      fontSize="18px"
      fontSizeSmall="18px"
      color="#ffffff"
      className="uppercase"
      onClick={handleClick}
    >
      LOAD MORE
    </Common.button>
  );
}
