import { Common } from "../../common/Common";

interface ValueProps {
  number: number;
}

// Function to insert a newline after every 10 digits
const formatNumberWithNewline = (number: string) => {
  const formatted = number.replace(/(\d{10})(?=\d)/g, "$1\n"); // Adds newline after every 10 digits
  return formatted;
};

const Value: React.FC<ValueProps> = ({ number }) => {
  // Format the number to remove commas before applying the newline
  const formattedNumber = new Intl.NumberFormat()
    .format(number)
    .replace(/,/g, "");

  return (
    <Common.typography
      fontFamily="Sequel100Wide95"
      fontSize="43.69px"
      fontSizeSmall="25px"
      color="#393939"
      className="uppercase text-center w-auto overflow-y-auto max-h-[200px] sm_md:pt-[39px]"
    >
      {formatNumberWithNewline(formattedNumber)}
    </Common.typography>
  );
};

export default Value;
