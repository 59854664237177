import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useAuth } from "../../Contexts/AuthContext";
import {
  loginByEmailPassword,
  registerByEmailPassword,
} from "../../Services/jwtService";
import Input from "../Input";
import MembershipBox, { MembershipBoxProps } from "../MembershipBox";
import CurrencyBox from "../CurrencyBox";
import Payments from "./Payments";
import Button from "../Button";
import _ from "lodash";
import { Alert } from "@mui/material";
import ForgetPasswordPopup from "../Profile Components/ForgetPasswordPopup";

const schema = yup.object().shape({
  emailaddress: yup.string().email().required("email is required"),
  password: yup.string().required("password is required"),
  confirmpassword: yup.string().required("confirm password is required"),
});

const RegisterForm = () => {
  const methods = useForm({
    mode: "onChange",
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const {
    watch,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, errors, dirtyFields },
  } = methods;
  watch();
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const [isRegistered, setIsRegistered] = useState(false);
  const params = useParams();
  const [emailaddress, setEmailaddress] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = React.useState<string>("");

  const { portal } = params;

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const result = await registerByEmailPassword(
        data?.emailaddress,
        data?.password,
        data?.confirmpassword
      );
      console.log(result);
      setIsRegistered(true);
      setLoading(false);
      if (result) {
        setEmailaddress(data?.emailaddress);
        setPassword(data?.password);
        // const loginData = await loginByEmailPassword(
        //   data?.emailaddress,
        //   data?.password
        // );
        // if (loginData) {
        //   const token = loginData?.accessToken;
        //   if (token) {
        //     setToken(token);

        //     //navigate("/user/dashboard", { replace: true });
        //   }
        // }
      }
    } catch (error: any) {
      console.log("error", error);
      setIsRegistered(false);
      setLoading(false);
      setError(error?.response?.data?.error);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  return (
    <FormProvider {...methods}>
      {!isRegistered && (
        <div className="flex flex-col items-center gap-[2.5em] sm:gap-[2em]">
          {error && (
            <Alert
              className="w-full rounded-full font-['Sequel100WideVF45']"
              variant="filled"
              sx={{
                borderRadius: 20,
                fontFamily: "Sequel100WideVF45",
              }}
              severity="error"
            >
              {error}
            </Alert>
          )}
          <Controller
            name="emailaddress"
            control={control}
            render={({ field }) => (
              <div>
                <div className="flex items-center text-white text-[20px] font-light gap-4 sm:flex-col md:flex-col sm:items-start">
                  <label
                    htmlFor=""
                    className="text-right w-[190px] sm:w-auto"
                    style={{
                      fontSize: "clamp(1px,3.26308206687vw,20.92px)",
                    }}
                  >
                    Email Address
                  </label>
                  <Input
                    {...field}
                    type={"email"}
                    className={
                      "w-[529px] h-[41px] sm:w-[302px] md:w-[302px] rounded-full pl-2"
                    }
                  />
                </div>
                {errors.emailaddress && (
                  <div className="xl:pl-[15rem] lg:pl-[15rem]">
                    <p className="text-red-500 font-['Sequel100WideVF45']">
                      {errors.emailaddress.message}
                    </p>
                  </div>
                )}
              </div>
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <div>
                <div className="flex items-center text-white text-[20px] font-light gap-4 sm:flex-col md:flex-col sm:items-start">
                  <label
                    htmlFor=""
                    className="text-right  w-[190px] sm:w-auto"
                    style={{
                      fontSize: "clamp(1px,3.26308206687vw,20.92px)",
                    }}
                  >
                    Password
                  </label>
                  <Input
                    {...field}
                    type={"password"}
                    className={
                      "w-[529px] h-[41px] sm:w-[302px] md:w-[302px] rounded-full pl-2"
                    }
                  />
                </div>
                {errors.password && (
                  <div className="xl:pl-[15rem] lg:pl-[15rem]">
                    <p className="text-red-500 font-['Sequel100WideVF45']">
                      {errors.password.message}
                    </p>
                  </div>
                )}
              </div>
            )}
          />
          <Controller
            name="confirmpassword"
            control={control}
            render={({ field }) => (
              <div>
                <div className="flex items-center text-white text-[20px] font-light gap-4 sm:flex-col md:flex-col sm:items-start">
                  <label
                    htmlFor=""
                    className="text-right  w-[190px] sm:w-auto"
                    style={{
                      fontSize: "clamp(1px,3.26308206687vw,20.92px)",
                    }}
                  >
                    Confirm Password
                  </label>
                  <Input
                    {...field}
                    type={"password"}
                    className={
                      "w-[529px] h-[41px] sm:w-[302px] md:w-[302px] rounded-full pl-2"
                    }
                  />
                </div>
                {errors.confirmpassword && (
                  <div className="xl:pl-[15rem] lg:pl-[15rem]">
                    <p className="text-red-500 font-['Sequel100WideVF45']">
                      {errors.confirmpassword.message}
                    </p>
                  </div>
                )}
              </div>
            )}
          />

          <Button
            disabled={_.isEmpty(dirtyFields) || !isValid}
            loading={isSubmitting}
            content={"Submit"}
            onClick={handleSubmit(onSubmit)}
            className="text-[#232323] text-[26px] font-extrabold font-[MontBlancBlack] rounded-full bg-primary w-[306px] h-[42px] ml-[-0.6em] uppercase mt-[1em] sm:w-[175px] sm:text-[15px]"
            customStyle={{
              fontSize: "clamp(1px,3.45804013087vw,25.96px)",
            }}
          ></Button>
          <Link to={"/auth/signin"} className="text-white">
            Already have an account? Login
          </Link>
        </div>
      )}
      {isRegistered && (
        <>
          {/* <h1 className="text-white text-[45px] font-extrabold sm:text-[28px] md:text-[28px]">
            PAYMENT TYPE
          </h1> */}
          <Payments emailaddress={emailaddress} password={password} />
        </>
      )}
    </FormProvider>
  );
};

export default RegisterForm;
