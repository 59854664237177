import { tableData } from "./FinishedCompetitionDetails";
import TableTitle from "./texts/TableTitle";
import TableValue from "./texts/TableValue";

export default function Table({ tableData }: { tableData: tableData[] }) {
  return (
    <div className="overflow-y-auto h-[500px] w-[calc(100%-62px)] bg-[#1A1A1A]">
      <div className="bg-[#1A1A1A] border-b-[3px] sticky top-[60px] z-[100px] border-primary"></div>
      <table className="table-auto w-full border-collapse">
        <thead className="bg-[#1A1A1A] sticky top-0 z-10">
          <tr>
            <th className="px-8">
              <TableTitle title="TX HASH" />
            </th>
            <th className="px-8">
              <TableTitle title="MIN" />
            </th>
            <th className="px-8">
              <TableTitle title="MAX" />
            </th>
            <th className="px-8">
              <TableTitle title="WINNING NUMBER" />
            </th>
            <th className="px-8">
              <TableTitle title="RESULT" />
            </th>
          </tr>
          <tr className="h-[32.75px]" />
        </thead>

        {/* <tbody>
        <tr className="h-[32.75px] " />
        <tr>
          <td className="px-8">
            <TableValue
              title="ASKCJA28189SQWERTYQWERTYQWERTYQW"
              copyable
              truncated
            />
          </td>
          <td className="px-8">
            <TableValue
              copyable
              title="ASKCJA28189SQWERTYQWERTYQWERTYQWASKCJA28189SQWERTYQWERTYQWERTYQWqw"
              truncated
            />
          </td>
          <td className="px-8">
            <TableValue title="1" truncated />
          </td>
          <td className="px-8">
            <TableValue title="999999999999" truncated />
          </td>
          <td className="px-8">
            <TableValue title="432,329,000" />
          </td>
        </tr>
      </tbody> */}
        {tableData?.map((data, index) => (
          <tbody key={index}>
            <tr className="h-[32.75px] " />
            <tr>
              <td className="px-8">
                <TableValue title={data?.TXHash} copyable truncated isTXHash />
              </td>
              <td className="px-8">
                <TableValue title={data?.Min?.toString()} truncated />
              </td>
              <td className="px-8">
                <TableValue title={data?.Max?.toString()} truncated />
              </td>
              <td className="px-8">
                <TableValue title={data?.WinningNumber?.toString()} truncated />
              </td>
              <td className="px-8">
                <TableValue title={data?.Result} />
              </td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
}
