import { useNavigate } from "react-router-dom";
import { Common } from "../../../common/Common";

export default function ExitUserDetailsButton() {
  const handleClick = () => {
    // Set the window location to the desired path (forces navigation and refresh)
    window.location.href = "/account";
  };

  return (
    <Common.button
      text="Exit Edit Mode"
      backgroundColor="#6e6e6e"
      color="#000000"
      roundness="25.05px"
      maxWidthBig="394px"
      heightBig="55px"
      fontFamily="Sequel100Wide95"
      fontSize="22px"
      fontSizeSmall="18.37px"
      heightSmall="45.93px"
      maxWidthSmall="100%"
      onClick={handleClick}
    />
  );
}
