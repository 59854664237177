import React, { useEffect } from "react";
import Payments from "../../../Components/Auth Components/Payments";
import WithNonAbsolutedSidebarNavbarFooter from "../../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import VSpacer from "../../../Components/common/Spacer/VSpacer";
import { Common } from "../../../Components/common/Common";
import { useNavigate } from "react-router-dom";

export default function BuyTickets() {
  useEffect(() => {
    document.getElementById("buy_tickets_main")?.scrollIntoView();
  }, []);

  const isAuthenticated = (): boolean => {
    const token = localStorage.getItem("token"); // Or your preferred method of storing the token
    return !!token; // Return true if token exists, otherwise false
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/"); // Redirect to the homepage if not authenticated
    }
  }, [navigate]);


  useEffect(() => {
    // Dynamically create and append the script
    const script = document.createElement('script');
    script.innerHTML = `
      gtag('event', 'conversion', {
        'send_to': 'AW-16801096370/KvwACOSn0_AZELLFscs-',
        'value': 1.0,
        'currency': 'USD',
        'transaction_id': ''
      });
    `;
    document.head.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <WithNonAbsolutedSidebarNavbarFooter
      sidebarContainerClassName="mb-4"
      Component={
        <div
          className="h-auto overflow-hidden box-border bg-[#1A1A1A] w-full sm_md_lg:mx-auto sm_md_lg:w-[calc(100%-20px)]  sm_md_lg:my-[0px] xl:my-8 mx-[43px] rounded-[30px] py-[51px] px-[78px] sm_md:py-[51px] sm_md:px-[11px] flex flex-col items-center "
          id="buy_tickets_main"
        >
          <div className="uppercase">
            <Common.typography
              fontFamily="Sequel100Wide95"
              fontSize="40px"
              color="#fff"
              fontSizeSmall="28px"
            >
              Buy Tickets
            </Common.typography>
          </div>
          <VSpacer small={35} big={37} />
          <Payments skipable={false} />
        </div>
      }
    />
  );
}
