import { useEffect, useState } from "react";
import "./Dropdown.css";
import { Common } from "../Common";

export interface PaymentOption {
    value: string;
    price: string;
    savings: string;
    logo: "star" | "doubleStar" | "tripleStar" | "uniqueStar" | "diamond";
}

interface DropdownProps {
    optionType?: "normal" | "payments";
    options: string[] | PaymentOption[];
    selected: string;
    setSelected: (value: string) => void;
}

export default function Dropdown({
    optionType = "normal",
    options,
    selected,
    setSelected,
}: DropdownProps) {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [dropdown, setDropdown] = useState(false);
    const [key, setKey] = useState(Math.random());
    const toggleDropdown = () => setDropdown((prev) => !prev);
    const handleMouseEnter = (index: number) => setHoveredIndex(index);
    const handleMouseLeave = () => setHoveredIndex(null);

    useEffect(() => {
        const closeDropdown = (e: MouseEvent) => {
            if (
                e.target instanceof HTMLElement &&
                !e.target.closest(".dropdown__container")
            ) {
                setDropdown(false);
            }
        };

        document.addEventListener("click", closeDropdown);

        const closeOnSelect = (e: MouseEvent) => {
            if (
                e.target instanceof HTMLElement &&
                e.target.closest(".dropdown__option")
            ) {
                setDropdown(false);
            }
        };

        document.addEventListener("click", closeOnSelect);

        return () => {
            document.removeEventListener("click", closeDropdown);
            document.removeEventListener("click", closeOnSelect);
        };
    }, []);

    return (
        <div key={key} className="dropdown__container w-full relative">
            <div
                className={`dropdown__handle ${
                    dropdown && "z-10"
                }  relative rounded-[10px] text-white flex items-center justify-between gap-2 px-[12px] py-[15px] cursor-pointer bg-[#2c2c2c] w-full`}
                onClick={toggleDropdown}
            >
                <Common.typography
                    className="uppercase"
                    fontSize="15px"
                    fontSizeSmall="14px"
                    color="#FFFFFF"
                    fontFamily="Sequel100Wide-65"
                >
                    {selected}
                </Common.typography>
                <img src="/Images/chevron_down.svg" alt="Chevron down icon" />
            </div>

            {dropdown && (
                <div className="dropdown__options rounded-b-[10px]">
                    {optionType === "normal" &&
                        (options as string[]).map((option, index) => (
                            <div
                                key={index}
                                className="dropdown__option"
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => setSelected(option)}
                            >
                                <Common.typography
                                    className={
                                        "cursor-pointer " +
                                        (index === hoveredIndex &&
                                            "bg-[#dde406] ")
                                    }
                                    fontFamily="Sequel100Wide-65"
                                    fontSize="15px"
                                    fontSizeSmall="12px"
                                    color="#FFFFFF"
                                >
                                    {option}
                                </Common.typography>
                                <hr />
                            </div>
                        ))}
                    {optionType === "payments" && (
                        <div className="hidden sm:flex w-full">
                            <Common.typography
                                className="max-w-[51px] w-full min-w-0 flex"
                                fontFamily="Sequel100WideVF45"
                                fontSize="10px"
                                fontSizeSmall="10px"
                                color="#DDE404"
                            >
                                Ticket
                            </Common.typography>
                            <span className="w-full flex justify-start min-w-0" />
                            <Common.typography
                                className="w-full flex"
                                fontFamily="Sequel100WideVF45"
                                fontSize="10px"
                                fontSizeSmall="10px"
                                color="#DDE404"
                            >
                                Price
                            </Common.typography>

                            <Common.typography
                                className="w-full flex"
                                fontFamily="Sequel100WideVF45"
                                fontSize="10px"
                                fontSizeSmall="10px"
                                color="#DDE404"
                            >
                                Savings
                            </Common.typography>
                        </div>
                    )}
                    {optionType === "payments" &&
                        (options as PaymentOption[]).map((option, index) => {
                            const isHovered = index === hoveredIndex;
                            return (
                                <div
                                    className="xl:py-[5px] py-[3.47px]"
                                    key={index}
                                >
                                    <div
                                        className={`dropdown__option flex cursor-pointer border-b-[1px] border-[#fff] xl:h-[24px] h-[18px] font-[Sequel100wide] ${
                                            isHovered ? "bg-[#dde406]" : ""
                                        }`}
                                        key={index}
                                        onMouseEnter={() =>
                                            handleMouseEnter(index)
                                        }
                                        onMouseLeave={handleMouseLeave}
                                        onClick={() =>
                                            setSelected(option.value)
                                        }
                                    >
                                        <div className="max-w-[51px] sm:w-full min-w-0 flex">
                                            <Common.typography
                                                className={`!text-[#fff] ${
                                                    isHovered
                                                        ? "!text-[#343434]"
                                                        : ""
                                                } block `}
                                                fontSize="15px"
                                                fontSizeSmall="12px"
                                                fontFamily="Sequel100Wide-65"
                                            >
                                                {option.value}
                                            </Common.typography>
                                        </div>
                                        <div className="w-full flex justify-start min-w-0">
                                            <img
                                                className="w-full h-full"
                                                src={`/Images/${option.logo}.svg`}
                                                alt={`${option.logo} logo`}
                                            />
                                        </div>

                                        <div className="w-full flex">
                                            {/* <Text
                        className={`text-[#fff] ${
                          isHovered ? "text-[#343434]" : ""
                        } block `}
                        textSize={[12, 12, 15]}
                      >
                        ${option.price}
                      </Text> */}
                                            <Common.typography
                                                className={`!text-[#fff] ${
                                                    isHovered
                                                        ? "!text-[#343434]"
                                                        : ""
                                                } block `}
                                                fontSize="15px"
                                                fontSizeSmall="12px"
                                                fontFamily="Sequel100Wide-65"
                                            >
                                                ${option.price}
                                            </Common.typography>
                                        </div>
                                        <div className="w-full flex justify-start">
                                            {/* <Text
                        className={`text-[#EF008F] ${
                          isHovered ? "text-white" : ""
                        } block text-nowrap text-left w-auto truncate `}
                        textSize={[12, 12, 15]}
                      >
                        ${option.savings} savings
                      </Text> */}
                                            <Common.typography
                                                className={`block text-nowrap text-left w-auto truncate !text-[#EF008F] ${
                                                    isHovered
                                                        ? "!text-white"
                                                        : ""
                                                } block `}
                                                fontSize="15px"
                                                fontSizeSmall="12px"
                                                fontFamily="Sequel100WideVF45"
                                            >
                                                ${option.savings}{" "}
                                                <span className="sm:hidden">
                                                    savings
                                                </span>
                                            </Common.typography>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            )}
        </div>
    );
}
