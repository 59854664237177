/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

const getEthereumSignature = async (body: {
  chain: string;
  contractAddress: string;
  userWalletAddress: string;
}) => {
  try {
    const response = await axios.post("/getethereumsignature", body);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getBaseSignature = async (body: {
  chain: string;
  contractAddress: string;
  userWalletAddress: string;
  paymentTokenAddress: string;
  payment: number;
}) => {
  try {
    const response = await axios.post("/getethereumsignature", body);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getTronSignature = async (body: {
  contractAddress: string;
  userWalletAddress: string;
  paymentTokenAddress: string;
  payment: number;
}) => {
  try {
    const response = await axios.post("/gettronsignature", body);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export { getEthereumSignature, getTronSignature, getBaseSignature };
