import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { openGlobalModal } from "./ClipboardModalContext";

const AdvertBox = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    const token = localStorage.getItem("token"); // Check for a token in localStorage

    if (token) {
      // Token exists, navigate to the buy tickets page
      navigate("/buytickets");
    } else {
      // Token doesn't exist, show an alert
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Adds a smooth scrolling effect
      });
      if (openGlobalModal) {
        openGlobalModal({
          text: "Please connect your wallet to enter competitions.",
        });
      }
      // alert("Please connect your wallet to enter competitions");
    }
  };

  return (
    <div className="advertBox bg-black h-[860px] w-full py-[12%] sm:h-[711px] sm:flex sm:flex-col md:h-screen md:flex md:flex-col md:py-0 md:gap-5 md:pt-4 relative">
      <div className="flex flex-col gap-1 ml-[5em] sm:text-center sm:w-full sm:ml-0 sm:items-center h-full sm:p-5 md:text-center md:w-full md:ml-0 md:items-center md:p-5">
        <h3
          className="text-white sm:text-[18px] md:text-[25px]"
          style={{
            fontSize: "clamp(1rem, 4.260140417vw, 35.47px)",
          }}
        >
          GET YOUR TICKETS FAST!
        </h3>
        <h1
          className="text-white sm:text-[35px] md:text-[50px] font-[Sequel100Wide95]"
          style={{
            fontSize: "clamp(1rem, 7.459207459vw, 72.41px)",
          }}
        >
          BEFORE THE <br />
          NEXT DRAW!
        </h1>
        <button
          className="mt-[63px] sm:mt-[36.79px] shadow-black shadow-lg bg-primary h-[80px] sm:h-[45.87px] sm:text-[1.3rem] px-10 sm:px-3 sm:w-max sm:px-[22px] rounded-full font-[Sequel100Wide95] text-black text-[28.53px] font-[900] w-fit sm:text-base border-2 border-white relative z-[100001]"
          style={{
            fontSize: "clamp(1rem, 4.267120842vw, 35.53px)",
          }}
          onClick={handleButtonClick} // Add the onClick handler
        >
          ENTER NOW
        </button>
      </div>
    </div>
  );
};

export default AdvertBox;
