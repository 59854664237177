import { useEffect, useState } from "react";
import { getAllAvatars, setAvatar } from "../../../../Services/userService";
import { CircularProgress } from "@mui/material";

interface IAvatar {
  UID: string;
  IMAGE_URL: string;
}
interface IAvatarSelectorPopupProps {
  onConfirm: (selected: string) => void;
  onClose: () => void;
}
export default function AvatarSelectorPopup(props: IAvatarSelectorPopupProps) {
  const [selected, setSelected] = useState<string>("");
  const [avatars, setAvatars] = useState<IAvatar[]>([]);
  const handleGetAllAvatars = async () => {
    const avatars = await getAllAvatars();
    setAvatars(avatars?.data);
  };

  useEffect(() => {
    handleGetAllAvatars();
  }, []);
  useEffect(() => {
    console.log("avatars: ", avatars);
  }, [avatars]);

  return (
    <div
      className="
        w-2/3 h-2/3
      bg-general_bg
        rounded-[30px] 
        absolute
        top-1/2 left-1/2
        transform -translate-x-1/2 -translate-y-1/2
        sm_md_lg:w-[calc(100%-50px)]
        flex justify-center items-center flex-col
        p-6
  "
    >
      {avatars.length === 0 ? (
        <div className="text-white text-[1.5rem] font-[Sequel100Wide95] flex flex-col justify-center items-center">
          <CircularProgress sx={{ color: "#fff" }} />
          Loading...
        </div>
      ) : (
        <>
          <div className="flex flex-wrap gap-1 justify-center m-auto relative overflow-y-auto scrollbar scrollbar-thumb-secondary scrollbar-track-[#3B3B3B] scrollbar-w-[4px] ">
            {avatars.map((avatar) => (
              <div key={avatar.UID} className="">
                <img
                  onClick={() => setSelected(avatar.IMAGE_URL)}
                  src={avatar.IMAGE_URL}
                  alt="Avatar"
                  className={`w-28 h-28 rounded-[12px] border-2 ${
                    selected === avatar.IMAGE_URL
                      ? "border-primary"
                      : "border-transparent"
                  } `}
                />
              </div>
            ))}
          </div>
          {/* Confirmation buttons */}
          <div className="flex gap-4 mt-4 w-full justify-end px-4 sm_md:justify-between flex-wrap">
            <button
              className="bg-black font-[Sequel100Wide95] text-white text-[1.5rem] p-2 px-4 rounded-full sm_md:w-full"
              onClick={props.onClose}
            >
              Cancel
            </button>
            <button
              className="bg-primary font-[Sequel100Wide95] text-black text-[1.5rem] p-2 px-4 rounded-full sm_md:w-full"
              onClick={() => props?.onConfirm(selected)}
            >
              Confirm
            </button>
          </div>
        </>
      )}
    </div>
  );
}
