import React from "react";
import { rngData } from "../../../Pages/RandomNumberGeneratorPage";
import TableTitle from "../texts/TableTitle";
import TableValue from "../texts/TableValue";

export default function Table({ tableData }: { tableData: rngData[] }) {
  return (
    <table className="overflow-auto sm_md_lg:hidden w-[calc(100%-62px)]">
      <thead className="border-b-[3px] border-primary">
        <tr>
          <th className="px-8">
            <TableTitle title="WALLET ADDRESS" />
          </th>
          <th className="px-8">
            <TableTitle title="TX HASH" />
          </th>
          <th className="px-8">
            <TableTitle title="MIN" />
          </th>
          <th className="px-8">
            <TableTitle title="MAX" />
          </th>
          <th className="px-8">
            <TableTitle title="WINNING NUMBER" />
          </th>
        </tr>
        <tr className="h-[32.75px] " />
      </thead>
      {/* <tbody>
        <tr className="h-[32.75px] " />
        <tr>
          <td className="px-8">
            <TableValue
              title="ASKCJA28189SQWERTYQWERTYQWERTYQW"
              copyable
              truncated
            />
          </td>
          <td className="px-8">
            <TableValue
              copyable
              title="ASKCJA28189SQWERTYQWERTYQWERTYQWASKCJA28189SQWERTYQWERTYQWERTYQWqw"
              truncated
            />
          </td>
          <td className="px-8">
            <TableValue title="1" truncated />
          </td>
          <td className="px-8">
            <TableValue title="999999999999" truncated />
          </td>
          <td className="px-8">
            <TableValue title="432,329,000" />
          </td>
        </tr>
      </tbody> */}
      {tableData?.map((data, index) => (
        <tbody key={index}>
          <tr className="h-[32.75px] " />
          <tr>
            <td className="px-8">
              <TableValue title={data?.walletaddress} copyable truncated />
            </td>
            <td className="px-8">
              <TableValue
                copyable
                title={data?.transactionhash}
                truncated
                trxHash={true}
              />
            </td>
            <td className="px-8">
              <TableValue title={data?.minnumber.toString()} truncated />
            </td>
            <td className="px-8">
              <TableValue title={data?.maxnumber.toString()} truncated />
            </td>
            <td className="px-8">
              <TableValue title={data?.numbergenerated.toString()} />
            </td>
          </tr>
        </tbody>
      ))}
    </table>
  );
}
