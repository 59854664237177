import { useEffect, useState } from "react";
import VSpacer from "../../../Components/common/Spacer/VSpacer";
import EditUserDetailsButton from "../../../Components/User Pages Components/My Account/Buttons/EditUserDetailsButton";
import { AvatarContainer } from "../../../Components/User Pages Components/My Account/Containers/AvatarContainer";
import DataContainer from "../../../Components/User Pages Components/My Account/Containers/DataContainer";
import Title2Container from "../../../Components/User Pages Components/My Account/Containers/Title2Container";
import Avatar from "../../../Components/User Pages Components/My Account/images/Avatar";
import DataInfo from "../../../Components/User Pages Components/My Account/texts/DataInfo";
import DataTitle from "../../../Components/User Pages Components/My Account/texts/DataTitle";
import Description from "../../../Components/User Pages Components/My Account/texts/Description";
import Title from "../../../Components/User Pages Components/My Account/texts/Title";
import Title2 from "../../../Components/User Pages Components/My Account/texts/Title2";
import Title3 from "../../../Components/User Pages Components/My Account/texts/Title3";
import { useAuth } from "../../../Contexts/AuthContext";
import WithNonAbsolutedSidebarNavbarFooter from "../../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import AvatarSelectorPopup from "../../../Components/User Pages Components/My Account/others/AvatarSelectorPopup";
import { getUserDetails, setAvatar } from "../../../Services/userService";
import { useWallet } from "../../../Contexts/WalletContext";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../Components/common/loader/Loader";

export default function MyAccount() {
  const [openPopup, setOpenPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    document.getElementById("my_account_main")?.scrollIntoView();
  }, []);
  const { user, fetchUser, account } = useWallet();
  const [userInformation, setUserInformation] = useState({
    username: "No Data",
    telegramHandle: "No Data",
    telephoneNumber: "No Data",
    emailAddress: "No Data",
  });
  useEffect(() => {
    // const userDetails = getUserDetails(user?.userName || "");
    const userDetails = getUserDetails(account || "");
    console.log("userDetails: ", userDetails);
  }, []);
  useEffect(() => {
    if (user) {
      setShowLoader(false);
    }
    setUserInformation({
      username: user?.userName || "No Data",
      telegramHandle: user?.telegram || "No Data",
      telephoneNumber: user?.phone || "No Data",
      // emailAddress: user?.userName || "No Data",
      // emailAddress: localStorage?.getItem("emailaddress") || "No Data",
      emailAddress: user?.email || "No Data",
    });
    console.log("user: ", user);
    console.log("image is: ", user?.imageUrl);
  }, [user]);
  const onConfirm = async (selected: string) => {
    const response = await setAvatar(selected);
    console.log("response is: ", response);
    setOpenPopup(false);
    // reset the user context
    fetchUser();
  };

  const isAuthenticated = (): boolean => {
    const token = localStorage.getItem("token"); // Or your preferred method of storing the token
    return !!token; // Return true if token exists, otherwise false
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/"); // Redirect to the homepage if not authenticated
    }
  }, [navigate]);

  return (
    <WithNonAbsolutedSidebarNavbarFooter
      Component={
        // Main Container
        <div
          id="my_account_main"
          className="overflow-hidden box-border bg-[#181818] w-full sm_md_lg:mx-auto sm_md_lg:w-[calc(100%-20px)] sm_md:relative sm_md_lg:my-[0px] xl:my-8 h-full mx-[43px] rounded-[30px] py-[63px]  sm_md:py-[44px]  flex flex-col "
        >
          {/* <Loader show={showLoader} /> */}
          {openPopup && (
            <AvatarSelectorPopup
              onConfirm={onConfirm}
              onClose={() => {
                setOpenPopup(false);
              }}
            />
          )}
          {/* Title */}
          <Title />
          <VSpacer small={64} big={59} />
          {/* 2- Title 2 container */}
          <Title2Container>
            {/* Title2 */}
            <Title2 />
          </Title2Container>

          <VSpacer small={47} big={65} />
          <div className="sm_md:w-[calc(100%-30px)] w-full px-[87px] sm_md:px-0 //w-[calc(100%-78px)] m-auto">
            {/* Avatar Container row */}
            <AvatarContainer.Row>
              {/* Avatar image */}

              <Avatar
                src={user?.imageUrl || "/Images/No Image.svg"}
                onClick={() => {
                  setOpenPopup(true);
                }}
              />

              {/* Avatar Container col */}
              <AvatarContainer.Col>
                {/* Title3 */}
                <Title3 />
                {/* avatar description */}
                <Description />
              </AvatarContainer.Col>
            </AvatarContainer.Row>
            <VSpacer small={40} big={39} />
            {/* Data info */}
            {/* 4.1 Username */}
            <DataContainer>
              <DataTitle text="Username" />
              <DataInfo text={userInformation?.username} />
            </DataContainer>
            {/* 4.2 Telegram Handle */}
            <DataContainer>
              <DataTitle text="Telegram Handle" />
              <DataInfo text={userInformation?.telegramHandle} />
            </DataContainer>
            {/* 4.3 Telephone Number */}
            <DataContainer>
              <DataTitle text="Telephone Number" />
              <DataInfo text={userInformation?.telephoneNumber} />
            </DataContainer>
            {/* 4.4 Email Address */}
            <DataContainer>
              <DataTitle text="Email Address" />
              <DataInfo text={userInformation?.emailAddress} />
            </DataContainer>
            <VSpacer small={37} big={42.06} />
            {/* Edit button */}
          </div>
          <div className="sm_md:flex items-center justify-center w-full sm_md:px-0 px-[87px]">
            <EditUserDetailsButton />
          </div>
        </div>
      }
    />
  );
}
