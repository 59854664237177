/* eslint-disable @typescript-eslint/no-explicit-any */
import { BrowserProvider, Eip1193Provider, ethers, parseEther } from "ethers";
import EventEmitter from "events";
import { baseConfigs, ethereumConfigs } from "../Config/blockchain.config";
import { signConfirm } from "../Services/userService";
import { WalletConnector } from "../factories/WalletConnector";

export class MetamaskConnector extends EventEmitter implements WalletConnector {
  private walletState:
    | {
        connected: boolean;
        provider: BrowserProvider | null;
        account: string | null;
        network: string | null;
        providerType: string | null;
      }
    | undefined;
  private intervalMap: Map<string, NodeJS.Timeout>;
  private metamaskProvider: Eip1193Provider | undefined;

  constructor(metamaskProvider?: Eip1193Provider) {
    super();
    this.intervalMap = new Map();
    this.metamaskProvider = metamaskProvider;
  }

  async connect(network: string, storedAccount?: string): Promise<void> {
    try {
      if (network !== "ethereum" && network !== "base") {
        throw new Error("Metamask does not support this network.");
      }
      // Logic to connect with Metamask
      console.log(`Connecting to ${network} with Metamask...`);

      const ethereum = this.metamaskProvider;
      // Check if window.ethereum (MetaMask) is available

      if (!ethereum) {
        if (
          /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
            navigator.userAgent
          )
        ) {
          window.location.href =
            "https://metamask.app.link/dapp/https://theprize.io";

          throw new Error("Please use metamask app browser.");
        } else {
          throw new Error(
            "MetaMask is not installed. Please install MetaMask to proceed."
          );
        }
      }

      if (ethereum) {
        const provider = new BrowserProvider(ethereum);
        const connectedNetwork = await provider.getNetwork();
        const correctChainId =
          network === "base" ? baseConfigs.CHAIN_ID : ethereumConfigs.CHAIN_ID;

        if (Number(connectedNetwork.chainId) !== correctChainId) {
          console.log(`Switching network to ${network}...`);
          await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${correctChainId.toString(16)}` }],
          });
        }

        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });

        this.walletState = {
          connected: true,
          provider,
          account: accounts[0].toString(),
          network: network,
          providerType: "metamask",
        };

        this.emit("stateChange", this.walletState);

        if (storedAccount !== accounts[0].toString()) {
          console.log("Account changed");
          this.emit("accountChanged", accounts[0].toString());
        }
      } else {
        throw new Error('"MetaMask is not installed"');
      }
    } catch (err) {
      console.error("Error connecting to wallet:", err);
      throw new Error(err as any);
    }
  }

  async reconnect(network: string, account: string): Promise<void> {
    try {
      // Logic to connect with Metamask
      console.log(`ReConnecting to ${network} with Metamask...`);
      return await this.connect(network, account);
    } catch (err) {
      console.error("Error connecting to wallet:", err);
      throw new Error(err as any);
    }
  }

  async authenticate(): Promise<string> {
    try {
      if (this.walletState) {
        // Sign a message with the selected account
        const message = this.walletState.account?.toString() ?? "";
        const signer = await this.walletState.provider?.getSigner();
        const signature = await signer?.signMessage(message);

        if (signature) {
          // If signature is successful, call your signConfirm function
          const accessToken = await signConfirm(
            this.walletState.account ?? "",
            this.walletState.account ?? "",
            signature,
            "ethereum"
          );

          return accessToken;
        } else {
          throw new Error("Failed to generate signature.");
        }
      } else {
        throw new Error("Wallet not initialized");
      }
    } catch (err) {
      throw new Error(err as any);
    }
  }

  async disconnect(): Promise<void> {
    console.log("Disconnected from Metamask.");
    this.walletState = undefined;
    this.emit("stateChange", this.walletState);
  }

  async isConnected(): Promise<boolean> {
    return this.walletState?.connected ?? false;
  }

  async callContractMethod(
    contractAddress: string,
    abi: any[],
    methodName: string,
    args: any[] = [],
    isWrite: boolean = false,
    value: string = "0"
  ): Promise<any> {
    try {
      if (!this.walletState || !this.walletState.provider) {
        throw new Error("Wallet not connected or signer not available.");
      }

      const signer = await this.walletState.provider.getSigner();

      const contract = new ethers.Contract(contractAddress, abi, signer);

      console.log(`Calling ${methodName} on contract ${contractAddress}...`);

      if (isWrite) {
        // Write (state-changing) operation
        const tx = await contract[methodName](...args, {
          value: parseEther(value),
        });
        console.log("Transaction sent:", tx);

        return tx;
      } else {
        // Read (non-state-changing) operation
        const result = await contract[methodName](...args);
        console.log("Method result:", result);

        return result;
      }
    } catch (error) {
      console.error("Error calling contract method:", error);
      throw new Error(error as any);
    }
  }

  async sendTransaction(
    to: string,
    value: string,
    data?: string
  ): Promise<any> {
    try {
      if (!this.walletState || !this.walletState.provider) {
        throw new Error("Wallet not connected or signer not available.");
      }

      const signer = await this.walletState.provider.getSigner();

      const tx = {
        to,
        value: ethers.parseEther(value),
        data: data || "0x",
      };

      console.log("Sending transaction...", tx);

      const transactionResponse = await signer.sendTransaction(tx);
      console.log("Transaction sent:", transactionResponse);

      // Wait for the transaction to be mined (optional)
      const receipt = await transactionResponse.wait();
      console.log("Transaction confirmed:", receipt);

      return receipt;
    } catch (error) {
      console.error("Error sending transaction:", error);
      throw new Error(error as any);
    }
  }

  async listenToContractEvent(
    contractAddress: string,
    abi: any[],
    eventName: string,
    callback: (eventData: any) => void
  ): Promise<void> {
    try {
      if (!this.walletState || !this.walletState.provider) {
        throw new Error("Wallet not connected or provider not available.");
      }

      const provider = this.walletState.provider;
      const contract = new ethers.Contract(contractAddress, abi, provider);

      console.log(
        `Listening to event "${eventName}" on contract ${contractAddress}...`
      );

      // Set up the event listener
      contract.on(eventName, (...args) => {
        const eventData = args.slice(0, args.length - 1); // Exclude the event metadata
        const event = args[args.length - 1]; // Extract event metadata
        console.log(`Event "${eventName}" detected:`, { eventData, event });

        // Invoke the callback with event data
        callback({ eventData, event });

        // Emit the event locally
        this.emit(eventName, { eventData, event });
      });

      // Set up the interval as a fallback for missed events
      const intervalId = setInterval(async () => {
        const currentBlock = await provider.getBlockNumber();
        const filter = contract.filters[eventName]();
        const logs = await provider.getLogs({
          topics: await filter.getTopicFilter(),
          fromBlock: Math.max(currentBlock - 10, 0), // Adjust block range as needed
          toBlock: "latest",
        });

        logs?.forEach((log) => {
          const parsedLog = contract.interface.parseLog(log);
          if (parsedLog) {
            // Extract event arguments and convert to array
            const eventData = Object.values(parsedLog.args);

            // Create event metadata similar to contract.on
            const event = {
              blockNumber: log.blockNumber,
              transactionHash: log.transactionHash,
              logIndex: log.index,
              address: log.address,
              data: log.data,
              topics: log.topics,
            };

            console.log(`Event "${eventName}" detected:`, { eventData, event });

            // Invoke the callback with formatted event data and metadata
            callback({ eventData, event });

            // Emit the event locally (optional)
            this.emit(eventName, { eventData, event });
          }
        });
      }, 5_000); // Every 5 seconds

      // Store the interval ID in the map
      const key = `${contractAddress}-${eventName}`;
      this.intervalMap.set(key, intervalId);
    } catch (error) {
      console.error(`Error listening to contract event "${eventName}":`, error);
      throw new Error(error as any);
    }
  }

  async stopListeningToContractEvent(
    contractAddress: string,
    abi: any[],
    eventName: string
  ): Promise<void> {
    try {
      if (!this.walletState || !this.walletState.provider) {
        throw new Error("Wallet not connected or provider not available.");
      }

      const provider = this.walletState.provider;
      const contract = new ethers.Contract(contractAddress, abi, provider);

      console.log(
        `Stopping listener for event "${eventName}" on contract ${contractAddress}...`
      );

      contract.removeAllListeners(eventName);

      // Clear the interval
      const key = `${contractAddress}-${eventName}`;
      const intervalId = this.intervalMap.get(key);
      if (intervalId) {
        clearInterval(intervalId);
        this.intervalMap.delete(key);
      }
    } catch (error) {
      console.error(
        `Error stopping listener for contract event "${eventName}":`,
        error
      );
      throw new Error(error as any);
    }
  }
}
