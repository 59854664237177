import React from "react";
import { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import PageLink, { PageLinkProps } from "./Link";
import { getUserBalance } from "../Services/jwtService";
import numeral from "numeral";
import UserDetail from "./User Pages Components/common/NavbarItems/UserDetail";
import { useWallet } from "../Contexts/WalletContext";
import LoggedInUserBox from "./LoggedInUserBox";
import TelegramConfirmationPopup from "./common/confirmation/telegram_confirmation";

const Navbar = ({
  opener,
  setOpener,
  connectWalletFunction,
  showConnectWallet,
}: // account,
{
  opener?: boolean;
  setOpener?: React.Dispatch<React.SetStateAction<boolean>>;
  connectWalletFunction: (showState: boolean) => void;
  showConnectWallet: boolean;
  // account: undefined | String;
}) => {
  const location = useLocation();
  const params = useParams();
  const { portal } = params;
  const [currentRoute, setCurrentRoute] = useState("");
  const [userSideHamburger, setUserSideHamburger] = useState(false);
  const [hamburger, setHamburger] = useState(false);

  const {
    isConnecting,
    connect,
    token,
    account,
    disconnect,
    network,
    setNetwork,
    user,
  } = useWallet();

  useEffect(() => {
    if (opener) {
      setHamburger(opener);
    }
  }, [opener]);

  useEffect(() => {
    if (opener) {
      setHamburger(opener);
    }
  }, [opener]);

  const tickets = (user as { numberOfTickets: number })?.numberOfTickets;

  const telegram = (user as { telegram: string })?.telegram;

  const username = (user as { userName?: string })?.userName;
  const [balance, setBalance] = useState(tickets * 0.99);

  const user_name = username;
  const account_code = "account code xxxxx";

  const [accountname, setAccountName] = useState("");

  // Retrieve username from localStorage
  const savedAccount = localStorage.getItem("account");
  useEffect(() => {
    if (savedAccount) {
      setAccountName(savedAccount); // Update state with the username
      // } else if (accountname != undefined && accountname != "") {
      //     setAccountName(accountname);
    }
  }, [savedAccount]);

  useEffect(() => {
    if (user) {
      setBalance(tickets * 0.99);
    }
  }, [user]);

  useEffect(() => {
    async function getRoute() {
      const route = location.pathname;
      await setCurrentRoute(route);
    }

    getRoute();
  }, [portal]);

  const navLinks: PageLinkProps[] = [
    {
      src: "/",
      content: "Home",
    },
    {
      src: "/competitions/all",
      content: "Competitions",
    },
    {
      src: "/howtoplay",
      content: "How to Play",
    },
    {
      src: "/rng",
      content: "RNG",
    },
    {
      src: "/winners",
      content: "Winners",
    },
  ];

  const userPagesnavLinks: PageLinkProps[] = [
    {
      src: "/buytickets",
      content: "Buy Tickets",
    },
    {
      src: "/wallet",
      content: "My Wallet",
    },
    {
      src: "/entries/live",
      content: "Entries",
    },
    {
      src: "/orders",
      content: "My Orders",
    },
    {
      src: "/account",
      content: "My Account",
    },
  ];

  const navigate = useNavigate();

  const logoutHandler = () => {
    disconnect(); // Ensure 'disconnect' is a valid function and properly imported or defined.
    navigate("/"); // Ensure 'navigate' is initialized using useNavigate().
  };

  return (
    <>
      {account && token && !isConnecting && telegram == null ? (
        <TelegramConfirmationPopup></TelegramConfirmationPopup>
      ) : (
        <></>
      )}
      <div
        id="navbar"
        className="//p-10 mr-[40px] ml-[20px] pt-0 md:hidden sm:hidden lg:hidden sticky top-2 z-[88] flex mt-[2em]"
      >
        <div className="flex items-center ml-[1.5em] gap-4">
          <Link to={"/"}>
            <img
              src="/Images/header_image.png"
              alt=""
              className="h-[120px] min-w-[262px]"
            />
          </Link>
        </div>
        <div className="h-[120px] w-full flex items-center justify-between bg-[#1A1A1A] rounded-r-[5px] px-[29px]">
          {/* <div className="flex items-center ml-[1.5em] gap-4">
                        <Link to={"/"}>
                            <img src="/Images/logo.png" alt="" className="" />
                        </Link>
                    </div> */}
          <div className="flex flex-1 justify-evenly font-['Sequel100WideVF45'] font-extralight">
            {navLinks &&
              navLinks.map((link, index) => (
                <PageLink
                  src={link.src}
                  content={link.content}
                  className={`${
                    link.src === currentRoute
                      ? "text-primary font-['Sequel100Wide']"
                      : "font-normal text-white"
                  } uppercase text-xl `}
                  key={index}
                />
              ))}
          </div>

          {account && token ? (
            // <LoggedInUserBox
            //     accountname={account.substring(0, 12) + ".."}
            //     ticketsNum={"241"}
            //     logoutButtonHandler={disconnect}
            // ></LoggedInUserBox>
            <UserDetail
              // name={accountname?.substring(0, 12) + ".."}
              name={accountname}
              tickets={tickets}
              logoutButtonHandler={logoutHandler}
            />
          ) : (
            <button className="bg-primary m-[1em]  text-lg font-semibold font-[MontBlancBold] w-[200px] h-[89.85px] rounded-[5px] ">
              <div
                onClick={() => connectWalletFunction(true)}
                className="flex flex-wrap justify-between flex-row  px-[30px]"
              >
                <p
                  className="text-black font-['Sequel100Wide']
                                   uppercase text-l"
                >
                  CONNECT <br />
                  WALLET
                </p>
                <img
                  src="/Images/download-icon.svg"
                  alt=""
                  className="sm:w-[20px] cursor-pointer rounded-full"
                ></img>
              </div>
            </button>
          )}
        </div>
        <div
          style={{ display: userSideHamburger ? "block" : "none" }}
          className=" mt-0 mb-0 relative   z-[88]"
        >
          <div className="flex flex-col gap-4 w-full p-12 absolute bg-[#2b2b2b]">
            <img
              src="/Images/coin_colored_half.svg"
              alt=""
              className="absolute bottom-0 right-0 opacity-50 select-none pointer-events-none "
            />
            <div className="flex items-center justify-between">
              <Link
                to={"/wallet"}
                className={`${
                  currentRoute === "/wallet"
                    ? "text-primary font-extrabold"
                    : "font-normal text-white"
                } uppercase text-[18px] `}
              >
                {" "}
                My Wallet
              </Link>
            </div>

            <div className="flex items-center justify-between">
              <Link
                to={"/entries/live"}
                className={`${
                  currentRoute === "/entries/live"
                    ? "text-primary font-extrabold"
                    : "font-normal text-white"
                } uppercase text-[18px] `}
              >
                {" "}
                Entries
              </Link>
            </div>

            <div className="flex items-center justify-between">
              <Link
                to={"/orders"}
                className={`${
                  "/orders" === currentRoute
                    ? "text-primary font-extrabold"
                    : "font-normal text-white"
                } uppercase text-[18px] `}
              >
                {" "}
                My Orders
              </Link>
            </div>
            <div className="flex items-center">
              <Link
                to={"/account"}
                className={`${
                  currentRoute === "/account"
                    ? "text-primary font-extrabold"
                    : "font-normal text-white"
                } uppercase text-[18px] `}
              >
                {" "}
                My Account
              </Link>
            </div>
            <div className="flex items-center justify-between">
              <Link
                to={"/buytickets"}
                className={`${
                  currentRoute === "/buytickets"
                    ? "text-primary font-extrabold"
                    : "font-normal text-white"
                } uppercase text-[18px] `}
              >
                {" "}
                Buy Tickets
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* for mobile screen */}

      <div className="xl:hidden flex lg:flex">
        <div className=" items-center ml-[3%] gap-4 ">
          <Link to={"/"}>
            <img
              src="/Images/header_image.png"
              alt=""
              className="h-[112px] min-w-[180px] w-[100%]  flex items-center gap-4"
            />
          </Link>
        </div>
        <div
          id="navbar-mobile"
          className="w-[80%] h-[112px] pt-3  relative z-[88] bg-[#1A1A1A] mr-[3%] rounded-r-[5px] "
        >
          <div className="flex items-left justify-end px-5">
            <div
              className={hamburger ? "open" : "hamburger"}
              onClick={() => {
                if (hamburger) {
                  connectWalletFunction(false);
                }
                setHamburger(!hamburger);
              }}
            >
              <span className="top"></span>
              <span className="middle"></span>
              <span className="bottom"></span>
            </div>
          </div>
        </div>
      </div>

      {hamburger && (
        <div className="relative z-[88] mobileNav mt-4 //opacity-[0.95]">
          <div className="flex flex-col gap-4 w-full p-12 bg-[#2b2b2b] relative">
            {/* <img
                            src="/Images/coin_colored_half.svg"
                            alt=""
                            className="absolute bottom-0 right-0 opacity-50 select-none pointer-events-none"
                        /> */}
            {navLinks &&
              navLinks.map((link, index) => (
                <div key={index} className="flex items-center justify-between pl-2">
                  <Link
                    to={link.src}
                    className={`${
                      link.src === currentRoute
                        ? "text-primary font-extrabold"
                        : "font-normal text-white"
                    } uppercase text-[18px] `}
                  >
                    {" "}
                    {link.content}
                  </Link>
                  {link.src === currentRoute && (
                    <img src="/Images/pointer.png" alt="" />
                  )}
                </div>
              ))}
            {user && (
              <>
                <hr />
                <div onClick={()=>{
                  navigate("/buytickets");
                }} className="flex py-2 rounded-md bg-primary items-center justify-between pl-2">
                  <Link
                    to={"/buytickets"}
                    className={`bg-primary text-black font-extrabold
                       
                    } uppercase text-[18px] `}
                  >
                    {" "}
                    Buy Tickets
                  </Link>
                </div>
                <div className="flex items-center justify-between pl-2">
                  <Link
                    to={"/wallet"}
                    className={`${
                      currentRoute === "/wallet"
                        ? "text-primary font-extrabold"
                        : "font-normal text-white"
                    } uppercase text-[18px] `}
                  >
                    {" "}
                    My Wallet
                  </Link>
                </div>
                <div className="flex items-center justify-between pl-2">
                  <Link
                    to={"/entries/live"}
                    className={`${
                      currentRoute === "/entries/live"
                        ? "text-primary font-extrabold"
                        : "font-normal text-white"
                    } uppercase text-[18px] `}
                  >
                    {" "}
                    My Entries
                  </Link>
                </div>
                <div className="flex items-center justify-between pl-2">
                  <Link
                    to={"/orders"}
                    className={`${
                      "/orders" === currentRoute
                        ? "text-primary font-extrabold"
                        : "font-normal text-white"
                    } uppercase text-[18px] `}
                  >
                    {" "}
                    My Orders
                  </Link>
                </div>
                <div className="flex items-center justify-between pl-2">
                  <Link
                    to={"/account"}
                    className={`${
                      currentRoute === "/account"
                        ? "text-primary font-extrabold"
                        : "font-normal text-white"
                    } uppercase text-[18px] `}
                  >
                    {" "}
                    My Account
                  </Link>
                </div>
              </>
            )}
          </div>
          {user ? (
            <>
              <UserDetail
                // name={accountname?.substring(0, 12) + ".."}
                name={accountname}
                tickets={tickets}
                logoutButtonHandler={logoutHandler}
              />
              {/* <button
                onClick={disconnect}
                className="bg-primary text-lg font-semibold w-full h-[72px] font-[MontBlancBold] text-left pl-12"
              >
                DISCONNECT
              </button> */}
            </>
          ) : (
            <button className="bg-primary text-lg font-semibold w-full h-[72px] font-[MontBlancBold] text-left pl-12 pr-10 rounded-b-[15px] shadow-[0px_4px_8px_rgba(0,0,0,0.5)] ">
              <div
                onClick={() => connectWalletFunction(true)}
                className="flex flex-wrap justify-between flex-row  "
              >
                <p
                  className="text-black 
                          uppercase text-[18px] font-extrabold"
                >
                  CONNECT WALLET
                </p>
                <img
                  src="/Images/download-icon.svg"
                  alt=""
                  className="sm:w-[20px] cursor-pointer rounded-full ]"
                ></img>
              </div>
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default Navbar;
