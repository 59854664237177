import React from "react";

interface CategoryProps {
  text: string;
  onClick: () => void;
  isActive?: boolean;
}
export default function Category(props: CategoryProps) {
  return (
    <div
      className={`text-center overflow-hidden cursor-pointer px-5 box-border size-full flex justify-center items-center //max-w-[421.42px] h-[72.54px] //sm_md_lg:max-w-[352.58px] sm_md_lg:max-w-full sm_md_lg:min-h-[50.54px]  rounded-full ${
        props?.isActive
          ? props.text === "Live Competitions"
            ? "bg-primary  border-[1.71px] border-white"
            : "bg-[#EF008F] *:!text-white border-[1.71px] border-white"
          : "bg-[#383838]"
      }`}
      onClick={props.onClick}
    >
      <p
        className={` text-center text-[20px] sm_md_lg:text-[18px] uppercase ${
          props.isActive
            ? "text-black font-[Sequel100Wide95]"
            : "text-white font-[Sequel100WideVF55]"
        }`}
      >
        {props.text}
      </p>
    </div>
  );
}
