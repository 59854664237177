import { Common } from "../../common/Common";

interface GenerateButtonProps {
  onClick: () => void;
}
export default function GenerateButton(props: GenerateButtonProps) {
  return (
    <Common.button
      backgroundColor="#dde406"
      fontFamily="Sequel100Wide85"
      fontSize="35px"
      fontSizeSmall="25px"
      heightBig="97px"
      heightSmall="57px"
      maxWidthBig="100%"
      maxWidthSmall="100%"
      text="Generate"
      color="#393939"
      roundness="10px"
      className="uppercase"
      onClick={props.onClick}
    />
  );
}
