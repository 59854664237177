import { useEffect, useState } from "react";
import VSpacer from "../../../Components/common/Spacer/VSpacer";
import ExitUserDetailsButton from "../../../Components/User Pages Components/Edit User/Buttons/ExitUserDetailsButton";
import { AvatarContainer } from "../../../Components/User Pages Components/Edit User/Containers/AvatarContainer";
import DataContainer from "../../../Components/User Pages Components/Edit User/Containers/DataContainer";
import Title2Container from "../../../Components/User Pages Components/Edit User/Containers/Title2Container";
import Avatar from "../../../Components/User Pages Components/Edit User/images/Avatar";
import DataField from "../../../Components/User Pages Components/Edit User/texts/DataField";
import Description from "../../../Components/User Pages Components/Edit User/texts/Description";
import Title from "../../../Components/User Pages Components/Edit User/texts/Title";
import Title2 from "../../../Components/User Pages Components/Edit User/texts/Title2";
import Title3 from "../../../Components/User Pages Components/Edit User/texts/Title3";
import { useAuth } from "../../../Contexts/AuthContext";
import WithNonAbsolutedSidebarNavbarFooter from "../../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import {
  editTelegramHandle,
  editUserDetails,
  setAvatar,
} from "../../../Services/userService";
import AvatarSelectorPopup from "../../../Components/User Pages Components/My Account/others/AvatarSelectorPopup";
import { useWallet } from "../../../Contexts/WalletContext";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../Components/common/loader/Loader";

interface IInformation {
  target:
    | "Email"
    | "Username"
    | "MobileNumber"
    | "TelegramHandle"
    | "AddressLine1"
    | "AddressLine2"
    | "City"
    | "Country"
    | "Postcode";
  value: string;
}

export default function EditUser() {
  const [openPopup, setOpenPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    document.getElementById("edit_user_main")?.scrollIntoView();
  }, []);

  const { user, fetchUser, account } = useWallet();

  useEffect(() => {
    if (user) {
      setShowLoader(false);
    }
  }, [user]);

  const initialUserInformation = {
    WalletId: account || "No Data",

    Email: user?.email || "" || null,
    Name: user?.name || "",
    MobileNumber: user?.phone || "",
    TelegramHandle: user?.telegram || "No Data",
    AddressLine1: user?.address1 || "No Data",
    AddressLine2: user?.address2 || "No Data",
    City: user?.city || "No Data",
    Country: user?.country || "No Data",
    Postcode: user?.postcode || "No Data",
    Username: user?.userName || "No Data" || null,
  };
  const [userInformation, setUserInformation] = useState(
    initialUserInformation
  );
  useEffect(() => {
    setUserInformation({
      WalletId: account || "",
      // Email: user?.userName || "",
      Email: user?.email || "",
      Name: user?.name || "",
      MobileNumber: user?.phone || "",
      TelegramHandle: user?.telegram || "",
      AddressLine1: user?.address1 || "",
      AddressLine2: user?.address2 || "",
      City: user?.city || "",
      Country: user?.country || "",
      Postcode: user?.postcode || "",
      Username: user?.userName || "" || null,
    });
  }, [user]);
  const changeInfo = ({ target, value }: IInformation) => {
    setUserInformation((prev) => ({ ...prev, [target]: value }));
  };
  const saveInfo = async ({ target, value }: IInformation) => {
    const submitData = { ...initialUserInformation, [target]: value };
    console.log("Saving: ", submitData, target, user);

    // if (user?.userName) {
    //   submitData.Username = null;
    // }

    // if (user?.email) {
    //   submitData.Email = null;
    // }

    if (target === "Username") {
      try {
        const response1 = await editUserDetails({ Username: value });
        if (response1) {
          alert("Username updated successfully");
        }
        return;
      } catch (e: any) {
        if (e?.response?.data?.error) {
          alert(e?.response?.data?.error);
        }
        return;
      }
    }

    if (target === "Email") {
      try {
        const response1 = await editUserDetails({ Email: value });
        if (response1) {
          alert("Email updated successfully");
        }
        return;
      } catch (e: any) {
        if (e?.response?.data?.error) {
          alert(e?.response?.data?.error);
        }
        return;
      }
    }

    if (target === "MobileNumber") {
      const response1 = await editUserDetails({ MobileNumber: value });
      if (response1) {
        alert("Mobile Number updated successfully");
      }
      return;
    }

    const response1 = await editUserDetails(submitData);
    // editTelegramHandle("@abc");
    if (target === "TelegramHandle") {
      const response2 = await editTelegramHandle(
        userInformation?.TelegramHandle || "test"
      );
      if (response2) {
        alert("Telegram handle updated successfully");
      }
      return;
    }
    if (response1) {
      alert("User details updated successfully");
    }
  };

  const onConfirm = (selected: string) => {
    const response = setAvatar(selected);
    setOpenPopup(false);
    // reset the user context
    fetchUser();
  };
  return (
    <WithNonAbsolutedSidebarNavbarFooter
      Component={
        // Main Container
        <div
          id="edit_user_main"
          className="overflow-hidden box-border bg-[#181818] w-full sm_md_lg:mx-auto sm_md_lg:w-[calc(100%-20px)] sm_md:relative  sm_md_lg:my-[20px] xl:my-8 h-full mx-[43px] rounded-[30px] py-[63px]  sm_md:py-[44px]  flex flex-col "
        >
          {/* <Loader show={showLoader} /> */}
          {openPopup && (
            <AvatarSelectorPopup
              onConfirm={onConfirm}
              onClose={() => setOpenPopup(false)}
            />
          )}
          {/* Title */}
          <Title />
          <VSpacer small={64} big={59} />
          {/* 2- Title 2 container */}
          <Title2Container>
            {/* Title2 */}
            <Title2 />
          </Title2Container>

          <VSpacer small={47} big={65} />
          <div className="sm_md:w-[calc(100%-30px)] //w-[calc(100%-78px)] w-full px-[87px] sm_md:px-0 m-auto">
            {/* Avatar Container row */}
            <AvatarContainer.Row>
              {/* Avatar image */}
              <Avatar
                src={user?.imageUrl || "/Images/No Image.svg"}
                onClick={() => {
                  setOpenPopup(true);
                }}
              />
              {/* Avatar Container col */}
              <AvatarContainer.Col>
                {/* Title3 */}
                <Title3 />
                {/* avatar description */}
                <Description />
              </AvatarContainer.Col>
            </AvatarContainer.Row>
            <VSpacer small={40} big={39} />
            {/* Data info */}
            {/* 4.1 Username */}
            <DataContainer>
              <DataField
                onSave={() =>
                  saveInfo({
                    target: "Username",
                    value: userInformation?.Username || "",
                  })
                }
                placeholder="Enter Username"
                value={userInformation?.Username || ""}
                label="Username"
                onChange={(e) =>
                  changeInfo({
                    target: "Username",
                    value: e.target.value,
                  })
                }
              />
              <VSpacer small={17} big={10} />
            </DataContainer>
            {/* 4.2 Telegram Handle */}
            <DataContainer>
              <DataField
                onSave={() =>
                  saveInfo({
                    target: "TelegramHandle",
                    value: userInformation?.TelegramHandle || "",
                  })
                }
                placeholder="Enter Telegram Handle"
                value={userInformation?.TelegramHandle || ""}
                label="Telegram Handle"
                onChange={(e) =>
                  changeInfo({
                    target: "TelegramHandle",
                    value: e.target.value,
                  })
                }
              />
              <VSpacer small={17} big={10} />
            </DataContainer>
            {/* 4.3 Telephone Number */}
            <DataContainer>
              <DataField
                onSave={() =>
                  saveInfo({
                    target: "MobileNumber",
                    value: userInformation?.MobileNumber || "",
                  })
                }
                placeholder="Enter Telephone Number"
                value={userInformation?.MobileNumber || ""}
                label="Telephone Number"
                onChange={(e) =>
                  changeInfo({
                    target: "MobileNumber",
                    value: e.target.value,
                  })
                }
              />
              <VSpacer small={17} big={10} />
            </DataContainer>
            {/* 4.4 Email Address */}
            <DataContainer>
              <DataField
                onSave={() =>
                  saveInfo({
                    target: "Email",
                    value: userInformation?.Email || "",
                  })
                }
                placeholder="Enter Email Address"
                value={userInformation?.Email || ""}
                label="Email Address"
                onChange={(e) =>
                  changeInfo({
                    target: "Email",
                    value: e.target.value,
                  })
                }
              />
              <VSpacer small={17} big={10} />
            </DataContainer>
            <VSpacer small={37} big={42.06} />
            {/* Edit button */}
          </div>
          <div className="sm_md:w-[calc(100%)]  //w-[calc(100%-78px)] w-full sm:md:px-0 sm:!p-4 px-[87px] m-auto *:uppercase">
            <ExitUserDetailsButton />
          </div>
        </div>
      }
    />
  );
}
