interface GeneratorContainerProps {
  left: React.ReactNode;
  right: React.ReactNode;
}
export default function GeneratorContainer(props: GeneratorContainerProps) {
  return (
    <div className="grid grid-cols-2 sm_md:grid-cols-1 border-primary border-[1px] rounded-[30px] h-[443px] max-w-[1240px] w-full gap-y-[24px] sm_md:h-auto ">
      {props.left}

      {props.right}
    </div>
  );
}
