import React, { useEffect, useState } from "react";
import Button from "../Button";
import { poundSign } from "../../Pages/LandingPage";
import RangeSlider from "../RangeSlider";
import moment from "moment";
import Countdown from "react-countdown";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { CircularProgress, styled } from "@mui/material";
import { useAuth } from "../../Contexts/AuthContext";
import Popup from "../Dashboard Components/Popup";
import { joinCompetition } from "../../Services/userService";
import { useNavigate } from "react-router-dom";
import BuyTicketsPopup from "../Dashboard Components/BuyTicketsPopup";
import { CompetitionImages } from "./Competitions";
import { Text } from "../react-responsive-utilities/src";
import Counter from "../common/Counter/Counter";
import VSpacer from "../common/Spacer/VSpacer";
import { NumberPicker } from "../NumberPicker";
import Unit from "./Slider (backup)/Counter/Unit";
import ColoredCoin from "../Animated/ColoredCoin";
import { Common } from "../common/Common";
import PopupPrizes, { PopupPrizeData } from "./PopupPrizes/PopupPrizes";
import { useWallet } from "../../Contexts/WalletContext";
import { openGlobalModal } from "./ClipboardModalContext";

const TicketLinearProgress = styled(LinearProgress)<LinearProgressProps>(
  ({ theme }) => ({
    height: "10px",
    borderRadius: 20,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    ".MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#EF008F",
      borderRadius: 20,
    },
  })
);

const progress = (200 / 460) * 100;
// const small_chicken = "/Images/small_yellow_chicken.png";

const LiveCompetitionDetails: React.FC<any> = ({
  getCompetition,
  competition,
  instant = false,
  totalItems = 0,
}) => {
  const [loader, setLoader] = useState(true);
  const [isInsufficientAmount, setIsInsufficientAmount] = useState(false);
  const [isMaxReached, setIsMaxReached] = useState(false);
  const [isFull, setIsFull] = useState(false);
  const { user, fetchUser, account } = useWallet();
  const [max, setMax] = useState(-1);
  const [min, setMin] = useState(-1);
  const [value, setValue] = useState(min);
  useEffect(() => {
    if (user && competition) {
      setLoader(false);
    }
  }, [user, competition]);
  useEffect(() => {
    if (
      competition?.totalticketsbought === competition?.competitionticketsize
    ) {
      setIsFull(true);
    }
  }, [min, max, competition, user]);

  useEffect(() => {
    // if (user) {
    //   setIsInsufficientAmount(user?.numberOfTickets < value ? true : false);
    // }
    if (user) {
      if (competition) {
        const userTickets = competition?.userTickets ?? 0;
        const walletBalance = user?.numberOfTickets ?? 0;
        const minTickets = competition?.minnumberoftickets ?? 0;

        // for user ticket 0
        if (userTickets === "0") {
          if (walletBalance < minTickets) {
            setIsInsufficientAmount(true);
            return;
          }
        }

        // for user ticket > 0
        if (parseInt(userTickets) > 0) {
          if (walletBalance === 0) {
            setIsInsufficientAmount(true);
            return;
          }
        }
      }
    }
  }, [
    user,
    competition?.userTickets,
    competition?.minnumberoftickets,
    competition?.maxticketsperuser,
  ]);

  const getMax = () => {
    if (isInsufficientAmount) {
      return 0;
    }
    const userTickets = competition?.userTickets;
    const max = competition?.maxticketsperuser;
    // if (parseInt(userTickets) > 0) {
    //   const value = max - userTickets;
    //   return value;
    // }
    const walletBalance = user?.numberOfTickets ?? 0;
    const diffTicketSizeTotalTicket =
      competition?.competitionticketsize - competition?.totalticketsbought;
    const diffMaxTicketSizeUserTicket = max - parseInt(userTickets);
    // if (user && user?.numberOfTickets < (max - parseInt(userTickets))) {
    //   return user?.numberOfTickets;
    // }
    const minimumValue = Math.min(
      walletBalance,
      diffTicketSizeTotalTicket,
      diffMaxTicketSizeUserTicket
    );
    return minimumValue;
    //  else {
    //   return max - parseInt(userTickets);
    // }
  };

  const getMin = () => {
    if (isInsufficientAmount) {
      return 0;
    }
    const userTickets = parseInt(competition?.userTickets);
    const min = competition?.minnumberoftickets;
    // if (max === 0) {
    //   return 0;
    // }
    if (userTickets > 0) {
      if (user && user?.numberOfTickets > 0) {
        if (
          competition?.maxticketsperuser !== parseInt(competition?.userTickets)
        ) {
          return 1;
        }
      }
    } else {
      return min;
    }
  };
  useEffect(() => {
    if (parseInt(competition?.userTickets) !== 0) {
      if (
        competition?.maxticketsperuser === parseInt(competition?.userTickets)
      ) {
        setIsMaxReached(true);
      } else {
        setMax(getMax() ?? 0);
        setMin(getMin());
      }
    } else {
      setMax(getMax() ?? 0);
      setMin(getMin());
    }
  }, [isInsufficientAmount, competition, user]);

  useEffect(() => {
    setValue(min);
  }, [min]);

  const entriesonly = (competition?.minnumberoftickets * 0.99).toFixed(2);
  const maxentries = competition?.competitionticketsize;
  const maxperperson = competition?.maxticketsperuser;
  const totalprizes = totalItems ? totalItems + 1 : 1;
  const totalvalue = (competition?.competitionticketsize * 0.99).toFixed(2);
  const [currentImageIndex] = useState(0);
  const navigate = useNavigate();

  const [buying, setBuying] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [noOfTicket, setNoOfTicket] = useState(0);
  const [competitionImages, setCompetitionImages] = React.useState<
    CompetitionImages[]
  >([]);

  useEffect(() => {
    console.log("competitions log:", competition);
  }, [competition]);

  const [popupPrizes, setPopupPrizes] = useState(false);
  const [prizes, setPrizes] = useState<PopupPrizeData[]>([]);

  const [featuredImage, setFeaturedImage] = useState<CompetitionImages>();

  const sold = (200 / 460) * 100;

  console.log(user, "user");

  const images = [
    {
      imgSrc: "/Images/coin_btc.png",
    },
    {
      imgSrc: "/Images/coin_btc.png",
    },
    {
      imgSrc: "/Images/coin_btc.png",
    },
    {
      imgSrc: "/Images/coin_btc.png",
    },
    {
      imgSrc: "/Images/coin_btc.png",
    },
    {
      imgSrc: "/Images/coin_btc.png",
    },
    {
      imgSrc: "/Images/coin_btc.png",
    },
    {
      imgSrc: "/Images/coin_btc.png",
    },
  ];

  const aboutWinning = [
    { image: "/Images/trophy.png", desc: "MINIMUM ENTRY $" + entriesonly },
    { image: "/Images/growth-icon.svg", desc: "MAX ENTRIES " + maxentries },
    {
      image: "/Images/maxpersonicon.svg",
      desc: "MAX " + maxperperson + " PER PERSON",
    },
    { image: "/Images/gift-icon.svg", desc: "TOTAL PRIZES " + totalprizes },
    { image: "/Images/value-icon.svg", desc: "TOTAL VALUE $" + totalvalue },
  ];

  const getImages = async () => {
    // const images = await getCompetitionImages({
    //   competitionid: competition?.UID ?? "",
    // });
    const images = competition?.imageUrls;
    console.log(images);
    if (images?.length > 0) {
      setFeaturedImage(images[0]);
      setCompetitionImages(images);
    }
  };

  useEffect(() => {
    if (competition?.UID) {
      getImages();
    }
  }, [competition?.UID]);
  const chickenSpot = sold - sold / 22;

  async function buyTicket() {
    if (isNoTicketLeft()) {
      return alert("No ticket left");
    }
    if (user) {
      await setNoOfTicket(value);
      setShowPopUp(true);
    } else {
      if (openGlobalModal) {
        openGlobalModal({
          text: "Please connect your wallet to enter competitions.",
        });
      }
      // alert("Please connect your wallet to enter competitions");
    }
  }

  const navigateToResult = (competition?: any, UID?: string) => {
    navigate(`/competitionResult/${UID}`);
  };

  const ticketSold = (
    totalticketsbought?: number,
    competitionticketsize?: number
  ) => {
    if (totalticketsbought && competitionticketsize) {
      const sold = ((totalticketsbought / competitionticketsize) * 100).toFixed(
        0
      );
      return sold;
    } else {
      return 0;
    }
  };

  const changeNumberOfTickets = (number: number) => {
    if (number < min) {
      return;
    } else if (number > max) {
      return;
    }
    setValue(number);
  };

  const finishedCompetition = (competitionenddate?: Date) => {
    const currentDate = new Date();
    const competitionEndDate = new Date(competitionenddate ?? new Date());
    if (currentDate > competitionEndDate) {
      return true;
    } else {
      return false;
    }
  };

  function popUpFunctions(action: string) {
    if (action === "reject") {
      setShowPopUp(false);
      setSuccess("");
      setError("");
      setBuying(false);
    }

    if (action === "accept") {
      buyTicketFunction();
    }
  }

  const buyTicketFunction = async () => {
    try {
      setBuying(true);
      const result = await joinCompetition({
        competitionid: competition?.UID,
        numberoftickets: noOfTicket,
        walletaddress: account || "",
      });
      if (instant) {
        console.log("result: ", result);
        if (result?.data?.length > 0) {
          setPrizes(result?.data);
          setPopupPrizes(true);
        } else {
          window.location.reload();
          console.log("No prize");
        }
      } else {
        window.location.reload();
      }

      console.log(result);
      setBuying(false);
      setSuccess(result?.message);
      setTimeout(() => {
        setShowPopUp(false);
        setSuccess("");
      }, 3000);
      //setShowPopUp(false);
    } catch (error: any) {
      console.log(error);
      setBuying(false);
      setError(error?.response?.data?.error);
      //setShowPopUp(false);
    }
  };

  const Title = ({ title }: { title: string }) => (
    <Text
      textSize={[30, 30.0, 50.0]}
      className="text-[#FFFFFF] sm:!text-[24px] font-['Sequel100Wide95'] //leading-[35px] //leading-normal font-[60] block sm:text-center md:text-center lg:text-center leading-none"
    >
      {title}
    </Text>
  );

  const Description = ({ description }: { description: string }) => (
    <Text
      textSize={[10, 14.0, 19.96]}
      className="
      text-[#FFFFFF] font-['Sequel100WideVF45'] sm:!leading-[19px] sm:!text-[12px] leading-[29px] font-[10] block sm:text-center md:text-center lg:text-center"
    >
      {description}
    </Text>
  );

  interface HeroCounterProps {
    competitionEndDate: Date;
  }

  const Unit = ({
    value,
    unit,
    isLast = false,
  }: {
    value: string;
    unit: string;
    isLast?: boolean;
  }) => (
    <div className="w-full">
      <div className="flex *:m-1 justify-center items-center">
        <div className="px-[clamp(0px,2vw,13px)] flex max-w-[45.17px] min-h-[clamp(0px,3vw,45px)] bg-white w-full text-center justify-center items-center rounded-[10px]">
          <Text
            textSize={[16, 21.93, 27.61]}
            className="text-[#2E2122] font-['MontBlancBlack'] leading-[29px] font-[218]"
          >
            {value[0]}
          </Text>
        </div>
        <div className="px-[clamp(0px,2vw,13px)] flex max-w-[45.17px] min-h-[clamp(0px,3vw,45px)] bg-white w-full text-center justify-center items-center rounded-[10px]">
          <Text
            textSize={[16, 21.93, 27.61]}
            className="text-[#2E2122] font-['MontBlancBlack'] leading-[29px] font-[218]"
          >
            {value[1]}
          </Text>
        </div>
        {!isLast && (
          <Text
            textSize={[16, 21.93, 27.61]}
            className={`text-[#FFFFFF] font-['MontBlancBlack'] leading-[29px] font-[218] block ${
              unit === "Hours" ? "lg:hidden" : ""
            }`}
          >
            :
          </Text>
        )}
      </div>

      <Text
        textSize={[0, 14.93, 16.0]}
        className="text-[#FFFFFF] font-['MontBlancRegular'] font-semibold leading-[20px] //font-[120] uppercase"
      >
        {unit}
      </Text>
    </div>
  );

  const closePopupPrizes = () => {
    window.location.reload();
    setPopupPrizes(false);
  };

  const isNoTicketLeft = () => {
    if (
      Number(
        ticketSold(
          competition?.totalticketsbought,
          competition?.competitionticketsize
        )
      ) === 100
    ) {
      console.log("No ticket left");
      return true;
    } else {
      return false;
    }
  };

  // if (loader) {
  //   return (
  //     <div className="w-full h-[100vh] fixed top-0 left-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
  //       <CircularProgress
  //         size={100}
  //         style={{ position: "absolute", top: "50%", left: "50%" }}
  //       />
  //     </div>
  //   );
  // }

  const handleButtonClick = () => {
    // min !== 0 && !isInsufficientAmount && buyTicket();
    if (isInsufficientAmount) {
      navigate("/buytickets");
      return;
    }
    if (min !== 0) {
      buyTicket();
    }
  };

  return (
    <div className="w-full ">
      <div className="w-auto  flex flex-col gap-[2em] sm:gap-0 md:gap-0 lg:gap-0 sm:w-auto md:w-auto lg:w-auto">
        <div
          className="//flex grid xl:grid-cols-2 grid-cols-1 w-auto  sm:flex-col md:flex-col 
                    lg:flex-col justify-center mx-2 xl:mx-12 xl:w-auto overflow-hidden relative"
        >
          {/* <div className="absolute sm:hidden md:right-[50%] md:top-[50%] lg:-right-[2%] lg:top-[25%] xl:-right-[9%] xl:top-[2%] sm:transform sm:translate-x-1/2 sm:-translate-y-1/2 md:transform md:translate-x-1/2 md:-translate-y-1/2 opacity-[15%] ">
            <ColoredCoin height="540" width="540" />
          </div> */}
          {/* FOR DESKTOP SCREENS */}
          <div
            className="sm:hidden md:hidden lg:hidden w-full  
                    ticketBox flex flex-col gap-5 lg:gap-0 xl:gap-0 sm:w-full md:w-full lg:w-full sm:h-auto md:h-auto lg:h-auto bg-secondary_bg pl-3 pt-3 pb-3 rounded-l-3xl sm:rounded-tr-3xl md:rounded-tr-3xl lg:rounded-tr-3xl sm:rounded-bl-none md:rounded-bl-none lg:rounded-bl-none sm:mt-[1em] md:mt-[1em] lg:mt-[1em] sm:pr-3 md:pr-3 lg:pr-3"
          >
            <div className="xl:bg-[#111111] xl:h-[842px] w-auto rounded-3xl relative overflow-hidden">
              {instant && (
                <div className="bg-secondary h-[80.96px] max-w-[552px] w-full rounded-br-3xl absolute flex justify-between items-center px-[22.93px] sm_md:px-[14.58px]">
                  <img src="/Images/instant-win-wheel.svg" alt="" />
                  <Common.typography
                    fontFamily="Sequel100Wide95"
                    fontSizeSmall="24.73px"
                    fontSize="38.89px"
                    color="white"
                  >
                    Instant Win
                  </Common.typography>
                  <img src="/Images/instant-win-wheel.svg" alt="" />
                </div>
              )}
              <img
                src={featuredImage?.imageurl ?? "/Images/No Image.svg"}
                alt=""
                className="w-full h-[622px] sm:w-full md:w-full lg:w-full sm:h-auto md:h-auto lg:h-auto rounded-3xl object-cover"
                onError={(e) => {
                  e.currentTarget.src = "/Images/No Image.svg";
                }}
              />

              <div className="flex flex-row  h-[240px] w-full flex-wrap items-center justify-center px-[10%] py-[7%] gap-4 text-white text-[18px] sm:text-[12px] md:text-[15px] font-[MontBlancRegular]">
                {aboutWinning.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-2 //min-w-[calc(50%-8px)]"
                  >
                    <img src={item.image} alt="" className="w-[35px]" />
                    <p className="whitespace-nowrap font-[MontBlancBold] text-[18.88px]">
                      {item.desc}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* FOR MOBILE SCREENS */}

          <div
            className="xl:hidden w-full relative 
                     flex flex-col justify-center gap-5 lg:gap-0 xl:gap-0 sm:w-full md:w-full lg:w-full sm:h-auto md:h-auto lg:h-auto bg-secondary_bg pl-3 pt-3 pb-3 pr-3  rounded-l-3xl sm:rounded-tr-3xl md:rounded-tr-3xl lg:rounded-tr-3xl sm:rounded-bl-none md:rounded-bl-none lg:rounded-bl-none sm:mt-[1em] md:mt-[1em] lg:mt-[1em] "
          >
            <div
              className="relative h-full sm:min-h-[410.4px] min-h-[622px] sm:w-full md:w-full lg:w-full sm:h-auto md:h-auto lg:h-auto rounded-3xl bg-cover bg-center bg-no-repeat"
              style={{
                // backgroundImage: `url(${featuredImage?.imageurl})`,
                backgroundImage: featuredImage?.imageurl
                  ? `url(${featuredImage?.imageurl})`
                  : `url('${window.location.origin}/Images/No Image.svg')`,
              }}
            >
              {instant && (
                <div className="bg-secondary h-[60.96px] w-full rounded-t-3xl absolute flex justify-between items-center px-[22.93px] sm_md:px-[14.58px]">
                  <img src="/Images/instant-win-wheel.svg" alt="" />
                  <Common.typography
                    fontFamily="Sequel100Wide95"
                    fontSizeSmall="24.73px"
                    fontSize="38.89px"
                    color="white"
                  >
                    Instant Win
                  </Common.typography>
                  <img src="/Images/instant-win-wheel.svg" alt="" />
                </div>
              )}
              {/* <img
                src={featuredImage?.imageurl ?? "/Images/No Image.svg"}
                alt=""
                className="w-auto h-[622px] sm:w-full md:w-full lg:w-full sm:h-auto md:h-auto lg:h-auto rounded-3xl object-cover"
                onError={(e) => {
                  e.currentTarget.src = "/Images/No Image.svg";
                }}
              /> */}
              <div className="absolute gap-y-4 //sm:min-h-[142px] //md:min-h-[242px] //lg:min-h-[262px] bg-[rgba(8,8,8,0.35)] //lg:w-[97.5%] //md:w-[96.5%] //sm:w-[95.5%] bottom-0  w-full flex flex-col gap-5   //sm:gap-2 //md:gap-4 //md:py-6 l//g:gap-6 //lg:py-6 rounded-3xl px-[4px]">
                <VSpacer big={0} small={15} />
                <Title title={competition?.competitionname} />
                {/* <VSpacer big={0} small={11.2} /> */}
                <hr className="w-[75%] mx-auto border-b-[5px] border-t-0 border-[#EF008F] hidden sm:block md:block lg:block h-[3.14px] rounded-full" />
                {/* <VSpacer big={0} small={10.9} /> */}
                <Description
                  description={competition?.competitioninformation}
                />
                <VSpacer big={0} small={15.76} />
              </div>
            </div>
          </div>

          <div className=" w-full relative flex flex-col gap-3 ticketBox //pl-[1.5em] sm:items-center md:items-center lg:items-center sm:pr-0 md:pr-0 lg:pr-0 sm:pl-0 md:pl-0 lg:pl-0 bg-secondary_bg rounded-r-3xl  sm:rounded-tr-none md:rounded-tr-none  lg:rounded-tr-none sm:rounded-b-3xl md:rounded-b-3xl lg:rounded-b-3xl ">
            <div className="max-w-[750px] mx-10">
              {/* FOR DESKTOP SCREENS */}
              <div className="sm:hidden md:hidden lg:hidden flex flex-col gap-5 my-[40px] sm:m-[30px] md:m-[30px] lg:m-[30px] sm:gap-2 md:gap-2 lg:gap-2 mt-[60px] *:m-[2px]">
                <Title title={competition?.competitionname} />
                {/* <VSpacer small={11.96} big={24} /> */}
                <hr className="max-w-[320.18px] w-full border-b-[5px] border-t-0 border-[#EF008F] sm_md:hidden  h-[3.14px] rounded-full" />
                {/* <VSpacer small={12.9} big={0} /> */}
                <Description
                  description={competition?.competitioninformation}
                />
                <img
                  src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
                  alt=""
                  className="absolute right-0 select-none top-0 object-cover object-center hidden xl:block !z-[1px]"
                />
              </div>

              <div className="flex flex-col w-auto relative sm:mt-[30px] md:mt-[40px] lg:mt-[50px] lg:flex-col md:flex-col sm:flex-col xl:timeRemaining2 ">
                <div className="//m-[40px] mt-[-20px] sm:items-center md:items-center lg:items-center //border-t-4 border-t-secondary sm:border-t-0 md:border-t-0 lg:border-t-0 pt-[15px] mb-0 flex flex-col gap-3 sm:pt-0 md:pt-0 lg:pt-0 md:w-full md:ml-0 sm_md_lg:text-center">
                  {/* <HeroCounter
                      competitionEndDate={competition?.competitionenddate}
                      key={competition?.UID}
                    /> */}
                  <Counter
                    title={"TIME REMAINING!"}
                    competitionEndDate={competition?.competitionenddate}
                    key={competition?.UID}
                  />

                  {/* <p
                    className="text-secondary font-['Sequel100Wide95'] text-[14px] font-semibold sm:hidden md:hidden lg:hidden"
                    style={{
                      fontSize: "clamp(1px, 2.815621311vw, 14.18px)",
                    }}
                  >
                    BUY TICKETS AND SPIN TO WIN!
                  </p> */}
                  <Common.typography
                    fontFamily="Sequel100Wide95"
                    fontSize="24.35px"
                    fontSizeSmall="20px"
                    color="#EF008F"
                    className="uppercase"
                  >
                    BUY TICKETS AND SPIN TO WIN!
                  </Common.typography>
                </div>

                <div className="h-[75px] bg-bg_small flex items-center p-6 gap-4 //m-[20px] mt-[0.5em] sm:mt-0 xl:w-full w-[620px] relative sm:w-[300px] sm:h-auto md:h-auto lg:h-auto rounded-full sm:hidden md:hidden lg:hidden">
                  <div className="w-[100%] //mt-5 md:w-full">
                    <TicketLinearProgress
                      variant="determinate"
                      sx={{
                        "&.MuiLinearProgress-root": {
                          height: "13px",
                          borderRadius: 20,
                        },
                      }}
                      value={Number(
                        ticketSold(
                          competition?.totalticketsbought,
                          competition?.competitionticketsize
                        )
                      )}
                    />

                    <span
                      className="text-white font-['MontBlancRegular'] uppercase text-[12px] md:text-sm"
                      style={{
                        fontSize: "clamp(11px, 2.441720196vw, 11.19px)",
                      }}
                    >
                      {ticketSold(
                        competition?.totalticketsbought,
                        competition?.competitionticketsize
                      )}
                      % tickets sold
                    </span>
                  </div>
                  <div>
                    <h3
                      className="font-extrabold text-[26px] font-['MontBlancBlack'] text-primary m-0 p-0 mb-[-0.4em] sm:text-[19px]"
                      style={{
                        fontSize: "clamp(1px, 4.815615702vw, 23.77px)",
                      }}
                    >
                      {poundSign}
                      0.99
                    </h3>
                    <span
                      className="text-base text-[13px] text-white "
                      style={{
                        fontSize: "clamp(1px, 2.418197682vw, 11.19px)",
                      }}
                    >
                      PER TICKET
                    </span>
                  </div>
                </div>
              </div>
              <div>
                {/* <div className="xl:ml-5 bg-bg_small h-auto rounded-3xl mt-[0em] mb-[20px] sm:mb-[10px] w-max m-2 md:mt-[1em] sm:w-[100%] md:w-[100%] lg:w-[100%] sm:m-auto md:m-auto xl:w-full "> */}
                {/* <div className="px-4 flex gap-4 items-center xl:hidden">
                                <div className="mt-5 flex-1 md:w-full">
                                    <TicketLinearProgress
                                        variant="determinate"
                                        value={Number(
                                            ticketSold(
                                                competition?.totalticketsbought,
                                                competition?.competitionticketsize
                                            )
                                        )}
                                    />
                                    <Text
                                        textSize={[12, 13.19, 14.19]}
                                        className="text-[#fff] block"
                                    >
                                        {ticketSold(
                                            competition?.totalticketsbought,
                                            competition?.competitionticketsize
                                        )}
                                        % tickets sold
                                    </Text>
                                </div>
                                <div>
                                    <Text
                                        textSize={[16, 23.77, 24]}
                                        className="text-primary font-[MontBlancBlack] block leading-none"
                                    >
                                        {poundSign}
                                        0.99
                                    </Text>
                                    <Text
                                        textSize={[10, 11.19, 12]}
                                        className="text-white block font-[MontBlancRegular] uppercase"
                                    >
                                        per ticket
                                    </Text>
                                </div>
                            </div> */}
                <VSpacer big={13.19} small={24.87} />
                {!finishedCompetition(competition?.competitionenddate) && (
                  <div className="relative z-[2px] p-5 bg-bg_small mb-5 rounded-2xl  w-full flex flex-col justify-center items-center">
                    <h2
                      className="text-white font-['Sequel100Wide95'] text-[30px] sm:text-[16px] sm:text-center md:text-center lg:text-center text-left w-full"
                      style={{
                        fontSize: "clamp(1px,3.609558064vw,30px)",
                      }}
                    >
                      HOW MANY TICKETS?
                    </h2>
                    <div
                      className="mt-[2em] sm:mt-[2em] md:mt-[2em] lg:mt-[2em] relative w-full *:m-auto"
                      id="sales"
                    >
                      <div
                        className="flex justify-between text-white font-bold font-[MontBlancBold]"
                        style={{ marginBottom: "10px" }}
                      >
                        <p className="text-[24px] sm:text-[18px]">MIN</p>
                        <p className="text-[24px] sm:text-[18px]">MAX</p>
                      </div>
                      <RangeSlider
                        min={min}
                        max={
                          // competition?.maxticketsperuser >
                          // competition?.competitionticketsize -
                          //   competition?.totalticketsbought
                          //   ? competition?.competitionticketsize -
                          //     competition?.totalticketsbought
                          //   : competition?.maxticketsperuser
                          max
                        }
                        value={value}
                        onChange={changeNumberOfTickets}
                        buffered={1}
                        className="//max-w-[570px] w-full sm:w-full md:w-full lg:w-full "
                      />
                      <VSpacer big={5.69} small={6.51} />
                      <p
                        className="text-white font-[MontBlancBold] text-[23.71px] //max-w-[570px] w-full sm:w-full md:w-full lg:w-full flex items-center justify-between mt-[1em]"
                        // style={{
                        //   fontSize: "clamp(10px,1.611926605vw,15px)",
                        // }}
                      >
                        <span>{isFull || isMaxReached ? 0 : value}</span>
                        <span>{isFull || isMaxReached ? 0 : max}</span>
                      </p>
                    </div>
                    <div className="//max-w-[570px] w-full sm:w-full md:w-full lg:w-full flex font-['Sequel100Wide95'] items-center justify-between mt-[1.5em]">
                      <img
                        src="/Images/minus.png"
                        alt=""
                        className="sm:max-w-[20px] cursor-pointer rounded-full"
                        onClick={() => changeNumberOfTickets(value - 1)}
                      />

                      <div className="flex items-center gap-x-2">
                        <h4
                          className="text-white font-[MontBlancSemiBold] text-[30.55px] sm:text-[18.81px] sm:text-center"
                          // style={{
                          //   fontSize: "clamp(11px,2.33vw,20px)",
                          // }}
                        >
                          LUCKY DIPS:
                        </h4>

                        <h4
                          className="text-primary font-[MontBlancBlack] text-[30.55px] sm:text-[18.81px] sm:text-center"
                          // style={{
                          //   fontSize: "clamp(11px,2.33vw,20px)",
                          // }}
                        >
                          {isFull || isMaxReached
                            ? 0
                            : competition?.competitionticketsize ===
                              competition?.totalticketsbought
                            ? 0
                            : value}
                        </h4>
                      </div>

                      <img
                        src="/Images/plus.png"
                        alt=""
                        className="sm:max-w-[20px] cursor-pointer rounded-full"
                        onClick={() => changeNumberOfTickets(value + 1)}
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* <VSpacer big={17.69} small={31} /> */}
              <div className="w-full flex justify-center items-center">
                <Button
                  disabled={
                    finishedCompetition(competition?.competitionenddate) ||
                    isFull ||
                    isMaxReached
                  }
                  className={`${
                    finishedCompetition(competition?.competitionenddate)
                      ? "bg-bg_small text-[#2D2022]"
                      : "bg-primary border-white border-2 text-black"
                  }
                  
                   ${min === 0 ? "opacity-10" : ""}
                   ${isInsufficientAmount ? "opacity-100" : ""}
                   ${isFull ? "opacity-10" : ""}
                   ${isMaxReached ? "opacity-10" : ""}
                  h-[75px]  text-[29px] font-['Sequel100Wide'] w-full rounded-full   sm:hidden md:hidden lg:hidden`}
                  content={
                    finishedCompetition(competition?.competitionenddate)
                      ? "FINISHED"
                      : isFull
                      ? "COMPETITION IS FULL"
                      : isInsufficientAmount || min === 0
                      ? "ENTER NOW"
                      : isMaxReached
                      ? "LIMIT REACHED"
                      : "ENTER NOW"
                  }
                  onClick={
                    finishedCompetition(competition?.competitionenddate)
                      ? () => {
                          navigateToResult(competition, competition?.UID);
                        }
                      : () => {
                          handleButtonClick();
                        }
                  }
                />

                <Button
                  disabled={
                    finishedCompetition(competition?.competitionenddate) ||
                    isFull ||
                    isMaxReached
                  }
                  className={`${
                    finishedCompetition(competition?.competitionenddate)
                      ? "bg-bg_small"
                      : "bg-primary border-white border-2"
                  } 
                  ${min === 0 ? "opacity-10" : ""}
                  ${isInsufficientAmount ? "opacity-100" : ""}
                  ${isFull ? "opacity-10" : ""}
                  ${isMaxReached ? "opacity-10" : ""}
                  h-[75px] text-[#2D2022] text-[29px] font-['Sequel100Wide'] w-[100%] rounded-full xl:hidden`}
                  content={
                    finishedCompetition(competition?.competitionenddate)
                      ? "FINISHED"
                      : isFull
                      ? "COMPETITION IS FULL"
                      : isInsufficientAmount || min === 0
                      ? "ENTER NOW"
                      : isMaxReached
                      ? "LIMIT REACHED"
                      : "ENTER NOW"
                  }
                  customStyle={{
                    fontSize: "clamp(1px,5.361012052vw,30px)",
                  }}
                  onClick={
                    finishedCompetition(competition?.competitionenddate)
                      ? () => {
                          navigateToResult(competition, competition?.UID);
                        }
                      : () => {
                          // min !== 0 && !isInsufficientAmount && buyTicket();
                          handleButtonClick();
                        }
                  }
                />
              </div>
              <div className="text-center mt-2">
                <a
                  href="/policies/t&c#targetSection"
                  className="text-white underline text-[13px] sm:text-[13px] font-['Sequel100Wide']"
                >
                  {"Click for free entry details".toUpperCase()}
                </a>
              </div>
              <VSpacer big={17.11} small={17.11} />
              {/* <p
                className="text-secondary text-center font-['Sequel100Wide95'] text-[14px] font-semibold mb-[3%] xl:hidden"
                style={{
                  fontSize: "clamp(1px, 2.815621311vw, 14.18px)",
                }}
              >
                BUY TICKETS AND SPIN TO WIN!
              </p> */}
              <VSpacer big={0} small={26} />
            </div>
          </div>
          <div className="xl:hidden flex flex-col justify-center gap-y-[25px] items-center border-[1.03px] rounded-[12.37px] border-[#EF008F] w-[calc(100%-40px)] relative z-[-1] -translate-y-2 h-[377px] m-auto">
            {aboutWinning.map((item, index) => (
              <div key={index} className="flex items-center gap-6">
                <img src={item.image} alt="" />
                <p className="whitespace-nowrap font-[MontBlancBold] text-[17.59px] text-white">
                  {item.desc}
                </p>
              </div>
            ))}
          </div>
          {popupPrizes && (
            <PopupPrizes onClose={closePopupPrizes} data={prizes} />
          )}
          {user && (
            <BuyTicketsPopup
              buying={buying}
              success={success}
              error={error}
              competitionName={competition?.competitionname}
              noOfTickets={noOfTicket}
              open={showPopUp}
              handleClose={() => {
                getCompetition(competition?.UID);
                setShowPopUp(false);
                setSuccess("");
                setError("");
                setBuying(false);
                fetchUser();
              }}
              rejectClick={() => popUpFunctions("reject")}
              acceptClick={() => popUpFunctions("accept")}
            />
          )}
        </div>

        {/* <div className="xl:hidden w-[97.5%] sm:w-[96%] relative h-[200px] w-full top-[-20px] rounded-t-none rounded-b-3xl border-l border-r border-b border-[#EF008F]"></div> */}
      </div>

      <NumberPicker
        minimumTickets={min}
        maximumTickets={max}
        competitionID={competition?.UID}
        competition={competition}
        isMaxReached={isMaxReached}
      />
    </div>
  );
};

export default LiveCompetitionDetails;
