import React from "react";
import { Common } from "../../../common/Common";

interface ButtonProps {
  date?: string;
  onClick?: () => void;
  winner?: string;
}
export default function Button({ date, onClick, winner }: ButtonProps) {
  const isFinished = new Date(date || "") < new Date() || winner;
  return (
    <Common.button
      onClick={onClick}
      backgroundColor={!isFinished ? "#DDE404" : "#767676"}
      roundness="55.07px"
      maxWidthBig="100%"
      maxWidthSmall="100%"
      heightSmall="40.35px"
      heightBig="49px"
      // fontSizeSmall="19.71px"
      fontSizeSmall={!isFinished ? "19.71px" : "13.14px"}
      fontSize={!isFinished ? "23.94px" : "16px"}
      color="#000000"
      fontFamily={!isFinished ? "Sequel100Wide95" : "Sequel100WideVF55"}
      className="uppercase shadow-lg shadow-black"
    >
      {isFinished ? "CLICK HERE FOR DETAILS" : "ENTER NOW"}
    </Common.button>
  );
}
