import React, { useState, useEffect } from "react";
import { submitTelegram } from "../../../Services/userService";

const TelegramConfirmationPopup = () => {
  const [isOpen, setIsOpen] = useState(true); // Popup is shown by default on page load
  const [telegramHandle, setTelegramHandle] = useState("");

  // const handleClose = () => setIsOpen(false);
  const handleConfirm = async ({
    telegramHandle,
  }: {
    telegramHandle: string;
  }) => {
    try {
      const result = await submitTelegram({ telegram: telegramHandle });
      console.log("result", result);
      if (result) {
        setIsOpen(false);
        window.location.reload();
      }
    } catch (error: any) {
      console.log("error", error);
    }

    // Add any functionality for confirming the telegram handle here
  };

  useEffect(() => {
    // Adding the 'blur' class to the background content only
    document.body.classList.add("overflow-hidden");

    // Clean up the blur effect when the popup is closed
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  const handleClickOutside = (event: MouseEvent) => {
    const popup = document.querySelector(".bg-primary");
    if (popup && !popup.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* Popup */}
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[10010003300000]">
          <div className="bg-primary rounded-lg shadow-lg p-6 xl:w-[40%] lg:w-[40%] md:w-[60%] sm:w-[90%] z-10">
            <span className="font-[Sequel100Wide] text-lg font-semibold mb-4">
              PLEASE NOTE:{" "}
            </span>
            <span className="font-[Sequel100WideVF45] text-lg font-semibold mb-4">
              Enter your Telegram Handle Below so we can be in touch in regards
              to Competition Winning.
            </span>
            <div className="h-[20px]"></div>

            {/* Flex container to align the input and button in one line on XL screens only */}
            <div className="flex xl:flex-row flex-col gap-4 items-center">
              <input
                type="text"
                value={telegramHandle}
                onChange={(e) => setTelegramHandle(e.target.value)}
                placeholder="Enter Telegram"
                className="bg-white text-black px-4 py-2 rounded-md w-full xl:w-[70%] sm:w-full lg:w-full h-[56px] font-[Sequel100wideVF45]"
              />
              <button
                onClick={() => handleConfirm({ telegramHandle })}
                className="bg-[#212121] xl:py-[13px] rounded-[14px] border-2 border-white font-[Sequel100wide] text-white h-[56px] w-full xl:w-[30%] sm:w-full lg:w-full"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TelegramConfirmationPopup;
