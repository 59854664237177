export const isProduction = process.env.REACT_APP_ENV === "production";

export const supportedNetworks = ["ethereum", "base", "solana", "btc"];

export const supportedWalletProviders = [
  "metamask",
  "phantom",
  "walletconnect",
];

export const ethereumConfigs = {
  AVAILABLE_PAYMENT_METHODS: ["ETH", "USDT", "USDC", "DAI", "BTC"] as const,
  RPC: isProduction
    ? "https://eth-mainnet.g.alchemy.com/v2/ltT29_i7QwnmgRMK4WladC_nHujxaNs4"
    : "https://eth-sepolia.g.alchemy.com/v2/PLv1H6SjYfs8J7fJM9vEqjpqQpAta4mz",
  NETWORKS: {
    MAINNET: 1,
    SEPOLIA: 11155111,
  },
  CHAIN_ID: isProduction ? 1 : 11155111,
  NETWORK_PARAMS: {
    chainId: isProduction ? "0x1" : "0xaa36a7", // Hexadecimal representation of 1
    chainName: isProduction ? "Ethereum Mainnet" : "Ethereum Sepolia Testnet",
    nativeCurrency: {
      name: isProduction ? "Ethereum" : "Sepolia Ether",
      symbol: "ETH", // Native currency symbol
      decimals: 18,
    },
    rpcUrls: isProduction
      ? [
          "https://eth-mainnet.g.alchemy.com/v2/ltT29_i7QwnmgRMK4WladC_nHujxaNs4",
        ]
      : [
          "https://eth-sepolia.g.alchemy.com/v2/PLv1H6SjYfs8J7fJM9vEqjpqQpAta4mz",
        ], // Replace with your Infura RPC URL
    blockExplorerUrls: isProduction
      ? ["https://etherscan.io"]
      : ["https://sepolia.etherscan.io"], // Ethereum Mainnet Block Explorer URL
  },
  TICKET_PAYMENT: {
    CONTRACT_ADDRESS: isProduction
      ? "0xe8f7348d9ba67d21c0D67b4b77c5bE97dccC2C40"
      : "0x7c5F9d1284a65cC91eA009cE4daAA9f69C7f90A1",
    ABI: [
      {
        inputs: [
          { internalType: "address", name: "initialOwner", type: "address" },
          {
            internalType: "address",
            name: "_verifierAddress",
            type: "address",
          },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        inputs: [{ internalType: "address", name: "owner", type: "address" }],
        name: "OwnableInvalidOwner",
        type: "error",
      },
      {
        inputs: [{ internalType: "address", name: "account", type: "address" }],
        name: "OwnableUnauthorizedAccount",
        type: "error",
      },
      { inputs: [], name: "ReentrancyGuardReentrantCall", type: "error" },
      {
        inputs: [{ internalType: "address", name: "token", type: "address" }],
        name: "SafeERC20FailedOperation",
        type: "error",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "buyer",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "paymentToken",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "cost",
            type: "uint256",
          },
        ],
        name: "Payment",
        type: "event",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "bytes", name: "_signature", type: "bytes" },
          { internalType: "uint256", name: "nonce", type: "uint256" },
          { internalType: "address", name: "_paymentToken", type: "address" },
          { internalType: "uint256", name: "_cost", type: "uint256" },
        ],
        name: "payment",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "", type: "address" },
          { internalType: "uint256", name: "", type: "uint256" },
        ],
        name: "processedNonces",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "_paymentToken", type: "address" },
        ],
        name: "setPaymentMethod",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "_treasuryAddress",
            type: "address",
          },
        ],
        name: "setTreasury",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "", type: "address" }],
        name: "supportedPaymentMethods",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "treasury",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      { stateMutability: "payable", type: "receive" },
    ],
  },
  USDC: {
    CONTRACT_ADDRESS: isProduction
      ? "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
      : "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238",
    DIGITS: 6,
    ABI: [
      {
        constant: true,
        inputs: [],
        name: "name",
        outputs: [{ name: "", type: "string" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "_upgradedAddress", type: "address" }],
        name: "deprecate",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "_spender", type: "address" },
          { name: "_value", type: "uint256" },
        ],
        name: "approve",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "deprecated",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "_evilUser", type: "address" }],
        name: "addBlackList",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "totalSupply",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "_from", type: "address" },
          { name: "_to", type: "address" },
          { name: "_value", type: "uint256" },
        ],
        name: "transferFrom",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "upgradedAddress",
        outputs: [{ name: "", type: "address" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "", type: "address" }],
        name: "balances",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "maximumFee",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "_totalSupply",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [],
        name: "unpause",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "_maker", type: "address" }],
        name: "getBlackListStatus",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          { name: "", type: "address" },
          { name: "", type: "address" },
        ],
        name: "allowed",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "paused",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "who", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [],
        name: "pause",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "getOwner",
        outputs: [{ name: "", type: "address" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "owner",
        outputs: [{ name: "", type: "address" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "symbol",
        outputs: [{ name: "", type: "string" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "_to", type: "address" },
          { name: "_value", type: "uint256" },
        ],
        name: "transfer",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "newBasisPoints", type: "uint256" },
          { name: "newMaxFee", type: "uint256" },
        ],
        name: "setParams",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "amount", type: "uint256" }],
        name: "issue",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "amount", type: "uint256" }],
        name: "redeem",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          { name: "_owner", type: "address" },
          { name: "_spender", type: "address" },
        ],
        name: "allowance",
        outputs: [{ name: "remaining", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "basisPointsRate",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "", type: "address" }],
        name: "isBlackListed",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "_clearedUser", type: "address" }],
        name: "removeBlackList",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "MAX_UINT",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "newOwner", type: "address" }],
        name: "transferOwnership",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "_blackListedUser", type: "address" }],
        name: "destroyBlackFunds",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { name: "_initialSupply", type: "uint256" },
          { name: "_name", type: "string" },
          { name: "_symbol", type: "string" },
          { name: "_decimals", type: "uint256" },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "amount", type: "uint256" }],
        name: "Issue",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "amount", type: "uint256" }],
        name: "Redeem",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "newAddress", type: "address" }],
        name: "Deprecate",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: false, name: "feeBasisPoints", type: "uint256" },
          { indexed: false, name: "maxFee", type: "uint256" },
        ],
        name: "Params",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: false, name: "_blackListedUser", type: "address" },
          { indexed: false, name: "_balance", type: "uint256" },
        ],
        name: "DestroyedBlackFunds",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "_user", type: "address" }],
        name: "AddedBlackList",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "_user", type: "address" }],
        name: "RemovedBlackList",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, name: "owner", type: "address" },
          { indexed: true, name: "spender", type: "address" },
          { indexed: false, name: "value", type: "uint256" },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, name: "from", type: "address" },
          { indexed: true, name: "to", type: "address" },
          { indexed: false, name: "value", type: "uint256" },
        ],
        name: "Transfer",
        type: "event",
      },
      { anonymous: false, inputs: [], name: "Pause", type: "event" },
      { anonymous: false, inputs: [], name: "Unpause", type: "event" },
    ],
  },
  USDT: {
    CONTRACT_ADDRESS: isProduction
      ? "0xdAC17F958D2ee523a2206206994597C13D831ec7"
      : "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    DIGITS: 6,
    ABI: [
      {
        constant: true,
        inputs: [],
        name: "name",
        outputs: [{ name: "", type: "string" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "_upgradedAddress", type: "address" }],
        name: "deprecate",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "_spender", type: "address" },
          { name: "_value", type: "uint256" },
        ],
        name: "approve",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "deprecated",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "_evilUser", type: "address" }],
        name: "addBlackList",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "totalSupply",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "_from", type: "address" },
          { name: "_to", type: "address" },
          { name: "_value", type: "uint256" },
        ],
        name: "transferFrom",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "upgradedAddress",
        outputs: [{ name: "", type: "address" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "", type: "address" }],
        name: "balances",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "maximumFee",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "_totalSupply",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [],
        name: "unpause",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "_maker", type: "address" }],
        name: "getBlackListStatus",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          { name: "", type: "address" },
          { name: "", type: "address" },
        ],
        name: "allowed",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "paused",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "who", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [],
        name: "pause",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "getOwner",
        outputs: [{ name: "", type: "address" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "owner",
        outputs: [{ name: "", type: "address" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "symbol",
        outputs: [{ name: "", type: "string" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "_to", type: "address" },
          { name: "_value", type: "uint256" },
        ],
        name: "transfer",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "newBasisPoints", type: "uint256" },
          { name: "newMaxFee", type: "uint256" },
        ],
        name: "setParams",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "amount", type: "uint256" }],
        name: "issue",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "amount", type: "uint256" }],
        name: "redeem",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          { name: "_owner", type: "address" },
          { name: "_spender", type: "address" },
        ],
        name: "allowance",
        outputs: [{ name: "remaining", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "basisPointsRate",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "", type: "address" }],
        name: "isBlackListed",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "_clearedUser", type: "address" }],
        name: "removeBlackList",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "MAX_UINT",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "newOwner", type: "address" }],
        name: "transferOwnership",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "_blackListedUser", type: "address" }],
        name: "destroyBlackFunds",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { name: "_initialSupply", type: "uint256" },
          { name: "_name", type: "string" },
          { name: "_symbol", type: "string" },
          { name: "_decimals", type: "uint256" },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "amount", type: "uint256" }],
        name: "Issue",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "amount", type: "uint256" }],
        name: "Redeem",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "newAddress", type: "address" }],
        name: "Deprecate",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: false, name: "feeBasisPoints", type: "uint256" },
          { indexed: false, name: "maxFee", type: "uint256" },
        ],
        name: "Params",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: false, name: "_blackListedUser", type: "address" },
          { indexed: false, name: "_balance", type: "uint256" },
        ],
        name: "DestroyedBlackFunds",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "_user", type: "address" }],
        name: "AddedBlackList",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "_user", type: "address" }],
        name: "RemovedBlackList",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, name: "owner", type: "address" },
          { indexed: true, name: "spender", type: "address" },
          { indexed: false, name: "value", type: "uint256" },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, name: "from", type: "address" },
          { indexed: true, name: "to", type: "address" },
          { indexed: false, name: "value", type: "uint256" },
        ],
        name: "Transfer",
        type: "event",
      },
      { anonymous: false, inputs: [], name: "Pause", type: "event" },
      { anonymous: false, inputs: [], name: "Unpause", type: "event" },
    ],
  },
  DAI: {
    CONTRACT_ADDRESS: isProduction
      ? "0x6B175474E89094C44Da98b954EedeAC495271d0F"
      : "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238",
    DIGITS: 18,
    ABI: [
      {
        inputs: [
          { internalType: "uint256", name: "chainId_", type: "uint256" },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "src",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "guy",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "wad",
            type: "uint256",
          },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: true,
        inputs: [
          {
            indexed: true,
            internalType: "bytes4",
            name: "sig",
            type: "bytes4",
          },
          {
            indexed: true,
            internalType: "address",
            name: "usr",
            type: "address",
          },
          {
            indexed: true,
            internalType: "bytes32",
            name: "arg1",
            type: "bytes32",
          },
          {
            indexed: true,
            internalType: "bytes32",
            name: "arg2",
            type: "bytes32",
          },
          {
            indexed: false,
            internalType: "bytes",
            name: "data",
            type: "bytes",
          },
        ],
        name: "LogNote",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "src",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "dst",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "wad",
            type: "uint256",
          },
        ],
        name: "Transfer",
        type: "event",
      },
      {
        constant: true,
        inputs: [],
        name: "DOMAIN_SEPARATOR",
        outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "PERMIT_TYPEHASH",
        outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          { internalType: "address", name: "", type: "address" },
          { internalType: "address", name: "", type: "address" },
        ],
        name: "allowance",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "usr", type: "address" },
          { internalType: "uint256", name: "wad", type: "uint256" },
        ],
        name: "approve",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ internalType: "address", name: "", type: "address" }],
        name: "balanceOf",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "usr", type: "address" },
          { internalType: "uint256", name: "wad", type: "uint256" },
        ],
        name: "burn",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ internalType: "address", name: "guy", type: "address" }],
        name: "deny",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "usr", type: "address" },
          { internalType: "uint256", name: "wad", type: "uint256" },
        ],
        name: "mint",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "src", type: "address" },
          { internalType: "address", name: "dst", type: "address" },
          { internalType: "uint256", name: "wad", type: "uint256" },
        ],
        name: "move",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "name",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ internalType: "address", name: "", type: "address" }],
        name: "nonces",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "holder", type: "address" },
          { internalType: "address", name: "spender", type: "address" },
          { internalType: "uint256", name: "nonce", type: "uint256" },
          { internalType: "uint256", name: "expiry", type: "uint256" },
          { internalType: "bool", name: "allowed", type: "bool" },
          { internalType: "uint8", name: "v", type: "uint8" },
          { internalType: "bytes32", name: "r", type: "bytes32" },
          { internalType: "bytes32", name: "s", type: "bytes32" },
        ],
        name: "permit",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "usr", type: "address" },
          { internalType: "uint256", name: "wad", type: "uint256" },
        ],
        name: "pull",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "usr", type: "address" },
          { internalType: "uint256", name: "wad", type: "uint256" },
        ],
        name: "push",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ internalType: "address", name: "guy", type: "address" }],
        name: "rely",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "symbol",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "totalSupply",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "dst", type: "address" },
          { internalType: "uint256", name: "wad", type: "uint256" },
        ],
        name: "transfer",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "src", type: "address" },
          { internalType: "address", name: "dst", type: "address" },
          { internalType: "uint256", name: "wad", type: "uint256" },
        ],
        name: "transferFrom",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "version",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ internalType: "address", name: "", type: "address" }],
        name: "wards",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ],
  },
  RNG: {
    CONTRACT_ADDRESS: "0x73499869B0c49d8CaEabe472261732D930B61868", // sepolia address (mainnet rng is deployed on base)
    ABI: [
      {
        inputs: [
          { internalType: "uint256", name: "subscriptionId", type: "uint256" },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        inputs: [
          { internalType: "address", name: "have", type: "address" },
          { internalType: "address", name: "want", type: "address" },
        ],
        name: "OnlyCoordinatorCanFulfill",
        type: "error",
      },
      {
        inputs: [
          { internalType: "address", name: "have", type: "address" },
          { internalType: "address", name: "owner", type: "address" },
          { internalType: "address", name: "coordinator", type: "address" },
        ],
        name: "OnlyOwnerOrCoordinator",
        type: "error",
      },
      { inputs: [], name: "ZeroAddress", type: "error" },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "vrfCoordinator",
            type: "address",
          },
        ],
        name: "CoordinatorSet",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
        ],
        name: "OwnershipTransferRequested",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "requestId",
            type: "uint256",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "result",
            type: "uint256",
          },
        ],
        name: "RandomNumberSelected",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "requestId",
            type: "uint256",
          },
          {
            indexed: true,
            internalType: "address",
            name: "roller",
            type: "address",
          },
        ],
        name: "SelectingRandomNumber",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "allowed",
            type: "address",
          },
        ],
        name: "rovkedWhiteListedUser",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "allowed",
            type: "address",
          },
        ],
        name: "whiteListedUser",
        type: "event",
      },
      {
        inputs: [
          { internalType: "address", name: "player", type: "address" },
          { internalType: "uint256", name: "requestId", type: "uint256" },
        ],
        name: "RNG",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "acceptOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "callbackGasLimit",
        outputs: [{ internalType: "uint32", name: "", type: "uint32" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "allower", type: "address" }],
        name: "checkAllowance",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "_address", type: "address" },
        ],
        name: "get_balance",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "get_min_max",
        outputs: [
          { internalType: "uint256", name: "", type: "uint256" },
          { internalType: "uint256", name: "", type: "uint256" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "max_range",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "min_range",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "numOfNumbers",
        outputs: [{ internalType: "uint32", name: "", type: "uint32" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "requestId", type: "uint256" },
          { internalType: "uint256[]", name: "randomWords", type: "uint256[]" },
        ],
        name: "rawFulfillRandomWords",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "requestConfirmations",
        outputs: [{ internalType: "uint16", name: "", type: "uint16" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "allower", type: "address" }],
        name: "revokeWhitelistAddress",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "roller", type: "address" },
          { internalType: "address", name: "reciever", type: "address" },
        ],
        name: "rollDice",
        outputs: [
          { internalType: "uint256", name: "requestId", type: "uint256" },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "s_subscriptionId",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "s_vrfCoordinator",
        outputs: [
          {
            internalType: "contract IVRFCoordinatorV2Plus",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "_vrfCoordinator", type: "address" },
        ],
        name: "setCoordinator",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "min", type: "uint256" },
          { internalType: "uint256", name: "max", type: "uint256" },
        ],
        name: "set_min_max_range",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "to", type: "address" }],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "allower", type: "address" }],
        name: "whitelistAddress",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
  },
  VRF: {
    CONTRACT_ADDRESS: "0x9DdfaCa8183c41ad55329BdeeD9F6A8d53168B1B", // sepolia address (mainnet rng is deployed on base)
    KEY_HASH:
      "0x787d74caea10b2b357790d5b5247c2f63d1d91572a9846f780606e4d953677ae",
    SUBSCRIPTION_ID:
      "23858541449590536280694343406383936049100487622047366309089041954185018897216",
    ABI: [
      {
        inputs: [
          { internalType: "address", name: "blockhashStore", type: "address" },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        inputs: [
          { internalType: "uint256", name: "internalBalance", type: "uint256" },
          { internalType: "uint256", name: "externalBalance", type: "uint256" },
        ],
        name: "BalanceInvariantViolated",
        type: "error",
      },
      {
        inputs: [
          { internalType: "uint256", name: "blockNum", type: "uint256" },
        ],
        name: "BlockhashNotInStore",
        type: "error",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "coordinatorAddress",
            type: "address",
          },
        ],
        name: "CoordinatorAlreadyRegistered",
        type: "error",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "coordinatorAddress",
            type: "address",
          },
        ],
        name: "CoordinatorNotRegistered",
        type: "error",
      },
      { inputs: [], name: "FailedToSendNative", type: "error" },
      { inputs: [], name: "FailedToTransferLink", type: "error" },
      {
        inputs: [
          { internalType: "uint32", name: "have", type: "uint32" },
          { internalType: "uint32", name: "want", type: "uint32" },
        ],
        name: "GasLimitTooBig",
        type: "error",
      },
      {
        inputs: [
          { internalType: "uint256", name: "gasPrice", type: "uint256" },
          { internalType: "uint256", name: "maxGas", type: "uint256" },
        ],
        name: "GasPriceExceeded",
        type: "error",
      },
      { inputs: [], name: "IncorrectCommitment", type: "error" },
      { inputs: [], name: "IndexOutOfRange", type: "error" },
      { inputs: [], name: "InsufficientBalance", type: "error" },
      { inputs: [], name: "InvalidCalldata", type: "error" },
      {
        inputs: [
          { internalType: "uint256", name: "subId", type: "uint256" },
          { internalType: "address", name: "consumer", type: "address" },
        ],
        name: "InvalidConsumer",
        type: "error",
      },
      { inputs: [], name: "InvalidExtraArgsTag", type: "error" },
      {
        inputs: [{ internalType: "int256", name: "linkWei", type: "int256" }],
        name: "InvalidLinkWeiPrice",
        type: "error",
      },
      {
        inputs: [
          { internalType: "uint8", name: "premiumPercentage", type: "uint8" },
          { internalType: "uint8", name: "max", type: "uint8" },
        ],
        name: "InvalidPremiumPercentage",
        type: "error",
      },
      {
        inputs: [
          { internalType: "uint16", name: "have", type: "uint16" },
          { internalType: "uint16", name: "min", type: "uint16" },
          { internalType: "uint16", name: "max", type: "uint16" },
        ],
        name: "InvalidRequestConfirmations",
        type: "error",
      },
      { inputs: [], name: "InvalidSubscription", type: "error" },
      { inputs: [], name: "LinkAlreadySet", type: "error" },
      {
        inputs: [
          {
            internalType: "uint32",
            name: "flatFeeLinkDiscountPPM",
            type: "uint32",
          },
          { internalType: "uint32", name: "flatFeeNativePPM", type: "uint32" },
        ],
        name: "LinkDiscountTooHigh",
        type: "error",
      },
      { inputs: [], name: "LinkNotSet", type: "error" },
      {
        inputs: [
          { internalType: "uint256", name: "have", type: "uint256" },
          { internalType: "uint32", name: "max", type: "uint32" },
        ],
        name: "MsgDataTooBig",
        type: "error",
      },
      {
        inputs: [
          { internalType: "address", name: "proposedOwner", type: "address" },
        ],
        name: "MustBeRequestedOwner",
        type: "error",
      },
      {
        inputs: [{ internalType: "address", name: "owner", type: "address" }],
        name: "MustBeSubOwner",
        type: "error",
      },
      { inputs: [], name: "NoCorrespondingRequest", type: "error" },
      {
        inputs: [{ internalType: "bytes32", name: "keyHash", type: "bytes32" }],
        name: "NoSuchProvingKey",
        type: "error",
      },
      {
        inputs: [
          { internalType: "uint32", name: "have", type: "uint32" },
          { internalType: "uint32", name: "want", type: "uint32" },
        ],
        name: "NumWordsTooBig",
        type: "error",
      },
      { inputs: [], name: "OnlyCallableFromLink", type: "error" },
      { inputs: [], name: "PaymentTooLarge", type: "error" },
      { inputs: [], name: "PendingRequestExists", type: "error" },
      {
        inputs: [{ internalType: "bytes32", name: "keyHash", type: "bytes32" }],
        name: "ProvingKeyAlreadyRegistered",
        type: "error",
      },
      { inputs: [], name: "Reentrant", type: "error" },
      { inputs: [], name: "TooManyConsumers", type: "error" },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "uint16",
            name: "minimumRequestConfirmations",
            type: "uint16",
          },
          {
            indexed: false,
            internalType: "uint32",
            name: "maxGasLimit",
            type: "uint32",
          },
          {
            indexed: false,
            internalType: "uint32",
            name: "stalenessSeconds",
            type: "uint32",
          },
          {
            indexed: false,
            internalType: "uint32",
            name: "gasAfterPaymentCalculation",
            type: "uint32",
          },
          {
            indexed: false,
            internalType: "int256",
            name: "fallbackWeiPerUnitLink",
            type: "int256",
          },
          {
            indexed: false,
            internalType: "uint32",
            name: "fulfillmentFlatFeeNativePPM",
            type: "uint32",
          },
          {
            indexed: false,
            internalType: "uint32",
            name: "fulfillmentFlatFeeLinkDiscountPPM",
            type: "uint32",
          },
          {
            indexed: false,
            internalType: "uint8",
            name: "nativePremiumPercentage",
            type: "uint8",
          },
          {
            indexed: false,
            internalType: "uint8",
            name: "linkPremiumPercentage",
            type: "uint8",
          },
        ],
        name: "ConfigSet",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "coordinatorAddress",
            type: "address",
          },
        ],
        name: "CoordinatorDeregistered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "coordinatorAddress",
            type: "address",
          },
        ],
        name: "CoordinatorRegistered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "uint256",
            name: "requestId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "int256",
            name: "fallbackWeiPerUnitLink",
            type: "int256",
          },
        ],
        name: "FallbackWeiPerUnitLinkUsed",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "FundsRecovered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "newCoordinator",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
        ],
        name: "MigrationCompleted",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "NativeFundsRecovered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
        ],
        name: "OwnershipTransferRequested",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "bytes32",
            name: "keyHash",
            type: "bytes32",
          },
          {
            indexed: false,
            internalType: "uint64",
            name: "maxGas",
            type: "uint64",
          },
        ],
        name: "ProvingKeyDeregistered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "bytes32",
            name: "keyHash",
            type: "bytes32",
          },
          {
            indexed: false,
            internalType: "uint64",
            name: "maxGas",
            type: "uint64",
          },
        ],
        name: "ProvingKeyRegistered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "requestId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "outputSeed",
            type: "uint256",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint96",
            name: "payment",
            type: "uint96",
          },
          {
            indexed: false,
            internalType: "bool",
            name: "nativePayment",
            type: "bool",
          },
          {
            indexed: false,
            internalType: "bool",
            name: "success",
            type: "bool",
          },
          {
            indexed: false,
            internalType: "bool",
            name: "onlyPremium",
            type: "bool",
          },
        ],
        name: "RandomWordsFulfilled",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "bytes32",
            name: "keyHash",
            type: "bytes32",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "requestId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "preSeed",
            type: "uint256",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint16",
            name: "minimumRequestConfirmations",
            type: "uint16",
          },
          {
            indexed: false,
            internalType: "uint32",
            name: "callbackGasLimit",
            type: "uint32",
          },
          {
            indexed: false,
            internalType: "uint32",
            name: "numWords",
            type: "uint32",
          },
          {
            indexed: false,
            internalType: "bytes",
            name: "extraArgs",
            type: "bytes",
          },
          {
            indexed: true,
            internalType: "address",
            name: "sender",
            type: "address",
          },
        ],
        name: "RandomWordsRequested",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amountLink",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amountNative",
            type: "uint256",
          },
        ],
        name: "SubscriptionCanceled",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "address",
            name: "consumer",
            type: "address",
          },
        ],
        name: "SubscriptionConsumerAdded",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "address",
            name: "consumer",
            type: "address",
          },
        ],
        name: "SubscriptionConsumerRemoved",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "address",
            name: "owner",
            type: "address",
          },
        ],
        name: "SubscriptionCreated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "oldBalance",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "newBalance",
            type: "uint256",
          },
        ],
        name: "SubscriptionFunded",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "oldNativeBalance",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "newNativeBalance",
            type: "uint256",
          },
        ],
        name: "SubscriptionFundedWithNative",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: false,
            internalType: "address",
            name: "to",
            type: "address",
          },
        ],
        name: "SubscriptionOwnerTransferRequested",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: false,
            internalType: "address",
            name: "to",
            type: "address",
          },
        ],
        name: "SubscriptionOwnerTransferred",
        type: "event",
      },
      {
        inputs: [],
        name: "BLOCKHASH_STORE",
        outputs: [
          {
            internalType: "contract BlockhashStoreInterface",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "LINK",
        outputs: [
          {
            internalType: "contract LinkTokenInterface",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "LINK_NATIVE_FEED",
        outputs: [
          {
            internalType: "contract AggregatorV3Interface",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "MAX_CONSUMERS",
        outputs: [{ internalType: "uint16", name: "", type: "uint16" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "MAX_NUM_WORDS",
        outputs: [{ internalType: "uint32", name: "", type: "uint32" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "MAX_REQUEST_CONFIRMATIONS",
        outputs: [{ internalType: "uint16", name: "", type: "uint16" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "acceptOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "subId", type: "uint256" }],
        name: "acceptSubscriptionOwnerTransfer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "subId", type: "uint256" },
          { internalType: "address", name: "consumer", type: "address" },
        ],
        name: "addConsumer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "subId", type: "uint256" },
          { internalType: "address", name: "to", type: "address" },
        ],
        name: "cancelSubscription",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "createSubscription",
        outputs: [{ internalType: "uint256", name: "subId", type: "uint256" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "target", type: "address" }],
        name: "deregisterMigratableCoordinator",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256[2]",
            name: "publicProvingKey",
            type: "uint256[2]",
          },
        ],
        name: "deregisterProvingKey",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            components: [
              { internalType: "uint256[2]", name: "pk", type: "uint256[2]" },
              { internalType: "uint256[2]", name: "gamma", type: "uint256[2]" },
              { internalType: "uint256", name: "c", type: "uint256" },
              { internalType: "uint256", name: "s", type: "uint256" },
              { internalType: "uint256", name: "seed", type: "uint256" },
              { internalType: "address", name: "uWitness", type: "address" },
              {
                internalType: "uint256[2]",
                name: "cGammaWitness",
                type: "uint256[2]",
              },
              {
                internalType: "uint256[2]",
                name: "sHashWitness",
                type: "uint256[2]",
              },
              { internalType: "uint256", name: "zInv", type: "uint256" },
            ],
            internalType: "struct VRF.Proof",
            name: "proof",
            type: "tuple",
          },
          {
            components: [
              { internalType: "uint64", name: "blockNum", type: "uint64" },
              { internalType: "uint256", name: "subId", type: "uint256" },
              {
                internalType: "uint32",
                name: "callbackGasLimit",
                type: "uint32",
              },
              { internalType: "uint32", name: "numWords", type: "uint32" },
              { internalType: "address", name: "sender", type: "address" },
              { internalType: "bytes", name: "extraArgs", type: "bytes" },
            ],
            internalType: "struct VRFTypes.RequestCommitmentV2Plus",
            name: "rc",
            type: "tuple",
          },
          { internalType: "bool", name: "onlyPremium", type: "bool" },
        ],
        name: "fulfillRandomWords",
        outputs: [{ internalType: "uint96", name: "payment", type: "uint96" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "subId", type: "uint256" }],
        name: "fundSubscriptionWithNative",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "startIndex", type: "uint256" },
          { internalType: "uint256", name: "maxCount", type: "uint256" },
        ],
        name: "getActiveSubscriptionIds",
        outputs: [
          { internalType: "uint256[]", name: "ids", type: "uint256[]" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "subId", type: "uint256" }],
        name: "getSubscription",
        outputs: [
          { internalType: "uint96", name: "balance", type: "uint96" },
          { internalType: "uint96", name: "nativeBalance", type: "uint96" },
          { internalType: "uint64", name: "reqCount", type: "uint64" },
          { internalType: "address", name: "subOwner", type: "address" },
          { internalType: "address[]", name: "consumers", type: "address[]" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256[2]", name: "publicKey", type: "uint256[2]" },
        ],
        name: "hashOfKey",
        outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        stateMutability: "pure",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "subId", type: "uint256" },
          { internalType: "address", name: "newCoordinator", type: "address" },
        ],
        name: "migrate",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
          { internalType: "bytes", name: "data", type: "bytes" },
        ],
        name: "onTokenTransfer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "subId", type: "uint256" }],
        name: "ownerCancelSubscription",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "subId", type: "uint256" }],
        name: "pendingRequestExists",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "to", type: "address" }],
        name: "recoverFunds",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address payable", name: "to", type: "address" },
        ],
        name: "recoverNativeFunds",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "target", type: "address" }],
        name: "registerMigratableCoordinator",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256[2]",
            name: "publicProvingKey",
            type: "uint256[2]",
          },
          { internalType: "uint64", name: "maxGas", type: "uint64" },
        ],
        name: "registerProvingKey",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "subId", type: "uint256" },
          { internalType: "address", name: "consumer", type: "address" },
        ],
        name: "removeConsumer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            components: [
              { internalType: "bytes32", name: "keyHash", type: "bytes32" },
              { internalType: "uint256", name: "subId", type: "uint256" },
              {
                internalType: "uint16",
                name: "requestConfirmations",
                type: "uint16",
              },
              {
                internalType: "uint32",
                name: "callbackGasLimit",
                type: "uint32",
              },
              { internalType: "uint32", name: "numWords", type: "uint32" },
              { internalType: "bytes", name: "extraArgs", type: "bytes" },
            ],
            internalType: "struct VRFV2PlusClient.RandomWordsRequest",
            name: "req",
            type: "tuple",
          },
        ],
        name: "requestRandomWords",
        outputs: [
          { internalType: "uint256", name: "requestId", type: "uint256" },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "subId", type: "uint256" },
          { internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "requestSubscriptionOwnerTransfer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "s_config",
        outputs: [
          {
            internalType: "uint16",
            name: "minimumRequestConfirmations",
            type: "uint16",
          },
          { internalType: "uint32", name: "maxGasLimit", type: "uint32" },
          { internalType: "bool", name: "reentrancyLock", type: "bool" },
          { internalType: "uint32", name: "stalenessSeconds", type: "uint32" },
          {
            internalType: "uint32",
            name: "gasAfterPaymentCalculation",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "fulfillmentFlatFeeNativePPM",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "fulfillmentFlatFeeLinkDiscountPPM",
            type: "uint32",
          },
          {
            internalType: "uint8",
            name: "nativePremiumPercentage",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "linkPremiumPercentage",
            type: "uint8",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "s_currentSubNonce",
        outputs: [{ internalType: "uint64", name: "", type: "uint64" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "s_fallbackWeiPerUnitLink",
        outputs: [{ internalType: "int256", name: "", type: "int256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "s_provingKeyHashes",
        outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        name: "s_provingKeys",
        outputs: [
          { internalType: "bool", name: "exists", type: "bool" },
          { internalType: "uint64", name: "maxGas", type: "uint64" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "s_requestCommitments",
        outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "s_totalBalance",
        outputs: [{ internalType: "uint96", name: "", type: "uint96" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "s_totalNativeBalance",
        outputs: [{ internalType: "uint96", name: "", type: "uint96" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint16",
            name: "minimumRequestConfirmations",
            type: "uint16",
          },
          { internalType: "uint32", name: "maxGasLimit", type: "uint32" },
          { internalType: "uint32", name: "stalenessSeconds", type: "uint32" },
          {
            internalType: "uint32",
            name: "gasAfterPaymentCalculation",
            type: "uint32",
          },
          {
            internalType: "int256",
            name: "fallbackWeiPerUnitLink",
            type: "int256",
          },
          {
            internalType: "uint32",
            name: "fulfillmentFlatFeeNativePPM",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "fulfillmentFlatFeeLinkDiscountPPM",
            type: "uint32",
          },
          {
            internalType: "uint8",
            name: "nativePremiumPercentage",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "linkPremiumPercentage",
            type: "uint8",
          },
        ],
        name: "setConfig",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "link", type: "address" },
          { internalType: "address", name: "linkNativeFeed", type: "address" },
        ],
        name: "setLINKAndLINKNativeFeed",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "to", type: "address" }],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "recipient", type: "address" },
        ],
        name: "withdraw",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address payable",
            name: "recipient",
            type: "address",
          },
        ],
        name: "withdrawNative",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
  },
};

export const baseConfigs = {
  AVAILABLE_PAYMENT_METHODS: ["ETH", "USDC", "DAI", "BTC"] as const,
  RPC: isProduction
    ? "https://base-mainnet.g.alchemy.com/v2/ltT29_i7QwnmgRMK4WladC_nHujxaNs4"
    : "https://base-sepolia.g.alchemy.com/v2/ltT29_i7QwnmgRMK4WladC_nHujxaNs4",
  NETWORKS: {
    MAINNET: 8453,
    SEPOLIA: 84532,
  },
  CHAIN_ID: isProduction ? 8453 : 84532,
  NETWORK_PARAMS: {
    chainId: isProduction ? "0x2105" : "0x14a33",
    chainName: isProduction ? "Base Mainnet" : "Base Sepolia Testnet",
    nativeCurrency: {
      name: isProduction ? "Ethereum" : "Sepolia Ether",
      symbol: "ETH", // Native currency symbol
      decimals: 18,
    },
    rpcUrls: isProduction
      ? [
          "https://base-mainnet.g.alchemy.com/v2/ltT29_i7QwnmgRMK4WladC_nHujxaNs4",
        ]
      : [
          "https://base-sepolia.g.alchemy.com/v2/ltT29_i7QwnmgRMK4WladC_nHujxaNs4",
        ],
    blockExplorerUrls: isProduction
      ? ["https://explorer.base.org"]
      : ["https://sepolia.basescan.org"], // Base Mainnet Block Explorer URL
  },
  TICKET_PAYMENT: {
    CONTRACT_ADDRESS: isProduction
      ? "0xF5984e16695C1D94c8ab3B9DFFD52a9b31e5589b"
      : "0x6a92a4311e2b43a48769E6258daFd3Acd0ff2B3A",
    ABI: [
      {
        inputs: [
          { internalType: "address", name: "initialOwner", type: "address" },
          {
            internalType: "address",
            name: "_verifierAddress",
            type: "address",
          },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        inputs: [{ internalType: "address", name: "owner", type: "address" }],
        name: "OwnableInvalidOwner",
        type: "error",
      },
      {
        inputs: [{ internalType: "address", name: "account", type: "address" }],
        name: "OwnableUnauthorizedAccount",
        type: "error",
      },
      { inputs: [], name: "ReentrancyGuardReentrantCall", type: "error" },
      {
        inputs: [{ internalType: "address", name: "token", type: "address" }],
        name: "SafeERC20FailedOperation",
        type: "error",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "buyer",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "paymentToken",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "cost",
            type: "uint256",
          },
        ],
        name: "Payment",
        type: "event",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "bytes", name: "_signature", type: "bytes" },
          { internalType: "uint256", name: "nonce", type: "uint256" },
          { internalType: "address", name: "_paymentToken", type: "address" },
          { internalType: "uint256", name: "_cost", type: "uint256" },
        ],
        name: "payment",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "", type: "address" },
          { internalType: "uint256", name: "", type: "uint256" },
        ],
        name: "processedNonces",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "_paymentToken", type: "address" },
        ],
        name: "setPaymentMethod",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "_treasuryAddress",
            type: "address",
          },
        ],
        name: "setTreasury",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "", type: "address" }],
        name: "supportedPaymentMethods",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "treasury",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      { stateMutability: "payable", type: "receive" },
    ],
  },
  USDC: {
    CONTRACT_ADDRESS: isProduction
      ? "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913"
      : "0x036CbD53842c5426634e7929541eC2318f3dCF7e",
    DIGITS: 6,
    ABI: [
      {
        constant: true,
        inputs: [],
        name: "name",
        outputs: [{ name: "", type: "string" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "_upgradedAddress", type: "address" }],
        name: "deprecate",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "_spender", type: "address" },
          { name: "_value", type: "uint256" },
        ],
        name: "approve",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "deprecated",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "_evilUser", type: "address" }],
        name: "addBlackList",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "totalSupply",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "_from", type: "address" },
          { name: "_to", type: "address" },
          { name: "_value", type: "uint256" },
        ],
        name: "transferFrom",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "upgradedAddress",
        outputs: [{ name: "", type: "address" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "", type: "address" }],
        name: "balances",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "maximumFee",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "_totalSupply",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [],
        name: "unpause",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "_maker", type: "address" }],
        name: "getBlackListStatus",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          { name: "", type: "address" },
          { name: "", type: "address" },
        ],
        name: "allowed",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "paused",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "who", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [],
        name: "pause",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "getOwner",
        outputs: [{ name: "", type: "address" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "owner",
        outputs: [{ name: "", type: "address" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "symbol",
        outputs: [{ name: "", type: "string" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "_to", type: "address" },
          { name: "_value", type: "uint256" },
        ],
        name: "transfer",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "newBasisPoints", type: "uint256" },
          { name: "newMaxFee", type: "uint256" },
        ],
        name: "setParams",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "amount", type: "uint256" }],
        name: "issue",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "amount", type: "uint256" }],
        name: "redeem",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          { name: "_owner", type: "address" },
          { name: "_spender", type: "address" },
        ],
        name: "allowance",
        outputs: [{ name: "remaining", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "basisPointsRate",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "", type: "address" }],
        name: "isBlackListed",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "_clearedUser", type: "address" }],
        name: "removeBlackList",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "MAX_UINT",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "newOwner", type: "address" }],
        name: "transferOwnership",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "_blackListedUser", type: "address" }],
        name: "destroyBlackFunds",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { name: "_initialSupply", type: "uint256" },
          { name: "_name", type: "string" },
          { name: "_symbol", type: "string" },
          { name: "_decimals", type: "uint256" },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "amount", type: "uint256" }],
        name: "Issue",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "amount", type: "uint256" }],
        name: "Redeem",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "newAddress", type: "address" }],
        name: "Deprecate",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: false, name: "feeBasisPoints", type: "uint256" },
          { indexed: false, name: "maxFee", type: "uint256" },
        ],
        name: "Params",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: false, name: "_blackListedUser", type: "address" },
          { indexed: false, name: "_balance", type: "uint256" },
        ],
        name: "DestroyedBlackFunds",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "_user", type: "address" }],
        name: "AddedBlackList",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "_user", type: "address" }],
        name: "RemovedBlackList",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, name: "owner", type: "address" },
          { indexed: true, name: "spender", type: "address" },
          { indexed: false, name: "value", type: "uint256" },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, name: "from", type: "address" },
          { indexed: true, name: "to", type: "address" },
          { indexed: false, name: "value", type: "uint256" },
        ],
        name: "Transfer",
        type: "event",
      },
      { anonymous: false, inputs: [], name: "Pause", type: "event" },
      { anonymous: false, inputs: [], name: "Unpause", type: "event" },
    ],
  },
  DAI: {
    CONTRACT_ADDRESS: isProduction
      ? "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb"
      : "0x7683022d84F726a96c4A6611cD31DBf5409c0Ac9",
    DIGITS: 18,
    ABI: [
      {
        inputs: [{ internalType: "address", name: "owner", type: "address" }],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
        ],
        name: "Transfer",
        type: "event",
      },
      {
        inputs: [
          { internalType: "address", name: "owner", type: "address" },
          { internalType: "address", name: "spender", type: "address" },
        ],
        name: "allowance",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "spender", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "approve",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "account", type: "address" }],
        name: "balanceOf",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "decimals",
        outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "spender", type: "address" },
          { internalType: "uint256", name: "subtractedValue", type: "uint256" },
        ],
        name: "decreaseAllowance",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "spender", type: "address" },
          { internalType: "uint256", name: "addedValue", type: "uint256" },
        ],
        name: "increaseAllowance",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "to", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "mint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "name",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "nativeToTokenRatio",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "publicMint",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "symbol",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "totalSupply",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "to", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "transfer",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "from", type: "address" },
          { internalType: "address", name: "to", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "transferFrom",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "withdraw",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
  },
  RNG: {
    CONTRACT_ADDRESS: "0x568Ba9169598F714386Cfbe941ad94c18ff9E227", // mainet address
    ABI: [
      {
        inputs: [
          {
            internalType: "uint256",
            name: "subscriptionId",
            type: "uint256",
          },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "have",
            type: "address",
          },
          {
            internalType: "address",
            name: "want",
            type: "address",
          },
        ],
        name: "OnlyCoordinatorCanFulfill",
        type: "error",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "have",
            type: "address",
          },
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            internalType: "address",
            name: "coordinator",
            type: "address",
          },
        ],
        name: "OnlyOwnerOrCoordinator",
        type: "error",
      },
      {
        inputs: [],
        name: "ZeroAddress",
        type: "error",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "vrfCoordinator",
            type: "address",
          },
        ],
        name: "CoordinatorSet",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
        ],
        name: "OwnershipTransferRequested",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "requestId",
            type: "uint256",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "result",
            type: "uint256",
          },
        ],
        name: "RandomNumberSelected",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "requestId",
            type: "uint256",
          },
          {
            indexed: true,
            internalType: "address",
            name: "roller",
            type: "address",
          },
        ],
        name: "SelectingRandomNumber",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "allowed",
            type: "address",
          },
        ],
        name: "rovkedWhiteListedUser",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "allowed",
            type: "address",
          },
        ],
        name: "whiteListedUser",
        type: "event",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "player",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "requestId",
            type: "uint256",
          },
        ],
        name: "RNG",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "acceptOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "callbackGasLimit",
        outputs: [
          {
            internalType: "uint32",
            name: "",
            type: "uint32",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "allower",
            type: "address",
          },
        ],
        name: "checkAllowance",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "fee",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "_address",
            type: "address",
          },
        ],
        name: "get_balance",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "get_min_max",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "max_range",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "min_range",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "numOfNumbers",
        outputs: [
          {
            internalType: "uint32",
            name: "",
            type: "uint32",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "requestId",
            type: "uint256",
          },
          {
            internalType: "uint256[]",
            name: "randomWords",
            type: "uint256[]",
          },
        ],
        name: "rawFulfillRandomWords",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "requestConfirmations",
        outputs: [
          {
            internalType: "uint16",
            name: "",
            type: "uint16",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "allower",
            type: "address",
          },
        ],
        name: "revokeWhitelistAddress",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "roller",
            type: "address",
          },
          {
            internalType: "address",
            name: "reciever",
            type: "address",
          },
        ],
        name: "rollDice",
        outputs: [
          {
            internalType: "uint256",
            name: "requestId",
            type: "uint256",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "s_subscriptionId",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "s_vrfCoordinator",
        outputs: [
          {
            internalType: "contract IVRFCoordinatorV2Plus",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "_vrfCoordinator",
            type: "address",
          },
        ],
        name: "setCoordinator",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "_newFee",
            type: "uint256",
          },
        ],
        name: "setFee",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "min",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "max",
            type: "uint256",
          },
        ],
        name: "set_min_max_range",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "allower",
            type: "address",
          },
        ],
        name: "whitelistAddress",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
  },
  VRF: {
    CONTRACT_ADDRESS: "0xd5D517aBE5cF79B7e95eC98dB0f0277788aFF634", // mainnet address
    KEY_HASH:
      "0xdc2f87677b01473c763cb0aee938ed3341512f6057324a584e5944e786144d70",
    SUBSCRIPTION_ID:
      "32165591027277067322357712838192428520914446717316624291387501507226855466446",
    ABI: [
      {
        inputs: [
          { internalType: "address", name: "blockhashStore", type: "address" },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        inputs: [
          { internalType: "uint256", name: "internalBalance", type: "uint256" },
          { internalType: "uint256", name: "externalBalance", type: "uint256" },
        ],
        name: "BalanceInvariantViolated",
        type: "error",
      },
      {
        inputs: [
          { internalType: "uint256", name: "blockNum", type: "uint256" },
        ],
        name: "BlockhashNotInStore",
        type: "error",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "coordinatorAddress",
            type: "address",
          },
        ],
        name: "CoordinatorAlreadyRegistered",
        type: "error",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "coordinatorAddress",
            type: "address",
          },
        ],
        name: "CoordinatorNotRegistered",
        type: "error",
      },
      { inputs: [], name: "FailedToSendNative", type: "error" },
      { inputs: [], name: "FailedToTransferLink", type: "error" },
      {
        inputs: [
          { internalType: "uint32", name: "have", type: "uint32" },
          { internalType: "uint32", name: "want", type: "uint32" },
        ],
        name: "GasLimitTooBig",
        type: "error",
      },
      {
        inputs: [
          { internalType: "uint256", name: "gasPrice", type: "uint256" },
          { internalType: "uint256", name: "maxGas", type: "uint256" },
        ],
        name: "GasPriceExceeded",
        type: "error",
      },
      { inputs: [], name: "IncorrectCommitment", type: "error" },
      { inputs: [], name: "IndexOutOfRange", type: "error" },
      { inputs: [], name: "InsufficientBalance", type: "error" },
      { inputs: [], name: "InvalidCalldata", type: "error" },
      {
        inputs: [
          { internalType: "uint256", name: "subId", type: "uint256" },
          { internalType: "address", name: "consumer", type: "address" },
        ],
        name: "InvalidConsumer",
        type: "error",
      },
      { inputs: [], name: "InvalidExtraArgsTag", type: "error" },
      {
        inputs: [{ internalType: "uint8", name: "mode", type: "uint8" }],
        name: "InvalidL1FeeCalculationMode",
        type: "error",
      },
      {
        inputs: [{ internalType: "uint8", name: "coefficient", type: "uint8" }],
        name: "InvalidL1FeeCoefficient",
        type: "error",
      },
      {
        inputs: [{ internalType: "int256", name: "linkWei", type: "int256" }],
        name: "InvalidLinkWeiPrice",
        type: "error",
      },
      {
        inputs: [
          { internalType: "uint8", name: "premiumPercentage", type: "uint8" },
          { internalType: "uint8", name: "max", type: "uint8" },
        ],
        name: "InvalidPremiumPercentage",
        type: "error",
      },
      {
        inputs: [
          { internalType: "uint16", name: "have", type: "uint16" },
          { internalType: "uint16", name: "min", type: "uint16" },
          { internalType: "uint16", name: "max", type: "uint16" },
        ],
        name: "InvalidRequestConfirmations",
        type: "error",
      },
      { inputs: [], name: "InvalidSubscription", type: "error" },
      { inputs: [], name: "LinkAlreadySet", type: "error" },
      {
        inputs: [
          {
            internalType: "uint32",
            name: "flatFeeLinkDiscountPPM",
            type: "uint32",
          },
          { internalType: "uint32", name: "flatFeeNativePPM", type: "uint32" },
        ],
        name: "LinkDiscountTooHigh",
        type: "error",
      },
      { inputs: [], name: "LinkNotSet", type: "error" },
      {
        inputs: [
          { internalType: "uint256", name: "have", type: "uint256" },
          { internalType: "uint32", name: "max", type: "uint32" },
        ],
        name: "MsgDataTooBig",
        type: "error",
      },
      {
        inputs: [
          { internalType: "address", name: "proposedOwner", type: "address" },
        ],
        name: "MustBeRequestedOwner",
        type: "error",
      },
      {
        inputs: [{ internalType: "address", name: "owner", type: "address" }],
        name: "MustBeSubOwner",
        type: "error",
      },
      { inputs: [], name: "NoCorrespondingRequest", type: "error" },
      {
        inputs: [{ internalType: "bytes32", name: "keyHash", type: "bytes32" }],
        name: "NoSuchProvingKey",
        type: "error",
      },
      {
        inputs: [
          { internalType: "uint32", name: "have", type: "uint32" },
          { internalType: "uint32", name: "want", type: "uint32" },
        ],
        name: "NumWordsTooBig",
        type: "error",
      },
      { inputs: [], name: "OnlyCallableFromLink", type: "error" },
      { inputs: [], name: "PaymentTooLarge", type: "error" },
      { inputs: [], name: "PendingRequestExists", type: "error" },
      {
        inputs: [{ internalType: "bytes32", name: "keyHash", type: "bytes32" }],
        name: "ProvingKeyAlreadyRegistered",
        type: "error",
      },
      { inputs: [], name: "Reentrant", type: "error" },
      { inputs: [], name: "TooManyConsumers", type: "error" },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "uint16",
            name: "minimumRequestConfirmations",
            type: "uint16",
          },
          {
            indexed: false,
            internalType: "uint32",
            name: "maxGasLimit",
            type: "uint32",
          },
          {
            indexed: false,
            internalType: "uint32",
            name: "stalenessSeconds",
            type: "uint32",
          },
          {
            indexed: false,
            internalType: "uint32",
            name: "gasAfterPaymentCalculation",
            type: "uint32",
          },
          {
            indexed: false,
            internalType: "int256",
            name: "fallbackWeiPerUnitLink",
            type: "int256",
          },
          {
            indexed: false,
            internalType: "uint32",
            name: "fulfillmentFlatFeeNativePPM",
            type: "uint32",
          },
          {
            indexed: false,
            internalType: "uint32",
            name: "fulfillmentFlatFeeLinkDiscountPPM",
            type: "uint32",
          },
          {
            indexed: false,
            internalType: "uint8",
            name: "nativePremiumPercentage",
            type: "uint8",
          },
          {
            indexed: false,
            internalType: "uint8",
            name: "linkPremiumPercentage",
            type: "uint8",
          },
        ],
        name: "ConfigSet",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "coordinatorAddress",
            type: "address",
          },
        ],
        name: "CoordinatorDeregistered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "coordinatorAddress",
            type: "address",
          },
        ],
        name: "CoordinatorRegistered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "uint256",
            name: "requestId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "int256",
            name: "fallbackWeiPerUnitLink",
            type: "int256",
          },
        ],
        name: "FallbackWeiPerUnitLinkUsed",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "FundsRecovered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "uint8",
            name: "mode",
            type: "uint8",
          },
          {
            indexed: false,
            internalType: "uint8",
            name: "coefficient",
            type: "uint8",
          },
        ],
        name: "L1FeeCalculationSet",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "uint256",
            name: "fee",
            type: "uint256",
          },
        ],
        name: "L1GasFee",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "newCoordinator",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
        ],
        name: "MigrationCompleted",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "NativeFundsRecovered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
        ],
        name: "OwnershipTransferRequested",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "bytes32",
            name: "keyHash",
            type: "bytes32",
          },
          {
            indexed: false,
            internalType: "uint64",
            name: "maxGas",
            type: "uint64",
          },
        ],
        name: "ProvingKeyDeregistered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "bytes32",
            name: "keyHash",
            type: "bytes32",
          },
          {
            indexed: false,
            internalType: "uint64",
            name: "maxGas",
            type: "uint64",
          },
        ],
        name: "ProvingKeyRegistered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "requestId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "outputSeed",
            type: "uint256",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint96",
            name: "payment",
            type: "uint96",
          },
          {
            indexed: false,
            internalType: "bool",
            name: "nativePayment",
            type: "bool",
          },
          {
            indexed: false,
            internalType: "bool",
            name: "success",
            type: "bool",
          },
          {
            indexed: false,
            internalType: "bool",
            name: "onlyPremium",
            type: "bool",
          },
        ],
        name: "RandomWordsFulfilled",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "bytes32",
            name: "keyHash",
            type: "bytes32",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "requestId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "preSeed",
            type: "uint256",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint16",
            name: "minimumRequestConfirmations",
            type: "uint16",
          },
          {
            indexed: false,
            internalType: "uint32",
            name: "callbackGasLimit",
            type: "uint32",
          },
          {
            indexed: false,
            internalType: "uint32",
            name: "numWords",
            type: "uint32",
          },
          {
            indexed: false,
            internalType: "bytes",
            name: "extraArgs",
            type: "bytes",
          },
          {
            indexed: true,
            internalType: "address",
            name: "sender",
            type: "address",
          },
        ],
        name: "RandomWordsRequested",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amountLink",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amountNative",
            type: "uint256",
          },
        ],
        name: "SubscriptionCanceled",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "address",
            name: "consumer",
            type: "address",
          },
        ],
        name: "SubscriptionConsumerAdded",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "address",
            name: "consumer",
            type: "address",
          },
        ],
        name: "SubscriptionConsumerRemoved",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "address",
            name: "owner",
            type: "address",
          },
        ],
        name: "SubscriptionCreated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "oldBalance",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "newBalance",
            type: "uint256",
          },
        ],
        name: "SubscriptionFunded",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "oldNativeBalance",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "newNativeBalance",
            type: "uint256",
          },
        ],
        name: "SubscriptionFundedWithNative",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: false,
            internalType: "address",
            name: "to",
            type: "address",
          },
        ],
        name: "SubscriptionOwnerTransferRequested",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "subId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: false,
            internalType: "address",
            name: "to",
            type: "address",
          },
        ],
        name: "SubscriptionOwnerTransferred",
        type: "event",
      },
      {
        inputs: [],
        name: "BLOCKHASH_STORE",
        outputs: [
          {
            internalType: "contract BlockhashStoreInterface",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "LINK",
        outputs: [
          {
            internalType: "contract LinkTokenInterface",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "LINK_NATIVE_FEED",
        outputs: [
          {
            internalType: "contract AggregatorV3Interface",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "MAX_CONSUMERS",
        outputs: [{ internalType: "uint16", name: "", type: "uint16" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "MAX_NUM_WORDS",
        outputs: [{ internalType: "uint32", name: "", type: "uint32" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "MAX_REQUEST_CONFIRMATIONS",
        outputs: [{ internalType: "uint16", name: "", type: "uint16" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "acceptOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "subId", type: "uint256" }],
        name: "acceptSubscriptionOwnerTransfer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "subId", type: "uint256" },
          { internalType: "address", name: "consumer", type: "address" },
        ],
        name: "addConsumer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "subId", type: "uint256" },
          { internalType: "address", name: "to", type: "address" },
        ],
        name: "cancelSubscription",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "createSubscription",
        outputs: [{ internalType: "uint256", name: "subId", type: "uint256" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "target", type: "address" }],
        name: "deregisterMigratableCoordinator",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256[2]",
            name: "publicProvingKey",
            type: "uint256[2]",
          },
        ],
        name: "deregisterProvingKey",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            components: [
              { internalType: "uint256[2]", name: "pk", type: "uint256[2]" },
              { internalType: "uint256[2]", name: "gamma", type: "uint256[2]" },
              { internalType: "uint256", name: "c", type: "uint256" },
              { internalType: "uint256", name: "s", type: "uint256" },
              { internalType: "uint256", name: "seed", type: "uint256" },
              { internalType: "address", name: "uWitness", type: "address" },
              {
                internalType: "uint256[2]",
                name: "cGammaWitness",
                type: "uint256[2]",
              },
              {
                internalType: "uint256[2]",
                name: "sHashWitness",
                type: "uint256[2]",
              },
              { internalType: "uint256", name: "zInv", type: "uint256" },
            ],
            internalType: "struct VRF.Proof",
            name: "proof",
            type: "tuple",
          },
          {
            components: [
              { internalType: "uint64", name: "blockNum", type: "uint64" },
              { internalType: "uint256", name: "subId", type: "uint256" },
              {
                internalType: "uint32",
                name: "callbackGasLimit",
                type: "uint32",
              },
              { internalType: "uint32", name: "numWords", type: "uint32" },
              { internalType: "address", name: "sender", type: "address" },
              { internalType: "bytes", name: "extraArgs", type: "bytes" },
            ],
            internalType: "struct VRFTypes.RequestCommitmentV2Plus",
            name: "rc",
            type: "tuple",
          },
          { internalType: "bool", name: "onlyPremium", type: "bool" },
        ],
        name: "fulfillRandomWords",
        outputs: [{ internalType: "uint96", name: "payment", type: "uint96" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "subId", type: "uint256" }],
        name: "fundSubscriptionWithNative",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "startIndex", type: "uint256" },
          { internalType: "uint256", name: "maxCount", type: "uint256" },
        ],
        name: "getActiveSubscriptionIds",
        outputs: [
          { internalType: "uint256[]", name: "ids", type: "uint256[]" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "subId", type: "uint256" }],
        name: "getSubscription",
        outputs: [
          { internalType: "uint96", name: "balance", type: "uint96" },
          { internalType: "uint96", name: "nativeBalance", type: "uint96" },
          { internalType: "uint64", name: "reqCount", type: "uint64" },
          { internalType: "address", name: "subOwner", type: "address" },
          { internalType: "address[]", name: "consumers", type: "address[]" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256[2]", name: "publicKey", type: "uint256[2]" },
        ],
        name: "hashOfKey",
        outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        stateMutability: "pure",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "subId", type: "uint256" },
          { internalType: "address", name: "newCoordinator", type: "address" },
        ],
        name: "migrate",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
          { internalType: "bytes", name: "data", type: "bytes" },
        ],
        name: "onTokenTransfer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "subId", type: "uint256" }],
        name: "ownerCancelSubscription",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "subId", type: "uint256" }],
        name: "pendingRequestExists",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "to", type: "address" }],
        name: "recoverFunds",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address payable", name: "to", type: "address" },
        ],
        name: "recoverNativeFunds",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "target", type: "address" }],
        name: "registerMigratableCoordinator",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256[2]",
            name: "publicProvingKey",
            type: "uint256[2]",
          },
          { internalType: "uint64", name: "maxGas", type: "uint64" },
        ],
        name: "registerProvingKey",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "subId", type: "uint256" },
          { internalType: "address", name: "consumer", type: "address" },
        ],
        name: "removeConsumer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            components: [
              { internalType: "bytes32", name: "keyHash", type: "bytes32" },
              { internalType: "uint256", name: "subId", type: "uint256" },
              {
                internalType: "uint16",
                name: "requestConfirmations",
                type: "uint16",
              },
              {
                internalType: "uint32",
                name: "callbackGasLimit",
                type: "uint32",
              },
              { internalType: "uint32", name: "numWords", type: "uint32" },
              { internalType: "bytes", name: "extraArgs", type: "bytes" },
            ],
            internalType: "struct VRFV2PlusClient.RandomWordsRequest",
            name: "req",
            type: "tuple",
          },
        ],
        name: "requestRandomWords",
        outputs: [
          { internalType: "uint256", name: "requestId", type: "uint256" },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "subId", type: "uint256" },
          { internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "requestSubscriptionOwnerTransfer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "s_config",
        outputs: [
          {
            internalType: "uint16",
            name: "minimumRequestConfirmations",
            type: "uint16",
          },
          { internalType: "uint32", name: "maxGasLimit", type: "uint32" },
          { internalType: "bool", name: "reentrancyLock", type: "bool" },
          { internalType: "uint32", name: "stalenessSeconds", type: "uint32" },
          {
            internalType: "uint32",
            name: "gasAfterPaymentCalculation",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "fulfillmentFlatFeeNativePPM",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "fulfillmentFlatFeeLinkDiscountPPM",
            type: "uint32",
          },
          {
            internalType: "uint8",
            name: "nativePremiumPercentage",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "linkPremiumPercentage",
            type: "uint8",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "s_currentSubNonce",
        outputs: [{ internalType: "uint64", name: "", type: "uint64" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "s_fallbackWeiPerUnitLink",
        outputs: [{ internalType: "int256", name: "", type: "int256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "s_l1FeeCalculationMode",
        outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "s_l1FeeCoefficient",
        outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "s_provingKeyHashes",
        outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        name: "s_provingKeys",
        outputs: [
          { internalType: "bool", name: "exists", type: "bool" },
          { internalType: "uint64", name: "maxGas", type: "uint64" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "s_requestCommitments",
        outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "s_totalBalance",
        outputs: [{ internalType: "uint96", name: "", type: "uint96" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "s_totalNativeBalance",
        outputs: [{ internalType: "uint96", name: "", type: "uint96" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint16",
            name: "minimumRequestConfirmations",
            type: "uint16",
          },
          { internalType: "uint32", name: "maxGasLimit", type: "uint32" },
          { internalType: "uint32", name: "stalenessSeconds", type: "uint32" },
          {
            internalType: "uint32",
            name: "gasAfterPaymentCalculation",
            type: "uint32",
          },
          {
            internalType: "int256",
            name: "fallbackWeiPerUnitLink",
            type: "int256",
          },
          {
            internalType: "uint32",
            name: "fulfillmentFlatFeeNativePPM",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "fulfillmentFlatFeeLinkDiscountPPM",
            type: "uint32",
          },
          {
            internalType: "uint8",
            name: "nativePremiumPercentage",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "linkPremiumPercentage",
            type: "uint8",
          },
        ],
        name: "setConfig",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint8", name: "mode", type: "uint8" },
          { internalType: "uint8", name: "coefficient", type: "uint8" },
        ],
        name: "setL1FeeCalculation",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "link", type: "address" },
          { internalType: "address", name: "linkNativeFeed", type: "address" },
        ],
        name: "setLINKAndLINKNativeFeed",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "to", type: "address" }],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "recipient", type: "address" },
        ],
        name: "withdraw",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address payable",
            name: "recipient",
            type: "address",
          },
        ],
        name: "withdrawNative",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
  },
};

// !only supporting mainnet
export const tronConfigs = {
  AVAILABLE_PAYMENT_METHODS: ["USDT", "BTC"] as const,
  NETWORKS: {
    MAINNET: "0x2b6653dc",
    DEVNET: "0xcd8690dc",
  },
  CHAIN_ID: [`tron:0x2b6653dc`],
  HOST: "https://api.trongrid.io/",
  TICKET_PAYMENT: {
    CONTRACT_ADDRESS: "TV64kS6H3DcEMxz1Gy3haVvD9ruXSupe8r",
    ABI: [
      {
        inputs: [
          { name: "initialOwner", type: "address" },
          { name: "_verifierAddress", type: "address" },
        ],
        stateMutability: "Nonpayable",
        type: "Constructor",
      },
      {
        inputs: [{ name: "owner", type: "address" }],
        name: "OwnableInvalidOwner",
        type: "Error",
      },
      {
        inputs: [{ name: "account", type: "address" }],
        name: "OwnableUnauthorizedAccount",
        type: "Error",
      },
      { name: "ReentrancyGuardReentrantCall", type: "Error" },
      {
        inputs: [{ name: "token", type: "address" }],
        name: "SafeERC20FailedOperation",
        type: "Error",
      },
      {
        inputs: [
          { indexed: true, name: "previousOwner", type: "address" },
          { indexed: true, name: "newOwner", type: "address" },
        ],
        name: "OwnershipTransferred",
        type: "Event",
      },
      {
        inputs: [
          { indexed: true, name: "buyer", type: "address" },
          { indexed: true, name: "paymentToken", type: "address" },
          { name: "cost", type: "uint256" },
        ],
        name: "Payment",
        type: "Event",
      },
      {
        outputs: [{ type: "address" }],
        name: "owner",
        stateMutability: "View",
        type: "Function",
      },
      {
        inputs: [
          { name: "_signature", type: "bytes" },
          { name: "nonce", type: "uint256" },
          { name: "_paymentToken", type: "address" },
          { name: "_cost", type: "uint256" },
        ],
        name: "payment",
        stateMutability: "Payable",
        type: "Function",
      },
      {
        outputs: [{ type: "bool" }],
        inputs: [{ type: "address" }, { type: "uint256" }],
        name: "processedNonces",
        stateMutability: "View",
        type: "Function",
      },
      {
        name: "renounceOwnership",
        stateMutability: "Nonpayable",
        type: "Function",
      },
      {
        inputs: [{ name: "_paymentToken", type: "address" }],
        name: "setPaymentMethod",
        stateMutability: "Nonpayable",
        type: "Function",
      },
      {
        inputs: [{ name: "_treasuryAddress", type: "address" }],
        name: "setTreasury",
        stateMutability: "Nonpayable",
        type: "Function",
      },
      {
        outputs: [{ type: "bool" }],
        inputs: [{ type: "address" }],
        name: "supportedPaymentMethods",
        stateMutability: "View",
        type: "Function",
      },
      {
        inputs: [{ name: "newOwner", type: "address" }],
        name: "transferOwnership",
        stateMutability: "Nonpayable",
        type: "Function",
      },
      {
        outputs: [{ type: "address" }],
        name: "treasury",
        stateMutability: "View",
        type: "Function",
      },
      { stateMutability: "Payable", type: "Receive" },
    ],
  },
  USDT: {
    CONTRACT_ADDRESS: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
    DIGITS: 6,
    ABI: [
      {
        constant: true,
        inputs: [],
        name: "name",
        outputs: [{ name: "", type: "string" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "_upgradedAddress", type: "address" }],
        name: "deprecate",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "_spender", type: "address" },
          { name: "_value", type: "uint256" },
        ],
        name: "approve",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "deprecated",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "_evilUser", type: "address" }],
        name: "addBlackList",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "totalSupply",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "_from", type: "address" },
          { name: "_to", type: "address" },
          { name: "_value", type: "uint256" },
        ],
        name: "transferFrom",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "upgradedAddress",
        outputs: [{ name: "", type: "address" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [{ name: "", type: "uint8" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "maximumFee",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "_totalSupply",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [],
        name: "unpause",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "_maker", type: "address" }],
        name: "getBlackListStatus",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "paused",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "_spender", type: "address" },
          { name: "_subtractedValue", type: "uint256" },
        ],
        name: "decreaseApproval",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "who", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "_value", type: "uint256" }],
        name: "calcFee",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [],
        name: "pause",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "owner",
        outputs: [{ name: "", type: "address" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "symbol",
        outputs: [{ name: "", type: "string" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "_to", type: "address" },
          { name: "_value", type: "uint256" },
        ],
        name: "transfer",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "who", type: "address" }],
        name: "oldBalanceOf",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "newBasisPoints", type: "uint256" },
          { name: "newMaxFee", type: "uint256" },
        ],
        name: "setParams",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "amount", type: "uint256" }],
        name: "issue",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "_spender", type: "address" },
          { name: "_addedValue", type: "uint256" },
        ],
        name: "increaseApproval",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "amount", type: "uint256" }],
        name: "redeem",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          { name: "_owner", type: "address" },
          { name: "_spender", type: "address" },
        ],
        name: "allowance",
        outputs: [{ name: "remaining", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "basisPointsRate",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "", type: "address" }],
        name: "isBlackListed",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "_clearedUser", type: "address" }],
        name: "removeBlackList",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "MAX_UINT",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "newOwner", type: "address" }],
        name: "transferOwnership",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [{ name: "_blackListedUser", type: "address" }],
        name: "destroyBlackFunds",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { name: "_initialSupply", type: "uint256" },
          { name: "_name", type: "string" },
          { name: "_symbol", type: "string" },
          { name: "_decimals", type: "uint8" },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, name: "_blackListedUser", type: "address" },
          { indexed: false, name: "_balance", type: "uint256" },
        ],
        name: "DestroyedBlackFunds",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "amount", type: "uint256" }],
        name: "Issue",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "amount", type: "uint256" }],
        name: "Redeem",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, name: "newAddress", type: "address" }],
        name: "Deprecate",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: true, name: "_user", type: "address" }],
        name: "AddedBlackList",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [{ indexed: true, name: "_user", type: "address" }],
        name: "RemovedBlackList",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: false, name: "feeBasisPoints", type: "uint256" },
          { indexed: false, name: "maxFee", type: "uint256" },
        ],
        name: "Params",
        type: "event",
      },
      { anonymous: false, inputs: [], name: "Pause", type: "event" },
      { anonymous: false, inputs: [], name: "Unpause", type: "event" },
      {
        anonymous: false,
        inputs: [
          { indexed: true, name: "previousOwner", type: "address" },
          { indexed: true, name: "newOwner", type: "address" },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, name: "owner", type: "address" },
          { indexed: true, name: "spender", type: "address" },
          { indexed: false, name: "value", type: "uint256" },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, name: "from", type: "address" },
          { indexed: true, name: "to", type: "address" },
          { indexed: false, name: "value", type: "uint256" },
        ],
        name: "Transfer",
        type: "event",
      },
    ],
  },
};

export const solanaConfigs = {
  AVAILABLE_PAYMENT_METHODS: ["SOL", "USDC", "USDT", "BTC"] as const,
  NETWORKS: {
    MAINNET:
      "https://wandering-frosty-snowflake.solana-mainnet.quiknode.pro/6f3d3a483f48802783b280258d3f782b45bacb6c",
    DEVNET: "https://api.devnet.solana.com",
  },
  RPC: isProduction
    ? "https://wandering-frosty-snowflake.solana-mainnet.quiknode.pro/6f3d3a483f48802783b280258d3f782b45bacb6c"
    : "https://api.devnet.solana.com",
  CLUSTER_API_URL: isProduction ? "mainnet-beta" : "devnet",
  TICKET_PAYMENT: {
    CONTRACT_ADDRESS: isProduction
      ? "4vXN3fFMy1rX2pw8Xd2117VjAyPwZTTL2UC8wvuBydmJ"
      : "CvHW5jh9wgvW7PUh1QJw8F4x6Fg3mmgfT753f9Sfjjh2",
  },
  ACCOUNTS: {
    STATE: isProduction
      ? "2sTr8biRPuqTySUnjXxkvsJQNBNfVMGxRL5iiN4SFKJ6"
      : "7EABanH95JcgQbp6HBeaATDXf7GSVGmaiZKtDefGUScA",
    TREASURY: isProduction
      ? "3n2N7P4ZrgVzfaaLPwBiU1KXRDVXUgSfhfA9MbBST9LR"
      : "dzQeR3D3aWhV4Z1EJbYvWE3GaSiUZwjBmtm7aWQyWqP",
    SYSTEM_PROGRAM: "11111111111111111111111111111111",
    TOKEN_PROGRAM: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA",
  },
  USDC: {
    DIGITS: 6,
    CONTRACT_ADDRESS: isProduction
      ? "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
      : "FNcErxN3wWdm5qaMWBmuyurrm7PETSa1ukVWPUdXAALP",
    TREASURY_TOKEN_ACCOUNT_ADDRESS: isProduction
      ? "DM4qup9r9ZNcTCMufouYjzJnvdx1vFDe1KZMzY6B3mgo"
      : "6Bz5phviW1gZeD17YLEMshZ2BuGsCPrGgwp9fBcUzyen",
  },
  // !only supporting mainnet
  USDT: {
    DIGITS: 6,
    CONTRACT_ADDRESS: "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
    TREASURY_TOKEN_ACCOUNT_ADDRESS:
      "BUx34GfjsvaguCVg9GoxXizaWSvNrCewESZjprihKdLK",
  },
  // !only supporting mainnet
  DAI: {
    DIGITS: 8,
    CONTRACT_ADDRESS: "EjmyN6qEC1Tf1JxiG1ae7UTJhUxSwk1TCWNWqxWV4J6o",
    TREASURY_TOKEN_ACCOUNT_ADDRESS:
      "4PypmNDCkEmxZTxU44fvp2mDS7vbBkGrRWu4WSPoaP1F",
  },
};
